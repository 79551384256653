import { Link } from "react-router-dom";
import { IAffiliate, redirectOptions, statusOptions } from "../../models/IAffiliates";
import { formatDateFullTime, formatOption } from "../../utils/formats";
import CheckboxField from "../common/form/CheckboxField";
import { ColumnDef, PaginationType } from "../common/tables/types";

type IColumn = ColumnDef<IAffiliate>;

interface Options {
  pagination: PaginationType;
  handleChangeStatus: (status: boolean, id: string) => void;
}

export default function getColumnDefsAffiliates(options: Options): IColumn[] {
  const { pagination, handleChangeStatus } = options;

  const numberIndex: IColumn = {
    Cell: ({ row: { index } }: { row: { index: number } }) => {
      return <div>{index + (pagination.from || 1)}</div>;
    },
    Header: "№",
    id: "№",
    disableFilters: true,
    disableSortBy: true,
    width: "25px",
  };

  const name: IColumn = {
    Cell: (cell) => (
      <Link className="font-semibold" to={`/affiliates/${cell.row.original.id}`}>
        {cell.value}
      </Link>
    ),
    Header: "Name",
    id: "name",
    accessor: "name",
  };

  const affiliate: IColumn = {
    Header: "Affiliate",
    id: "affiliate",
    accessor: "affiliate",
  };

  const price: IColumn = {
    Header: "Price",
    id: "price",
    accessor: "price",
    width: "60px",
  };

  const duplicate: IColumn = {
    Cell: (cell) => (
      <span>
        {formatOption<string>(cell.row.original.isAcceptDuplicate.toString(), statusOptions)}/
        {cell.value}
      </span>
    ),
    Header: "Duplicate",
    id: "duplicate",
    accessor: "afterDays",
    width: "120px",
  };

  const redirect: IColumn = {
    Cell: (cell) => <span>{formatOption<string>(cell.value.toString(), redirectOptions)}</span>,
    Header: "Redirect",
    id: "redirect",
    accessor: "isShowRedirect",
    width: "100px",
  };

  const time: IColumn = {
    Cell: (cell) => <span>{cell.row.original.startTime + "/" + cell.value}</span>,
    Header: "Time",
    id: "time",
    accessor: "endTime",
  };

  const status: IColumn = {
    Cell: (cell) => (
      <CheckboxField
        value={Boolean(cell.value)}
        onChange={(value: boolean) => handleChangeStatus(value, cell.row.original.id)}
      />
    ),
    Header: "Status",
    id: "status",
    accessor: "status",
    width: "60px",
  };

  const isSelf: IColumn = {
    Cell: (cell) => <CheckboxField value={Boolean(cell.value)} onChange={() => {}} />,
    Header: "IsSelf",
    id: "isSelf",
    accessor: "isSelf",
    width: "60px",
  };

  const balanceAt: IColumn = {
    Cell: (cell) => <span>{formatDateFullTime(cell.value)}</span>,
    Header: "Balance",
    id: "balance",
    accessor: "balanceAt",
  };

  const createdAt: IColumn = {
    Cell: (cell) => <span>{formatDateFullTime(cell.value)}</span>,
    Header: "Created",
    id: "createdAt",
    accessor: "createdAt",
  };

  return [
    numberIndex,
    name,
    affiliate,
    price,
    duplicate,
    redirect,
    time,
    isSelf,
    status,
    balanceAt,
    createdAt,
  ];
}
