import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { camelizeKeys, decamelizeKeys } from "humps";
import {
  IReplacementsStatisticAffiliates,
  IReplacementsStatisticClients,
  ReplacementsClientsFiltersRequest,
  ReplacementsFilters,
  ReplacementsStatisticRequest,
} from "../models/IStatistics";
import { ListResWithoutPag, baseURL, filterEmpyValues, prepareHeaders } from "./utils";
const baseUrl = `${baseURL}/v1/statistics/replacement`;

const formatSendingData = (data: ReplacementsFilters): ReplacementsStatisticRequest => {
  const filter: ReplacementsClientsFiltersRequest = {
    ...filterEmpyValues(data),
    localizationId: data.localization?.map(item => item?.id).join(","),
    affiliateId: data.affiliate?.id,
    "client.type": data.clientType?.value,
  };

  delete filter.clientType;
  delete filter.affiliate;
  delete filter.localization;

  return decamelizeKeys({
    filter,
    type: "query",
  }) as ReplacementsStatisticRequest;
};

export const statisticsReplacementsApi = createApi({
  reducerPath: "statisticsReplacementsApi",
  tagTypes: ["StatisticsReplacements"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    getReplacemetsClients: build.mutation<IReplacementsStatisticClients[], ReplacementsFilters>({
      query: (data) => ({
        url: "clients",
        method: "post",
        body: formatSendingData(data),
      }),
      transformResponse: (response: ListResWithoutPag<IReplacementsStatisticClients>) => camelizeKeys(response.list) as IReplacementsStatisticClients[],
    }),
    getReplacemetsAffiliates: build.mutation<IReplacementsStatisticAffiliates[], ReplacementsFilters>({
      query: (data) => ({
        url: "affiliates",
        method: "post",
        body: formatSendingData(data),
      }),
      transformResponse: (response: ListResWithoutPag<IReplacementsStatisticAffiliates>) => camelizeKeys(response.list) as IReplacementsStatisticAffiliates[],
    }),
  }),
});

export const {
  useGetReplacemetsClientsMutation,
  useGetReplacemetsAffiliatesMutation,
} = statisticsReplacementsApi;
