import React from "react";
import Table from "../../../components/common/tables/Table";
import { SettingsTableKey } from "../../../models/IUserSettings";
import { IReplacementsStatisticClients, ReplacementsFilters } from "../../../models/IStatistics";
import Loading from "../../_layouts/Loading";
import useGetStatisticClientsColumnDefs from "./columnDefs";

interface Props {
  data?: IReplacementsStatisticClients[],
  isLoading: boolean,
  filter: ReplacementsFilters
}

const StatisticClients = ({ data, isLoading, filter }: Props) => {
  const columns = useGetStatisticClientsColumnDefs(filter);

  if (isLoading || !data) return <Loading />;

  return (
    <div className="mb-4 card">
      <div className="card-header py-3">
        <p className="card-heading">Clients</p>
      </div>
      <div className="card-body table-responsive">
        <Table<IReplacementsStatisticClients>
          pageKey={SettingsTableKey.REPORTS_CLIENT}
          columns={columns}
          containerClass="overflow-auto"
          fixed
          items={data}
        />
      </div>
    </div>
  );
};

export default StatisticClients;
