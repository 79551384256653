import React from "react";
import Sprite from "../../../assets/icons/orion-svg-sprite.svg";

interface Props {
  class?: string;
  name: string;
}

const Icon = (props: Props) => {
  const className = props.class || "svg-icon-md";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={`svg-icon ${className}`}
    >
      <use xlinkHref={`${Sprite}#${props.name}`} />
    </svg>
  );
};

export default Icon;
