import cn from "classnames";
import { FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import React from "react";
import { Button } from "react-bootstrap";
import { IField, IFieldBlock, TypesOfInput } from "../../../../models/fields/IField";
import { handleCopy } from "../../../../utils/formats";
import Icon from "../../items/Icon";
import Field from "./Field";

const FieldBlock = <T extends object>({
  size,
  fields,
  title,
  isList,
  isCopiedValue,
  name,
  isHidden,
}: IFieldBlock<T>) => {
  const { values }: { values: { [k: string]: string[] } } = useFormikContext();

  const getAddingValues: { [k: string]: string } = {};
  if (isList) {
    fields.forEach((field) => (getAddingValues[field.name.split(".")[1]] = ""));
  }

  const getFieldOptions = (name: string): IField<T> | undefined => {
    return fields.find((field) => field.name === name);
  };

  const fieldNames = fields.map((field) => field.name);

  const renderFieldPack = (name: string, arrayHelpers: FieldArrayRenderProps, isCopiedValue?: boolean) => {
    return Object.values(values[name]).map((field, index) => {
      const value = values[name][index];
      const arrayKeys = Object.keys(field);
      const lastKey = arrayKeys[arrayKeys.length -1];
      let valueToCopy = ''

      if (values[name][index] && values[name][index][lastKey as FixMeLater]) {
        if (typeof value === 'object' && (
          values[name][index][lastKey as FixMeLater] as unknown as { value: boolean | string | number, label: string }
        ).value) {
          valueToCopy = (
            values[name][index][lastKey as FixMeLater] as unknown as { value: boolean | string | number, label: string }
          ).value.toString()
        } else {
          valueToCopy = values[name][index][lastKey as FixMeLater].toString();
        }
      }

      return (
        <React.Fragment key={index}>
          <div className="row align-items-center">
            <div className="col-9">
              <div className="row">
                {Object.keys(field).map(
                  (subName) =>
                    fieldNames.includes(`${name}.${subName}`) && (
                      <Field
                        type={TypesOfInput.TEXT}
                        {...getFieldOptions(`${name}.${subName}`)}
                        key={subName}
                        name={`${name}[${index}][${subName}]`}
                      />
                    ),
                )}
              </div>
            </div>
            <div className="col-3 row">
              {isCopiedValue && valueToCopy && (
                <div className="col-6">
                  <Button
                    className="mb-3 w-auto"
                    onClick={() => handleCopy(valueToCopy.toString())}
                    >
                    <Icon name="hidden-file-1" class="svg-icon-sm" />
                  </Button>
                </div>
              )}
              <div className="col-6">
                <Button
                  className="mb-3 w-auto"
                  variant="danger"
                  onClick={() => arrayHelpers.remove(index)}
                  >
                  <Icon name="bin-1" class="svg-icon-sm" />
                </Button>
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    });
  };

  if (isHidden) return null;

  return (
    <div className={cn(`col-12 mb-3 col-md-${size || 12}`)}>
      <div className="row">
        {title && <h4>{title}</h4>}
        {isList && name ? (
          <FieldArray
            name={name || ""}
            render={(arrayHelpers) => (
              <>
                {values[name] && renderFieldPack(name, arrayHelpers, isCopiedValue)}
                <Button
                  className="ms-2.5 w-auto mb-3"
                  variant="primary"
                  onClick={() => arrayHelpers.push(getAddingValues)}
                >
                  Add {name}
                </Button>
              </>
            )}
          />
        ) : (
          fields.map((field) => <Field key={field.name} {...field} />)
        )}
      </div>
    </div>
  );
};

export default FieldBlock;
