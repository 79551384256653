import * as Yup from "yup";
import { IAllFields, TypesOfInput } from "../../models/fields/IField";
import { AsyncObject } from "../../models/fields/ISelect";
import { IClient } from "../../models/IClients";
import { ITeam } from "../../models/ITeam";
import { IUserChange, IUserFilter, roleOptions } from "../../models/IUsers";

export const validationSchema = (isEditMode: boolean) =>
  Yup.object<Record<keyof Omit<IUserChange, "id">, Yup.AnySchema>>({
    team: Yup.object().typeError("Required"),
    roles: Yup.object().typeError("Required"),
    name: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    surname: Yup.string().nullable(),
    password: isEditMode ? Yup.string() : Yup.string().required("Required").min(6),
  });

interface Params {
  asyncTeams: AsyncObject;
  teamOptions: ITeam[];
  asyncClients: AsyncObject;
  clientsOptions: IClient[];
}

export const createFields = ({ asyncTeams, teamOptions, asyncClients, clientsOptions }: Params): IAllFields<IUserChange>[] => [
  {
    name: "team",
    type: TypesOfInput.SELECT,
    options: teamOptions,
    async: asyncTeams,
    valueName: "id",
    labelName: "name",
  },
  {
    name: "roles",
    type: TypesOfInput.SELECT,
    options: roleOptions,
  },
  {
    isMulti: true,
    name: "clients",
    type: TypesOfInput.SELECT,
    options: clientsOptions,
    async: asyncClients,
    valueName: "id",
    labelName: "name",
  },
  { name: "name" },
  { name: "email" },
  { name: "surname" },
];

export const filterFields = (): IAllFields<IUserFilter>[] => [
  {
    name: "roles",
    type: TypesOfInput.SELECT,
    options: roleOptions,
  },
  { name: "email" },
];
