import { Api, filterEmpyValues, ListRes, ModelRes } from "./utils";
import { getResponseData } from "./utils";
import { IApplicationsFiltersRequest, IApplications } from "../models/IApplications";

const baseURL = "/v1/applications";

export const getList = (params: IApplicationsFiltersRequest): Promise<ListRes<IApplications>> => {
  return Api.post(baseURL + "/list", filterEmpyValues(params)).then(getResponseData);
};

export const getItem = (id: string): Promise<ModelRes<IApplications>> => {
  return Api.get(`${baseURL}/${id}`).then(getResponseData);
};
