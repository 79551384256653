import * as Yup from "yup";
import { IStatusChange, statusOptions, typeOptions } from '../../models/IStatus';
import { YupValidationFields } from "../../types/global";
import { IAllFields, TypesOfInput } from "../../models/fields/IField";

export const validationSchema = Yup.object<YupValidationFields<IStatusChange>>({
  status: Yup.string().required("Required"),
  system: Yup.object().typeError("Required"),
  type: Yup.object().nullable(),
});

export const createFields: IAllFields<IStatusChange>[] = [
  {
    label: "Status",
    name: "status",
    placeholder: "Status",
    type: TypesOfInput.TEXT,
  },
  {
    label: "System",
    name: "system",
    options: statusOptions,
    placeholder: "System",
    type: TypesOfInput.SELECT,
  },
  {
    label: "Type",
    name: "type",
    options: typeOptions,
    placeholder: "Type",
    type: TypesOfInput.SELECT,
  },
];
