import { baseURL, prepareHeaders } from "./utils";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { decamelizeKeys } from "humps";
import { Advertisements, AdvertisementsRequest } from "../models/Advertisements";

const baseUrl = `${baseURL}/v1/advertisements`;
const tagType = "Advertisements";

export const advertisementsApi = createApi({
  reducerPath: "advertisementsApi",
  tagTypes: [tagType],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    getAdvertisements: build.query<Advertisements, AdvertisementsRequest>({
      query: ({ filter, key }) => ({
        url: "list",
        method: "POST",
        body: decamelizeKeys({
          filter,
          key,
          type: "query",
        }),
      }),
      providesTags: (result, error, arg) => [{ type: tagType, id: arg.key }],
    }),
  }),
});

export const { useGetAdvertisementsQuery } = advertisementsApi;
