import { ITransaction, ITransactionChange, ITransactionCreateRequest, MODEL_TYPE, RequestTransaction } from "../models/ITransactions";
import { Api, filterEmpyValues, ListRes } from "./utils";
import { getResponseData } from "./utils";
import { toast } from "react-toastify";

const PATH = "/v1/transactions";

export const getTransactions = (params: RequestTransaction): Promise<ListRes<ITransaction>> => (
  Api.post(`${PATH}/list`, filterEmpyValues(params)).then(getResponseData)
);

export const createTransaction = (data: ITransactionChange) => {
  const sendingData: ITransactionCreateRequest = {
    ...data,
    clientId: data.client?.id || "",
    type: data.type?.value || "",
    modelId: data.model?.id || "",
    modelType: MODEL_TYPE,
  };
  delete sendingData.model;
  delete sendingData.client;

  return Api.post(PATH, sendingData).then(() => {
    toast.success("Transaction successfully created");
    window.location.pathname = "/transactions";
  });
};
