import { Link } from "react-router-dom";
import { sendOneItem } from "../../api/push-api";
import { IPush } from "../../models/IPush";
import { formatDateFullTime } from "../../utils/formats";
import Label from "../common/items/Label";
import { ColumnDef, PaginationType } from "../common/tables/types";

type IColumn = ColumnDef<IPush>;

interface Options {
  pagination: PaginationType;
}

export default function getColumnDefs(options: Options): IColumn[] {
  const { pagination } = options;

  const numberIndex: IColumn = {
    Cell: ({ row: { index } }: { row: { index: number } }) => {
      return <div>{index + (pagination.from || 1)}</div>;
    },
    Header: "№",
    id: "№",
    disableFilters: true,
    disableSortBy: true,
    width: "25px",
  };

  const title: IColumn = {
    Cell: (cell) => (
      <Link className="font-semibold" to={`/pushes/${cell.row.original.id}`}>
        {cell.value}
      </Link>
    ),
    Header: "Title",
    id: "title",
    accessor: "title",
    width: "130px",
  };

  const createdAt: IColumn = {
    Cell: (cell) => <span>{formatDateFullTime(cell.value)}</span>,
    Header: "Created",
    id: "createdAt",
    accessor: "createdAt",
    width: "95px",
  };

  const isSend: IColumn = {
    Cell: (cell) => <Label status={Boolean(cell.value)} />,
    Header: "Is send",
    id: "isSend",
    accessor: "isSend",
    width: "50px",
  };

  const locale: IColumn = {
    Header: "Locale",
    id: "locale",
    accessor: "locale",
  };

  const status: IColumn = {
    Header: "Status",
    id: "status",
    accessor: "status",
  };

  const type: IColumn = {
    Header: "Type",
    id: "type",
    accessor: "type",
  };

  const offer: IColumn = {
    Header: "Offer",
    id: "offer",
    Cell: (cell) => (
      <span>
        {cell.value ? (
          <Link className="font-semibold" to={`/offers/${cell.value.id}`}>
            {cell.value.title}
          </Link>
        ) : (
          "No"
        )}
      </span>
    ),

    accessor: "offer",
    width: "120px",
  };

  const send: IColumn = {
    Header: "Send",
    id: "send",
    Cell: (cell) => (
      <span>
        {cell.value === "single" ? (
          <span
            className="Label Label--active "
            role="button"
            onClick={() => sendOneItem(cell.row.original.id)}
          >
            Send
          </span>
        ) : (
          "No"
        )}
      </span>
    ),
    accessor: "type",
  };

  return [numberIndex, title, offer, locale, type, status, send, isSend, createdAt];
}
