import * as Yup from "yup";
import { IAllFields, TypesOfInput } from "../../models/fields/IField";
import { AsyncObject, GenericOption, OptionModel } from "../../models/fields/ISelect";
import { ICountry } from "../../models/ILocalizations";
import {
  IOffer,
  IOfferChange,
  IOfferFilters,
  OfferType,
  offerTypeOptions,
} from "../../models/IOffer";
import { IReview } from "../../models/IReview";
import { ITag } from "../../models/ITag";
import { FilterConditionally } from "../../types/global";
import { checkFileSize, fieldsConstructor } from "../../utils/formats";

export const getValidation = (localizations: string[]) => {
  type YupObject = {
    [key: string]: Yup.StringSchema<string | undefined, UnknownRecord, string | undefined>;
  };
  const object: YupObject = {};
  localizations.forEach((localization) => {
    const schema = Yup.string().test("check localizations", "Required", (_, context) => {
      return !!Object.values(context.parent).filter(Boolean).length;
    });
    object[localization] = schema;
  });

  type NewType = string &
    keyof FilterConditionally<
      IOfferChange,
      string | File | null | OptionModel<GenericOption<OfferType>>
    >;

  return Yup.object<Record<NewType, Yup.AnySchema>>({
    title: Yup.string().required("Required"),
    type: Yup.object().typeError("Required"),
    domain: Yup.string().required("Required"),
    color: Yup.string().required("Required"),
    background: Yup.string().required("Required"),
    logo: Yup.mixed()
      .test("fileSize", "Required", (value) => {
        if (!value && typeof value !== "string") return false;
        return true;
      })
      .test("fileSize", "The file is too large", checkFileSize),
    footer: Yup.mixed()
      .test("fileSize", "Required", (value) => {
        if (!value && typeof value !== "string") return false;
        return true;
      })
      .test("fileSize", "The file is too large", checkFileSize),
  });
};

const filterFielsNames = (): (keyof IOfferFilters)[] => [
  "title",
  "domain",
  // "background",
  // "color",
];

interface Params {
  asyncReviews: AsyncObject;
  reviewOptions: IReview[];
  asyncTags: AsyncObject;
  asyncOffers: AsyncObject;
  tagOptions: ITag[];
  offerOptions: IOffer[];
  selectedCountry: ICountry | null;
}

const fieldNames = ({
  asyncReviews,
  reviewOptions,
  tagOptions,
  offerOptions,
  asyncOffers,
  asyncTags,
  selectedCountry,
}: Params): (keyof IOfferChange | IAllFields<IOfferChange>)[] => [
  ...(filterFielsNames() as (keyof IOfferChange)[]),
  {
    name: "background",
    type: TypesOfInput.COLOR_PICKER,
    size: 2,
  },
  {
    name: "color",
    type: TypesOfInput.COLOR_PICKER,
    size: 2,
  },
  {
    name: "reviews",
    type: TypesOfInput.SELECT,
    options: reviewOptions,
    async: asyncReviews,
    isMulti: true,
    valueName: "id",
    labelName: "slug",
  },
  {
    name: "tags",
    type: TypesOfInput.SELECT,
    options: tagOptions,
    async: asyncTags,
    isMulti: true,
    valueName: "id",
    labelName: "slug",
  },
  {
    name: "similar",
    type: TypesOfInput.SELECT,
    options: offerOptions,
    async: asyncOffers,
    isMulti: true,
    valueName: "id",
    labelName: "title",
  },
  {
    name: "type",
    type: TypesOfInput.SELECT,
    options: offerTypeOptions,
  },
  {
    label: `Bonus  ${selectedCountry?.flag}`,
    name: "bonus",
    prefix: true,
  },
  {
    label: `Preview  ${selectedCountry?.flag}`,
    name: "preview",
    prefix: true,
  },
  {
    label: `Download Title  ${selectedCountry?.flag}`,
    name: "download",
    prefix: true,
  },
  {
    label: `Download Subtitle  ${selectedCountry?.flag}`,
    name: "subtitle",
    prefix: true,
  },
  {
    label: `Description  ${selectedCountry?.flag}`,
    name: "description",
    prefix: true,
    type: TypesOfInput.TEXT_EDITOR,
    size: 12,
  },
  {
    name: "logo",
    type: TypesOfInput.FILE,
    size: 6,
  },
  {
    name: "footer",
    type: TypesOfInput.FILE,
    size: 6,
  },
  {
    label: `Images  ${selectedCountry?.flag}`,
    name: "images",
    type: TypesOfInput.FILE,
    isMulti: true,
    prefix: true,
    size: 12,
  },
];

export const createFields = (params: Params) => {
  return fieldsConstructor<IOfferChange>(fieldNames(params));
};

export const filterFields = () => {
  return fieldsConstructor<IOfferFilters>(filterFielsNames());
};
