import React, { useEffect, useMemo, useState } from "react";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import Field from "../../components/common/form/formik/Field";
import PageTitle from "../../components/common/items/page-title";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { editLabel, fetchLabels } from "../../store/thunks/labels";
import { emptyValues, ILabelCreate } from "../../models/ILabels";
import { createLabelFields, createLabelValidation } from "../../components/fields/create-label";

const CreateLabel = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState<ILabelCreate>(emptyValues);
  const { labels } = useAppSelector((state) => state.labelsReducer);

  useEffect(() => {
    if (id) {
      if (!labels.data.length) {
        dispatch(fetchLabels({}));
      }
      const currentLabel = labels.data.find((label) => label.id === id);
      setInitialValues(currentLabel || emptyValues);
    }
  }, [id, labels]);

  const fields = useMemo(() => createLabelFields(), []);

  const handleSubmit = (values: ILabelCreate) => {
    dispatch(editLabel(values));
  };

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <Link to="/labels" className="w-auto">
          <Button className="w-auto mb-3" variant="primary">
            Back
          </Button>
        </Link>
        <PageTitle title="Creating label" />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={createLabelValidation}
                onSubmit={handleSubmit}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {fields.map((field) => (
                      <Field key={field.name} {...field} />
                    ))}
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                  >
                    Create
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CreateLabel;
