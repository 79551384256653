import * as Yup from "yup";
import { IAffiliateChange, statusOptions } from "../../models/IAffiliates";
import { IAllFields, TypesOfInput } from "../../models/fields/IField";
import { AsyncObject } from "../../models/fields/ISelect";
import { IUser } from "../../models/IUsers";
import { ITeam } from "../../models/ITeam";

export const createAffiliateValidation = Yup.object<Record<keyof IAffiliateChange, Yup.AnySchema>>({
  affiliate: Yup.string().required("Required"),
  afterDays: Yup.number().typeError("Must be a number").notRequired(),
  endTime: Yup.string().required("Required"),
  isAcceptDuplicate: Yup.boolean().notRequired(),
  isShowRedirect: Yup.boolean().notRequired(),
  isSelf: Yup.boolean().notRequired(),
  name: Yup.string().required("Required"),
  price: Yup.number().required("Required"),
  startTime: Yup.string().required("Required"),
  status: Yup.object().typeError("Required"),
  team: Yup.object().typeError("Required"),
  users: Yup.array().min(1, "Required"),
});

interface affiliateFiltersParams {
  userOptions: IUser[];
  asyncUsers: AsyncObject;
  teamOptions: ITeam[];
  asyncTeams: AsyncObject;
}

export const createAffiliateFields = ({
  userOptions,
  asyncUsers,
  teamOptions,
  asyncTeams,
}: affiliateFiltersParams): IAllFields<IAffiliateChange>[] => [
  {
    label: "Name",
    name: "name",
    placeholder: "Name",
    type: TypesOfInput.TEXT,
  },
  {
    label: "Affiliate",
    name: "affiliate",
    placeholder: "Affiliate",
    type: TypesOfInput.TEXT,
  },
  {
    async: asyncUsers,
    label: "Users",
    labelName: "name",
    name: "users",
    options: userOptions,
    placeholder: "Users",
    type: TypesOfInput.SELECT,
    isMulti: true,
    valueName: "id",
  },
  {
    async: asyncTeams,
    label: "Team",
    labelName: "name",
    name: "team",
    options: teamOptions,
    type: TypesOfInput.SELECT,
    valueName: "id",
  },
  {
    label: "Price",
    name: "price",
    placeholder: "Price",
    type: TypesOfInput.TEXT,
  },
  {
    label: "After days",
    name: "afterDays",
    placeholder: "afterDays",
    type: TypesOfInput.TEXT,
  },
  {
    label: "Start time",
    name: "startTime",
    placeholder: "Start time",
    type: TypesOfInput.TIMEPICKER,
  },
  {
    label: "End time",
    name: "endTime",
    placeholder: "End time",
    type: TypesOfInput.TIMEPICKER,
  },
  {
    label: "Status",
    name: "status",
    options: statusOptions,
    placeholder: "Status",
    type: TypesOfInput.SELECT,
  },
  {
    label: "Show redirect",
    name: "isShowRedirect",
    placeholder: "isShowRedirect",
    type: TypesOfInput.CHECKBOX,
  },
  {
    label: "Accept duplicate",
    name: "isAcceptDuplicate",
    placeholder: "isAcceptDuplicate",
    type: TypesOfInput.CHECKBOX,
  },
  {
    label: "Is self",
    name: "isSelf",
    placeholder: "isSelf",
    type: TypesOfInput.CHECKBOX,
  },
];
