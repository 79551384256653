import { ColumnDef } from "../../../components/common/tables/types";
import { ClientsLeadsStatisticT } from "../../../models/IClientsStatistics";
import { formatDateFullTime } from "../../../utils/formats";

type IColumn = ColumnDef<ClientsLeadsStatisticT>;

export const getLeadsColumnDefs = (): IColumn[] => {
  const country: IColumn = {
    Header: "Country",
    accessor: "country",
    width: "auto",
    id: "country",
  };

  const sentAt: IColumn = {
    Cell: (cell) => <span>{formatDateFullTime(cell.value)}</span>,
    Header: "Sent at",
    id: "sentAt",
    accessor: "sentAt",
  };


  const status: IColumn = {
    Header: "Status",
    accessor: "status",
    width: "auto",
    id: "status",
  };

  const language: IColumn = {
    Header: "Language",
    accessor: "language",
    width: "auto",
    id: "language",
  };

  const landing: IColumn = {
    Header: "Landing",
    accessor: "landing",
    width: "auto",
    id: "landing",
  };

  const id: IColumn = {
    Cell: ({ value }) => <span>{value.split("-")[0]}</span>,
    Header: "id",
    accessor: "id",
    width: "auto",
    id: "id",
  };

  return [id, country, language, status, landing, sentAt];
}
