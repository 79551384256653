import React, { memo, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import useGetWindiwSize from "../../hooks/useGetWindowSize";
import { IClient } from "../../models/IClients";
import { SettingsTableKey } from "../../models/IUserSettings";
import Loading from "../../pages/_layouts/Loading";
import { clientsSlice } from "../../store/reducers/clientsSlice";
import { changeAcceptHold, changeStatus, fetchClients } from '../../store/thunks/clients';
import { SortOrderType } from "../../types/global";
import Table from "../common/tables/Table";
import getColumnDefsClients from "./ColumnDefs";

interface Props {
  searchTerm: string,
}

const ClientsTable = ({ searchTerm }: Props) => {
  const dispatch = useAppDispatch();
  const { pagination, clients, orderBy, order } = useAppSelector((state) => state.clientsReducer);
  const { selectClients, setPagination, setOrderBy } = clientsSlice.actions;
  const { atLeastMd } = useGetWindiwSize();

  const onChangePagination = (page: number) => {
    dispatch(setPagination({ page }));
    dispatch(fetchClients({ searchTerm }));
  };

  const onChangePerPage = (take: number) => {
    dispatch(setPagination({ take }));
    dispatch(fetchClients({ searchTerm }));
  };

  const handleSelect = (ids: unknown[]) => {
    dispatch(selectClients(ids as string[]));
  };

  const handleChangeStatus = (status: boolean, id: string) => {
    dispatch(changeStatus({ id, status }));
  };

  const handleChangeAcceptHold = (acceptHold: boolean, id: string) => {
    dispatch(changeAcceptHold({ id, acceptHold }));
  };

  const handleSort = (calName: keyof IClient, order: SortOrderType) => {
    dispatch(setOrderBy({ orderBy: calName, order }));
    dispatch(fetchClients({ searchTerm }));
  };

  const columns = useMemo(
    () =>
      getColumnDefsClients(
        {
          handleChangeAcceptHold,
          handleChangeStatus,
          pagination,
        },
        !atLeastMd,
      ),
    [pagination, clients, atLeastMd],
  );

  if (clients.loading) {
    return <Loading />;
  }

  return (
    <Table<IClient>
      pageKey={SettingsTableKey.CLIENTS}
      changePage={onChangePagination}
      changePageSize={onChangePerPage}
      className={atLeastMd ? "min-w-screen-lg" : ""}
      columns={columns}
      containerClass="overflow-auto"
      fixed
      items={clients.data}
      pagination={pagination}
      rowSelect
      handleSelect={handleSelect}
      handleSort={handleSort}
      columnSortFlags={["name",
        "price",
        "balance",
        "status",
        "credit"]}
        sortByColumn={{order, orderBy}}
    />
  );
};

export default ClientsTable;
