import React, { ChangeEvent } from "react";
import ReactPaginate from "react-paginate";
import { PAGINATION_TAKE } from "../../../types/global";
import { PaginationType } from "./types";
interface Props {
  changePage?: (page: number) => void;
  changePageSize: (pageSize: number) => void;
  pagination: PaginationType;
}

const Pagination: React.FC<Props> = (props: Props) => {
  const { changePage, pagination, changePageSize } = props;
  const { total, lastPage, to, from, page, take } = pagination;

  const handleChangePage = ({ selected }: { selected: number }) => {
    if (!changePage) return;
    changePage(selected + 1);
  };

  const handleChangePageSize = (e: ChangeEvent<HTMLSelectElement>) => {
    changePageSize(Number(e.target.value));
  };

  return (
    <>
      <div className="my-2">
        Showing {from} to {to} of {total} entries
      </div>
      <nav className="dataTable-pagination">
        <ReactPaginate
          previousLabel={<span className="pager">‹</span>}
          nextLabel={<span className="pager">›</span>}
          pageCount={lastPage || 1}
          marginPagesDisplayed={1}
          onPageChange={handleChangePage}
          containerClassName={"dataTable-pagination-list"}
          activeClassName={"active"}
          pageRangeDisplayed={2}
          forcePage={page - 1}
        />
      </nav>
      <div>
        <label>
          {"Page size "}
          <select
            className="dataTable-selector form-select form-select-sm"
            value={take}
            onChange={handleChangePageSize}
          >
            <option value={PAGINATION_TAKE}>{PAGINATION_TAKE}</option>
            <option value="100">100</option>
            <option value="125">125</option>
            <option value="150">150</option>
          </select>
        </label>
      </div>
    </>
  );
};

export default Pagination;
