import React, { useMemo, useRef } from "react";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { Button } from "react-bootstrap";
import Field from "../../components/common/form/formik/Field";
import PageTitle from "../../components/common/items/page-title";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { emptyValues, IPushChange } from "../../models/IPush";
import { changeItem } from "../../store/thunks/pushes";
import { createFields, validationSchema } from "../../components/fields/pushes";
import { RejectedWithValueAction } from "../../store/thunks/createAppThunk";
import { fetchList } from "../../store/thunks/offer";
import { FetchOptionsParams } from "../../types/global";

const CreateItem = () => {
  const dispatch = useAppDispatch();
  const formikRef: React.Ref<FormikProps<IPushChange>> | null = useRef(null);
  const { list } = useAppSelector((state) => state.offersReducer);

  const fields = useMemo(
    () =>
      createFields({
        asyncOffer: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchList(params)),
          isLoading: list.loading,
        },
        offerOptions: list.data,
      }),
    [list],
  );

  const handleSubmit = async (values: IPushChange, formikHelpers: FormikHelpers<IPushChange>) => {
    const result = (await dispatch(
      changeItem(values),
    )) as unknown as RejectedWithValueAction<IPushChange>;
    if (result.payload) {
      formikHelpers.setErrors(result.payload.errors);
    }
  };

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <Link to="/pushes" className="w-auto">
          <Button className="w-auto mb-3" variant="primary">
            Back
          </Button>
        </Link>
        <PageTitle title="Creating push" />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Formik
                innerRef={formikRef}
                enableReinitialize
                initialValues={emptyValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {fields.map((field) => (
                      <Field key={field.name} {...field} />
                    ))}
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                  >
                    Create
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CreateItem;
