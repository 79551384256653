import React, { memo, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import cn from 'classnames';
import ColorLabel from "../../../components/common/items/ColorLabel";
import { typeOptions } from "../../../models/IClients";
import { IStatisticClients, LeadCount, StatisticReportsFilters } from "../../../models/IStatistics";
import { formatDate, formatDateSrtToBackForm, formatDateToBack, formatDateToState, stringToDate } from "../../../utils/formats";
import { SortOrderType } from "../../../types/global";

interface Props {
  data?: IStatisticClients[];
  filter: StatisticReportsFilters;
}

const StatisticClients = ({ data, filter }: Props) => {
  const [sortBy, setSortBy] = useState<string | 'Total' | 'Name'>('Name');
  const [orderBy, setOrderBy] = useState<SortOrderType>('DESC');

  const formatByOrderBy = useCallback(
    (a: number, b: number) => orderBy === 'ASC' ? a - b : b - a,
    [orderBy],
  )

  const formatByOrderByForName = useCallback(
    (a: string, b: string) => {
      if (orderBy === 'DESC') {
        if (a < b) return -1;
        if (a > b) return 1;
      } else {
        if (a > b) return -1;
        if (a < b) return 1;
      }
      return 0;
    },
    [orderBy],
  )

  const formatData = useCallback((data: IStatisticClients[]) => (
    data
      .map((item) => ({ ...item, total: getTotal(item.leads) }))
      .sort((a, b) => sortBy === 'Total'
        ? formatByOrderBy(a.total ?? 0, b.total ?? 0)
        : sortBy === 'Name' ? formatByOrderByForName(a.name, b.name)
        : formatByOrderBy(a.leads[formatDateSrtToBackForm(sortBy)] ?? 0, b.leads[formatDateSrtToBackForm(sortBy)] ?? 0)
      )
  ), [sortBy, formatByOrderBy]);

  const handleSortBy = (key: string) => {
    if (key === sortBy) {
      if (orderBy === 'DESC') setOrderBy('ASC')
      else setOrderBy('DESC')
    } else {
      setSortBy(key)
      setOrderBy('DESC')
    }
  }

  const getTotal = (item: LeadCount) =>
    Object.values(item).reduce((partialSum, a) => partialSum + a, 0);

  const renderedData = useMemo(() => (formatData(data ?? [])), [formatData, data]);

  const getDates = (): number[] => {
    const dates = (data ?? []).map((item) => Object.keys(item.leads)) || [];
    const datesSet = new Set<string>(dates.flat());
    return Array.from(datesSet)
      .map((item) => {
        const formattedData = item.split("/").reverse().join("/");
        const date = stringToDate(formattedData);
        return date ? date.getTime() : 0;
      })
      .sort((a, b) => b - a)
  };

  const datesToRender = useMemo(() => getDates().map(item =>  formatDate(item) ?? ''), [formatDate])

  const totalSum = useMemo(
    () => renderedData?.reduce((partialSum, a) => partialSum + getTotal(a.leads), 0),
    [renderedData],
  );

  // const getLeadsCount = (leads: LeadCount): number[] => getDates().map((date) => leads[formatDateSrtToBackForm(date)] || 0)
  const getLeadsCount = (leads: LeadCount): number[] => datesToRender.map((date) => leads[formatDateSrtToBackForm(date)] || 0)

  const getLeadsTotalByDay = useCallback(
    (day: string) => renderedData?.reduce((prev, curr) => prev + (curr.leads[formatDateSrtToBackForm(day)] || 0), 0),
    [renderedData],
  );

  const formatClientUrl = useCallback((id: string) => {
    const params = new URLSearchParams();
    if (filter.sentAt[0] && filter.sentAt[1]) {3
      params.append("sentAt[0]", `${formatDateToState(formatDateToBack(filter.sentAt[0]))}`)
      params.append("sentAt[1]", `${formatDateToState(formatDateToBack(filter.sentAt[1]))}`)
    }
    return `/affiliate-reports/clients/${id}?${params.toString()}`;
  }, []);

  return (
    <div className="mb-4 card">
      <div className="card-header py-3">
        <p className="card-heading">Clients</p>
      </div>
      <div className="card-body table-responsive">
        <table className="card-text table overflow-auto">
          <thead>
            <tr>
              <th className={cn("c-pointer", { "fw-extra-bold": sortBy === 'Name' })} onClick={() => handleSortBy('Name')}>
                Name
                {sortBy === 'Name' && (orderBy === 'DESC' ? <span>&darr;</span> : <span>&uarr;</span>)}
              </th>
              <th className={cn("c-pointer", { "fw-extra-bold": sortBy === 'Total' })} onClick={() => handleSortBy('Total')}>
                Total
                {sortBy === 'Total' && (orderBy === 'DESC' ? <span>&darr;</span> : <span>&uarr;</span>)}
              </th>
              {datesToRender.map((date) => (
                <th className={cn("c-pointer", { "fw-extra-bold": sortBy === date })} key={date} onClick={() => handleSortBy(date)}>
                  {date}
                  {sortBy === date && (orderBy === 'DESC' ? <span>&darr;</span> : <span>&uarr;</span>)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {renderedData?.map((item) => (
              <tr key={item.id}>
                <th scope="row">
                  <Link to={formatClientUrl(item.id)}>
                    {item.name}
                    <ColorLabel
                      className="d-inline ml-2"
                      colors={typeOptions}
                      value={item.type}
                      label={item.type}
                    />
                  </Link>
                </th>
                <th>{item.total}</th>
                {getLeadsCount(item.leads).map((count, index) => (
                  <th key={index}>{count}</th>
                ))}
              </tr>
            ))}
            <tr>
              <th scope="row">Total</th>
              <th>{totalSum}</th>
              {datesToRender.map((date) => (
                <th key={date}>{getLeadsTotalByDay(date)}</th>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default memo(StatisticClients);
