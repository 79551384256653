import React, { useMemo } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import useGetWindiwSize from "../../hooks/useGetWindowSize";
import { DashboardDeposit } from "../../models/IDashboard";
import { SettingsTableKey } from "../../models/IUserSettings";
import getDepositColumnDefs from "../../utils/depositColumnDefs";
import PageTitle from "../common/items/page-title";
import Table from "../common/tables/Table";

interface Props {
  list: DashboardDeposit[];
}

const DepositCard = ({ list }: Props) => {
  const navigate = useNavigate();
  const { atLeastMd } = useGetWindiwSize();

  const handleFilterByProcessingStatus = async () => {
    navigate("/leads?hasDeposit=true");
  };

  const handleFilterByEmail = (email: DashboardDeposit['email']) => {
    navigate(`/leads?email=${email}`);
  };

  const columns = useMemo(() => getDepositColumnDefs(handleFilterByEmail), [])

  return (
    <div className="card mb-4">
      <div className="card-header py-3">
        <p className="card-heading">Deposits</p>
      </div>
      <div className="card-body">
        <Table<DashboardDeposit>
          pageKey={SettingsTableKey.STATUSES}
          className={atLeastMd ? "min-w-screen-lg" : ""}
          columns={columns}
          containerClass="overflow-auto"
          fixed
          items={list}
        />
      </div>
      <div className="card-footer">
        <Button onClick={handleFilterByProcessingStatus}>See more</Button>
      </div>
    </div>
  );
}

export default DepositCard;
