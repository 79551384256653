import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { DashboardCap } from "../../models/IDashboard";
import { LabelColors } from "../../types/global";
import ColorLabel from "../common/items/ColorLabel";
import { fetchOneClient } from '../../store/thunks/clients';
import { PayloadAction } from '@reduxjs/toolkit';
import { ModelRes } from '../../api/utils';
import { IClient } from '../../models/IClients';
import { ICountry } from "../../models/ILocalizations";
import countriesJson from "../../countries.json";
import PageTitle from "../common/items/page-title";

interface Props {
  caps: DashboardCap[] | undefined;
  // country: string;
  // onClick: (country: string) => void;
  filterByClient: (clientId: string) => void;
}

const CapCard = ({ caps, filterByClient }: Props) => {
  // const totalCompletedLeads = useMemo(() => caps.reduce((acc, cur) => acc + cur.leads, 0), [caps]);
  // const totalLeads = useMemo(() => caps.reduce((acc, cur) => acc + Number(cur.today), 0), [caps]);
  // const totalBalances = useMemo(() => caps.reduce((acc, cur) => {
  //   if (cur.type === 'cpa') {
  //     return acc;
  //   }
  //   return acc + Number(cur.balance);
  // }, 0), [caps]);

  const handleCalcAvailableLeads = (cap: DashboardCap): number | boolean => {
    if (cap.type === 'cpa') {
      return false;
    }
    return Math.round(cap.balance / cap.price);
  };

  const renderLocalizations = (value: { country: string, id: string }[]) => {
    const countries: ICountry[] = countriesJson;
    const flags = new Set(value?.map((item) => countries.find((country) => country.code === item.country.toLowerCase())?.flag));
    return (
      <span>{(Array.from(flags)).join(" ")}</span>
    );
  }

  if (!caps?.length) return null;

  return (
    <section className="mb-3 mb-lg-5">
      <PageTitle title="Caps" />
      <div className="row">
        {
          caps.map((cap) => (
            <div key={cap.id} className="col-xl-4 col-md-6 mb-4">
              <div className="card h-100">
                <div
                  role="button"
                  className="card-header py-4 d-flex justify-content-between"
                  onClick={() => filterByClient(cap.id)}>
                  <h6 className="card-heading">
                    <ColorLabel
                      className="d-inline mb-2"
                      label={`${cap.name}`}
                      color={cap.status ? LabelColors.SUCCESS : LabelColors.FAILED}
                      isSingleColor
                    />
                  </h6>
                  {renderLocalizations(cap.localizations)}
                  <p className="card-heading">
                    {cap.leads} / {cap.today}
                  </p>
                </div>
                <div className="card-body pt-3">
                  <div className="row align-items-center">
                    <div>
                      {handleCalcAvailableLeads(cap) ? <ColorLabel
                        className="d-inline mb-2"
                        label={`Balance: ${cap.balance}$ (${handleCalcAvailableLeads(cap)})`}
                        color={LabelColors.PRIMARY}
                        isSingleColor
                      /> : ''}
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mb-1">
                        <div className="progress">
                          <div
                            className="progress-bar bg-primary"
                            role="progressbar"
                            style={{ width: `${cap.percent}%` }}
                          />
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <div className="mr-2 text-muted text-sm">Per: {cap.percent}%</div>
                        <div className="mr-2 text-muted text-sm">L: {cap.leads}</div>
                        <div className="mr-2 text-muted text-sm">T: {cap.today}</div>
                        <div className="mr-2 text-muted text-sm">Time: {cap.time.start.slice(0, 5)}-{cap.time.end.slice(0, 5)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </section>
  );
};

export default CapCard;
