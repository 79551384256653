import React, { useEffect } from "react";
import BrandSvg from "../../assets/icons/brand-1.jpg";
import Background from "../../assets/images/login-background.jpg";
import { login } from "../../store/thunks/auth";
import { Link, useNavigate } from "react-router-dom";
import Field from "../../components/common/form/formik/Field";
import { emptyValues, IAuth, validationSchema } from "../../models/IAuth";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { Form, Formik } from "formik";
import { Button } from "react-bootstrap";
import { TypesOfInput } from "../../models/fields/IField";

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAuth } = useAppSelector((state) => state.authSlice);

  useEffect(() => {
    if (isAuth) {
      navigate("/dashboard");
    }
  }, [isAuth]);

  const handleSubmit = (values: IAuth) => {
    dispatch(login(values)).then(() => navigate('/login/2fa'));
  };

  return (
    <div className="container-fluid px-0">
      <div className="row gx-0 min-vh-100">
        <div className="col-md-9 col-lg-6 col-xl-4 px-5 d-flex align-items-center shadow">
          <div className="w-100 py-5">
            <div className="text-center">
              <img className="img-fluid mb-4" src={BrandSvg} style={{ maxWidth: "6rem" }} />
              <h1 className="h4 text-uppercase mb-5">LEADBOLID WORK</h1>
            </div>
            <Formik
              initialValues={emptyValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
              validateOnBlur={false}
            >
              <Form className="w-100 px-4">
                <div className="mb-3">
                  <Field size="12" name="email" />
                </div>
                <div className="mb-2">
                  <Field size="12" name="password" type={TypesOfInput.PASSWORD} />
                </div>
                <Link to="/login/2fa">Type 2fa code</Link>
                <Button
                  type="submit"
                  className="mt-4 btn btn-primary text-uppercase w-100"
                  variant="primary"
                >
                  Sign in
                </Button>
              </Form>
            </Formik>
          </div>
        </div>
        <div className="col-md-3 col-lg-6 col-xl-8 d-none d-md-block">
          <div className="bg-cover h-100 me-n3" style={{ backgroundImage: `url(${Background})` }} />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
