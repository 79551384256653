import { ArcElement, Chart, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { IClient } from "../../models/IClients";
import { ClientsConversationsStatisticT } from "../../models/IClientsStatistics";
import { IFilterLead, initialLeadFilters, isBooleanOptions } from "../../models/ILead";
import { ClientStatisticReportsFilters } from "../../models/IStatistics";
import { leadsSlice } from "../../store/reducers/leadsSlice";
import { fetchLeads } from "../../store/thunks/leads";
import Loading from "../_layouts/Loading";

ChartJS.register(ArcElement, Tooltip, Legend);

const SimpleConversionChart = ({ conversations, leads, deposits }: ClientsConversationsStatisticT) => {
  const validReplaceData = {
    labels: ["client leads", "total leads"],
    datasets: [
      {
        label: "Valid replace",
        data: [leads, deposits],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const plugins = [
    {
      beforeDraw: function (chart: Chart) {
        const width = chart.width,
          height = chart.height - 20,
          ctx = chart.ctx;
        ctx.restore();
        const fontSize = (height / 200).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        const text = `${leads}/${deposits}/${conversations}%`,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  return (
    <>
      <Doughnut
        data={validReplaceData}
        options={{
          aspectRatio: 2,
          responsive: true,

          plugins: {
            legend: {
              display: false,
            },
          },
        }}
        plugins={plugins as FixMeLater}
      />
      <div>
        <p>{leads} - leads</p>
        <p>{deposits} - deposits</p>
        <p className="mb-0">{conversations}% - conversion </p>
      </div>
    </>
  );
};

export default SimpleConversionChart;
