import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationQuery, PaginationType } from "../../components/common/tables/types";
import { FetchedStoreModel, PAGINATION_TAKE } from "../../types/global";
import { toast } from "react-toastify";
import { IPush, IPushFilters, emptyFilters } from "../../models/IPush";
import { changeItem, deleteItem, fetchItem, fetchList } from "../thunks/pushes";

export interface State {
  list: FetchedStoreModel<IPush[]>;
  currentItem: FetchedStoreModel<IPush | null>;
  pagination: PaginationType;
  filter: IPushFilters;
}

const initialState: State = {
  list: {
    data: [],
    error: null,
    loading: true,
  },
  currentItem: {
    data: null,
    error: null,
    loading: true,
  },
  pagination: {
    take: PAGINATION_TAKE,
    total: null,
    page: 1,
    lastPage: null,
  },
  filter: emptyFilters,
};

export const pushesSlice = createSlice({
  name: "pushes",
  initialState,
  reducers: {
    setPagination: (state, action: PayloadAction<PaginationQuery>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    setFilters: (state, action: PayloadAction<IPushFilters>) => {
      state.filter = action.payload;
      state.pagination.page = 1;
    },
    resetFilters: (state) => {
      state.filter = emptyFilters;
      state.pagination.page = 1;
    },
  },
  extraReducers: (builder) => {
    // fetchList
    builder.addCase(fetchList.pending, (state) => {
      state.list.loading = true;
      state.list.error = null;
    });
    builder.addCase(fetchList.fulfilled, (state, action) => {
      state.list.data = action.payload.list;
      state.list.loading = false;
      state.pagination = { ...state.pagination, ...action.payload.pagination };
    });
    builder.addCase(fetchList.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.payload as FixMeLater;
    });

    // fetchItem
    builder.addCase(fetchItem.pending, (state) => {
      state.currentItem.loading = true;
      state.currentItem.error = null;
    });
    builder.addCase(fetchItem.fulfilled, (state, action) => {
      state.currentItem.data = action.payload.model;
      state.currentItem.loading = false;
    });
    builder.addCase(fetchItem.rejected, (state, action) => {
      state.currentItem.loading = false;
      state.currentItem.error = action.payload as FixMeLater;
    });

    builder.addCase(changeItem.fulfilled, (state, action) => {
      state.currentItem.data = action.payload.model;
      toast.success("Push successfully changed");
      window.location.pathname = "/pushes";
    });
    builder.addCase(deleteItem.fulfilled, () => {
      toast.success("Push successfully deleted");
      window.location.pathname = "/pushes";
    });
  },
});

export default pushesSlice.reducer;
