import React from "react";
import { useField, ErrorMessage } from "formik";
import { FormControl } from "react-bootstrap";
import { IField } from "../../../../models/fields/IField";
import { handleCopy } from "../../../../utils/formats";

const TextField = <T extends UnknownRecord>(props: IField<T>) => {
  const { label, prefix, name, placeholder, type, disabled } = props;
  const fieldName = prefix ? `${name}.${prefix}` : name;
  const [field, meta] = useField(fieldName);
  const { touched, error } = meta;

  const handleClick = () => {
    if (!disabled) return;
    handleCopy(field.value);
  }

  return (
    <div onClick={handleClick}>
      {label && <label>{label}</label>}
      <FormControl
        {...field}
        name={fieldName}
        placeholder={placeholder}
        type={type}
        isInvalid={!!(touched && error)}
        disabled={disabled}
      />
      <ErrorMessage
        name={fieldName}
        render={(msg) => <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>}
      />
    </div>
  );
};

export default TextField;
