import cn from 'classnames';
import React, { memo, useMemo } from "react";
import { Row } from "react-table";
import { getExportedFile } from "../../api/leads-api";
import { filterEmpyValues } from "../../api/utils";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { IFilterLeadRequest, ILead } from "../../models/ILead";
import { UserRole } from '../../models/IUsers';
import { SettingsTableKey } from "../../models/IUserSettings";
import Loading from "../../pages/_layouts/Loading";
import { leadsSlice } from "../../store/reducers/leadsSlice";
import { changeIsReplace, changeIsValid, fetchLeads, formatRequestFilters } from '../../store/thunks/leads';
import { SortOrderType } from '../../types/global';
import Table from "../common/tables/Table";
import getColumnDefs from "./ColumnDefs";

interface Props {
  className?: string;
}

const LeadsTable = ({ className }: Props) => {
  const dispatch = useAppDispatch();
  const { auth } = useAppSelector((state) => state.authSlice);
  const { pagination, leads, filter, searchTerm, order, orderBy } = useAppSelector((state) => state.leadsReducer);
  const { selectLeads, setPagination, setOrderBy } = leadsSlice.actions;

  const role = useMemo(() => auth.user?.roles, [auth]);

  const handleSort = (calName: keyof ILead, order: SortOrderType) => {
    dispatch(setOrderBy({ orderBy: calName, order }));
    dispatch(fetchLeads({}));
  };

  const onChangePagination = (page: number) => {
    dispatch(setPagination({ page }));
    dispatch(fetchLeads({}));
  };

  const onChangePerPage = (take: number) => {
    dispatch(setPagination({ take }));
    dispatch(fetchLeads({}));
  };

  const handleSelectLead = (ids: unknown[]) => {
    dispatch(selectLeads(ids as string[]));
  };

  const handleChangeIsValid = (isValid: boolean, id: string) => {
    dispatch(changeIsValid({ id, isValid }));
  };

  const handleChangeIsReplace = (isReplace: boolean, id: string) => {
    dispatch(changeIsReplace({ id, isReplace }));
  };

  const handleExport = () => {
    const filterRequest = filterEmpyValues(formatRequestFilters(filter)) as IFilterLeadRequest;
    return getExportedFile(filterRequest, searchTerm)
  }

  const columns = useMemo(
    () => getColumnDefs({ pagination, role: role || UserRole.TEAM_LEAD, handleChangeIsValid, handleChangeIsReplace }),
    [pagination, leads],
  );

  return (
    <Table<ILead>
      pageKey={SettingsTableKey.LEADS}
      changePage={onChangePagination}
      changePageSize={onChangePerPage}
      className={className}
      columns={columns}
      containerClass="overflow-auto"
      fixed
      items={leads.data}
      isLoading={leads.loading}
      pagination={pagination}
      max
      rowSelect
      handleSelect={handleSelectLead}
      exportApi={role === 'admin' ? handleExport : undefined}
      renderRowBg={(row: Row<ILead>) => cn({
        "bg-success": row.isSelected,
        "bg-danger-light": !row.original.isValid && row.original.isReplace && !row.isSelected,
        "bg-warning-light": !row.original.isValid && !row.original.isReplace && !row.isSelected && !row.original.depositedAt,
        "bg-success-light": row.original.depositedAt !== null && !row.isSelected,
      })}
      handleSort={handleSort}
      columnSortFlags={["landing",
        "status",
        "sentAt",
        "createdAt", "depositedAt"]}
        sortByColumn={{order, orderBy}}
    />
  );
};

export default memo(LeadsTable);
