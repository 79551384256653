import { FilterConditionally, Modify, ObjectWithKeys } from "../types/global";
import { IReview } from "./IReview";
import { ITag } from "./ITag";
import { GenericOption, OptionModel } from "./fields/ISelect";

export type OfferType = "crypto" | "gambling";

export type IOffer = IOfferChange & {
  id: string;
  type: string;
  createdAt: string;
  isSelected?: boolean;
};

export type IOfferChange = {
  title: string;
  domain: string;
  color: string;
  background: string;
  type: OptionModel<GenericOption<OfferType>>;

  logo: File | null;
  footer: File | null;

  reviews: IReview[];
  tags: ITag[];
  similar: IOffer[];

  bonus?: ObjectWithKeys;
  preview?: ObjectWithKeys;
  download?: ObjectWithKeys;
  subtitle?: ObjectWithKeys;
  description?: ObjectWithKeys;

  images?: ObjectWithKeys<File[] | null[]>;
};

export type IOfferChangeRequest = Modify<
  Partial<IOfferChange>,
  Partial<{
    type: OfferType | "";
    tags: string[];
    similar: string[];
    reviews: string[];
    footer: File | null;
  }>
>;

export type IOfferFilters = FilterConditionally<IOfferChange, string>;

export const offerTypeOptions: GenericOption<OfferType>[] = [
  {
    label: "Crypto",
    value: "crypto",
  },
  {
    label: "Gambling",
    value: "gambling",
  },
];

export const emptyFilters: IOfferFilters = {
  title: "",
  domain: "",
  color: "",
  background: "",
};

export const emptyValues: IOfferChange = {
  title: "",
  domain: "",
  color: "",
  background: "",
  type: null,
  logo: null,
  footer: null,
  reviews: [],
  tags: [],
  similar: [],
  bonus: {},
  preview: {},
  download: {},
  subtitle: {},
  description: {},
  images: {},
};
