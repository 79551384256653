import { PaginationQuery } from "../components/common/tables/types";
import { ArrayOfDate, Modify } from "../types/global";
import { GenericOption, OptionModel } from "./fields/ISelect";
import { ITeam } from "./ITeam";
import { IUser } from "./IUsers";

export type IAffiliate = {
  id: string;
  isSelected?: boolean;
  name: string;
  affiliate: string;
  users: IUser[];
  price: number;
  afterDays: string;
  isShowRedirect: boolean;
  isAcceptDuplicate: boolean;
  isSelf: boolean;
  startTime: string;
  endTime: string;
  status: number;
  balanceAt: string;
  createdAt: string;
  team: ITeam;
};

export type IAffiliateChange = {
  affiliate: string;
  endTime: string;
  afterDays: string;
  isAcceptDuplicate: boolean;
  isShowRedirect: boolean;
  isSelf: boolean;
  name: string;
  price: number;
  startTime: string;
  status: GenericOption<AffiliateStatus> | null;
  users: OptionModel<IUser>[];
  team: OptionModel<ITeam>;
};

export type IAffiliateChangeRequest = Modify<
  Partial<IAffiliateChange>,
  {
    users: string[];
    status: string;
    teamId: string;
  }
>;

export type IShortAffilate = {
  affiliate: string;
  createdAt: string;
  id: string;
  name: string;
};

export type AffiliateStatus = "0" | "1";

export type IFilterAffiliate = Modify<
  Partial<IAffiliateChange>,
  {
    createdAt: ArrayOfDate;
    status: GenericOption<AffiliateStatus>[];
  }
>;

export type IFilterAffiliateRequest = Modify<
  Partial<IFilterAffiliate>,
  {
    users: string[];
    status: string[];
  }
>;

export const emptyValues: IAffiliateChange = {
  affiliate: "",
  endTime: "",
  afterDays: "",
  isAcceptDuplicate: false,
  isShowRedirect: false,
  isSelf: false,
  name: "",
  price: 0,
  startTime: "",
  status: null,
  team: null,
  users: [],
};

export const emptyFilters: IFilterAffiliate = {
  ...emptyValues,
  status: [],
  createdAt: [null, null],
};

export interface RequestAffiliate extends PaginationQuery {
  filter: IFilterAffiliateRequest;
}

export const statusOptions: GenericOption<AffiliateStatus>[] = [
  {
    label: "Выкл.",
    value: "0",
  },
  {
    label: "Вкл.",
    value: "1",
  },
];

export const redirectOptions = [
  {
    label: "Не отображаем",
    value: "0",
  },
  {
    label: "Отображаем",
    value: "1",
  },
];
