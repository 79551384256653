import React from "react";
import { useField } from "formik";
import CreatableSelect from "react-select/creatable";
import { ISelect, selectStyles } from "../../../../models/fields/ISelect";

const CustomSelect = <T, Option>(props: ISelect<T, Option>) => {
  const [field, , helpers] = useField(props.name);
  const { setValue } = helpers;

  return (
    <CreatableSelect
      {...field}
      {...props}
      onChange={(value) => setValue(value)}
      isClearable
      isMulti={false}
      styles={selectStyles}
    />
  );
};

export default CustomSelect;
