import { RequestList } from "../components/common/tables/types";
import { IOffer, IOfferChange, IOfferChangeRequest, IOfferFilters } from "../models/IOffer";
import { Api, formApi, filterEmpyValues, ListRes, ModelRes, decamelizeDataByFilter } from "./utils";
import { getResponseData } from "./utils";

const baseURL = "/v1/offers";

export const getList = (params: RequestList<IOfferFilters>): Promise<ListRes<IOffer>> => {
  return Api.post(baseURL + "/list", filterEmpyValues(params)).then(getResponseData);
};

export const changeItem = (
  data: IOfferChange,
  localizationKeys: string[],
): Promise<ModelRes<IOffer>> => {
  const sendingData: IOfferChangeRequest = {
    ...(decamelizeDataByFilter<IOfferChange>(data, [...localizationKeys]) as IOfferChangeRequest),
    footer: data.footer,
    images: data.images,
    logo: data.logo,
    type: data.type?.value || "",
    tags: data.tags.map((tag) => tag.id),
    reviews: data.reviews.map((review) => review.id),
    similar: data.similar.map((offer) => offer.id),
  };

  if (typeof sendingData.logo === "string") delete sendingData.logo;
  if (typeof sendingData.footer === "string") delete sendingData.footer;

  return formApi.post(baseURL, sendingData).then(getResponseData);
};

export const getItem = (id: string): Promise<ModelRes<IOffer>> => {
  return Api.get(`${baseURL}/${id}`).then(getResponseData);
};

export const deleteItem = (id: string) => {
  return Api.delete(`${baseURL}/${id}`).then(getResponseData);
};
