import { createAsyncThunk } from "@reduxjs/toolkit";
import * as clientsApi from "../../api/clients-api";
import { filterEmpyValues } from "../../api/utils";
import { IClient, IClientChange, IClientClone, IClientCloneRequest, IClientCreate, IFilterClientRequest, RequestClient, UpdateSomeClientsActions } from "../../models/IClients";
import { FetchOptionsParams } from "../../types/global";
import { camelToSnakeCase } from "../../utils/formats";
import { ClientsState } from "../reducers/clientsSlice";

export const fetchClients = createAsyncThunk("clients/fetchClients", (params: FetchOptionsParams, thunkAPI) => {
  const state: FixMeLater = thunkAPI.getState();
  const ClientsState: ClientsState = state.clientsReducer;

  const filter: IFilterClientRequest = {
    ...ClientsState.filter,
    labelId: ClientsState.filter.label?.id || "",
    status: ClientsState.filter.status?.value || "",
  };

  delete filter.label;

  const options: RequestClient = {
    filter,
    page: ClientsState.pagination.page,
    searchTerm: params.searchTerm || '',
    take: ClientsState.pagination.take,
    orderBy: camelToSnakeCase(ClientsState.orderBy) as keyof IClient,
    asc: ClientsState.order === "ASC" ? 0 : 1
  };
  return clientsApi.getClients(options);
});

export const fetchShortClients = createAsyncThunk(
  "clients/fetchShortClients",
  (params: FetchOptionsParams) => clientsApi.getShortClients({ ...params, take: 15 }),
);

export const fetchOneClient = createAsyncThunk("clients/fetchOneClient", (id: string) =>
  clientsApi.getClient(id),
);

export const changeClient = createAsyncThunk(
  "clients/changeClient",
  (values: IClientChange | IClientCreate) => clientsApi.changeClient(values),
);

export const changeStatus = createAsyncThunk(
  "clients/changeStatusClient",
  ({ status, id }: { status: boolean; id: string }) => clientsApi.changeStatusClient(status, id),
);

export const changeAcceptHold = createAsyncThunk(
  "clients/changeAcceptHoldClient",
  ({ acceptHold, id }: { acceptHold: boolean; id: string }) => clientsApi.changeAcceptHoldClient(acceptHold, id),
);

export const updateClients = createAsyncThunk(
  "clients/updateClients",
  (values: UpdateSomeClientsActions, thunkAPI) => {
    const state: FixMeLater = thunkAPI.getState();
    const ClientsState: ClientsState = state.clientsReducer;
    const data = filterEmpyValues(values);
    return clientsApi.updateSomeClients({ ids: ClientsState.selectedItems, ...data });
  },
);

export const cloneClient = createAsyncThunk(
  "clients/cloneClient",
  ({ data, id }: { data: IClientClone; id: string }) => {
    const sendingData: IClientCloneRequest = {
      name: data.name,
      price: data.price,
      integrationId: data.integration?.id || "",
      url: data.url,
    };
    return clientsApi.cloneClient(sendingData, id);
  },
);
