import React from "react";
import { ISelectField } from "../../../../models/fields/ISelect";
import { ObjectWithKeys } from "../../../../types/global";
import ErrorText from "../ErrorText";
import AsyncMultipleSelect from "./AsyncMultipleSelect";
import AsyncSelect from "./AsyncSelect";
import CustomSelect from "./CustomSelect";
import MultipleSelect from "./MultipleSelect";
import SingleSelect from "./SingleSelect";

const Select = <T, Option extends ObjectWithKeys>(props: ISelectField<T, Option>) => {
  const labelName = props.labelName || ("label" as keyof Option);
  const valueName = props.valueName || ("value" as keyof Option);

  const defaultProps = {
    getOptionLabel: (item: Option) => item[labelName],
    getOptionValue: (item: Option) => item[valueName],
    isClearable: true,
    id: props.name,
  };

  return (
    <div>
      {props.label && (
        <label htmlFor={props.name} className="form-label-test">
          {props.label}
        </label>
      )}
      {props.isMulti && props.async ? (
        <AsyncMultipleSelect {...props} {...defaultProps} />
      ) : props.isMulti ? (
        <MultipleSelect {...props} {...defaultProps} />
      ) : props.isCustomValue ? (
        <CustomSelect {...props} {...defaultProps} />
      ) : props.async ? (
        <AsyncSelect {...props} {...defaultProps} />
      ) : (
        <SingleSelect {...props} {...defaultProps} />
      )}
      <ErrorText name={props.name} />
    </div>
  );
};

export default Select;
