import React, { useEffect, useMemo, useState } from "react";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { Link, useParams } from "react-router-dom";
import Field from "../../components/common/form/formik/Field";
import PageTitle from "../../components/common/items/page-title";
import Loading from "../_layouts/Loading";
import { emptyValues, IOfferChange, offerTypeOptions } from "../../models/IOffer";
import { changeItem, deleteItem, fetchItem } from "../../store/thunks/offer";
import { createFields, getValidation } from "../../components/fields/offers";
import LanguagesGroup from "../../components/common/form/LanguagesGroup";
import { FetchOptionsParams, ObjectWithKeys } from "../../types/global";
import { AnySchema } from "yup";
import { fetchList as fetchTagsList } from "../../store/thunks/tag";
import { fetchList as fetchReviewsList } from "../../store/thunks/review";
import { fetchList as fetchOffersList } from "../../store/thunks/offer";
import { countriesSlice, getOpenedCountriesCodes } from "../../store/reducers/countriesSlice";
import { getLocalizationsFromData } from "../../utils/formats";
import { filterDataByCountries } from "../../api/utils";
import useModal from "../../hooks/useModal";

const ChangeItem = () => {
  const dispatch = useAppDispatch();
  const { currentItem } = useAppSelector((state) => state.offersReducer);
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState<IOfferChange>(emptyValues);
  const [validationSchema, setValidationSchema] = useState<AnySchema | null>(null);
  const { selectedCountry, openedCountries } = useAppSelector((state) => state.countriesSlice);
  const { setOpenedCountriesByKey } = countriesSlice.actions;
  const { list: tagsList } = useAppSelector((state) => state.tagsReducer);
  const { list: reviewsList } = useAppSelector((state) => state.reviewsReducer);
  const { list: offersList } = useAppSelector((state) => state.offersReducer);
  const openedCountriesCodes = useAppSelector(getOpenedCountriesCodes);

  useEffect(() => {
    if (!id) return;
    dispatch(fetchItem(id));
  }, []);

  const handleDelete = () => {
    if (!currentItem.data) return;
    dispatch(deleteItem(currentItem.data.id));
  };

  const { ModalComp, handleShowModal } = useModal(handleDelete);

  useEffect(() => {
    if (!currentItem.loading && currentItem.data) {
      const localizations = getLocalizationsFromData(
        currentItem.data as unknown as ObjectWithKeys,
        [
          "bonus",
          "shortDescription",
          "feedbackInfo",
          "downloadTitle",
          "downloadSubtitle",
          "description",
          "images",
        ],
      );
      dispatch(setOpenedCountriesByKey(localizations));
    }
  }, [currentItem]);

  useEffect(() => {
    if (!currentItem.loading && currentItem.data) {
      const data = currentItem.data;
      const emptyObject: ObjectWithKeys = {};
      const objectFiles: ObjectWithKeys<File[] | null[]> = {};

      openedCountriesCodes.forEach((item) => {
        emptyObject[item] = "";
        objectFiles[item] = [];
      });

      setInitialValues({
        ...data,
        type: offerTypeOptions.find((item) => item.value === data.type) || null,
        bonus: {
          ...emptyObject,
          ...filterDataByCountries(data.bonus, openedCountriesCodes),
        },
        preview: {
          ...emptyObject,
          ...filterDataByCountries(data.preview, openedCountriesCodes),
        },
        download: {
          ...emptyObject,
          ...filterDataByCountries(data.download, openedCountriesCodes),
        },
        subtitle: {
          ...emptyObject,
          ...filterDataByCountries(data.subtitle, openedCountriesCodes),
        },
        description: {
          ...emptyObject,
          ...filterDataByCountries(data.description, openedCountriesCodes),
        },
        images: {
          ...objectFiles,
          ...filterDataByCountries(data.images, openedCountriesCodes),
        },
      });
      const validation = getValidation(openedCountriesCodes);
      setValidationSchema(validation);
    }
  }, [openedCountriesCodes]);

  const fields = useMemo(
    () =>
      createFields({
        asyncTags: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchTagsList(params)),
          isLoading: tagsList.loading,
        },
        asyncReviews: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchReviewsList(params)),
          isLoading: reviewsList.loading,
        },
        asyncOffers: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchOffersList(params)),
          isLoading: offersList.loading,
        },
        tagOptions: tagsList.data,
        reviewOptions: reviewsList.data,
        offerOptions: offersList.data.filter((offer) => offer.id !== id),
        selectedCountry,
      }),
    [reviewsList, tagsList, offersList, selectedCountry],
  );

  const handleSubmit = (values: IOfferChange) => {
    dispatch(changeItem({ data: values, localizations: openedCountries.map((item) => item.code) }));
  };

  if (currentItem.loading) {
    return <Loading />;
  }

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <div className="d-flex">
          <Link to="/offers" className="w-auto">
            <Button className="w-auto mb-3" variant="primary">
              Back
            </Button>
          </Link>
          <Button className="w-auto mb-3" variant="danger" onClick={handleShowModal}>
            Delete
          </Button>
        </div>
        <PageTitle title="Edit offer" />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <LanguagesGroup />
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {selectedCountry &&
                      fields.map((field) => (
                        <Field
                          key={field.name}
                          {...field}
                          prefix={field.prefix === true ? selectedCountry.code : false}
                        />
                      ))}
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                  >
                    Save
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
      </div>
      {ModalComp}
    </div>
  );
};

export default ChangeItem;
