import { RequestList } from "../components/common/tables/types";
import { Api, filterEmpyValues, ListRes, ModelRes, formApi } from "./utils";
import { getResponseData } from "./utils";
import { ISettingFilter, ISetting, ISettingChangeRequest } from "../models/ISettings";

const baseURL = "/v1/settings";

export const getList = (params: RequestList<ISettingFilter>): Promise<ListRes<ISetting>> =>
  Api.post(baseURL + "/list", filterEmpyValues(params)).then(getResponseData);

export const changeItem = (data: ISettingChangeRequest): Promise<ModelRes<ISetting>> =>
  formApi.post(baseURL, data).then(getResponseData);

export const getItem = (id: string): Promise<ModelRes<ISetting>> =>
  Api.get(`${baseURL}/${id}`).then(getResponseData);

export const deleteItem = (id: string) => Api.delete(`${baseURL}/${id}`);
