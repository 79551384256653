import * as apis from "../../api/review-api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IReview, IReviewChange, IReviewFiltersRequest } from "../../models/IReview";
import { ListRes } from "../../api/utils";
import { State } from "../reducers/reviewsSlice";
import { FetchOptionsParams } from "../../types/global";

export const fetchList = createAsyncThunk<
  ListRes<IReview>,
  FetchOptionsParams,
  { state: { reviewsReducer: State } }
>("reviews/fetchList", (params: FetchOptionsParams, { getState }) => {
  const state = getState().reviewsReducer;

  const filter: IReviewFiltersRequest = {
    ...state.filter,
    rating: state.filter.rating?.value || "",
  };

  const options = {
    filter,
    page: state.pagination.page,
    take: state.pagination.take,
    searchTerm: params.searchTerm,
  };

  if (!params.searchTerm) {
    delete options.searchTerm;
  }
  return apis.getList(options);
});

export const changeItem = createAsyncThunk("reviews/changeItem", (data: IReviewChange) =>
  apis.changeItem(data),
);

export const fetchItem = createAsyncThunk("reviews/fetchItem", (id: string) => apis.getItem(id));

export const deleteItem = createAsyncThunk("reviews/deleteItem", (id: string) =>
  apis.deleteItem(id),
);
