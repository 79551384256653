import * as Yup from "yup";
import { ILocalizationChange } from "../../models/ILocalizations";
import { IField, TypesOfInput } from "../../models/fields/IField";

export const createLocalizationValidation = Yup.object({
  country: Yup.string().required("Required"),
  language: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
});

export const createLocalizationFields: IField<ILocalizationChange>[] = [
  {
    label: "Location",
    name: "location",
    placeholder: "Location",
    type: TypesOfInput.TEXT,
  },
  {
    label: "Name",
    name: "name",
    placeholder: "Name",
    type: TypesOfInput.TEXT,
  },
  {
    label: "Country",
    name: "country",
    placeholder: "Country",
    type: TypesOfInput.TEXT,
  },
  {
    label: "Language",
    name: "language",
    placeholder: "Language",
    type: TypesOfInput.TEXT,
  },
];
