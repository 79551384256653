import React, { useEffect, useMemo, useRef, useState } from "react";
import { Formik, Form, FormikProps } from "formik";
import { Button } from "react-bootstrap";
import { AnySchema } from "yup";
import Field from "../../components/common/form/formik/Field";
import PageTitle from "../../components/common/items/page-title";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { createFields, getValidation } from "../../components/fields/create-tag";
import { emptyValues, ITagChange } from "../../models/ITag";
import { ObjectWithKeys } from "../../types/global";
import { changeItem } from "../../store/thunks/tag";
import LanguagesGroup from "../../components/common/form/LanguagesGroup";
import { getOpenedCountriesCodes } from "../../store/reducers/countriesSlice";
import { filterDataByCountries } from "../../api/utils";

const CreateItem = () => {
  const dispatch = useAppDispatch();
  const formikRef: React.Ref<FormikProps<ITagChange>> | null = useRef(null);
  const [initialValues, setInitialValues] = useState<ITagChange>(emptyValues);
  const [validationSchema, setValidationSchema] = useState<AnySchema | null>(null);
  const { selectedCountry, openedCountries } = useAppSelector((state) => state.countriesSlice);
  const openedCountriesCodes = useAppSelector(getOpenedCountriesCodes);

  useEffect(() => {
    const emptyObject: ObjectWithKeys = {};
    openedCountriesCodes.forEach((item) => {
      emptyObject[item] = "";
    });
    if (formikRef && formikRef.current) {
      setInitialValues({
        ...formikRef.current.values,
        name: {
          ...emptyObject,
          ...filterDataByCountries(formikRef.current.values.name, openedCountriesCodes),
        },
      });
    }
    const validation = getValidation(openedCountriesCodes);
    setValidationSchema(validation);
  }, [openedCountries]);

  const fields = useMemo(
    () =>
      createFields({
        selectedCountry,
      }),
    [selectedCountry],
  );

  const handleSubmit = (values: ITagChange) => {
    dispatch(changeItem(values));
  };

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <Link to="/tags" className="w-auto">
          <Button className="w-auto mb-3" variant="primary">
            Back
          </Button>
        </Link>
        <PageTitle title="Creating tag" />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <LanguagesGroup />
              <Formik
                innerRef={formikRef}
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {fields.map((field) => (
                      <Field
                        key={field.name}
                        {...field}
                        prefix={field.prefix === true ? selectedCountry?.code : false}
                      />
                    ))}
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                  >
                    Create
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CreateItem;
