import React, { memo, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { IAffiliate } from "../../models/IAffiliates";
import { SettingsTableKey } from "../../models/IUserSettings";
import Loading from "../../pages/_layouts/Loading";
import { affiliatesSlice } from "../../store/reducers/affiliatesSlice";
import { changeStatus, fetchAffiliates } from "../../store/thunks/affiliates";
import Table from "../common/tables/Table";
import getColumnDefsAffiliates from "./ColumnDefs";

interface Props {
  className?: string;
}

const AffiliatesTable = ({ className }: Props) => {
  const dispatch = useAppDispatch();
  const { pagination, affiliates } = useAppSelector((state) => state.affiliatesReducer);
  const { setPagination } = affiliatesSlice.actions;

  const onChangePagination = (page: number) => {
    dispatch(setPagination({ page }));
    dispatch(fetchAffiliates());
  };

  const onChangePerPage = (take: number) => {
    dispatch(setPagination({ take }));
    dispatch(fetchAffiliates());
  };

  const handleChangeStatus = (status: boolean, id: string) => {
    dispatch(changeStatus({ id, status }));
  };

  const columns = useMemo(
    () =>
      getColumnDefsAffiliates({
        handleChangeStatus,
        pagination,
      }),
    [pagination, affiliates],
  );

  if (affiliates.loading) {
    return <Loading />;
  }

  return (
    <Table<IAffiliate>
      pageKey={SettingsTableKey.AFFILIATES}
      changePage={onChangePagination}
      changePageSize={onChangePerPage}
      className={className}
      columns={columns}
      containerClass="overflow-auto"
      fixed
      items={affiliates.data}
      pagination={pagination}
    />
  );
};

export default memo(AffiliatesTable);
