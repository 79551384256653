import { RequestList } from "../components/common/tables/types";
import { FindByNamesRequest, FindByNamesResponse, IFilterLocalization, ILocalization, ILocalizationChange } from "../models/ILocalizations";
import { Api, ApiNonCamelize, filterEmpyValues, ListRes, ModelRes } from "./utils";
import { getResponseData } from "./utils";
import { toast } from "react-toastify";

const PATH = "/v1/localizations";

export const getLocalizations = (params: RequestList<IFilterLocalization>): Promise<ListRes<ILocalization>> => {
  return Api.post(`${PATH}/list`, filterEmpyValues(params)).then(getResponseData);
};

export const getLocalization = (id: string): Promise<ModelRes<ILocalization>> => {
  return Api.get(`${PATH}/${id}`).then(getResponseData);
};

export const getLocalizationsByName = (data: FindByNamesRequest): Promise<FindByNamesResponse> => {
  return ApiNonCamelize.post(`${PATH}/find-by-names`, data).then(getResponseData);
};

export const changeLocalization = (data: ILocalizationChange): Promise<ModelRes<ILocalization>> => {
  return Api.post(PATH, data).then((data: { data: ILocalization }) => {
    toast.success(`Localization ${data?.data?.name} successfully created`);
    return { model: data.data };
  });
};

export const deleteLocalization = (id: string) => {
  return Api.delete(`${PATH}/${id}`);
};
