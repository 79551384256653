import { IFilterLocalization } from "../../models/ILocalizations";
import { IField, TypesOfInput } from "../../models/fields/IField";
import { createLocalizationFields } from "./create-localization";

export const localizationFilters = (): IField<IFilterLocalization>[] => [
  ...createLocalizationFields,
  {
    label: "Created",
    name: "createdAt",
    placeholder: "Created",
    type: TypesOfInput.DATEPICKER_RANGE,
  },
];
