import ColorLabel from "../../../components/common/items/ColorLabel";
import { ColumnDef, PaginationType } from "../../../components/common/tables/types";
import { statusOptions } from "../../../models/IStatus";
import { LeadsStatistic } from "../../../models/IStatistics";
import { formatDateFullTime } from "../../../utils/formats";

type IColumn = ColumnDef<LeadsStatistic>;

interface Options {
  pagination: PaginationType;
}

export default function getColumnDefs(options: Options): IColumn[] {
  const { pagination } = options;

  const numberIndex: IColumn = {
    Cell: ({ row: { index } }: { row: { index: number } }) => {
      return <div>{index + (pagination.from || 1)}</div>;
    },
    Header: "№",
    disableFilters: true,
    disableSortBy: true,
    width: "25px",
    id: "№",
  };

  const email: IColumn = {
    Header: "Email",
    accessor: "email",
    width: "auto",
    id: "email",
  };

  const status: IColumn = {
    Cell: (cell) => {
      if (!cell.value) return <span>-</span>
      const currVal = statusOptions.find((item) => item.value === cell.value);
      if (!currVal) return <span>{cell.value}</span>
      return (
        <div className="d-flex justify-between">
          <ColorLabel
            className="d-inline"
            colors={statusOptions}
            value={cell.value}
            label={currVal.label}
          />
        </div>
      )
    },
    Header: "Status",
    accessor: "status",
    width: "auto",
    id: "status",
  };

  const depositedAt: IColumn = {
    Cell: (cell) => <span>{cell.value ? formatDateFullTime(cell.value) : "-"}</span>,
    Header: "Deposited at",
    accessor: "depositedAt",
    width: "auto",
    id: "depositedAt",
  };

  const createdAt: IColumn = {
    Cell: (cell) => <span>{formatDateFullTime(cell.value)}</span>,
    Header: "Created",
    accessor: "createdAt",
    width: "auto",
    id: "createdAt",
  };

  return [numberIndex, email, status, depositedAt, createdAt];
}
