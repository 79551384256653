import { PaginationQuery } from "../components/common/tables/types";
import { ListItem, Modify } from "../types/global";
import { GenericOption, OptionModel } from "./fields/ISelect";
import { IAffiliate } from "./IAffiliates";
import { IOffer } from "./IOffer";
import { PushStatus } from "./IPush";

export type IPlayer = ListItem<{
  ip: string;
  device: string;
  locale: string;
  language: string;
  pushId?: string;
  clickId?: string;
  hash: string;
  status: PushStatus;
  affiliate: OptionModel<IAffiliate>;
  offer: IOffer;
  sendTo: string;
}>;

export type IPlayerFilters = {
  offer: OptionModel<IOffer>;
  status: OptionModel<GenericOption<PushStatus>>;
  hash: string;
};

export type IPlayerRequest = Modify<
  Partial<IPlayerFilters>,
  {
    status: PushStatus | "";
    offerId: string;
  }
>;

export type IPlayerFiltersRequest = PaginationQuery & IPlayerRequest;

export const emptyFilters: IPlayerFilters = {
  status: null,
  offer: null,
  hash: "",
};
