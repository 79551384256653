import React from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { Link } from "react-router-dom";
import Field from "../../components/common/form/formik/Field";
import PageTitle from "../../components/common/items/page-title";
import {
  createAffiliateFields,
  createAffiliateValidation,
} from "../../components/fields/create-affiliate";
import { fetchList } from "../../store/thunks/users";
import { fetchList as fetchTeamList } from "../../store/thunks/teams";
import { emptyValues, IAffiliate, IAffiliateChange } from "../../models/IAffiliates";
import { changeAffiliate } from "../../store/thunks/affiliates";
import { RejectedWithValueAction } from "../../store/thunks/createAppThunk";
import { FetchOptionsParams } from "../../types/global";

const CreateAffiliate = () => {
  const dispatch = useAppDispatch();
  const { list } = useAppSelector((state) => state.usersReducer);
  const { list: teamList } = useAppSelector((state) => state.teamsSlice);

  const handleSubmit = async (
    values: IAffiliateChange,
    formikHelpers: FormikHelpers<IAffiliateChange>,
  ) => {
    const result = (await dispatch(
      changeAffiliate(values),
    )) as unknown as RejectedWithValueAction<IAffiliate>;
    if (result.payload) {
      formikHelpers.setErrors(result.payload.errors);
    }
  };

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <Link to="/affiliates" className="w-auto">
          <Button className="w-auto mb-3" variant="primary">
            Back
          </Button>
        </Link>
        <PageTitle title="Creating affiliate" />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Formik
                enableReinitialize
                initialValues={emptyValues}
                validationSchema={createAffiliateValidation}
                onSubmit={handleSubmit}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {createAffiliateFields({
                      asyncUsers: {
                        fetchCallback: (params: FetchOptionsParams) => dispatch(fetchList(params)),
                        isLoading: list.loading,
                      },
                      userOptions: list.data,
                      asyncTeams: {
                        fetchCallback: (params: FetchOptionsParams) => dispatch(fetchTeamList(params)),
                        isLoading: teamList.loading,
                      },
                      teamOptions: teamList.data,
                    }).map((field) => (
                      <Field key={field.name} {...field} />
                    ))}
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                  >
                    Create
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CreateAffiliate;
