import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store/reducers/index";
import { BrowserRouter } from "react-router-dom";

const domNode = document.getElementById("root");
const root = createRoot(domNode as Element);
root.render(<BrowserRouter basename={process.env.PUBLIC_URL}>
  <Provider store={store}>
    <App />
  </Provider>
</BrowserRouter>);
