import { Link } from "react-router-dom";
import { ITransaction, TransactionType, typeOptions } from "../../models/ITransactions";
import { formatDateFullTime, formatOption } from "../../utils/formats";
import ColorLabel from "../common/items/ColorLabel";
import { ColumnDef, PaginationType } from "../common/tables/types";

type ITransactionColumn = ColumnDef<ITransaction>;

interface Options {
  pagination: PaginationType;
}

export default function getColumnDefsTransactions(options: Options): ITransactionColumn[] {
  const { pagination } = options;

  const numberIndex: ITransactionColumn = {
    Cell: ({ row: { index } }: { row: { index: number } }) => {
      return <div>{index + (pagination.from || 1)}</div>;
    },
    Header: "№",
    id: "№",
    disableFilters: true,
    disableSortBy: true,
    width: "25px",
  };

  const client: ITransactionColumn = {
    Cell: (cell) =>
      cell.value?.name ? (
        <Link className="font-semibold" to={`/clients/${cell.value.id}`}>
          {cell.value.name}
        </Link>
      ) : (
        <span>No</span>
      ),
    Header: "Client",
    id: "client",
    accessor: "client",
    width: "70px",
  };

  const balance: ITransactionColumn = {
    Header: "Balance",
    id: "balance",
    accessor: "balance",
    width: "30px",
  };

  const amount: ITransactionColumn = {
    Header: "Amount",
    id: "amount",
    accessor: "amount",
    width: "30px",
  };

  const type: ITransactionColumn = {
    Cell: (cell) =>
      cell.value && (
        <div className="d-flex justify-between">
          {formatOption<TransactionType>(cell.value, typeOptions)}
          <ColorLabel className="d-inline" colors={typeOptions} value={cell.value} />
        </div>
      ),
    Header: "Type",
    id: "type",
    accessor: "type",
    width: "70px",
  };

  const comment: ITransactionColumn = {
    Header: "Comment",
    id: "comment",
    accessor: "comment",
    width: "60px",
  };

  const createdAt: ITransactionColumn = {
    Cell: (cell) => <span>{cell.value ? formatDateFullTime(cell.value) : ""}</span>,
    Header: "Created",
    id: "createdAt",
    accessor: "createdAt",
    width: "80px",
  };

  return [numberIndex, client, balance, amount, type, comment, createdAt];
}
