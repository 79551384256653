import { ILabel, emptyFilters, ILabelFilter } from "../../models/ILabels";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { editLabel, fetchLabels } from "../thunks/labels";
import { PaginationQuery, PaginationType } from "../../components/common/tables/types";
import { FetchedStoreModel, PAGINATION_TAKE } from "../../types/global";
import { toast } from "react-toastify";

export interface LabelsState {
  labels: FetchedStoreModel<ILabel[]>;
  currentLabel: ILabel | null;
  pagination: PaginationType;
  filter: ILabelFilter;
}

const initialState: LabelsState = {
  labels: {
    data: [],
    error: null,
    loading: true,
  },
  currentLabel: null,
  pagination: {
    take: PAGINATION_TAKE,
    total: null,
    page: 1,
    lastPage: null,
  },
  filter: emptyFilters,
};

export const labelsSlice = createSlice({
  name: "labels",
  initialState,
  reducers: {
    setPagination: (state, action: PayloadAction<PaginationQuery>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    setFilters: (state, action: PayloadAction<ILabelFilter>) => {
      state.filter = action.payload;
      state.pagination.page = 1;
    },
  },
  extraReducers: (builder) => {
    // fetchLabels
    builder.addCase(fetchLabels.pending, (state) => {
      state.labels.loading = true;
      state.labels.error = null;
    });
    builder.addCase(fetchLabels.fulfilled, (state, action) => {
      state.labels.data = action.payload.list;
      state.labels.loading = false;
      state.pagination = { ...state.pagination, ...action.payload.pagination };
    });
    builder.addCase(fetchLabels.rejected, (state, action) => {
      state.labels.loading = false;
      state.labels.error = action.payload as FixMeLater;
    });

    // editLabel
    builder.addCase(editLabel.fulfilled, (state, action) => {
      state.currentLabel = action.payload.model;
      toast.success("Label successfully updated");
      window.location.pathname = "/labels";
    });
  },
});

export default labelsSlice.reducer;
