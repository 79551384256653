import React, { memo, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { IDictionaryWord } from "../../models/IDictionaryWords";
import { SettingsTableKey } from "../../models/IUserSettings";
import Loading from "../../pages/_layouts/Loading";
import { dictionaryWordsSlice } from "../../store/reducers/dictionaryWordsSlice";
import { fetchLocalizations } from "../../store/thunks/localizations";
import Table from "../common/tables/Table";
import getColumnDefsDictionaryWords from "./DictionaryWordsColumnDefs";

interface Props {
  className?: string;
}

const DictionaryWordsTable = ({ className }: Props) => {
  const dispatch = useAppDispatch();
  const { pagination, dictionaryWords } = useAppSelector((state) => state.dictionaryWordsReducer);
  const { setPagination } = dictionaryWordsSlice.actions;

  const onChangePagination = (page: number) => {
    dispatch(setPagination({ page }));
    dispatch(fetchLocalizations({}));
  };

  const onChangePerPage = (take: number) => {
    dispatch(setPagination({ take }));
    dispatch(fetchLocalizations({}));
  };

  const columns = useMemo(
    () =>
      getColumnDefsDictionaryWords({
        pagination,
      }),
    [pagination, dictionaryWords],
  );

  if (dictionaryWords.loading) {
    return <Loading />;
  }

  return (
    <Table<IDictionaryWord>
      pageKey={SettingsTableKey.DICTIONARY_WORDS}
      changePage={onChangePagination}
      changePageSize={onChangePerPage}
      className={className}
      columns={columns}
      containerClass="overflow-auto"
      fixed
      items={dictionaryWords.data}
      pagination={pagination}
    />
  );
};

export default memo(DictionaryWordsTable);
