import { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDeleteClientsStatisticsMutation, useLazyGetClientsConversationsStatisticQuery, useLazyGetClientsLeadsStatisticQuery, useLazyGetClientsStatusesStatisticQuery } from "../../../api/statistics-clients-api";
import FilterForm from "../../../components/common/form/formik/FilterForm";
import Table from "../../../components/common/tables/Table";
import { filterClientReportsFields } from "../../../components/fields/create-fake-statistics";
import { clientFilters, ClientsLeadsStatisticT, ClientsStatisticFilterT, ClientsStatisticRequestT, DestroyResponseT } from "../../../models/IClientsStatistics";
import { SettingsTableKey } from "../../../models/IUserSettings";
import { formatDate, stringToDate } from "../../../utils/formats";
import Loading from "../../_layouts/Loading";
import SimpleConversionChart from "../SimpleConversionChart";
import { getLeadsColumnDefs } from "./columnDefs";

const ClientsReports = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [savedFilters, setSavedFilters] = useState<ClientsStatisticFilterT>(clientFilters);
  const [deleteClientsStatisticsMutation, { isLoading }] = useDeleteClientsStatisticsMutation();

  const formatRequestFilters = (filter: ClientsStatisticFilterT): ClientsStatisticRequestT => ({
    type: "query",
    filter
  });

  const [fetchClientsConversations, { data: conversations, isFetching: isFetchingConversations }] = useLazyGetClientsConversationsStatisticQuery();
  const [fetchClientsLeads, { data: leads, isFetching: isFetchingLeads }] = useLazyGetClientsLeadsStatisticQuery();
  const [fetchStatusesStatistic, { data: statuses, isFetching: isFetchinStatuses }] = useLazyGetClientsStatusesStatisticQuery();

  useEffect(() => {
    fetchClientsConversations(formatRequestFilters(savedFilters))
    fetchClientsLeads(formatRequestFilters(savedFilters))
    fetchStatusesStatistic(formatRequestFilters(savedFilters))
  }, [savedFilters])

  const columns = useMemo(() => getLeadsColumnDefs(), []);
  const fields = useMemo(() => filterClientReportsFields(), []);

  const handleFilter = (filters: ClientsStatisticFilterT) => {
    setSavedFilters({...filters})
    return false;
  };

  const handleDelete = () => {
    deleteClientsStatisticsMutation(formatRequestFilters(savedFilters)).then(({ data: { data }}: FixMeLater ) => {
      toast.success(`${data.deleted} rows successfully deleted`);
    })
  }

  if (isFetchingConversations || isFetchingLeads || isFetchinStatuses || !savedFilters) return <Loading />;

  const formatFilterDate = (
    dateOrStr: (null | string | Date)[],
  ): string => {
    if (!dateOrStr) return '';
    if (typeof dateOrStr[0] === "string" && typeof dateOrStr[1] === "string") return `${formatDate(stringToDate(dateOrStr[0]))} - ${formatDate(stringToDate(dateOrStr[1]))}`;
    return `${formatDate(dateOrStr[0])} - ${formatDate(dateOrStr[1])}`
  };

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <div className="d-flex mb-3">
          <Button
            variant="primary"
            className="mb-3 mr-5"
            onClick={() => setIsFilterOpen((prevState) => !prevState)}
          >
            {isFilterOpen ? "Close" : "Open"} Filters
          </Button>
          <Button
            variant="danger"
            className="mb-3"
            disabled={isLoading}
            onClick={handleDelete}
          >
            Delete by filters
          </Button>
        </div>
        {isFilterOpen && (
          <FilterForm
            className="mb-3"
            fields={fields}
            handleSubmit={handleFilter}
            initialValues={savedFilters}
          />
        )}
      </div>
      <div className="row">
        {conversations && (
          <div className="offset-md-3 col-12 col-md-6">
            <div className="mb-4 card">
              <div className="card-header py-3 flex justify-content-between">
                <p>Conversion {savedFilters.country}{savedFilters.language && savedFilters.country && ' / '}{savedFilters.language}</p>
                <p>{formatFilterDate(savedFilters.sentAt)}</p>
              </div>
              <div className="card-body">
                <SimpleConversionChart {...conversations.data} />
              </div>
            </div>
          </div>
        )}
        <div className="col-md-12">
          <div className="mb-4 card">
            <div className="card-header py-3">
              <p className="card-heading">Statuses</p>
            </div>
            <div className="card-body table-responsive">
              <table className="card-text table overflow-auto Table--fixed">
                <thead>
                  <tr>
                    {statuses?.data?.map((item) => {
                      const [key] = Object.keys(item);
                      return (
                        <th key={key} style={{ width: "100px" }}>{key}</th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {statuses?.data?.map((item) => {
                      const [[key, value]] = Object.entries(item);
                      return (
                        <th key={key} style={{ width: "100px" }}>{value}</th>
                      )
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="mb-4 card">
            <div className="card-header py-3">
              <p className="card-heading">Leads</p>
            </div>
            <div className="card-body">
              {isFetchingLeads ? (
                <Loading />
              ) : (
                <Table<ClientsLeadsStatisticT>
                  pageKey={SettingsTableKey.REPORTS_CLIENTS_LEADS}
                  columns={columns}
                  containerClass="overflow-auto"
                  items={leads || []}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientsReports;
