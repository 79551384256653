import { Formik, Form } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import FieldBlock from "../../components/common/form/formik/FieldBlock";
import PageTitle from "../../components/common/items/page-title";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  HeaderElement,
  ParameterElement,
  IIntegrationChange,
  emptyValues,
  bodyValueOptions,
  answerOptions,
  httpMethodOptions,
  redirectOptions,
  statusOptions,
  typeOptions,
  BodyElement,
  headerValueOptions,
} from "../../models/IIntegration";
import { changeIntegration, fetchOneIntegration } from "../../store/thunks/integrations";
import Loading from "../_layouts/Loading";
import { filterExtraFieldsFromArray, getValuesFromModel } from "../../api/utils";
import { deleteIntegration } from "../../api/integrations-api";
import {
  editIntegrationFields,
  integrationValidation,
} from "../../components/fields/create-integration";
import useModal from "../../hooks/useModal";
import { decamelizeKeys } from "humps";

const EditIntegration = () => {
  const { integrationId, id: clientId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentIntegration } = useAppSelector((state) => state.integrationsReducer);
  const [initialValues, setInitialValues] = useState(emptyValues);

  useEffect(() => {
    if (!integrationId) return;
    dispatch(fetchOneIntegration(integrationId));
  }, []);

  const handleDeleteIntegration = () => {
    if (currentIntegration.data) {
      void deleteIntegration(currentIntegration.data.id);
      navigate(clientId ? `/clients/${clientId}/integrations` : "/logs");
    }
  };

  const { ModalComp, handleShowModal } = useModal(handleDeleteIntegration);

  useEffect(() => {
    if (!currentIntegration.loading && currentIntegration.data) {
      const current = currentIntegration.data;
      const postback = current.type === "postback"
        ? JSON.stringify(decamelizeKeys(current.postback ?? {}), null, 2)
        : current.postback;

      const data = getValuesFromModel<IIntegrationChange>(
        {
          ...current,
          answer: current.answer
            ? {
                ...current.answer,
                type:
                  answerOptions.find((option) => option.value === current?.answer?.type) || null,
              }
            : null,
          body: filterExtraFieldsFromArray(current.body, ["key", "value"]).map((item) => {
            const bodyOption = bodyValueOptions.find((option) => option.value === item.value);
            return {
              ...item,
              value:
                bodyOption || item.value
                  ? {
                      label: item.value,
                      value: item.value,
                    }
                  : null,
            };
          }) as BodyElement[],
          headers: filterExtraFieldsFromArray(current.headers, ["key", "value"]).map((item) => {
            const headerOption = headerValueOptions.find((option) => option.value === item.value);
            return {
              ...item,
              value:
              headerOption || item.value
                  ? {
                      label: item.value,
                      value: item.value,
                    }
                  : null,
            };
          }) as HeaderElement[],
          method: httpMethodOptions.find((option) => option.value === current.method) || null,
          parameters: filterExtraFieldsFromArray<ParameterElement>(current.parameters || [], [
            "key",
            "value",
            "landing",
          ]),
          redirect: current.redirect
            ? {
                ...current.redirect,
                type:
                  redirectOptions.find((option) =>
                    current.redirect ? option.value === current.redirect.type : option,
                  ) || null,
              }
            : {
                value: "",
                type: null,
              },
          status: statusOptions.find((option) => option.value === current.status) || null,
          type: typeOptions.find((option) => option.value === current.type) || null,
          wrapArray: Boolean(current.wrapArray),
          withoutPlus: Boolean(current.withoutPlus),
          postback,
        },
        emptyValues,
      );

      setInitialValues({...data, id: integrationId ?? ""});
    }
  }, [currentIntegration]);

  const handleSubmit = (values: IIntegrationChange) => {
    if (clientId) {
      dispatch(
        changeIntegration({
          ...values,
          clientId: clientId,
        }),
      );
    }
  };

  const fields = useMemo(() => editIntegrationFields(initialValues), [initialValues]);

  if (currentIntegration.loading) {
    return <Loading />;
  }

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <div className="d-flex justify-between">
          <Link to={clientId ? `/clients/${clientId}/integrations` : "/logs"} className="w-auto">
            <Button className="w-auto mb-3" variant="primary">
              Back
            </Button>
          </Link>
          <Button className="w-auto mb-3" variant="danger" onClick={handleShowModal}>
            Delete Integration
          </Button>
        </div>
        <PageTitle title={"Edit integration " + currentIntegration.data?.name} />
        <div className="d-flex mb-3">
          {clientId &&
            (currentIntegration.data?.token ? (
              <Link className="btn btn-primary" to={`/clients/${clientId}/token/edit`}>
                Edit Token
              </Link>
            ) : (
              <Link className="btn btn-primary" to={`/clients/${clientId}/token/new`}>
                Create Token
              </Link>
            ))}
        </div>
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={integrationValidation}
                onSubmit={handleSubmit}
              >
                {({ values }) => {
                  useEffect(() => {
                    if (values.type?.value === "postback") {
                      setInitialValues({
                        ...values,
                        postback: values.postback
                      });
                    }
                  }, [values]);

                  return (
                    <Form className="w-100 px-4">
                      <div className="row">
                        {fields.map((field) => (
                          <FieldBlock key={field.title} {...field} />
                        ))}
                      </div>
                      <Button
                        type="submit"
                        className="form-control w-25 m-auto d-block"
                        variant="primary"
                      >
                        Save
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </section>
      </div>
      {ModalComp}
    </div>
  );
};

export default EditIntegration;
