import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { PaginationQuery, PaginationType } from "../../components/common/tables/types";
import {
  emptyFilters,
  IDictionaryWord,
  IFilterDictionaryWord,
} from "../../models/IDictionaryWords";
import { FetchedStoreModel, PAGINATION_TAKE } from "../../types/global";
import {
  changeDictionaryWord,
  fetchDictionaryWords,
  fetchOneDictionaryWord,
} from "../thunks/dictionaryWords";

export interface dictionaryWordsState {
  dictionaryWords: FetchedStoreModel<IDictionaryWord[]>;
  currentDictionaryWord: FetchedStoreModel<IDictionaryWord | null>;
  pagination: PaginationType;
  filter: IFilterDictionaryWord;
}

const initialState: dictionaryWordsState = {
  dictionaryWords: {
    data: [],
    error: null,
    loading: true,
  },
  currentDictionaryWord: {
    data: null,
    error: null,
    loading: true,
  },
  pagination: {
    take: PAGINATION_TAKE,
    total: null,
    page: 1,
    lastPage: null,
  },
  filter: emptyFilters,
};

export const dictionaryWordsSlice = createSlice({
  name: "dictionaryWords",
  initialState,
  reducers: {
    setPagination: (state, action: PayloadAction<PaginationQuery>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    setFilters: (state, action: PayloadAction<IFilterDictionaryWord>) => {
      state.filter = action.payload;
      state.pagination.page = 1;
    },
    resetFilters: (state) => {
      state.filter = emptyFilters;
      state.pagination.page = 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDictionaryWords.pending, (state) => {
      state.dictionaryWords.loading = true;
      state.dictionaryWords.error = null;
    });
    builder.addCase(fetchDictionaryWords.fulfilled, (state, action) => {
      state.dictionaryWords.data = action.payload.list;
      state.dictionaryWords.loading = false;
      state.pagination = { ...state.pagination, ...action.payload.pagination };
    });
    builder.addCase(fetchDictionaryWords.rejected, (state, action) => {
      state.dictionaryWords.loading = false;
      state.dictionaryWords.error = action.payload as FixMeLater;
    });
    builder.addCase(fetchOneDictionaryWord.pending, (state) => {
      state.currentDictionaryWord.loading = true;
      state.currentDictionaryWord.error = null;
    });
    builder.addCase(fetchOneDictionaryWord.fulfilled, (state, action) => {
      state.currentDictionaryWord.data = action.payload.model;
      state.currentDictionaryWord.loading = false;
    });
    builder.addCase(fetchOneDictionaryWord.rejected, (state, action) => {
      state.currentDictionaryWord.loading = false;
      state.currentDictionaryWord.error = action.payload as FixMeLater;
    });
    builder.addCase(changeDictionaryWord.fulfilled, (state, action) => {
      state.currentDictionaryWord.data = action.payload.model;
      toast.success(`Dictionary word ${action.payload.model.title} successfully updated`);
      window.location.pathname = "/dictionary-words";
    });
  },
});

export default dictionaryWordsSlice.reducer;
