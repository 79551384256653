import * as apis from "../../api/user-settings-api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { SettingType } from "../../models/ISettings";
import { TableSettings, SettingsTableKey } from "../../models/IUserSettings";

export const changeTableSettings = createAsyncThunk(
  "user-settings/changeTableSettings",
  (data: TableSettings<UnknownRecord>) => {
    const sendingData = {
      type: SettingType.USER_TABLE_COLUMN,
      ...data
    };
    return apis.postTableSettings(sendingData);
  },
);

export const fetchTableSettings = createAsyncThunk("user-settings/fetchTableSettings", (key: SettingsTableKey) => (
  apis.getTableSettings(key)
));
