import * as pushesApi from "../../api/push-api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ModelRes, ResponseError } from "../../api/utils";
import { createAppThunk } from "./createAppThunk";
import { RootState } from "../reducers";
import { State } from "../reducers/pushesSlice";
import { IPush, IPushChange, IPushFiltersRequest } from "../../models/IPush";

export const fetchList = createAsyncThunk("pushes/fetchList", (_, thunkAPI) => {
  const rootState: RootState = thunkAPI.getState() as RootState;
  const state: State = rootState.pushesSlice;

  const options: IPushFiltersRequest = {
    ...state.filter,
    page: state.pagination.page,
    take: state.pagination.take,
    offerId: state.filter.offer?.id || "",
    status: state.filter.status ? state.filter.status?.value : "",
    type: state.filter.type ? state.filter.type?.value : "",
    offerType: state.filter.offerType ? state.filter.offerType?.value : "",
    isSend: Number(state.filter.isSend),
  };
  return pushesApi.getList(options);
});

export const fetchItem = createAsyncThunk("pushes/fetchItem", (id: string) =>
  pushesApi.getItem(id),
);

export const deleteItem = createAsyncThunk("pushes/deleteItem", (id: string) =>
  pushesApi.deleteItem(id),
);

export const changeItem = createAppThunk<ModelRes<IPush>, IPushChange & { id?: string }>(
  "pushes/changeItem",
  async (values: IPushChange & { id?: string }, thunkAPI) => {
    try {
      const response = await await pushesApi.changeItem(values);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error as ResponseError);
    }
  },
);
