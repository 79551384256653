import React from "react";
import { Field, ErrorMessage, FieldInputProps, FormikProps } from "formik";
import { IField } from "../../../../models/fields/IField";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const TextEditorField = <T extends UnknownRecord>(props: IField<T>) => {
  const { label, prefix, name, ...attr } = props;
  const fieldName = prefix ? `${name}.${prefix}` : name;

  return (
    <>
      {label && <label>{label}</label>}
      <Field {...attr} name={fieldName} component={Editor} />
      <ErrorMessage name={fieldName} render={(msg) => <div className="errorMessage">{msg}</div>} />
    </>
  );
};

const Editor = <T extends UnknownRecord>({
  field,
  form,
  ...props
}: {
  field: FieldInputProps<string>;
  form: FormikProps<T>;
}) => (
  <SunEditor
    {...field}
    {...props}
    onChange={(value) => {
      if (value !== "<p><br></p>") {
        form.setFieldValue(field.name, value);
      }
    }}
    setContents={field.value || ""}
    setOptions={{
      mode: "classic",
      katex: "window.katex",
      buttonList: [
        [
          "undo",
          "redo",
          "font",
          "fontSize",
          "formatBlock",
          "paragraphStyle",
          "blockquote",
          "bold",
          "underline",
          "italic",
          "strike",
          "subscript",
          "superscript",
          "fontColor",
          "hiliteColor",
          "textStyle",
          "removeFormat",
          "outdent",
          "indent",
          "align",
          "horizontalRule",
          "list",
          "lineHeight",
          "link",
          "fullScreen",
          "codeView",
          "preview",
        ],
      ],
    }}
  />
);

export default TextEditorField;
