import { createAsyncThunk } from "@reduxjs/toolkit";
import * as affiliatesApi from "../../api/affiliates-api";
import { ModelRes, ResponseError } from "../../api/utils";
import { IAffiliate, IAffiliateChange, IFilterAffiliateRequest, RequestAffiliate } from "../../models/IAffiliates";
import { FetchOptionsParams } from "../../types/global";
import { affiliatesState } from "../reducers/affiliatesSlice";
import { createAppThunk } from "./createAppThunk";

export const fetchAffiliates = createAsyncThunk("affiliates/fetchAffiliates", (_, thunkAPI) => {
  const state: FixMeLater = thunkAPI.getState();
  const affiliatesState: affiliatesState = state.affiliatesReducer;

  const filter: IFilterAffiliateRequest = {
    ...affiliatesState.filter,
    users: affiliatesState.filter.users?.map((user) => user?.id || "") || [],
    status: affiliatesState.filter.status?.map((option) => option.value) || [],
   };
  if (!filter.isAcceptDuplicate) {
    delete filter.isAcceptDuplicate;
  }
  if (!filter.isShowRedirect) {
    delete filter.isShowRedirect;
  }
  if (!filter.isSelf) {
    delete filter.isSelf;
  }
  const options: RequestAffiliate = {
    filter,
    page: affiliatesState.pagination.page,
    take: affiliatesState.pagination.take,
  };
  return affiliatesApi.getAffiliates(options);
});

export const fetchShortAffiliates = createAsyncThunk(
  "affiliates/fetchShortAffiliates",
  (params: FetchOptionsParams) => affiliatesApi.getShortAffiliates({ ...params, take: 15 }),
);

export const fetchOneAffiliate = createAsyncThunk("affiliates/fetchOneAffiliate", (id: string) =>
  affiliatesApi.getAffiliate(id),
);

export const changeAffiliate = createAppThunk<ModelRes<IAffiliate>, IAffiliateChange & { id?: string }>(
  "affiliates/changeAffiliate",
  async (values: IAffiliateChange & { id?: string }, thunkAPI) => {
    try {
      const response = await affiliatesApi.changeAffiliate(values);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error as ResponseError);
    }
  },
);

export const changeStatus = createAsyncThunk(
  "affiliates/changeStatusAffiliate",
  ({ status, id }: { status: boolean; id: string }) =>
    affiliatesApi.changeStatusAffiliate(status, id),
);
