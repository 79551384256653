import React, { memo, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { SettingsTableKey } from "../../models/IUserSettings";
import { ITransaction } from "../../models/ITransactions";
import Loading from "../../pages/_layouts/Loading";
import { transactionsSlice } from "../../store/reducers/transactionsSlice";
import { fetchTransactions } from "../../store/thunks/transactions";
import Table from "../common/tables/Table";
import getColumnDefsTransactions from "./TransactionsColumnDefs";

interface Props {
  className?: string;
}

const TransactionsTable = ({ className }: Props) => {
  const dispatch = useAppDispatch();
  const { pagination, transactions } = useAppSelector(state => state.transactionsReducer);
  const { setPagination } = transactionsSlice.actions;

  const onChangePagination = (page: number) => {
    dispatch(setPagination({ page }));
    dispatch(fetchTransactions());
  };

  const onChangePerPage = (take: number) => {
    dispatch(setPagination({ take }));
    dispatch(fetchTransactions());
  };

  const columns = useMemo(
    () => getColumnDefsTransactions({ pagination }),
    [pagination, transactions]
  );

  if (transactions.loading) {
    return <Loading />;
  }

  return (
    <Table<ITransaction>
      pageKey={SettingsTableKey.TRANSACTIONS}
      changePage={onChangePagination}
      changePageSize={onChangePerPage}
      className={className}
      columns={columns}
      containerClass="overflow-auto"
      fixed
      items={transactions.data}
      pagination={pagination}
    />
  );
};

export default memo(TransactionsTable);
