import { IFilterLead, ILead, initialLeadFilters } from "../../models/ILead";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  changeIsReplace,
  changeIsValid,
  deleteLead,
  editLead,
  fetchLeads,
  fetchOneLead,
  updateLeads
} from "../thunks/leads";
import { PaginationQuery, PaginationType } from "../../components/common/tables/types";
import { FetchedStoreModel, SortOrderByType } from "../../types/global";
import { toast } from "react-toastify";

export interface LeadsState extends SortOrderByType<ILead> {
  leads: FetchedStoreModel<ILead[]>;
  currentLead: FetchedStoreModel<ILead | null>;
  pagination: PaginationType;
  filter: IFilterLead;
  searchTerm: string;
  selectedItems: string[];
}

const initialState: LeadsState = {
  leads: {
    data: [],
    error: null,
    loading: true,
    fetchedCount: 0,
  },
  currentLead: {
    data: null,
    error: null,
    loading: true,
  },
  pagination: {
    take: 150,
    total: null,
    page: 1,
    lastPage: null,
  },
  selectedItems: [],
  filter: initialLeadFilters,
  searchTerm: "",
  orderBy: "createdAt",
  order: "ASC",
};

export const leadsSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {
    selectLeads: (state, action: PayloadAction<string[]>) => {
      state.selectedItems = action.payload;
    },
    setPagination: (state, action: PayloadAction<PaginationQuery>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    setFilters: (state, action: PayloadAction<IFilterLead>) => {
      state.filter = action.payload;
      state.pagination.page = 1;
    },
    resetFilters: (state) => {
      state.filter = initialLeadFilters;
      state.pagination.page = 1;
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
      state.pagination.page = 1;
    },
    setOrderBy: (state, action: PayloadAction<SortOrderByType<ILead>>) => {
      state.orderBy = action.payload.orderBy;
      state.order = action.payload.order;
    },
    resetFetchedCount: (state) => {
      state.leads.fetchedCount = 0;
    },
  },
  extraReducers: (builder) => {
    // fetchLeads
    builder.addCase(fetchLeads.pending, (state) => {
      state.leads.loading = true;
      state.leads.error = null;
    });
    builder.addCase(fetchLeads.fulfilled, (state, action) => {
      state.leads.fetchedCount = (state.leads.fetchedCount || 0) + 1;
      state.leads.data = action.payload.list;
      state.leads.loading = false;
      state.pagination = { ...state.pagination, ...action.payload.pagination };
    });
    builder.addCase(fetchLeads.rejected, (state, action) => {
      state.leads.loading = false;
      state.leads.error = action.payload as FixMeLater;
    });

    // fetchOneLead
    builder.addCase(fetchOneLead.pending, (state) => {
      state.currentLead.loading = true;
      state.currentLead.error = null;
    });
    builder.addCase(fetchOneLead.fulfilled, (state, action) => {
      state.currentLead.data = action.payload.model;
      state.currentLead.loading = false;
    });
    builder.addCase(fetchOneLead.rejected, (state, action) => {
      state.currentLead.loading = false;
      state.currentLead.error = action.payload as FixMeLater;
    });

    builder.addCase(editLead.fulfilled, (state, action) => {
      state.currentLead.data = action.payload.model;
      toast.success(`Lead ${action.payload.model.name} successfully updated`);
      window.location.pathname = "/leads";
    });

    // updateLeads
    builder.addCase(updateLeads.fulfilled, (state, action) => {
      const changedLeadsIds = action.payload.list.map((lead) => lead.id);
      const newLeads = state.leads.data.map((lead) => {
        if (changedLeadsIds.includes(lead.id)) {
          const currentLead = action.payload.list.find((changedLead) => changedLead.id === lead.id);
          return currentLead ? currentLead : lead;
        }
        return lead;
      });

      state.leads.data = newLeads;
      toast.success("Leads successfully updated");
    });
    builder.addCase(updateLeads.rejected, (state, action) => {
      state.leads.loading = false;
      state.leads.error = action.payload ?? null;
      toast.error(action.payload);
    });
    builder.addCase(deleteLead.fulfilled, () => {
      toast.success("Lead successfully deleted");
      window.location.pathname = "/leads";
    });

    // changeIsValid
    builder.addCase(changeIsValid.pending, (state) => {
      state.leads.loading = true;
    });
    builder.addCase(changeIsValid.fulfilled, (state, action) => {
      state.leads.data = state.leads.data.map((lead) =>
        lead.id === action.payload.model.id
          ? { ...lead, isValid: action.payload.model.isValid }
          : lead,
      );
      state.leads.loading = false;
      toast.success(`Lead ${action.payload.model.email} successfully updated`);
    });
    builder.addCase(changeIsValid.rejected, (state) => {
      state.leads.loading = false;
    });

    // changeIsReplace
    builder.addCase(changeIsReplace.pending, (state) => {
      state.leads.loading = true;
    });
    builder.addCase(changeIsReplace.fulfilled, (state, action) => {
      state.leads.data = state.leads.data.map((lead) =>
        lead.id === action.payload.model.id
          ? { ...lead, isReplace: action.payload.model.isReplace }
          : lead,
      );
      state.leads.loading = false;
      toast.success(`Lead ${action.payload.model.email} successfully updated`);
    });
    builder.addCase(changeIsReplace.rejected, (state) => {
      state.leads.loading = false;
    });
  },
});

export default leadsSlice.reducer;
