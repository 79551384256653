import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router";
import { useGetAdvertisementsQuery } from "../api/advertisements-api";
import PageTitle from "../components/common/items/page-title";
import {
  Advertisement,
  advertisementKeys,
  advertisementFilterKeys,
  AdvertisementsFilter,
} from "../models/Advertisements";
import Loading from "./_layouts/Loading";

const Advertisements = () => {
  const { affiliateId } = useParams();
  if (!affiliateId) return null;
  const [advertisementIndex, setAdvertisementIndex] = useState<number>(0);
  const [filter, setFilter] = useState<AdvertisementsFilter>({
    affiliateId,
  });
  const { data, isLoading, isFetching } = useGetAdvertisementsQuery({
    key: advertisementKeys[advertisementIndex],
    filter,
  });

  const handleAddFilter = (key: string) => {
    if (advertisementIndex < advertisementKeys.length - 1) {
      const nextStep = advertisementIndex + 1;
      setAdvertisementIndex(nextStep);
      setFilter((prev) => ({
        ...prev,
        [advertisementFilterKeys[nextStep]]: key,
      }));
    }
  };

  const handleChangeIndex = () => {
    if (advertisementIndex) {
      const keys = advertisementFilterKeys.slice(0, advertisementIndex);
      const newFilter = keys.reduce((prev, curr) => ({ ...prev, [curr]: filter[curr] }), {});
      setAdvertisementIndex(advertisementIndex - 1);
      setFilter(newFilter as AdvertisementsFilter);
    }
  };

  // const handleFilterByKey = (key: AdvertisementKeys) => {
    // const selectedKeyIndex = advertisementKeys.indexOf(key);
    // const keys = advertisementFilterKeys.slice(0, selectedKeyIndex + 1);
    // const newFilter = keys.reduce((prev, curr) => ({ ...prev, [curr]: filter[curr] }), {});
    // setAdvertisementIndex(selectedKeyIndex);
    // setFilter(newFilter as AdvertisementsFilter);
  // };

  if (isLoading) return <Loading />;
  if (!data) return null;

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <PageTitle title="Advertisements" />
      <div className="mb-4 card">
        <div className="card-header py-3 flex-center">
          {!!advertisementIndex && (
            <Button onClick={handleChangeIndex} variant="outline-primary" className="pager m-0">
              ‹
            </Button>
          )}
          <div className="flex-center">
            {advertisementKeys.slice(0, advertisementIndex).map((key) => (
              <Button
                // onClick={() => handleFilterByKey(key)}
                variant="outline-primary"
                className="ml-2"
                key={key}
              >
                <span className="font-bold">{key}</span>
                <span>
                  {" "}
                  {/*- {filter[advertisementFilterKeys[advertisementKeys.indexOf(key) + 1]]}*/}
                </span>
              </Button>
            ))}
          </div>
          <p className="card-heading ml-4">{advertisementKeys[advertisementIndex]}</p>
        </div>
        <div className="card-body table-responsive">
          {isFetching ? (
            <Loading />
          ) : (
            <table className="card-text table overflow-auto">
              <thead>
                <tr>
                  <th />
                  <th>Click</th>
                  <th>View</th>
                  <th>Deposit</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(data).map((key) => {
                  const advertisement: Advertisement = data[key];
                  return (
                    <tr key={key}>
                      <th
                        className="font-extrabold"
                        role="button"
                        onClick={() => handleAddFilter(key)}
                      >
                        {key}
                      </th>
                      <th className="font-normal">{advertisement.click}</th>
                      <th className="font-normal">{advertisement.view}</th>
                      <th className="font-normal">{advertisement.deposit}</th>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default Advertisements;
