import React from "react";
import ReactSelect, { SingleValue } from "react-select";
import { useField } from "formik";
import { ISelect, selectStyles } from "../../../../models/fields/ISelect";

const SingleSelect = <T, Option>(props: ISelect<T, Option>) => {
  const [field, , helpers] = useField<SingleValue<Option>>(props.name);

  const { setValue } = helpers;

  return (
    <ReactSelect
      {...field}
      {...props}
      isMulti={false}
      onChange={(value: SingleValue<Option>) => {
        setValue(value);
      }}
      styles={selectStyles}
    />
  );
};

export default SingleSelect;
