import { Link } from "react-router-dom";
import { ILocalization } from "../../models/ILocalizations";
import { formatDateFullTime } from "../../utils/formats";
import { ColumnDef, PaginationType } from "../common/tables/types";

type IColumn = ColumnDef<ILocalization>;

interface Options {
  pagination: PaginationType;
}

export default function getColumnDefsLocalizations(options: Options): IColumn[] {
  const { pagination } = options;

  const numberIndex: IColumn = {
    Cell: ({ row: { index } }: { row: { index: number } }) => {
      return <div>{index + (pagination.from || 1)}</div>;
    },
    Header: "№",
    id: "№",
    disableFilters: true,
    disableSortBy: true,
    width: "25px",
  };

  const location: IColumn = {
    Header: "Location",
    id: "location",
    accessor: "location",
  };

  const name: IColumn = {
    Cell: (cell) => (
      <Link className="font-semibold" to={`/localizations/${cell.row.original.id}`}>
        {cell.value}
      </Link>
    ),
    Header: "Name",
    id: "name",
    accessor: "name",
  };

  const country: IColumn = {
    Cell: (cell) => (
      <Link className="font-semibold" to={`/localizations/${cell.row.original.id}`}>
        {cell.value}
      </Link>
    ),
    Header: "Country",
    id: "country",
    accessor: "country",
  };

  const language: IColumn = {
    Cell: (cell) => (
      <Link className="font-semibold" to={`/localizations/${cell.row.original.id}`}>
        {cell.value}
      </Link>
    ),
    Header: "Language",
    id: "language",
    accessor: "language",
  };

  const createdAt: IColumn = {
    Cell: (cell) => <span>{formatDateFullTime(cell.value)}</span>,
    Header: "Created",
    id: "createdAt",
    accessor: "createdAt",
  };

  return [numberIndex, location, name, country, language, createdAt];
}
