import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteDictionaryWord } from "../../api/dictionaryWords-api";
import PageTitle from "../../components/common/items/page-title";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { fetchOneDictionaryWord, changeDictionaryWord } from "../../store/thunks/dictionaryWords";
import Loading from "../_layouts/Loading";
import {
  emptyValues,
  IDictionaryChange,
  IDictionaryWord,
  typeOptions,
} from "../../models/IDictionaryWords";
import { getValuesFromModel } from "../../api/utils";
import {
  createDictionaryWordValidation,
  createDictionaryWordFields,
} from "../../components/fields/create-dictionaryWord";
import Field from "../../components/common/form/formik/Field";
import useModal from "../../hooks/useModal";

const EditDictionaryWord = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentDictionaryWord } = useAppSelector((state) => state.dictionaryWordsReducer);
  const { id: dictionaryWordId } = useParams();
  const [initialValues, setInitialValues] = useState(emptyValues);

  useEffect(() => {
    if (!dictionaryWordId) return;
    dispatch(fetchOneDictionaryWord(dictionaryWordId));
  }, []);

  const handleDeleteDictionaryWord = () => {
    if (currentDictionaryWord.data) {
      void deleteDictionaryWord(currentDictionaryWord.data.id);
      navigate("/dictionaryWords");
    }
  };

  const { ModalComp, handleShowModal } = useModal(handleDeleteDictionaryWord);

  useEffect(() => {
    if (!currentDictionaryWord.loading && currentDictionaryWord.data) {
      const data = getValuesFromModel<IDictionaryChange>(
        {
          ...currentDictionaryWord.data,
          type:
            typeOptions.find(
              (option) => option.value === (currentDictionaryWord.data as IDictionaryWord).type,
            ) || null,
        },
        emptyValues,
      );
      setInitialValues(data);
    }
  }, [currentDictionaryWord]);

  const handleSubmit = (values: IDictionaryChange) => {
    if (!currentDictionaryWord.data?.id) {
      toast.error("Missing user id!");
      return;
    }
    dispatch(
      changeDictionaryWord({
        ...values,
        id: currentDictionaryWord.data.id,
      }),
    );
  };

  if (currentDictionaryWord.loading) {
    return <Loading />;
  }

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <div className="d-flex justify-between">
          <Link to="/dictionaryWords" className="w-auto">
            <Button className="w-auto mb-3" variant="primary">
              Back
            </Button>
          </Link>
          <Button className="w-auto mb-3" variant="danger" onClick={handleShowModal}>
            Delete DictionaryWord
          </Button>
        </div>
        <PageTitle title={currentDictionaryWord.data?.title} />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <p className="card-title">Edit Mode</p>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={createDictionaryWordValidation}
                onSubmit={handleSubmit}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {createDictionaryWordFields.map((field) => (
                      <Field key={field.name} {...field} />
                    ))}
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                  >
                    Save
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
      </div>
      {ModalComp}
    </div>
  );
};

export default EditDictionaryWord;
