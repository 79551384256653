import * as usersApi from "../../api/users-api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IUserChange, IUserChangeRequest, IUserFilterRequest } from "../../models/IUsers";
import { RootState } from "../reducers";
import { State } from "../reducers/usersSlice";
import { RequestList } from "../../components/common/tables/types";
import { FetchOptionsParams } from "../../types/global";
import { filterEmpyValues } from "../../api/utils";

export const fetchList = createAsyncThunk("users/fetchList", (params: FetchOptionsParams, thunkAPI) => {
  const rootState: RootState = thunkAPI.getState() as RootState;
  const state: State = rootState.usersReducer;

  const options: RequestList<IUserFilterRequest> = {
    filter: {
      ...state.filter,
      roles: state.filter.roles?.value || "",
    },
    page: state.pagination.page,
    take: state.pagination.take,
    searchTerm: params.searchTerm,
  };

  if (!params.searchTerm) {
    delete options.searchTerm;
  }
  return usersApi.getList(options);
});

export const changeItem = createAsyncThunk("users/changeItem", (values: IUserChange) => {
  const sendingValues: IUserChangeRequest = {
    ...values,
    teamId: values.team?.id || "",
    roles: values.roles?.value || "",
    clients: values.clients?.map(client => client.id) || [],
  };
  delete sendingValues.team;
  return usersApi.changeItem(sendingValues as IUserChangeRequest);
});

export const fetchItem = createAsyncThunk("users/fetchItem", (id: string) => usersApi.getItem(id));

export const deleteItem = createAsyncThunk("users/deleteItem", (id: string) =>
  usersApi.deleteItem(id),
);
