import { Link } from "react-router-dom";
import { IStatus, statusOptions, SystemsList, typeOptions, TypesList } from "../../models/IStatus";
import { formatDateFullTime, formatOption } from "../../utils/formats";
import ColorLabel from "../common/items/ColorLabel";
import { ColumnDef, PaginationType } from "../common/tables/types";

type IColumn = ColumnDef<IStatus>;

interface Options {
  pagination: PaginationType;
  handleSelectAll: VoidFunction;
  handleSelectItem: (id: string) => void;
  isSelectedAll: boolean;
}

export default function getColumnDefs(options: Options): IColumn[] {
  const { pagination } = options;

  const numberIndex: IColumn = {
    Cell: ({ row: { index } }: { row: { index: number } }) => {
      return <div>{index + (pagination.from || 1)}</div>;
    },
    Header: "№",
    id: "№",
    disableFilters: true,
    disableSortBy: true,
    width: "25px",
  };

  const status: IColumn = {
    Cell: (cell) => {
      return (
        <Link className="font-semibold" to={`/statuses/${cell.row.original.id}`}>
          <div>
            {cell.value}
          </div>
        </Link>
      );
    },
    Header: "Status",
    id: "status",
    accessor: "status",
  };

  const system: IColumn = {
    Cell: (cell) =>
      cell.value && (
        <div className="d-flex justify-between">
          {formatOption<SystemsList>(cell.value, statusOptions)}
          <ColorLabel className="d-inline" colors={statusOptions} value={cell.value} />
        </div>
      ),
    Header: "System",
    id: "system",
    accessor: "system",
  };

  const type: IColumn = {
    Cell: (cell) =>
      cell.value && (
        <div className="d-flex justify-between">
          {formatOption<TypesList>(cell.value, statusOptions)}
          <ColorLabel className="d-inline" colors={typeOptions} value={cell.value} />
        </div>
      ),
    Header: "Type",
    id: "type",
    accessor: "type",
  };

  const createdAt: IColumn = {
    Cell: (cell) => <span>{formatDateFullTime(cell.value)}</span>,
    Header: "Created",
    id: "createdAt",
    accessor: "createdAt",
    width: "95px",
  };

  return [numberIndex, status, system, type, createdAt];
}
