import { Link } from "react-router-dom";
import { ITeam } from "../../models/ITeam";
import { formatDateFullTime } from "../../utils/formats";
import { ColumnDef, PaginationType } from "../common/tables/types";

type IColumn = ColumnDef<ITeam>;

interface Options {
  pagination: PaginationType;
}

export default function getColumnDefs(options: Options): IColumn[] {
  const { pagination } = options;

  const numberIndex: IColumn = {
    Cell: ({ row: { index } }: { row: { index: number } }) => {
      return <div>{index + (pagination.from || 1)}</div>;
    },
    Header: "№",
    id: "№",
    disableFilters: true,
    disableSortBy: true,
    width: "25px",
  };

  const name: IColumn = {
    Cell: (cell) => (
      <Link className="font-semibold" to={`/teams/${cell.row.original.id}`}>
        {cell.value}
      </Link>
    ),
    Header: "Name",
    id: "name",
    accessor: "name",
    width: "auto",
  };

  const createdAt: IColumn = {
    Cell: (cell) => <span>{formatDateFullTime(cell.value)}</span>,
    Header: "Created",
    accessor: "createdAt",
    id: "createdAt",
    width: "auto",
  };

  return [numberIndex, name, createdAt];
}
