import { createAsyncThunk } from "@reduxjs/toolkit";
import * as dictionaryWordsApi from "../../api/dictionaryWords-api";
import { IDictionaryChange, IDictionaryFilterRequest, RequestDictionaryWord } from "../../models/IDictionaryWords";
import { dictionaryWordsState } from "../reducers/dictionaryWordsSlice";

export const fetchDictionaryWords = createAsyncThunk(
  "dictionaryWords/fetchDictionaryWords",
  (_, thunkAPI) => {
    const state: FixMeLater = thunkAPI.getState();
    const dictionaryWordsState: dictionaryWordsState = state.dictionaryWordsReducer;

    const filter: IDictionaryFilterRequest = {
      ...dictionaryWordsState.filter,
      type: dictionaryWordsState.filter.type?.value || "",
    }

    const options: RequestDictionaryWord = {
      filter,
      page: dictionaryWordsState.pagination.page,
      take: dictionaryWordsState.pagination.take,
    };
    return dictionaryWordsApi.getDictionaryWords(options);
  },
);

export const fetchOneDictionaryWord = createAsyncThunk(
  "dictionaryWords/fetchOneDictionaryWord",
  (id: string) => dictionaryWordsApi.getDictionaryWord(id),
);

export const changeDictionaryWord = createAsyncThunk(
  "dictionaryWords/changeDictionaryWord",
  (values: IDictionaryChange & { id?: string }) => dictionaryWordsApi.changeDictionaryWord(values),
);
