import { decamelizeKeys } from "humps";
import { toast } from "react-toastify";
import { IPush, IPushChange, IPushChangeRequest, IPushFiltersRequest } from "../models/IPush";
import { Api, formApi, filterEmpyValues, ListRes, ModelRes } from "./utils";
import { getResponseData } from "./utils";

const baseURL = "/v1/pushes";

export const getList = (params: IPushFiltersRequest): Promise<ListRes<IPush>> => {
  return Api.post(baseURL + "/list", filterEmpyValues(params)).then(getResponseData);
};

export const changeItem = (data: IPushChange & { id?: string }): Promise<ModelRes<IPush>> => {
  let sendingData: IPushChangeRequest = decamelizeKeys({
    ...data,
    offerId: data.offer?.id || null,
    status: data.status?.value || "",
    type: data.type?.value || "",
    offerType: data.offerType?.value || "",
    isSend: Number(data.isSend),
  }) as IPushChangeRequest;

  sendingData = {
    ...sendingData,
    icon: data.icon,
  } as IPushChangeRequest;

  delete sendingData.offer;
  if (typeof sendingData.icon === "string") delete sendingData.icon;

  return formApi.post(baseURL, sendingData).then(getResponseData);
};

export const getItem = (id: string): Promise<ModelRes<IPush>> => {
  return Api.get(`${baseURL}/${id}`).then(getResponseData);
};

export const deleteItem = (id: string) => {
  return Api.delete(`${baseURL}/${id}`).then(getResponseData);
};

export const sendOneItem = (id: string) => {
  return Api.post(`${baseURL}/${id}/send`).then(() => {
    toast.success("Push successfully sended");
  });
};
