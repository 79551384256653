import React, { memo, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { IUser } from "../../models/IUsers";
import { SettingsTableKey } from "../../models/IUserSettings";
import Loading from "../../pages/_layouts/Loading";
import { usersSlice } from "../../store/reducers/usersSlice";
import { fetchList } from "../../store/thunks/users";
import Table from "../common/tables/Table";
import getColumnDefs from "./ColumnDefs";

interface Props {
  className?: string;
}

const UsersTable = ({ className }: Props) => {
  const dispatch = useAppDispatch();
  const { pagination, list } = useAppSelector((state) => state.usersReducer);
  const { setPagination } = usersSlice.actions;

  const onChangePagination = (page: number) => {
    dispatch(setPagination({ page }));
    dispatch(fetchList({}));
  };

  const onChangePerPage = (take: number) => {
    dispatch(setPagination({ take }));
    dispatch(fetchList({}));
  };

  const columns = useMemo(
    () =>
      getColumnDefs({
        pagination,
      }),
    [pagination, list],
  );

  if (list.loading) {
    return <Loading />;
  }

  return (
    <Table<IUser>
      pageKey={SettingsTableKey.USERS}
      changePage={onChangePagination}
      changePageSize={onChangePerPage}
      className={className}
      columns={columns}
      containerClass="overflow-auto"
      fixed
      items={list.data}
      pagination={pagination}
    />
  );
};

export default memo(UsersTable);
