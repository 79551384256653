import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { getLeadAdditions } from "../../api/leads-api";
import PageTitle from "../../components/common/items/page-title";
import { IAdditions } from "../../models/IAdditions";
import { formatDateFullTime } from "../../utils/formats";
import Loading from "../_layouts/Loading";

const LeadComments = () => {
  const { search } = useLocation();
  const { id } = useParams();
  const [additions, setAdditions] = useState<IAdditions | null>(null);

  useEffect(() => {
    fetchAdditions();
  }, []);

  const fetchAdditions = async () => {
    if (!id) return;
    const data = await getLeadAdditions(id);
    setAdditions(data.model);
  };

  if (!additions) {
    return <Loading />;
  }

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <Link to={`/leads/${id}${search}`} className="w-auto">
          <Button className="w-auto mb-3" variant="primary">
            Back
          </Button>
        </Link>
        <PageTitle title="Additions" />
        <section className="mb-5 row">
          <div className="col-5 card">
            <h4 className="card-header">Comments</h4>
            <div className="card-body">
              {additions.comments.map((comment) => (
                <div key={comment.id}>
                  <p>
                    <b>Text: </b>
                    {comment.text}
                  </p>
                  <p>
                    <b>Type: </b>
                    {comment.type}
                  </p>
                  <hr />
                </div>
              ))}
            </div>
          </div>
          <div className="col-6 offset-1 card">
            <h4 className="card-header">Processing Histories</h4>
            <div className="card-body">
              {additions.processingHistories.map((history) => (
                <div key={history.id}>
                  <p>
                    <b>Text: </b>
                    {history.client ? history.client.name : "No"}
                  </p>
                  <p>
                    <b>Created at: </b>
                    {formatDateFullTime(history.createdAt)}
                  </p>
                  <p>
                    <b>Status: </b>
                    {history.status}
                  </p>
                  <hr />
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default LeadComments;
