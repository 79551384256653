import {
  ITelegramChat,
  ITelegramChatRequest,
  ITelegramChatUpdate,
  RequestTelegramChat,
} from "../models/ITelegramChats";
import { Api, filterEmpyValues, ListRes, ModelRes } from "./utils";
import { getResponseData } from "./utils";

const PATH = "/v1/telegram-chats";

export const getTelegramChats = (params: RequestTelegramChat): Promise<ListRes<ITelegramChat>> => (
  Api.post(`${PATH}/list`, filterEmpyValues(params)).then(getResponseData)
);

export const getTelegramChat = (id: string): Promise<ModelRes<ITelegramChat>> => {
  return Api.get(`${PATH}/${id}`).then(getResponseData);
};

export const changeTelegramChat = (data: ITelegramChatUpdate) => {
  const sendingData: ITelegramChatRequest = {
    ...data,
    clientId: data.client?.id || "",
    permissions: data.permissions.map((option) => option.value),
  };
  delete sendingData.client;
  return Api.post(PATH, sendingData).then(getResponseData);
};

export const deleteTelegramChat = (id: string) => {
  return Api.delete(`${PATH}/${id}`);
};
