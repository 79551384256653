import React, { useEffect, useMemo, useState } from "react";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { createIntegrationToken } from "../../store/thunks/integrations";
import { filterExtraFieldsFromArray } from "../../api/utils";
import FieldBlock from "../../components/common/form/formik/FieldBlock";
import PageTitle from "../../components/common/items/page-title";
import { createTokenFields, validation } from "../../components/fields/create-integrationToken";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  emptyValues,
  formatOptions,
  ITokenChange,
  parameterTokenOptions,
  ParameterTokenType,
  placeOptions,
  tokenMethodOptions,
} from "../../models/IIntegrationToken";

const EditToken = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentIntegration } = useAppSelector((state) => state.integrationsReducer);
  const [initialValues, setInitialValues] = useState(emptyValues);

  useEffect(() => {
    if (currentIntegration.data?.token) {
      const current = currentIntegration.data.token;
      if (current) {
        const parameters = filterExtraFieldsFromArray<ParameterTokenType>(current.parameters, [
          "type",
          "key",
          "value",
        ]).map((item) => ({
          ...item,
          type: parameterTokenOptions.find((option) => option.value === item.type) || null,
        }));

        setInitialValues({
          ...currentIntegration.data?.token,
          format: formatOptions.find((option) => option.value === current.format) || null,
          method: tokenMethodOptions.find((option) => option.value === current.method) || null,
          parameters,
          place: placeOptions.find((option) => option.value === current.place) || null,
        });
      }
    }
  }, []);

  const handleSubmit = (values: ITokenChange) => {
    if (currentIntegration && currentIntegration.data) {
      dispatch(createIntegrationToken({ ...values, integrationId: currentIntegration.data.id }));
    }
  };

  const fields = useMemo(() => createTokenFields(), []);

  if (!currentIntegration.data) {
    navigate("not-found");
  }

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <Link to={`/clients/${id}/integrations/${currentIntegration.data?.id}`} className="w-auto">
          <Button className="w-auto mb-3" variant="primary">
            Back
          </Button>
        </Link>
        <PageTitle title={`${currentIntegration.data?.name} token`} />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {fields.map((field) => (
                      <FieldBlock key={field.title} {...field} />
                    ))}
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                  >
                    Save
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default EditToken;
