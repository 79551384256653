import React, { useEffect, useMemo, useState } from "react";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import PageTitle from "../../components/common/items/page-title";
import { createTransactionValidation, createTransactionFields } from "../../components/fields/create-transaction";
import { useAppSelector, useAppDispatch } from "../../hooks/redux";
import { emptyValues, ITransactionChange } from "../../models/ITransactions";
import { fetchShortClients } from "../../store/thunks/clients";
import { FetchOptionsParams } from "../../types/global";
import Field from "../../components/common/form/formik/Field";
import { createTransaction } from "../../store/thunks/transactions";
import { fetchLeads } from "../../store/thunks/leads";

const CreateTransaction = () => {
  const { clientsShort } = useAppSelector(state => state.clientsReducer);
  const { leads } = useAppSelector(state => state.leadsReducer);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => setDisabled(false);
  }, [])

  const fields = useMemo(() => createTransactionFields({
    clientOptions: clientsShort.data,
    asyncClients: {
      fetchCallback: (params: FetchOptionsParams) =>
        dispatch(fetchShortClients(params)),
      isLoading: clientsShort.loading,
    },
    leadOptions: leads.data,
    asyncLeads: {
      fetchCallback: (params: FetchOptionsParams) => dispatch(fetchLeads(params)),
      isLoading: leads.loading
    }
  }), [clientsShort, leads])

  const handleSubmit = (values: ITransactionChange) => {
    setDisabled(true);
    dispatch(createTransaction(values));
  };

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <Link to="/transactions" className="w-auto">
          <Button className="w-auto mb-3" variant="primary">
            Back
          </Button>
        </Link>
        <PageTitle title="Add Transaction" />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Formik
                enableReinitialize
                initialValues={emptyValues}
                validationSchema={createTransactionValidation}
                onSubmit={handleSubmit}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {fields.map((field) => (
                      <Field key={field.name} {...field} />
                    ))}
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                    disabled={disabled}
                  >
                    Create
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CreateTransaction;
