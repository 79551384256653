import React, { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useGetClientsStatisticQuery, useGetLeadsStatisticQuery, useLazyGetClientsStatisticQuery, useLazyGetLeadsStatisticQuery } from "../../api/statistics-reports-api";
import FilterForm from "../../components/common/form/formik/FilterForm";
import Table from "../../components/common/tables/Table";
import { PaginationType } from "../../components/common/tables/types";
import { filterClientReportsFields } from "../../components/fields/filters-statistics";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  clientFilters,
  ClientsStatistic as ClientsStatisticType, ClientsStatisticItem,
  ClientStatisticReportsFilters,
  ClientStatisticReportsFiltersRequest,
  LeadsStatistic, StatisticReportsFilters, StatisticReportsFiltersRequest
} from "../../models/IStatistics";
import { SystemsList } from "../../models/IStatus";
import { SettingsTableKey } from "../../models/IUserSettings";
import { fetchShortAffiliates } from "../../store/thunks/affiliates";
import { fetchOneClient } from "../../store/thunks/clients";
import { FetchOptionsParams, PAGINATION_TAKE } from "../../types/global";
import { formatDate, stringToDate } from "../../utils/formats";
import Loading from "../_layouts/Loading";
import ConversionChart from "./ConversionChart";
import ClientChart from "./reports/ClientChart";
import getColumnDefs from "./reports/LeadsColumnDefs";

const ClientsStatistic = () => {
  const { clientId } = useParams();
  const dispatch = useAppDispatch();
  const { affiliatesShort } = useAppSelector((state) => state.affiliatesReducer);
  const { currentClient } = useAppSelector((state) => state.clientsReducer);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  if (!clientId) return null;
  const [pagination, setPagination] = useState<PaginationType>({
    take: PAGINATION_TAKE,
    page: 1,
  });
  const [currClientVal, setCurrClientVal] = useState<ClientsStatisticItem[] | null>(null);
  const [savedFilters, setSavedFilters] = useState<ClientStatisticReportsFilters | null>(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setSavedFilters({
      ...clientFilters,
      sentAt: [
        searchParams.get("sentAt[0]") as unknown as Date ?? null,
        searchParams.get("sentAt[1]") as unknown as Date ?? null
      ]})
  }, []);

  const formatRequestFilters = (filters: ClientStatisticReportsFilters): ClientStatisticReportsFiltersRequest => ({
    clientId,
    affiliateId: filters.affiliates.map((option) => option.id),
    sentAt: filters.sentAt,
    isValid: filters.isValid?.value !== null ? filters.isValid.value : "",
    isReplace: filters.isReplace?.value !== null ? filters.isReplace.value : "",
    hasDeposit: filters.hasDeposit?.value !== null ? filters.hasDeposit.value : "",
  });
  // const { data: leadsData, isFetching: isFetchingLeads } = useGetLeadsStatisticQuery({
  //   filter: formatRequestFilters(savedFilters),
  //   take: pagination.take,
  //   page: pagination.page,
  // });
  // const { data = {}, isFetching } = useGetClientsStatisticQuery({
  //   filter: formatRequestFilters(savedFilters),
  //   type: "query",
  // });
  const [fetchClientsStatistic, { data = {}, isFetching }] = useLazyGetClientsStatisticQuery();
  const [fetchLeadsStatistic, { data: leadsData, isFetching: isFetchingLeads }] = useLazyGetLeadsStatisticQuery();

  useEffect(() => {
    if (!clientId) return;
    dispatch(fetchOneClient(clientId));
  }, []);

  useEffect(() => {
    if (!savedFilters) return;
    fetchClientsStatistic({
      filter: formatRequestFilters(savedFilters),
      type: "query",
    })
    fetchLeadsStatistic({
      filter: formatRequestFilters(savedFilters),
      take: pagination.take,
      page: pagination.page,
    })
  }, [savedFilters])

  useEffect(() => {
    if (leadsData && pagination.total !== null) {
      setPagination((prev) => ({ ...prev, ...leadsData.pagination }));
    }
  }, [leadsData, isFetchingLeads]);

  const total = useMemo(
    () => Object.values(data).map((array) => array.reduce((prev, curr) => prev + curr.count, 0)),
    [data],
  );  

  const getStatuses = useMemo<SystemsList[]>(() => {
    const statuses = Object.values(data).map((item) => item.map((item) => item.status));
    const statusesSet: SystemsList[] = Array.from(new Set<SystemsList>(statuses.flat()));
    return statusesSet.sort();
  }, [data]);

  const getSumByStatuses = useMemo(() => (
    Object.values(data)
    .flat()
    .reduce((prev, curr) => {
        const existingItem = prev.find(i => i.status === curr.status);
        if (existingItem) {
          existingItem.count += curr.count;
          existingItem.percent = (existingItem.percent + parseFloat(curr.percent));
        } else {
          prev.push({
            status: curr.status,
            count: curr.count,
            percent: parseFloat(curr.percent)
          });
        }
        return prev;
    }, [] as { count: number, percent: number, status: SystemsList, }[])
    .sort((a, b) => (a.status < b.status ? -1 : 1))
  ), [data]);

  const getClientValues = useMemo<ClientsStatisticType>(() => {
    return Object.entries(data).reduce((prev, curr) => {
      const emptyStatuses = getStatuses
        .map((status) => {
          const currStatus = curr[1].find(item => item.status === status);
          return (currStatus ? currStatus : {
            count: 0,
            percent: "0",
            status,
          })
        })
      return {
        ...prev,
        [curr[0]]: emptyStatuses,
      };
    }, {});
  }, [getStatuses]);

  const getTotalByStatus = useMemo(() => {
    return getStatuses.map((status) => {
      const valuesByStatus = Object.values(getClientValues).map((values) =>
        values.find((value) => value.status === status),
      ) as unknown as ClientsStatisticItem[];

      return valuesByStatus.reduce(
        (prev, curr) => ({
          count: prev.count + curr.count,
          percent: `${Number(prev.percent) + Number(curr.percent)}`,
        }),
        {
          count: 0,
          percent: "0",
        },
      );
    });
  }, [getClientValues]);

  const onChangePagination = (page: number) => {
    setPagination((prev) => ({ ...prev, page }));
  };

  const onChangePerPage = (take: number) => {
    setPagination((prev) => ({ ...prev, take }));
  };

  const columns = useMemo(
    () =>
      getColumnDefs({
        pagination,
      }),
    [pagination, leadsData],
  );

  const fields = useMemo(
    () =>
      filterClientReportsFields({
        asyncAffiliates: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchShortAffiliates(params)),
          isLoading: affiliatesShort.loading,
        },
        affiliatesOptions: affiliatesShort.data,
      }),
    [affiliatesShort],
  );

  const handleFilter = (filters: ClientStatisticReportsFilters) => {
    setSavedFilters(filters)
    return false;
  };

  if (isFetching || !savedFilters || currentClient.loading || !currentClient.data) return <Loading />;

  const { count, percent } = getTotalByStatus.reduce(
    (prevValue, currValue) => ({
      count: prevValue.count + currValue.count,
      percent: prevValue.percent + Number(currValue.percent),
    }),
    { count: 0, percent: 0 },
  );

  const formatFilterDate = (
    dateOrStr: (null | string | Date)[],
  ): string => {
    if (!dateOrStr) return '';
    if (typeof dateOrStr[0] === "string" && typeof dateOrStr[1] === "string") return `${formatDate(stringToDate(dateOrStr[0]))} - ${formatDate(stringToDate(dateOrStr[1]))}`;
    return `${formatDate(dateOrStr[0])} - ${formatDate(dateOrStr[1])}`
  };

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <div className="d-flex mb-3">
          <Link to="/affiliate-reports">
            <Button className="mb-3" variant="primary">
              Back
            </Button>
          </Link>
          <Button
            variant="primary"
            className="mb-3"
            onClick={() => setIsFilterOpen((prevState) => !prevState)}
          >
            {isFilterOpen ? "Close" : "Open"} Filters
          </Button>
        </div>
        {isFilterOpen && (
          <FilterForm
            className="mb-3"
            fields={fields}
            handleSubmit={handleFilter}
            initialValues={savedFilters}
          />
        )}
      </div>
      <div className="row">
        <div className="offset-2 offset-md-3 col-8 col-md-6">
          <div className="mb-4 card">
              <div className="card-header py-3 flex justify-content-between">
              <p className="card-heading">
                Conversion
                <span className="ml-2">{currentClient.data.label?.name ?? ''}</span>
              </p>
              <p className="card-heading">{formatFilterDate(savedFilters.sentAt)}</p>
            </div>
            <div className="card-body">
              <ConversionChart clientLeads={pagination.total ?? 0} filters={savedFilters} currentClient={currentClient.data} />
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="mb-4 card">
            <div className="card-header py-3">
              <p className="card-heading">Statuses</p>
            </div>
            <div className="card-body table-responsive">
              <table className="card-text table overflow-auto">
                <thead>
                  <tr>
                    <th>Total</th>
                    {getStatuses.map((item) => (
                      <th key={item}>{item}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{count} / {Number(percent).toFixed(1)} %</td>
                    {getTotalByStatus.map(({ count, percent }, index) => (
                      <td key={index}>
                        {count} / {Number(percent).toFixed(1)} %
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="mb-4 card">
            <div className="card-header py-3 flex justify-content-between">
              <p className="card-heading">{currentClient.data?.name}</p>
              <p className="card-heading">{formatFilterDate(savedFilters.sentAt)}</p>
            </div>
            <div className="card-body table-responsive">
              <table className="card-text table overflow-auto">
                <thead>
                  <tr>
                    <th />
                    <th>Total</th>
                    {getStatuses.map((item) => (
                      <th key={item}>{item}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(getClientValues).map(([client, values], index) => (
                    <tr key={client}>
                      <td scope="row" onClick={() => setCurrClientVal(values)} className="c-pointer btn-link">{client}</td>
                      <td>{total[index]}</td>
                      {values.map((value, index) => (
                        <td key={index}>
                          {value.count} / {Number(value.percent).toFixed(2)} %
                        </td>
                      ))}
                    </tr>
                  ))}
                  <tr>
                    <th scope="row">Total</th>
                    <td>{total.reduce((prevVal, currVal) => (prevVal + currVal), 0)}</td>
                    {getSumByStatuses.map(({ count, percent, status }) => <td key={status}>{count} / {Number(percent).toFixed(2)} %</td>)}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="mb-4 card">
            <div className="card-header py-3">
              <p className="card-heading">Leads</p>
            </div>
            <div className="card-body">
              {isFetchingLeads ? (
                <Loading />
              ) : (
                <Table<LeadsStatistic>
                  pageKey={SettingsTableKey.REPORTS_LEADS}
                  changePage={onChangePagination}
                  changePageSize={onChangePerPage}
                  columns={columns}
                  containerClass="overflow-auto"
                  fixed
                  items={leadsData?.list || []}
                  pagination={pagination}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <ClientChart currClientVal={currClientVal} setCurrClientVal={setCurrClientVal} />
    </div>
  );
};

export default ClientsStatistic;
