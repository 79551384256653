import { IAuth, IAuthResponse, IAuth2FA, IAuth2FAForm } from "../models/IAuth";
import { IUser } from "../models/IUsers";
import { Api, getResponseData, ModelRes } from "./utils";

export const login = (user: IAuth): Promise<IAuth2FA> =>
  Api.post("/auth/login", user).then(getResponseData);

export const login2Fa = (data: IAuth2FAForm, id: string): Promise<ModelRes<IAuthResponse>> =>
  Api.post(`/auth/confirm/${id}`, data).then(getResponseData);

export const logout = () => Api.post("/auth/logout");

export const getCurrentUser = (): Promise<IUser> => Api.post("/v1/users/me").then(getResponseData);
