import { RequestList } from "../components/common/tables/types";
import { ITeamFilterRequest, ITeamChange, ITeam } from "../models/ITeam";
import { Api, filterEmpyValues, ListRes, ModelRes } from "./utils";
import { getResponseData } from "./utils";

const PATH = "/v1/teams";

export const getList = (params: RequestList<ITeamFilterRequest>): Promise<ListRes<ITeam>> => {
  return Api.post(`${PATH}/list`, filterEmpyValues(params)).then(getResponseData);
};

export const changeItem = (params: ITeamChange): Promise<ModelRes<ITeam>> => {
  return Api.post(PATH, params).then(getResponseData);
};

export const getItem = (id: string): Promise<ModelRes<ITeam>> => {
  return Api.get(`${PATH}/${id}`).then(getResponseData);
};

export const deleteItem = (id: string) => {
  return Api.delete(`${PATH}/${id}`);
};
