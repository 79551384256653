export type Sources = "google" | "tiktok" | "fb";
export type AdvertisementKeys = "source" | "account_id" | "campaign" | "group" | "ads";

export type Advertisement = {
  click?: number;
  view?: number;
  deposit?: number;
};

export type AdvertisementsFilter = {
  affiliateId: string;
  source?: Sources;
  accountId?: string;
  campaign?: string;
  group?: string;
};

export type AdvertisementsRequest = {
  filter: AdvertisementsFilter;
  key: AdvertisementKeys;
};

export type Advertisements = {
  [key: string]: Advertisements;
};

export const advertisementKeys: AdvertisementKeys[] = [
  "source",
  "account_id",
  "campaign",
  "group",
  "ads",
];
export const advertisementFilterKeys: (keyof AdvertisementsFilter)[] = [
  "affiliateId",
  "source",
  "accountId",
  "campaign",
  "group",
];
