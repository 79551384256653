import { createAsyncThunk } from "@reduxjs/toolkit";
import * as applicationsApi from "../../api/applications-api";
import { IApplicationsFiltersRequest } from "../../models/IApplications";
import { RootState } from "../reducers";
import { State } from "../reducers/applicationsSlice";

export const fetchList = createAsyncThunk("applications/fetchList", (_, thunkAPI) => {
  const rootState: RootState = thunkAPI.getState() as RootState;
  const state: State = rootState.applicationsSlice;

  const options: IApplicationsFiltersRequest = {
    page: state.pagination.page,
    take: state.pagination.take,
  };
  return applicationsApi.getList(options);
});

export const fetchItem = createAsyncThunk("applications/fetchItem", (id: string) =>
  applicationsApi.getItem(id),
);
