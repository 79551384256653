import { Formik, Form } from "formik";
import React from "react";
import { Button } from "react-bootstrap";
import { IField } from "../../../../models/fields/IField";
import Icon from "../../items/Icon";
import Field from "./Field";

interface FilterFormProps<T extends UnknownRecord> {
  fields: IField<T>[];
  className?: string;
  handleSubmit: (values: T) => void;
  handleReset?: () => void;
  initialValues: T;
}

const FilterForm = <T extends UnknownRecord>(props: FilterFormProps<T>) => {
  const { fields, className, initialValues, handleSubmit, handleReset } = props;

  return (
    <section className={className}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ isSubmitting }) => (
          <Form className="w-100">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  {fields.map((field) => (
                    <Field key={field.name} {...field} />
                  ))}
                </div>
              </div>
              <div className="card-footer d-flex">
                {handleReset && (
                  <Button
                    className="form-control w-25 m-auto d-flex justify-center align-items-center"
                    variant="outline-primary"
                    onClick={handleReset}
                  >
                    Reset
                    <Icon class="svg-icon-md ml-2" name="repeat" />
                  </Button>
                )}
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  className="form-control w-25 m-auto d-block"
                  variant="primary"
                >
                  Filter
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default FilterForm;
