import * as apis from "../../api/settings-api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ISetting, ISettingChange, ISettingChangeRequest, SettingType } from "../../models/ISettings";
import { ListRes } from "../../api/utils";
import { State } from "../reducers/settingsSlice";

export const fetchList = createAsyncThunk<
  ListRes<ISetting>,
  void,
  { state: { settingsSlice: State } }
>("settings/fetchList", (_, { getState }) => {
  const state: State = getState().settingsSlice;

  const options = {
    filter: state.filter,
    page: state.pagination.page,
    take: state.pagination.take,
  };
  return apis.getList(options);
});

export const changeItem = createAsyncThunk(
  "settings/changeItem",
  (data: ISettingChange & { id?: string }) => {
    const sendingData: ISettingChangeRequest = {
      ...data,
      type: data.type?.value || SettingType.POSTBACK_PARTNER_URL,
    };
    return apis.changeItem(sendingData);
    // .then(successCallback);
  },
);

export const fetchItem = createAsyncThunk("settings/fetchItem", (id: string) => apis.getItem(id));

export const deleteItem = createAsyncThunk("settings/deleteItem", (id: string) =>
  apis.deleteItem(id),
);
