import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { camelizeKeys, decamelizeKeys } from "humps";
import { ClientsConversationsStatisticT, ClientsLeadsStatisticT, ClientsStatisticRequestT, ClientsStatusesStatisticT, CreateClientsStatisticsT, DataT, DestroyResponseT } from "../models/IClientsStatistics";
import { baseURL, filterEmpyValues, prepareHeaders } from "./utils";
const baseUrl = `${baseURL}/v1/statistics/clients`;

export const statisticsClientsApi = createApi({
  reducerPath: "statisticsApi",
  tagTypes: ["Statistics"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    createClientsStatistics: build.mutation<void, CreateClientsStatisticsT>({
      query: (data) => ({
        url: "",
        method: "post",
        body: decamelizeKeys({...filterEmpyValues(data), statuses: data.statuses}),
      }),
    }),
    getClientsStatusesStatistic: build.query<DataT<ClientsStatusesStatisticT>, ClientsStatisticRequestT>({
      query: (fliter) => ({
        url: "statuses",
        method: "post",
        body: decamelizeKeys(filterEmpyValues(fliter)),
      }),
    }),
    getClientsLeadsStatistic: build.query<ClientsLeadsStatisticT[], ClientsStatisticRequestT>({
      query: (fliter) => ({
        url: "leads",
        method: "post",
        body: decamelizeKeys(filterEmpyValues(fliter)),
      }),
      transformResponse: (response: DataT<ClientsLeadsStatisticT[]>) => camelizeKeys(response.data) as ClientsLeadsStatisticT[],
    }),
    getClientsConversationsStatistic: build.query<DataT<ClientsConversationsStatisticT>, ClientsStatisticRequestT>({
      query: (fliter) => ({
        url: "conversations",
        method: "post",
        body: decamelizeKeys(filterEmpyValues(fliter)),
      }),
    }),
    deleteClientsStatistics: build.mutation<DataT<DestroyResponseT>, ClientsStatisticRequestT>({
      query: (fliter) => ({
        url: "destroy",
        method: "delete",
        body: decamelizeKeys(filterEmpyValues(fliter)),
      }),
    }),
  }),
});

export const {
  useCreateClientsStatisticsMutation,
  useDeleteClientsStatisticsMutation,
  useLazyGetClientsConversationsStatisticQuery,
  useLazyGetClientsLeadsStatisticQuery,
  useLazyGetClientsStatusesStatisticQuery,
} = statisticsClientsApi;
