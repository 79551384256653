import * as Yup from "yup";
import { ITransactionChange, typeOptions } from "../../models/ITransactions";
import { IAllFields, TypesOfInput } from "../../models/fields/IField";
import { AsyncObject } from "../../models/fields/ISelect";
import { IClient } from "../../models/IClients";
import { ILead } from "../../models/ILead";

export const createTransactionValidation = Yup.object<Record<keyof ITransactionChange, Yup.AnySchema>>({
  amount: Yup.number().required("Required").typeError("Must be a number"),
  client: Yup.object().typeError("Required"),
  comment: Yup.string(),
  type: Yup.object().typeError("Required"),
  model: Yup.object().nullable(),
});

interface Params {
  clientOptions: IClient[];
  asyncClients: AsyncObject;
  leadOptions: ILead[];
  asyncLeads: AsyncObject;
}

export const createTransactionFields = ({
  clientOptions,
  asyncClients,
  asyncLeads,
  leadOptions,
}: Params): IAllFields<ITransactionChange>[] => [
    {
      async: asyncClients,
      label: "Client",
      name: "client",
      options: clientOptions,
      placeholder: "Client",
      type: TypesOfInput.SELECT,
      valueName: "id",
      labelName: "name"
    },
    {
      label: "Amount",
      name: "amount",
      placeholder: "Amount",
      type: TypesOfInput.TEXT,
    },
    {
      label: "Type",
      name: "type",
      options: typeOptions,
      placeholder: "Type",
      type: TypesOfInput.SELECT,
    },
    {
      label: "Comment",
      name: "comment",
      placeholder: "Comment",
      type: TypesOfInput.TEXTAREA,
    },
    {
      async: asyncLeads,
      label: "Lead",
      name: "model",
      options: leadOptions,
      placeholder: "Lead",
      type: TypesOfInput.SELECT,
      valueName: "id",
      labelName: "name"
    },
  ];
