import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { changeIntegration, createIntegrationToken, fetchIntegrations, fetchOneIntegration } from "../thunks/integrations";
import { PaginationQuery, PaginationType } from "../../components/common/tables/types";
import { FetchedStoreModel, PAGINATION_TAKE } from "../../types/global";
import { toast } from "react-toastify";
import { IIntegrationModel } from "../../models/IIntegration";

export interface integrationsState {
  integrations: FetchedStoreModel<IIntegrationModel[]>;
  currentIntegration: FetchedStoreModel<IIntegrationModel | null>;
  pagination: PaginationType;
}

const initialState: integrationsState = {
  integrations: {
    data: [],
    error: null,
    loading: true,
  },
  currentIntegration: {
    data: null,
    error: null,
    loading: true,
  },
  pagination: {
    take: PAGINATION_TAKE,
    total: null,
    page: 1,
    lastPage: null,
  },
};

export const integrationsSlice = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    setPagination: (state, action: PayloadAction<PaginationQuery>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchIntegrations.pending, (state) => {
      state.integrations.loading = true;
      state.integrations.error = null;
    });
    builder.addCase(fetchIntegrations.fulfilled, (state, action) => {
      state.integrations.data = action.payload.list;
      state.integrations.loading = false;
      state.pagination = { ...state.pagination, ...action.payload.pagination };
    });
    builder.addCase(fetchIntegrations.rejected, (state, action) => {
      state.integrations.loading = false;
      state.integrations.error = action.payload as FixMeLater;
    });
    builder.addCase(fetchOneIntegration.pending, (state) => {
      state.currentIntegration.loading = true;
      state.currentIntegration.error = null;
    });
    builder.addCase(fetchOneIntegration.fulfilled, (state, action) => {
      state.currentIntegration.data = action.payload.model;
      state.currentIntegration.loading = false;
    });
    builder.addCase(fetchOneIntegration.rejected, (state, action) => {
      state.currentIntegration.loading = false;
      state.currentIntegration.error = action.payload as FixMeLater;
    });
    builder.addCase(changeIntegration.fulfilled, () => {
      toast.success("Integration successfully changed");
      setTimeout(() => {
        const newUrl = window.location.pathname.split(/\//g);
        newUrl.pop();
        window.location.pathname = newUrl.join("/");
      }, 1000);
    });
    builder.addCase(createIntegrationToken.fulfilled, () => {
      toast.success("Token successfully changed");
      setTimeout(() => {
        window.location.pathname = "/clients";
      }, 1000);
    });
  },
});

export default integrationsSlice.reducer;
