import React from "react";
import {
  useLazyGetDashboardStatusesListQuery,
  useLazyUseGetDashboardDepositsListQuery,
  useLazyUseGetDashboardGroupCapsQuery
} from "../../api/dashboard-api";
import PageTitle from "../../components/common/items/page-title";
import DepositCard from "../../components/ui/DepositCard";
import GroupCapsCard from "../../components/ui/GroupCapsCard";
import useRefreshPage from "../../hooks/useRefreshPage";
import Loading from "../_layouts/Loading";
import LeadsStatusCard from "../../components/ui/LeadsStatusCard";
import {isTestOptions, LeadStatus, statusOptions} from "../../models/ILead";
import {useNavigate} from "react-router-dom";

const DashboardAffiliate = () => {
  const navigate = useNavigate();

  const [fetchGroupCaps, capsData] = useLazyUseGetDashboardGroupCapsQuery();
  const [fetchDeposits, { data: depositsList, isFetching: isRefetchingDeposits }] = useLazyUseGetDashboardDepositsListQuery();
  const [fetchStatusees, { data: statusesList, isLoading: isLoadingStatuses, isFetching: isRefetchingStatuses }] = useLazyGetDashboardStatusesListQuery();

  useRefreshPage(
    () => {
      fetchGroupCaps();
      fetchDeposits();
      fetchStatusees();
    },
    [],
    "/dashboard",
  );

  const handleFilterByStatus = (status: LeadStatus) => {
    const selectedStatus = statusOptions.find((option) => option.value === status);
    navigate(`/leads?status=${selectedStatus?.value}&isTest=${isTestOptions[2].value}`);
  };

  if (isRefetchingDeposits) {
    return <Loading />;
  }

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <section className="mb-3 mb-lg-5">
        <PageTitle title="Statuses" />
        <div className="row">
          {statusesList &&
            statusesList.map((item, index) => (
              <LeadsStatusCard
                key={item.status}
                onClick={handleFilterByStatus}
                item={item}
                index={index}
              />
            ))}
        </div>
      </section>
      <GroupCapsCard
        capsData={{
          data: capsData.data,
          isLoading: capsData.isLoading,
          isFetching: capsData.isFetching,
        }}
        isShowPrice
      />
      {depositsList && <DepositCard list={depositsList} />}
    </div>
  );
};

export default DashboardAffiliate;
