import * as Yup from "yup";
import { IAllFields, IField, TypesOfInput } from "../../models/fields/IField";
import { ClientsStatisticFilterT } from "../../models/IClientsStatistics";
import { ILeadChange } from '../../models/ILead';
import { fieldsConstructor } from "../../utils/formats";

export const filterClientReportsFields = (): IField<ClientsStatisticFilterT>[] => [
  ...fieldsConstructor<ClientsStatisticFilterT>([
    "country",
    "landing",
    "language",
  ]),
  {
    label: "Sent At",
    name: "sentAt",
    placeholder: "Sent At",
    type: TypesOfInput.DATEPICKER_RANGE,
  },
];

export const createFakeStatisticsValidation = Yup.object<
  Record<keyof ILeadChange, Yup.AnySchema>
>({
  cap: Yup.number().required().min(1),
  country: Yup.string().required(),
  landing: Yup.string().required(),
  language: Yup.string().required(),
  timeEnd: Yup.string(),
  timeStart: Yup.string(),
  sentAt: Yup.array(),
});

export const createFakeStatisticsFields = (): IAllFields<ILeadChange>[] => [
  {
    name: "cap",
    type: TypesOfInput.TEXT,
  },
  ...fieldsConstructor<ILeadChange>([
    "country",
    "landing",
    "language",
  ]),
  {
    label: "Sent At",
    name: "sentAt",
    placeholder: "Sent At",
    type: TypesOfInput.DATEPICKER_RANGE,
  },
];
