import * as apis from "../../api/offer-api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IOffer, IOfferChange } from "../../models/IOffer";
import { ListRes } from "../../api/utils";
import { State } from "../reducers/offersSlice";
import { FetchOptionsParams } from "../../types/global";

// TODO nice example
export const fetchList = createAsyncThunk<
  ListRes<IOffer>,
  FetchOptionsParams,
  { state: { offersReducer: State } }
>("offers/fetchList", (params: FetchOptionsParams | void, { getState }) => {
  const state: State = getState().offersReducer;

  const options = {
    filter: state.filter,
    page: state.pagination.page,
    take: state.pagination.take,
    searchTerm: params?.searchTerm,
  };

  if (!params?.searchTerm) {
    delete options.searchTerm;
  }
  return apis.getList(options);
});

export const changeItem = createAsyncThunk(
  "offers/changeItem",
  ({ data, localizations }: { data: IOfferChange; localizations: string[] }) =>
    apis.changeItem(data, localizations),
);

export const fetchItem = createAsyncThunk("offers/fetchItem", (id: string) => apis.getItem(id));

export const deleteItem = createAsyncThunk("offers/deleteItem", (id: string) =>
  apis.deleteItem(id),
);
