import { GridSizes } from "../../types/global";

export enum TypesOfInput {
  TEXT = "text",
  PASSWORD = "password",
  FILE = "file",
  CHECKBOX = "checkbox",
  SELECT = "select",
  TEXTAREA = "textarea",
  DATEPICKER = "datepicker",
  TIMEPICKER = "timepicker",
  DATEPICKER_RANGE = "datepickerRange",
  PHONE = "phone",
  COLOR_PICKER = "color",
  TEXT_EDITOR = "text editor",
  LOCALIZATIONS = "localizations",
}
export interface IFieldBlock<T> {
  size?: GridSizes;
  title?: string;
  fields: IAllFields<T>[];
  isList?: boolean;
  isCopiedValue?: boolean;
  name?: string;
  label?: string;
  isHidden?: boolean;
}
export type IField<T> = {
  label?: string;
  name: string & keyof T;
  placeholder?: string;
  type?: TypesOfInput;
  isHidden?: boolean;
  size?: GridSizes;
  prefix?: boolean | string;
  disabled?: boolean;
  labels?: string[];
};

export type IFieldDatepicker<T> = IField<T> & {
  showTime?: boolean;
  showSec?: boolean;
};

export type IFileField<T> = IField<T> & {
  isMulti?: boolean;
};

export type IAllFields<T> = IField<T> | IFieldDatepicker<T> | IFileField<T> | FixMeLater;
