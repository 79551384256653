import React, { useMemo, useRef } from "react";
import { Formik, Form, FormikProps } from "formik";
import { Button } from "react-bootstrap";
import Field from "../../components/common/form/formik/Field";
import PageTitle from "../../components/common/items/page-title";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../hooks/redux";
import { emptyValues, IStatusChange } from "../../models/IStatus";
import { changeItem } from "../../store/thunks/statuses";
import { createFields, validationSchema } from "../../components/fields/statuses";

const CreateItem = () => {
  const dispatch = useAppDispatch();
  const fields = useMemo(() => createFields, []);

  const handleSubmit = (values: IStatusChange) => {
    dispatch(changeItem(values));
  };

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <Link to="/statuses" className="w-auto">
          <Button className="w-auto mb-3" variant="primary">
            Back
          </Button>
        </Link>
        <PageTitle title="Creating status" />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Formik
                enableReinitialize
                initialValues={emptyValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {fields.map((field) => (
                      <Field key={field.name} {...field} />
                    ))}
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                  >
                    Create
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CreateItem;
