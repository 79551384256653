import React, { memo, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { IIntegrationModel } from "../../models/IIntegration";
import { SettingsTableKey } from "../../models/IUserSettings";
import Loading from "../../pages/_layouts/Loading";
import { integrationsSlice } from "../../store/reducers/integrationsSlice";
import { fetchIntegrations } from "../../store/thunks/integrations";
import Table from "../common/tables/Table";
import getColumnDefs from "./ColumnDefs";

interface Props {
  className?: string;
}

const IntegrationsTable = ({ className }: Props) => {
  const { id: integrationId } = useParams();
  const dispatch = useAppDispatch();
  const { pagination, integrations } = useAppSelector((state) => state.integrationsReducer);
  const { setPagination } = integrationsSlice.actions;

  const onChangePagination = (page: number) => {
    dispatch(setPagination({ page }));
    dispatch(fetchIntegrations({ filter: { id: integrationId } }));
  };

  const onChangePerPage = (take: number) => {
    dispatch(setPagination({ take }));
    dispatch(fetchIntegrations({ filter: { id: integrationId } }));
  };

  const columns = useMemo(
    () =>
      getColumnDefs({
        pagination,
      }),
    [pagination, integrations],
  );

  if (integrations.loading) {
    return <Loading />;
  }

  return (
    <Table<IIntegrationModel>
      pageKey={SettingsTableKey.INTEGRATIONS}
      changePage={onChangePagination}
      changePageSize={onChangePerPage}
      className={className}
      columns={columns}
      fixed
      items={integrations.data}
      pagination={pagination}
    />
  );
};

export default memo(IntegrationsTable);
