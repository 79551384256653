import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { Link, useParams } from "react-router-dom";
import PageTitle from "../../components/common/items/page-title";
import Loading from "../_layouts/Loading";
import { fetchOneLog } from "../../store/thunks/logs";
import { JSONTree } from "react-json-tree";

const CurrentLog = () => {
  const dispatch = useAppDispatch();
  const { currentLog } = useAppSelector((state) => state.logsReducer);
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;
    dispatch(fetchOneLog(id));
  }, []);

  if (currentLog.loading) {
    return <Loading />;
  }

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <Link to="/logs" className="w-auto">
          <Button className="w-auto mb-3" variant="primary">
            Back
          </Button>
        </Link>
        <PageTitle title="Log" />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <JSONTree
                data={currentLog.data}
                theme={{ base00: "#fff" }}
                invertTheme={false}
                hideRoot={true}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CurrentLog;
