import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="footer bg-white shadow align-self-end py-3 px-xl-5 w-100">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 text-center text-md-start text-primary">
              <p className="mb-2 mb-md-0">Leadbolid © {new Date().getFullYear()}</p>
            </div>
            <div className="col-md-6 text-center text-md-end text-gray-400">
              <p className="mb-0">Version 1.0.0</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
