import * as Yup from "yup";
import { IAllFields, TypesOfInput } from "../../models/fields/IField";
import { ISettingChange, ISettingFilter, typeOptions } from "../../models/ISettings";

export const validationSchema = () =>
  Yup.object<Record<keyof ISettingChange, Yup.AnySchema>>({
    type: Yup.object().typeError("Required"),
    value: Yup.object().shape({
      url: Yup.string().required("Required"),
      name: Yup.string().required("Required"),
    }),
  });

export const createFields = (): IAllFields<ISettingChange>[] => [
  {
    name: "type",
    type: TypesOfInput.SELECT,
    options: typeOptions,
    size: 6
  },
  {
    name: "value.name",
    placeholder: "Name",
    label: "Name",
    size: 6
  },
  {
    name: "value.url",
    placeholder: "Url",
    label: "Url",
    size: 12
  }
];

export const filterFields = (): IAllFields<ISettingFilter>[] => createFields();
