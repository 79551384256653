export type ILabel = ILabelCreate & {
  id: string;
  createdAt: string;
};

export type ILabelCreate = {
  name: string;
  color: string;
};

export type ILabelFilter = ILabelCreate;

export const emptyValues: ILabelCreate = {
  name: "",
  color: "",
};

export const emptyFilters: ILabelCreate = {
  name: "",
  color: "",
};
