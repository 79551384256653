import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationQuery, PaginationType } from "../../components/common/tables/types";
import { FetchedStoreModel, PAGINATION_TAKE } from "../../types/global";
import { IApplications } from "../../models/IApplications";
import { fetchItem, fetchList } from "../thunks/applications";

export interface State {
  list: FetchedStoreModel<IApplications[]>;
  currentItem: FetchedStoreModel<IApplications | null>;
  pagination: PaginationType;
}

const initialState: State = {
  list: {
    data: [],
    error: null,
    loading: true,
  },
  currentItem: {
    data: null,
    error: null,
    loading: true,
  },
  pagination: {
    take: PAGINATION_TAKE,
    total: null,
    page: 1,
    lastPage: null,
  },
};

export const applicationsSlice = createSlice({
  name: "applications",
  initialState,
  reducers: {
    setPagination: (state, action: PayloadAction<PaginationQuery>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    // fetchList
    builder.addCase(fetchList.pending, (state) => {
      state.list.loading = true;
      state.list.error = null;
    });
    builder.addCase(fetchList.fulfilled, (state, action) => {
      state.list.data = action.payload.list;
      state.list.loading = false;
      state.pagination = { ...state.pagination, ...action.payload.pagination };
    });
    builder.addCase(fetchList.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.payload as FixMeLater;
    });

    // fetchItem
    builder.addCase(fetchItem.pending, (state) => {
      state.currentItem.loading = true;
      state.currentItem.error = null;
    });
    builder.addCase(fetchItem.fulfilled, (state, action) => {
      state.currentItem.data = action.payload.model;
      state.currentItem.loading = false;
    });
    builder.addCase(fetchItem.rejected, (state, action) => {
      state.currentItem.loading = false;
      state.currentItem.error = action.payload as FixMeLater;
    });
  },
});

export default applicationsSlice.reducer;
