import React, { useMemo } from "react";
import { useField } from "formik";
import Select, { MultiValue } from "react-select";
import { ISelect, selectStyles } from "../../../../models/fields/ISelect";

const MultipleSelect = <T, Option>(props: ISelect<T, Option>) => {
  const { name, options } = props;
  const [field, , helpers] = useField<MultiValue<Option>>(name);
  const { setValue } = helpers;

  const findValue = (): MultiValue<Option> => {
    if (!field.value) return [];
    return options.filter((option) => field.value.includes(option as Option)) as MultiValue<Option>;
  };

  return (
    <Select
      {...field}
      {...props}
      closeMenuOnSelect={false}
      isMulti
      value={findValue()}
      onChange={(selectedValue: MultiValue<Option>) => {
        setValue(selectedValue);
      }}
      styles={selectStyles}
    />
  );
};

export default MultipleSelect;
