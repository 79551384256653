import * as Yup from "yup";

export type FormErrors = Partial<{
  [key: string]: string | string[];
}>;

export type ObjectWithKeys<T = string> = {
  [key: string]: T;
};

export type Modify<T, R> = Omit<T, keyof R> & R;

export type FetchedStoreModel<T> = {
  data: T;
  error: null | string;
  loading: boolean;
  wasFetched?: boolean;
  fetchedCount?: number;
};

export type RequestOptions<F = object> = {
  filter?: F;
  take: number;
  searchTerm?: string;
};

export type FetchOptionsParams<F = object> = Omit<RequestOptions<F>, "take">;

export type ArrayOfDate = [string | null, string | null];

export type WeekDays =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

export type GridSizes = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export enum LabelColors {
  SUCCESS = "#11D3BC",
  FAILED = "#dc3545",
  WARNING = "#ffc107",
  PRIMARY = "#0d6efd",
  GRAY = "#6c757d",
  PINK = "#a90de7",
}

export type YupValidationFields<Model> = Partial<Record<keyof Model, Yup.AnySchema>>;

export type FilterConditionally<Source, Condition> = Pick<
  Source,
  {
    [K in keyof Source]: Source[K] extends Condition ? K : never;
  }[keyof Source]
>;

export type ListItem<T> = T & {
  id: string;
  createdAt: string;
  isSelected?: boolean;
};

export type SortOrderType = "ASC" | "DESC";

export type SortOrderByType<T extends object> = {
  orderBy: keyof T;
  order: SortOrderType;
};

export const PAGINATION_TAKE = 75;

const today = new Date();
const prevWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
export const prevWeekArr: [Date, Date] = [prevWeek, today];

declare global {
  type UnknownRecord = Record<string, unknown>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type FixMeLater = any;
}
