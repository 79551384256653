import React, { memo, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { ILog } from "../../models/ILog";
import { SettingsTableKey } from "../../models/IUserSettings";
import Loading from "../../pages/_layouts/Loading";
import { logsSlice } from "../../store/reducers/logsSlice";
import { fetchLogs } from "../../store/thunks/logs";
import Table from "../common/tables/Table";
import getColumnDefs from "./ColumnDefs";

interface Props {
  className?: string;
}

const LogsTable = ({ className }: Props) => {
  const dispatch = useAppDispatch();
  const { pagination, logs } = useAppSelector((state) => state.logsReducer);
  const { setPagination } = logsSlice.actions;

  const onChangePagination = (page: number) => {
    dispatch(setPagination({ page }));
    dispatch(fetchLogs());
  };

  const onChangePerPage = (take: number) => {
    dispatch(setPagination({ take }));
    dispatch(fetchLogs());
  };

  const columns = useMemo(() => getColumnDefs({ pagination }), [pagination, logs]);

  if (logs.loading) {
    return <Loading />;
  }

  return (
    <Table<ILog>
      pageKey={SettingsTableKey.LOGS}
      changePage={onChangePagination}
      changePageSize={onChangePerPage}
      className={className}
      columns={columns}
      containerClass="overflow-auto"
      items={logs.data}
      pagination={pagination}
    />
  );
};

export default memo(LogsTable);
