import axios, { AxiosError, AxiosRequestHeaders, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { camelizeKeys, decamelizeKeys } from "humps";
import { serialize } from "object-to-formdata";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { PaginationType } from "../components/common/tables/types";
import { ObjectWithKeys } from "../types/global";
import { formatDateToBack } from "../utils/formats";

export const baseURL = process.env.REACT_APP_ENV_NAME === "dev" ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL;
export const baseGetImageURL = process.env.REACT_APP_BASE_GET_IMAGE_URL;

export const getToken = (): string => {
  const cookies = new Cookies();
  const token = cookies.get("authorization");
  return token || "";
};

type CookiesValue = FixMeLater;

export const getCookies = (key: string): CookiesValue => {
  const cookies = new Cookies();
  return cookies.get(key);
};

export const setCookies = (key: string, value: CookiesValue) => {
  const cookies = new Cookies();
  cookies.set(key, value, { path: "/", maxAge: 31536000 });
};

export const removeCookies = (key: string) => {
  const cookies = new Cookies();
  cookies.remove(key, { path: "/", maxAge: 31536000 });
};

export const Api = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  maxRedirects: 0,
});

export const ApiNonCamelize = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  maxRedirects: 0,
});

export const formApi = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
  },
  maxRedirects: 0,
});

export type ModelRes<T> = {
  model: T;
};

export type ListRes<T> = {
  list: T[];
  pagination: PaginationType;
};

export type ListResWithoutPag<T> = {
  list: T[];
};

export type ResponseError = {
  message: string;
  errors: {
    [key: string]: string[];
  };
};

export type ShortModel = {
  id: string;
  name: string;
  affiliate: string;
  createdAt: string;
};
export const getResponseData = <T>(response: AxiosResponse<T>): T => response.data;

const camelizeResponse = (response: AxiosResponse): AxiosResponse => {
  return {
    ...response,
    data: camelizeKeys(response.data, (key, convert) => {
      return /^[A-Z]+$/.test(key) ? key : convert(key);
    }),
  };
};

const errorResponse = (error: AxiosError<ResponseError>) => {
  const { response } = error;
  if (response) {
    if (response.status === 401 && response.request.responseURL.endsWith("/users/me")) {
      removeCookies("authorization");
      window.location.href = "/login";
      return;
    }
    if (response.status === 401) {
      toast.error("You are unauthorized");
      setTimeout(() => {
        removeCookies("authorization");
        window.location.href = "/login";
      }, 1000);
    }
    toast.error(response.data.message);
    return Promise.reject(camelizeKeys(getResponseData(response)));
  }
};

const setAuthToken = (config: InternalAxiosRequestConfig) => {
  const token = getToken();
  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: "Bearer " + token,
    } as AxiosRequestHeaders;
  }
  return config;
};

const DATE_KEYS = ["sentAt", "createdAt", "depositedAt"];

export const filterEmpyValues = (data: object, isNotFilter?: boolean) => {
  const fullyObject: ObjectWithKeys<FixMeLater> = {};
  if (data === null || data === undefined) return data;

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value) && !isNotFilter) {
      // Handle Date
      if (DATE_KEYS.includes(key) && Array.isArray(value)) {
        if (value[0]) {
          fullyObject[`${key}_from`] = formatDateToBack(value[0]);
        }
        if (value[1]) {
          fullyObject[`${key}_to`] = formatDateToBack(value[1]);
        }
      } else {
        // Handle array
        if (value.length) {
          fullyObject[key] = value.join(",");
        }
      }
    } else if (Array.isArray(value)) {
      const newValueArray = value
        .map((elem) => filterEmpyValues(elem))
        .filter((elem) => Object.keys(elem).length);
      if (newValueArray.length) {
        fullyObject[key] = newValueArray;
      }
    } else if (value instanceof File) {
      fullyObject[key] = value;
    } else if (typeof value === "object" && value !== null) {
      const filteredSubObject = filterEmpyValues(value);
      if (filteredSubObject && Object.keys(filteredSubObject).length) {
        fullyObject[key] = filteredSubObject;
      }
    } else if (isNotFilter && !value && typeof value !== "boolean") {
      fullyObject[key] = null;
    } else if (value || value === false) {
      fullyObject[key] = value;
    }
  });

  return fullyObject;
};

export const getValuesFromModel = <T extends object>(model: object & T, values: T): T => {
  const newValues: T = { ...values };
  const valuesKeys = Object.keys(values);
  valuesKeys.forEach((key) => {
    newValues[key as keyof T] = model[key as keyof T];
  });
  return newValues;
};

export const filterExtraFieldsFromArray = <T extends object>(
  dataArray: T[],
  keys: (keyof T)[],
): T[] =>
  dataArray.map((elem) => {
    const newObj: T = {} as T;
    keys.forEach((key) => {
      const value = elem[key];
      newObj[key] = value;
    });
    return newObj;
  });

const decamelizeRequest = (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  if (request.method === "get") {
    return request;
  }

  return { ...request, data: decamelizeKeys(request.data) };
};

const createFormData = (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  return { ...request, data: serialize(request.data) };
};

export const decamelizeDataByFilter = <T extends object>(data: T, exceptions: string[]) => {
  return decamelizeKeys(data, (key, convert) => {
    return exceptions.includes(key) ? key : convert(key);
  });
};

export const filterDataByCountries = (
  data: ObjectWithKeys<string | File[] | null[]> | undefined,
  countries: string[],
) => {
  if (!data) return;

  return countries.reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: data[curr] || "",
    }),
    {},
  );
};

Api.interceptors.request.use(setAuthToken);
Api.interceptors.request.use(decamelizeRequest);
Api.interceptors.response.use(camelizeResponse, errorResponse);
formApi.interceptors.request.use(setAuthToken);
formApi.interceptors.request.use(createFormData);
formApi.interceptors.response.use((response) => response, errorResponse);
ApiNonCamelize.interceptors.request.use(setAuthToken);
ApiNonCamelize.interceptors.request.use(decamelizeRequest);
ApiNonCamelize.interceptors.response.use((response) => response, errorResponse);

export const prepareHeaders = (headers: Headers) => {
  headers.set("authorization", `Bearer ${getToken()}`);
  headers.set("accept", "application/json");
  return headers;
};

export const formatEmptyValuesToNull = (values: ObjectWithKeys<FixMeLater>): ObjectWithKeys<unknown> => {
  const fullyObject: ObjectWithKeys<unknown> = {};
  if (!values) return values;

  Object.entries(values).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      return value;
    } else if (value instanceof File) {
      fullyObject[key] = value;
    } else if (typeof value === "object") {
      const filteredSubObject = formatEmptyValuesToNull(value);
      fullyObject[key] = filteredSubObject;
    } else if (value || value === false) {
      fullyObject[key] = value;
    }
  });

  return fullyObject;
};
