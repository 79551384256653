import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { PaginationQuery, PaginationType } from "../../components/common/tables/types";
import { IClient, IFilterClient, emptyFilters } from "../../models/IClients";
import { FetchedStoreModel, PAGINATION_TAKE, SortOrderByType } from "../../types/global";
import {
  changeStatus,
  changeClient,
  fetchClients,
  fetchOneClient,
  fetchShortClients,
  updateClients,
  cloneClient, changeAcceptHold,
} from "../thunks/clients";

export interface ClientsState extends SortOrderByType<IClient> {
  clients: FetchedStoreModel<IClient[]>;
  clientsShort: FetchedStoreModel<IClient[]>;
  currentClient: FetchedStoreModel<IClient | null>;
  pagination: PaginationType;
  filter: IFilterClient;
  searchTerm: string;
  selectedItems: string[];
}

const initialState: ClientsState = {
  clients: {
    data: [],
    error: null,
    loading: true,
  },
  clientsShort: {
    data: [],
    error: null,
    loading: false,
  },
  currentClient: {
    data: null,
    error: null,
    loading: true,
  },
  pagination: {
    take: PAGINATION_TAKE,
    total: null,
    page: 1,
    lastPage: null,
  },
  selectedItems: [],
  filter: emptyFilters,
  searchTerm: "",
  orderBy: "status",
  order: "ASC",
};

export const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    selectClients: (state, action: PayloadAction<string[]>) => {
      state.selectedItems = action.payload;
    },
    setPagination: (state, action: PayloadAction<PaginationQuery>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    setFilters: (state, action: PayloadAction<IFilterClient>) => {
      state.filter = action.payload;
      state.pagination.page = 1;
    },
    resetFilters: (state) => {
      state.filter = emptyFilters;
      state.pagination.page = 1;
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
      state.pagination.page = 1;
    },
    setOrderBy: (state, action: PayloadAction<SortOrderByType<IClient>>) => {
      state.orderBy = action.payload.orderBy;
      state.order = action.payload.order;
    },
  },
  extraReducers: (builder) => {
    // fetchClients
    builder.addCase(fetchClients.pending, (state) => {
      state.clients.loading = true;
      state.clients.error = null;
    });
    builder.addCase(fetchClients.fulfilled, (state, action) => {
      state.clients.data = action.payload.list;
      state.clients.loading = false;
      state.pagination = { ...state.pagination, ...action.payload.pagination };
    });
    builder.addCase(fetchClients.rejected, (state, action) => {
      state.clients.loading = false;
      state.clients.error = action.payload as FixMeLater;
    });

    // fetchShortClients
    builder.addCase(fetchShortClients.pending, (state) => {
      state.clientsShort.loading = true;
      state.clientsShort.error = null;
    });
    builder.addCase(fetchShortClients.fulfilled, (state, action) => {
      state.clientsShort.data = action.payload.list;
      state.clientsShort.loading = false;
    });
    builder.addCase(fetchShortClients.rejected, (state, action) => {
      state.clientsShort.loading = false;
      state.clientsShort.error = action.payload as FixMeLater;
    });

    // fetchOneClient
    builder.addCase(fetchOneClient.pending, (state) => {
      state.currentClient.loading = true;
      state.currentClient.error = null;
    });
    builder.addCase(fetchOneClient.fulfilled, (state, action) => {
      state.currentClient.data = action.payload.model;
      state.currentClient.loading = false;
    });
    builder.addCase(fetchOneClient.rejected, (state, action) => {
      state.currentClient.loading = false;
      state.currentClient.error = action.payload as FixMeLater;
    });

    // changeClient
    builder.addCase(changeClient.fulfilled, () => {
      window.location.href = "/clients";
      toast.success("Client successfully changed");
    });

    // changeStatus
    builder.addCase(changeStatus.pending, (state) => {
      state.clients.loading = true;
    });
    builder.addCase(changeStatus.fulfilled, (state, action) => {
      state.clients.data = state.clients.data.map((client) =>
        client.id === action.payload.model.id
          ? { ...client, status: action.payload.model.status }
          : client,
      );
      state.clients.loading = false;
      toast.success(`Client ${action.payload.model.name} successfully updated`);
    });
    builder.addCase(changeStatus.rejected, (state) => {
      state.clients.loading = false;
    });

    // changeAcceptHold
    builder.addCase(changeAcceptHold.pending, (state) => {
      state.clients.loading = true;
    });
    builder.addCase(changeAcceptHold.fulfilled, (state, action) => {
      state.clients.data = state.clients.data.map((client) =>
        client.id === action.payload.model.id
          ? { ...client, acceptHold: action.payload.model.acceptHold }
          : client,
      );
      state.clients.loading = false;
      toast.success(`Client ${action.payload.model.name} successfully updated`);
    });
    builder.addCase(changeAcceptHold.rejected, (state) => {
      state.clients.loading = false;
    });

    // updateClients
    builder.addCase(updateClients.fulfilled, (state, action) => {
      const changedLeadsIds = action.payload.list.map((lead) => lead.id);
      const newLeads = state.clients.data.map((lead) => {
        if (changedLeadsIds.includes(lead.id)) {
          const currentLead = action.payload.list.find((changedLead) => changedLead.id === lead.id);
          return currentLead ? currentLead : lead;
        }
        return lead;
      });

      state.clients.data = newLeads;
      toast.success("Clients successfully updated");
      window.location.pathname = "/clients";
    });
    builder.addCase(updateClients.rejected, (state, action) => {
      state.clients.loading = false;
      state.clients.error = action.payload as FixMeLater;
      toast.error(action.payload as FixMeLater);
    });

    // cloneClient
    builder.addCase(cloneClient.fulfilled, () => {
      toast.success("Clients successfully cloned");
      window.location.pathname = "/clients";
    });
  },
});

export default clientsSlice.reducer;
