import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationQuery, PaginationType } from "../../components/common/tables/types";
import { FetchedStoreModel, PAGINATION_TAKE } from "../../types/global";
import { toast } from "react-toastify";
import { emptyFilters, IPlayer, IPlayerFilters } from "../../models/IPlayer";
import { deleteItems, fetchList } from "../thunks/players";
import { RootState } from ".";

export interface State {
  list: FetchedStoreModel<IPlayer[]>;
  pagination: PaginationType;
  isSelectedAll: boolean;
  filter: IPlayerFilters;
}

const initialState: State = {
  list: {
    data: [],
    error: null,
    loading: true,
  },
  pagination: {
    take: PAGINATION_TAKE,
    total: null,
    page: 1,
    lastPage: null,
  },
  isSelectedAll: false,
  filter: emptyFilters,
};

export const playersSlice = createSlice({
  name: "players",
  initialState,
  reducers: {
    selectItem: (state, action: PayloadAction<string>) => {
      state.list.data = state.list.data.map((item) =>
        item.id === action.payload ? { ...item, isSelected: !item.isSelected } : item,
      );
    },
    selectAll: (state) => {
      state.list.data = state.list.data.map((item) => ({
        ...item,
        isSelected: !state.isSelectedAll,
      }));
      state.isSelectedAll = !state.isSelectedAll;
    },
    setPagination: (state, action: PayloadAction<PaginationQuery>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    setFilters: (state, action: PayloadAction<IPlayerFilters>) => {
      state.filter = action.payload;
      state.pagination.page = 1;
    },
    resetFilters: (state) => {
      state.filter = emptyFilters;
      state.pagination.page = 1;
    },
  },
  extraReducers: (builder) => {
    // fetchList
    builder.addCase(fetchList.pending, (state) => {
      state.list.loading = true;
      state.list.error = null;
    });
    builder.addCase(fetchList.fulfilled, (state, action) => {
      state.list.data = action.payload.list;
      state.list.loading = false;
      state.pagination = { ...state.pagination, ...action.payload.pagination };
      state.isSelectedAll = false;
    });
    builder.addCase(fetchList.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.payload as FixMeLater;
    });

    // deleteItems
    builder.addCase(deleteItems.pending, (state) => {
      state.list.loading = true;
      state.list.error = null;
    });
    builder.addCase(deleteItems.fulfilled, (state, action) => {
      state.list.data = action.payload.list;
      toast.success("Players successfully deleted");
      state.pagination = { ...state.pagination, ...action.payload.pagination };
      state.list.loading = false;
    });
    builder.addCase(deleteItems.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.payload as FixMeLater;
    });
  },
});

export const getSelectedPlayersIds = (state: RootState) =>
  state.playersSlice.list.data.filter((item) => Boolean(item.isSelected)).map((item) => item.id);

export default playersSlice.reducer;
