import * as Yup from "yup";
import { IFieldBlock, TypesOfInput } from "../../models/fields/IField";
import { OptionModel, GenericOption } from "../../models/fields/ISelect";
import {
  answerOptions,
  bodyValueOptions,
  headerValueOptions,
  httpMethodOptions,
  IIntegrationChange,
  IIntegrationForm,
  IntegrationType,
  redirectOptions,
  statusOptions,
  typeOptions,
} from "../../models/IIntegration";
import { fieldsConstructor } from "../../utils/formats";

export const integrationValidation = Yup.object<Record<keyof IIntegrationChange, Yup.AnySchema>>({
  id: Yup.string(),
  answer: Yup.mixed().when("type", {
    is: (val: OptionModel<GenericOption<IntegrationType>>) => val?.value !== "postback",
    then: () => Yup.mixed().when("isSheet", {
      is: (val: boolean) => !val,
      then: () => Yup.object({
        key: Yup.string().nullable(),
        type: Yup.object().typeError("Required"),
        value: Yup.string().required("Required"),
      }),
    }),
    otherwise: (schema) => schema.nullable(),
  }),
  body: Yup.array().of(
    Yup.object().shape({
      key: Yup.string(),
      value: Yup.object().nullable(),
    }),
  ),
  headers: Yup.array().of(
    Yup.object().shape({
      key: Yup.string(),
      value: Yup.mixed(),
    }),
  ),
  method: Yup.object().typeError("Required"),
  isSheet: Yup.boolean(),
  name: Yup.string().required("Required"),
  parameters: Yup.array().of(
    Yup.object().shape({
      key: Yup.string(),
      landing: Yup.string(),
      value: Yup.string(),
    }),
  ),
  postback: Yup.mixed().when("type", {
    is: (val: OptionModel<GenericOption<IntegrationType>>) => val?.value === "postback",
    then: () => Yup.string(),
    otherwise: () => Yup.object().nullable(),
  }),
  redirect: Yup.object({
    type: Yup.object().nullable(),
    value: Yup.string(),
  }),
  status: Yup.object().nullable(),
  type: Yup.object().typeError("Required"),
  url: Yup.string().when("isSheet", {
    is: (val: boolean) => !val,
    otherwise: (schema) => schema.notRequired(),
    then: (schema) => schema.required("Required"),
  }),
  wrapArray: Yup.boolean(),
  withoutPlus: Yup.boolean(),
});

export const editIntegrationFields = (
  values?: IIntegrationChange,
): IFieldBlock<IIntegrationForm>[] => [
  {
    fields: [
      {
        name: "id",
        type: TypesOfInput.TEXT,
        disabled: true,
      },
      ...fieldsConstructor<IIntegrationForm>(["name", "url"]),
      {
        label: "Method",
        name: "method",
        options: httpMethodOptions,
        type: TypesOfInput.SELECT,
      },
      {
        label: "Google Sheet",
        name: "isSheet",
        type: TypesOfInput.CHECKBOX,
        isHidden: values?.type?.value === "postback",
      },
      {
        label: "Type",
        name: "type",
        options: typeOptions,
        type: TypesOfInput.SELECT,
      },
      {
        label: "Is extist Array in request",
        name: "wrapArray",
        type: TypesOfInput.CHECKBOX,
        isHidden: values?.type?.value === "postback",
      },
      {
        label: "Без плюса",
        name: "withoutPlus",
        type: TypesOfInput.CHECKBOX,
      },
      {
        label: "Status",
        name: "status",
        options: statusOptions,
        type: TypesOfInput.SELECT,
      },
      {
        label: "Postback",
        name: "postback.key",
        type: TypesOfInput.TEXT,
        isHidden: values?.type?.value === "postback",
      },
    ],
    title: "Integration Info",
  },
  {
    fields: [
      {
        label: "Postback",
        name: "postback",
        type: TypesOfInput.TEXTAREA,
        size: 12,
      },
    ],
    isHidden: values?.type?.value !== "postback",
    title: "Postback",
  },
  {
    fields: [
      {
        label: "Response type",
        name: "answer.type",
        options: answerOptions,
        placeholder: "Response type",
        type: TypesOfInput.SELECT,
      },
      {
        label: "Response key",
        name: "answer.key",
        placeholder: "Response key",
        type: TypesOfInput.TEXT,
      },
      {
        label: "Response value",
        name: "answer.value",
        placeholder: "Response value",
        type: TypesOfInput.TEXT,
      },
    ],
    isHidden: values?.type?.value === "postback",
    title: "Response",
  },
  {
    fields: [
      {
        label: "Redirect type",
        name: "redirect.type",
        options: redirectOptions,
        placeholder: "Redirect type",
        type: TypesOfInput.SELECT,
      },
      {
        label: "Redirect value",
        name: "redirect.value",
        placeholder: "Redirect value",
        type: TypesOfInput.TEXT,
      },
    ],
    isHidden: values?.type?.value === "postback",
    title: "Redirect",
  },
  {
    fields: [
      {
        label: "",
        name: "headers.key",
        placeholder: "Header key",
        size: 6,
        type: TypesOfInput.TEXT,
      },
      {
        label: "",
        name: "headers.value",
        placeholder: "Header value",
        size: 6,
        options: headerValueOptions,
        type: TypesOfInput.SELECT,
        isCustomValue: true,
      },
    ],
    isList: true,
    name: "headers",
    size: 6,
    title: "Headers",
    isCopiedValue: true,
  },
  {
    fields: [
      {
        label: "",
        name: "body.key",
        placeholder: "Body key",
        size: 6,
        type: TypesOfInput.TEXT,
      },
      {
        isCustomValue: true,
        label: "",
        name: "body.value",
        options: bodyValueOptions,
        placeholder: "Body value",
        size: 6,
        type: TypesOfInput.SELECT,
      },
    ],
    isCopiedValue: true,
    isList: true,
    name: "body",
    size: 6,
    title: "Body",
  },
  {
    fields: [
      {
        label: "",
        name: "parameters.key",
        options: answerOptions,
        placeholder: "Parameters key",
        type: TypesOfInput.TEXT,
      },
      {
        label: "",
        name: "parameters.value",
        placeholder: "Parameters value",
        type: TypesOfInput.TEXT,
      },
      {
        label: "",
        name: "parameters.landing",
        placeholder: "Parameters landing",
        type: TypesOfInput.TEXT,
      },
    ],
    isList: true,
    name: "parameters",
    title: "Parameters",
    isHidden: values?.type?.value === "postback",
  },
];

export const createIntegrationFields = (
  values?: IIntegrationChange,
): IFieldBlock<IIntegrationForm>[] => [
  {
    fields: [
      ...fieldsConstructor<IIntegrationForm>(["name", "url"]),
      {
        label: "Method",
        name: "method",
        options: httpMethodOptions,
        type: TypesOfInput.SELECT,
      },
      {
        label: "Google Sheet",
        name: "isSheet",
        type: TypesOfInput.CHECKBOX,
        isHidden: values?.type?.value === "postback",
      },
      {
        label: "Type",
        name: "type",
        options: typeOptions,
        type: TypesOfInput.SELECT,
      },
      {
        label: "Is extist Array in request",
        name: "wrapArray",
        type: TypesOfInput.CHECKBOX,
        isHidden: values?.type?.value === "postback",
      },
      {
        label: "Без плюса",
        name: "withoutPlus",
        type: TypesOfInput.CHECKBOX,
      },
      {
        label: "Status",
        name: "status",
        options: statusOptions,
        type: TypesOfInput.SELECT,
      },
      {
        label: "Postback",
        name: "postback.key",
        type: TypesOfInput.TEXT,
        isHidden: values?.type?.value === "postback",
      },
    ],
    title: "Integration Info",
  },
  {
    fields: [
      {
        label: "Postback",
        name: "postback",
        type: TypesOfInput.TEXTAREA,
        size: 12,
      },
    ],
    isHidden: values?.type?.value !== "postback",
    title: "Postback",
  },
  {
    fields: [
      {
        label: "Response type",
        name: "answer.type",
        options: answerOptions,
        placeholder: "Response type",
        type: TypesOfInput.SELECT,
      },
      {
        label: "Response key",
        name: "answer.key",
        placeholder: "Response key",
        type: TypesOfInput.TEXT,
      },
      {
        label: "Response value",
        name: "answer.value",
        placeholder: "Response value",
        type: TypesOfInput.TEXT,
      },
    ],
    isHidden: values?.type?.value === "postback",
    title: "Response",
  },
  {
    fields: [
      {
        label: "Redirect type",
        name: "redirect.type",
        options: redirectOptions,
        placeholder: "Redirect type",
        type: TypesOfInput.SELECT,
      },
      {
        label: "Redirect value",
        name: "redirect.value",
        placeholder: "Redirect value",
        type: TypesOfInput.TEXT,
      },
    ],
    isHidden: values?.type?.value === "postback",
    title: "Redirect",
  },
  {
    fields: [
      {
        label: "",
        name: "headers.key",
        placeholder: "Header key",
        size: 6,
        type: TypesOfInput.TEXT,
      },
      {
        label: "",
        name: "headers.value",
        placeholder: "Header value",
        size: 6,
        options: headerValueOptions,
        type: TypesOfInput.SELECT,
        isCustomValue: true,
      },
    ],
    isCopiedValue: true,
    isList: true,
    name: "headers",
    size: 6,
    title: "Headers",
  },
  {
    fields: [
      {
        label: "",
        name: "body.key",
        placeholder: "Body key",
        size: 6,
        type: TypesOfInput.TEXT,
      },
      {
        isCustomValue: true,
        label: "",
        name: "body.value",
        options: bodyValueOptions,
        placeholder: "Body value",
        size: 6,
        type: TypesOfInput.SELECT,
      },
    ],
    isCopiedValue: true,
    isList: true,
    name: "body",
    size: 6,
    title: "Body",
  },
  {
    fields: [
      {
        label: "",
        name: "parameters.key",
        options: answerOptions,
        placeholder: "Parameters key",
        type: TypesOfInput.TEXT,
      },
      {
        label: "",
        name: "parameters.value",
        placeholder: "Parameters value",
        type: TypesOfInput.TEXT,
      },
      {
        label: "",
        name: "parameters.landing",
        placeholder: "Parameters landing",
        type: TypesOfInput.TEXT,
      },
    ],
    isList: true,
    name: "parameters",
    title: "Parameters",
    isHidden: values?.type?.value === "postback",
  },
];
