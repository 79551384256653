import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationQuery, PaginationType } from "../../components/common/tables/types";
import { FetchedStoreModel, PAGINATION_TAKE } from "../../types/global";
import { toast } from "react-toastify";
import { IStatus, emptyFilters, IStatusFilter } from "../../models/IStatus";
import { changeItem, deleteItem, fetchGroupList, fetchItem, fetchList } from "../thunks/statuses";

export interface State {
  list: FetchedStoreModel<IStatus[]>;
  groupList: FetchedStoreModel<IStatus[]>;
  currentItem: FetchedStoreModel<IStatus | null>;
  pagination: PaginationType;
  isSelectedAll: boolean;
  filter: IStatusFilter;
}

const initialState: State = {
  list: {
    data: [],
    error: null,
    loading: true,
  },
  groupList: {
    data: [],
    error: null,
    loading: true,
  },
  currentItem: {
    data: null,
    error: null,
    loading: true,
  },
  pagination: {
    take: PAGINATION_TAKE,
    total: null,
    page: 1,
    lastPage: null,
  },
  isSelectedAll: false,
  filter: emptyFilters,
};

export const statusesSlice = createSlice({
  name: "statuses",
  initialState,
  reducers: {
    selectItem: (state, action: PayloadAction<string>) => {
      state.list.data = state.list.data.map((item) =>
        item.id === action.payload ? { ...item, isSelected: !item.isSelected } : item,
      );
    },
    selectAll: (state) => {
      state.list.data = state.list.data.map((item) => ({
        ...item,
        isSelected: !state.isSelectedAll,
      }));
      state.isSelectedAll = !state.isSelectedAll;
    },
    setPagination: (state, action: PayloadAction<PaginationQuery>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    setFilters: (state, action: PayloadAction<IStatusFilter>) => {
      state.filter = action.payload;
      state.pagination.page = 1;
    },
    resetFilters: (state) => {
      state.filter = emptyFilters;
      state.pagination.page = 1;
    },
  },
  extraReducers: (builder) => {
    // fetchList
    builder.addCase(fetchList.pending, (state) => {
      state.list.loading = true;
      state.list.error = null;
    });
    builder.addCase(fetchList.fulfilled, (state, action) => {
      state.list.data = action.payload.list;
      state.list.loading = false;
      state.pagination = { ...state.pagination, ...action.payload.pagination };
      state.isSelectedAll = false;
    });
    builder.addCase(fetchList.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.payload as FixMeLater;
    });

    // fetchGroupList
    builder.addCase(fetchGroupList.pending, (state) => {
      state.groupList.loading = true;
      state.groupList.error = null;
    });
    builder.addCase(fetchGroupList.fulfilled, (state, action) => {
      state.groupList.data = action.payload;
      state.groupList.loading = false;
      state.isSelectedAll = false;
    });
    builder.addCase(fetchGroupList.rejected, (state, action) => {
      state.groupList.loading = false;
      state.groupList.error = action.payload as FixMeLater;
    });

    // fetchItem
    builder.addCase(fetchItem.pending, (state) => {
      state.currentItem.loading = true;
      state.currentItem.error = null;
    });
    builder.addCase(fetchItem.fulfilled, (state, action) => {
      state.currentItem.data = action.payload.model;
      state.currentItem.loading = false;
    });
    builder.addCase(fetchItem.rejected, (state, action) => {
      state.currentItem.loading = false;
      state.currentItem.error = action.payload as FixMeLater;
    });

    builder.addCase(changeItem.fulfilled, (state, action) => {
      state.currentItem.data = action.payload.model;
      toast.success("Status successfully changed");
      window.location.pathname = "/statuses";
    });

    builder.addCase(deleteItem.fulfilled, () => {
      toast.success("Status successfully deleted");
      window.location.pathname = "/statuses";
    });
  },
});

export default statusesSlice.reducer;
