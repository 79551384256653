import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { PaginationQuery, PaginationType } from "../../components/common/tables/types";
import {
  ILocalization,
  IFilterLocalization,
  initialLocalizationFilters,
} from "../../models/ILocalizations";
import { FetchedStoreModel, PAGINATION_TAKE } from "../../types/global";
import {
  changeLocalization,
  fetchLocalizations,
  fetchOneLocalization,
} from "../thunks/localizations";

export interface localizationsState {
  localizations: FetchedStoreModel<ILocalization[]>;
  currentLocalization: FetchedStoreModel<ILocalization | null>;
  pagination: PaginationType;
  filter: IFilterLocalization;
}

const initialState: localizationsState = {
  localizations: {
    data: [],
    error: null,
    loading: true,
  },
  currentLocalization: {
    data: null,
    error: null,
    loading: true,
  },
  pagination: {
    take: PAGINATION_TAKE,
    total: null,
    page: 1,
    lastPage: null,
  },
  filter: initialLocalizationFilters,
};

export const localizationsSlice = createSlice({
  name: "localizations",
  initialState,
  reducers: {
    setPagination: (state, action: PayloadAction<PaginationQuery>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    setFilters: (state, action: PayloadAction<IFilterLocalization>) => {
      state.filter = action.payload;
      state.pagination.page = 1;
    },
    resetFilters: (state) => {
      state.filter = initialLocalizationFilters;
      state.pagination.page = 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLocalizations.pending, (state) => {
      state.localizations.loading = true;
      state.localizations.error = null;
    });
    builder.addCase(fetchLocalizations.fulfilled, (state, action) => {
      state.localizations.data = action.payload.list;
      state.localizations.loading = false;
      state.pagination = { ...state.pagination, ...action.payload.pagination };
    });
    builder.addCase(fetchLocalizations.rejected, (state, action) => {
      state.localizations.loading = false;
      state.localizations.error = action.payload as FixMeLater;
    });
    builder.addCase(fetchOneLocalization.pending, (state) => {
      state.currentLocalization.loading = true;
      state.currentLocalization.error = null;
    });
    builder.addCase(fetchOneLocalization.fulfilled, (state, action) => {
      state.currentLocalization.data = action.payload.model;
      state.currentLocalization.loading = false;
    });
    builder.addCase(fetchOneLocalization.rejected, (state, action) => {
      state.currentLocalization.loading = false;
      state.currentLocalization.error = action.payload as FixMeLater;
    });
    builder.addCase(changeLocalization.fulfilled, (state, action) => {
      state.currentLocalization.data = action.payload.model;
      toast.success(`Localization ${action.payload.model.name} successfully changed`);
      window.location.pathname = "/localizations";
    });
  },
});

export default localizationsSlice.reducer;
