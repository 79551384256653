import { PaginationQuery } from "../components/common/tables/types";
import { ArrayOfDate } from "../types/global";

export type ITeam = ITeamChange & {
  createdAt: string;
  id: string;
};

export type ITeamChange = {
  name: string;
  id?: string;
};

export type ITeamFilter = ITeamChange & {
  createdAt: ArrayOfDate;
};

export type ITeamFilterRequest = PaginationQuery & ITeamFilter;

export const emptyValues: ITeamChange = {
  name: "",
};

export const initialFilters: ITeamFilter = {
  name: "",
  createdAt: [null, null],
};
