import React, { useMemo } from "react";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import Field from "../../components/common/form/formik/Field";
import FieldBlock from "../../components/common/form/formik/FieldBlock";
import PageTitle from "../../components/common/items/page-title";
import ScheduleFields from "../../components/common/form/formik/ScheduleFields";
import { createEmptyValues, IClientCreate } from "../../models/IClients";
import { Link } from "react-router-dom";
import { createAccessLandingFields } from "../../components/fields/create-accessLanding";
import { changeClient } from "../../store/thunks/clients";
import { createClientFields, createClientValidation } from "../../components/fields/create-client";
import { fetchShortAffiliates } from "../../store/thunks/affiliates";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { fetchLabels } from "../../store/thunks/labels";
import { fetchList as fetchTeamList } from "../../store/thunks/teams";
import { FetchOptionsParams } from "../../types/global";
import { TypesOfInput } from "../../models/fields/IField";

const CreateClient = () => {
  const dispatch = useAppDispatch();
  const { affiliatesShort } = useAppSelector((state) => state.affiliatesReducer);
  const { labels } = useAppSelector((state) => state.labelsReducer);
  const { list: teamList } = useAppSelector((state) => state.teamsSlice);

  const fields = useMemo(
    () =>
      createClientFields({
        asyncLabels: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchLabels(params)),
          isLoading: labels.loading,
        },
        asyncTeams: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchTeamList(params)),
          isLoading: teamList.loading,
        },
        labelOptions: labels.data,
        teamOptions: teamList.data,
      }),
    [labels, teamList],
  );

  const accessLandingFields = useMemo(
    () =>
      createAccessLandingFields({
        affiliateOptions: affiliatesShort.data,
        asyncAffiliates: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchShortAffiliates(params)),
          isLoading: affiliatesShort.loading,
        },
      }),
    [affiliatesShort],
  );

  const handleSubmit = (values: IClientCreate) => {
    dispatch(changeClient(values));
  };

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <Link to="/clients" className="w-auto">
          <Button className="w-auto mb-3" variant="primary">
            Back
          </Button>
        </Link>
        <PageTitle title="Creating client" />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={createEmptyValues}
                validationSchema={createClientValidation}
                onSubmit={handleSubmit}
              >
                {({ values }) => (
                  <Form className="w-100 px-4">
                    <div className="row">
                      {fields.map((field) => (
                        <Field key={field.name} {...field} />
                      ))}
                      <Field
                        label="Percent"
                        name="percent"
                        placeholder="0"
                        type={TypesOfInput.TEXT}
                        isHidden={values.type?.value !== 'crg'}
                      />
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <ScheduleFields name="parameters" />
                      </div>
                      <div className="col-6">
                        <p className="m-0">Access Landings</p>
                        {accessLandingFields.map((field) => (
                          <FieldBlock key={field.name} {...field} />
                        ))}
                      </div>
                    </div>
                    <Button
                      type="submit"
                      className="form-control w-25 m-auto d-block"
                      variant="primary"
                    >
                      Create
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CreateClient;
