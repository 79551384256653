import { IAdditions } from "../models/IAdditions";
import {
  IFilterLeadRequest,
  ILead,
  ILeadChange,
  ILeadRequest,
  RequestLead,
  StatusHistory,
  TestLeadInitialValues,
  TestLeadRequest,
  TestLeadResponse,
  UpdateSomeLeadsActionsRequest,
} from "../models/ILead";
import { ILog } from "../models/ILog";
import { ObjectWithKeys } from "../types/global";
import { formatDateAndTimeToBack, handleDownload } from "../utils/formats";
import { Api, ApiNonCamelize, baseURL, filterEmpyValues, getToken, ListRes, ModelRes } from "./utils";
import { getResponseData } from "./utils";
import { decamelizeKeys } from "humps";

const LEADS_PATH = "/v1/leads";

export const getLeads = (params: RequestLead): Promise<ListRes<ILead>> => (
  Api.post(LEADS_PATH + "/list", filterEmpyValues(params)).then(getResponseData)
);

export const getLead = (id: string): Promise<ModelRes<ILead>> => {
  return Api.get(`${LEADS_PATH}/${id}`).then(getResponseData);
};

export const updateLead = (lead: ILeadChange & { id?: string }) => {
  const sendingData: ILeadRequest = {
    ...lead,
    affiliate: typeof lead.affiliate === "object" ? lead.affiliate?.affiliate || null : lead.affiliate,
    affiliateId: typeof lead.affiliate === "object" ? lead.affiliate?.id || null : null,
    localizationId: lead.localization?.id || null,
    clientId: lead.client?.id || null,
    status: lead.status?.value || "",
    teamId: lead.team?.id || null,
    sentAt: formatDateAndTimeToBack(lead.sentAt),
  };
  delete sendingData.client;
  delete sendingData.team;

  if (!sendingData.referer) {
    delete sendingData.referer;
  }
  if (!lead.url) {
    delete sendingData.url;
  }

  return Api.post(LEADS_PATH, sendingData).then(getResponseData);
};

export const updateSomeLeads = (data: UpdateSomeLeadsActionsRequest & { ids: string[] }): Promise<ListRes<ILead>> => {
  return Api.post(`${LEADS_PATH}/bulk-update`, data).then(getResponseData);
};

export const changeIsValidLead = (isValid: boolean, id: string) => {
  return Api.post(`${LEADS_PATH}/update-short`, { id, isValid }).then(getResponseData);
};

export const changeIsReplaceLead = (isReplace: boolean, id: string) => {
  return Api.post(`${LEADS_PATH}/update-short`, { id, isReplace }).then(getResponseData);
};

export const deleteLead = (id: string) => {
  return Api.delete(`${LEADS_PATH}/${id}`);
};

export const deleteLeads = (ids: string[]) => {
  return Api.post(`${LEADS_PATH}/bulk-destroy`, { ids });
};

export const searchLeads = (props: string) => {
  return Api.post("/leads/search", { keyword: props });
};

export const changeLeadsStatus = (props: { ids: string[]; status: string }) => {
  const params = props;

  return Api.post("/leads/change-status", {
    ids: params.ids,
    status: params.status,
  });
};

export const createTestLead = (lead: TestLeadInitialValues): Promise<TestLeadResponse> => {
  const sendingData: TestLeadRequest = {
    ...lead,
    teamId: lead.team?.id || "",
    integrationId: lead.integration?.id || "",
  };

  delete sendingData.integration;
  delete sendingData.team;

  return ApiNonCamelize.post(LEADS_PATH + "/send-test", sendingData).then(getResponseData);
};

export const getTestLeadFields = (): Promise<ModelRes<ObjectWithKeys>> => {
  return Api.get(`${LEADS_PATH}/make-test`).then(getResponseData);
};

export const getLeadLogs = (id: string): Promise<ListRes<ILog>> => {
  return Api.get(`${LEADS_PATH}/${id}/logs`).then(getResponseData);
};

export const getLeadAdditions = (id: string): Promise<ModelRes<IAdditions>> => {
  return Api.get(`${LEADS_PATH}/${id}/additions`).then(getResponseData);
};

export const getLeadStatusesHistories = (id: string): Promise<ListRes<StatusHistory>> => {
  return Api.get(`${LEADS_PATH}/${id}/statuses-histories`).then(getResponseData);
};

export const getLeadProcessingHistories = (id: string): Promise<ListRes<StatusHistory>> => {
  return Api.get(`${LEADS_PATH}/${id}/processing-histories/processing`).then(getResponseData);
};

export const getLeadClientStatuses = (id: string): Promise<ListRes<StatusHistory>> => {
  return Api.get(`${LEADS_PATH}/${id}/processing-histories/client`).then(getResponseData);
};

export const getExportedFile = async (filter: IFilterLeadRequest, searchTerm: string) => {
  const token = getToken();
  const res = await fetch(`${baseURL}${LEADS_PATH}/export`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(decamelizeKeys({ filter, searchTerm, type: "query" }))
  });
  const blob = await res.blob();
  return handleDownload(blob, "lead-list");
};
