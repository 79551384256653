import { RequestList } from "../components/common/tables/types";
import { IStatus, IStatusChange, IStatusRequest } from "../models/IStatus";
import { Api, ListRes, ModelRes, filterEmpyValues, getResponseData } from "./utils";

const baseURL = "/v1/statuses";

export const getList = (params: RequestList<IStatusRequest>): Promise<ListRes<IStatus>> => {
  return Api.post(baseURL + "/list", filterEmpyValues(params)).then(getResponseData);
};

export const getGroupList = (): Promise<IStatus[]> => {
  return Api.get(baseURL + "/group").then(getResponseData);
};

export const changeItem = (data: IStatusChange) => {
  const sendingData: IStatusRequest = {
    ...data,
    system: data.system?.value || "",
    type: data.type?.value || null,
  };

  return Api.post(baseURL, sendingData).then(getResponseData);
};

export const getItem = (id: string): Promise<ModelRes<IStatus>> => {
  return Api.get(`${baseURL}/${id}`).then(getResponseData);
};

export const deleteItem = (id: string) => {
  return Api.delete(`${baseURL}/${id}`).then(getResponseData);
};
