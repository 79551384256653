import * as Yup from "yup";
import { IAllFields, TypesOfInput } from "../../models/fields/IField";
import { AsyncObject } from "../../models/fields/ISelect";
import { IAffiliate } from "../../models/IAffiliates";
import { IClient } from "../../models/IClients";
import { ILeadChange, isBooleanOptions, statusOptions, UpdateSomeLeadsActions } from '../../models/ILead';
import { ITeam } from "../../models/ITeam";
import { fieldsConstructor } from "../../utils/formats";
import { UserRole } from "../../models/IUsers";
import { ILocalization } from "../../models/ILocalizations";
import { superRolesWithPM } from "../../models/IAuth";

export const EditLeadValidation = Yup.object<
  Record<keyof Omit<ILeadChange, "client" | "sentAt"| "depositedAt">, Yup.AnySchema>
>({
  id: Yup.string().required(),
  ip: Yup.string(),
  country: Yup.string().required(),
  email: Yup.string().required(),
  landing: Yup.string().required().nullable(),
  source: Yup.string().nullable(),
  team: Yup.object().nullable(),
  name: Yup.string().required(),
  phone: Yup.string().required(),
  phoneCode: Yup.string().nullable(),
  phoneNumber: Yup.string().nullable(),
  status: Yup.object().required(),
  surname: Yup.string().required(),
  url: Yup.string().nullable(),
  referer: Yup.string().nullable(),
  clickId: Yup.string().nullable(),
  language: Yup.string(),
  isTest: Yup.boolean(),
  isValid: Yup.boolean(),
  isReplace: Yup.boolean(),
  sub1: Yup.string().nullable(),
  sub2: Yup.string().nullable(),
  sub3: Yup.string().nullable(),
  sub4: Yup.string().nullable(),
  sub5: Yup.string().nullable(),
  attempt: Yup.number().min(0).max(3),
  affiliate: Yup.object().nullable(),
  localization: Yup.object().nullable(),
});

interface Params {
  affiliateOptions: IAffiliate[];
  clientOptions: IClient[];
  teamOptions: ITeam[];
  localizationsOptions: ILocalization[];
  asyncLocalizations: AsyncObject;
  asyncTeams: AsyncObject;
  asyncAffiliates: AsyncObject;
  asyncClients: AsyncObject;
  role?: UserRole,
}

export const editLeadFields = ({
  asyncLocalizations,
  localizationsOptions,
  affiliateOptions,
  asyncAffiliates,
  asyncClients,
  clientOptions,
  teamOptions,
  asyncTeams,
  role = UserRole.ADMIN,
}: Params): IAllFields<ILeadChange>[] => [
  {
    name: "id",
    type: TypesOfInput.TEXT,
    disabled: true,
  },
  ...fieldsConstructor<ILeadChange>([
    "name",
    "surname",
    "phone",
    "email",
    "landing",
    "source",
    "country",
    "language",
    "referer",
    "url",
    "ip",
    "phoneCode",
    "phoneNumber",
  ]),
  {
    label: "Sent At",
    name: "sentAt",
    placeholder: "Sent At",
    showTime: true,
    type: TypesOfInput.DATEPICKER,
  },
  {
    async: asyncAffiliates,
    label: "Affiliate",
    name: "affiliate",
    options: affiliateOptions,
    placeholder: "Affiliate",
    type: TypesOfInput.SELECT,
    valueName: "id",
    labelName: "name",
    formatOptionLabel: (option: IAffiliate) => (
      <span>{option.name} - {option.affiliate}</span>
    )
  },
  {
    async: asyncLocalizations,
    label: "Localization",
    name: "localization",
    options: localizationsOptions,
    placeholder: "Localization",
    type: TypesOfInput.SELECT,
    valueName: "id",
    labelName: "name",
  },
  {
    async: asyncTeams,
    name: "team",
    options: teamOptions,
    type: TypesOfInput.SELECT,
    valueName: "id",
    labelName: "name",
    isHidden: !superRolesWithPM.includes(role)
  },
  {
    async: asyncClients,
    label: "Client",
    name: "client",
    options: clientOptions,
    placeholder: "Client",
    type: TypesOfInput.SELECT,
    valueName: "id",
    labelName: "name",
    isHidden: !superRolesWithPM.includes(role)
  },
  {
    label: "Status",
    name: "status",
    options: statusOptions,
    placeholder: "Status",
    type: TypesOfInput.SELECT,
  },
  {
    label: "Is test",
    name: "isTest",
    placeholder: "isTest",
    type: TypesOfInput.CHECKBOX,
  },
  {
    label: "Is Valid",
    name: "isValid",
    placeholder: "isValid",
    type: TypesOfInput.CHECKBOX,
    disabled: !superRolesWithPM.includes(role),
    labels: ["No", "Yes"],
  },
  {
    label: "Is Replace",
    name: "isReplace",
    placeholder: "isReplace",
    type: TypesOfInput.CHECKBOX,
    disabled: !superRolesWithPM.includes(role),
    labels: ["No", "Yes"],
  },
  {
    name: "attempt",
    type: TypesOfInput.TEXT,
    isHidden: !superRolesWithPM.includes(role)
  },
  ...fieldsConstructor<ILeadChange>([
    // "clickId",
    // "sub1",
    // "sub2",
    // "sub3",
    // "sub4",
    // "sub5",
  ]),
];

export const editMassLeadsFields = (): IAllFields<UpdateSomeLeadsActions>[] => [
  {
    label: "Language",
    name: "language",
    type: TypesOfInput.TEXT,
  },
  {
    label: "Country",
    name: "country",
    type: TypesOfInput.TEXT,
  },
  {
    label: "Status",
    name: "status",
    options: statusOptions,
    type: TypesOfInput.SELECT,
  },
  {
    label: "Is valid",
    name: "isValid",
    options: isBooleanOptions,
    type: TypesOfInput.SELECT,
  },
  {
    label: "Is replace",
    name: "isReplace",
    options: isBooleanOptions,
    type: TypesOfInput.SELECT,
  },
];
