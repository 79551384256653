import { Link } from "react-router-dom";
import { ILog, typeOptions } from "../../models/ILog";
import { LabelColors } from "../../types/global";
import { formatDateFullTime, getColumnNumberIndex } from "../../utils/formats";
import ColorLabel from "../common/items/ColorLabel";
import { ColumnDef, PaginationType } from "../common/tables/types";

type IColumn = ColumnDef<ILog>;

interface Options {
  pagination: PaginationType;
}

export const getStatusColor = (value: string): LabelColors => {
  const status = Number(value);
  if (200 <= status && status < 300) {
    return LabelColors.SUCCESS;
  } else if (300 <= status && status < 500) {
    return LabelColors.WARNING;
  } else if (status >= 500) {
    return LabelColors.FAILED;
  }
  return LabelColors.GRAY;
};

export default function getColumnDefs(options: Options): IColumn[] {
  const { pagination } = options;

  const numberIndex: IColumn = {
    Cell: ({ row: { index } }: { row: { index: number } }) => {
      return (
        <div>{getColumnNumberIndex(pagination, index)}</div>
      );
    },
    Header: "№",
    disableFilters: true,
    disableSortBy: true,
    width: "25px",
    id: "№",
  };

  const type: IColumn = {
    Cell: (cell) => (
      <div className="d-flex justify-between">
        {cell.value}
        <ColorLabel className="d-inline" colors={typeOptions} value={cell.value} />
      </div>
    ),
    Header: "Type",
    id: "type",
    accessor: "type",
    width: "50px",
  };

  const lead: IColumn = {
    Cell: (cell) => (
      <span>
        {cell.value ? (
          <Link className="font-semibold" to={`/leads/${cell.value.id}`}>
            {cell.value.name}
          </Link>
        ) : (
          "No"
        )}
      </span>
    ),
    Header: "Lead",
    id: "lead",
    accessor: "lead",
    width: "80px",
  };

  const integration: IColumn = {
    Cell: (cell) => (
      <span>
        {cell.value ? (
          <Link className="font-semibold" to={`/integrations/${cell.value.id}`}>
            {cell.value.name}
          </Link>
        ) : (
          "No"
        )}
      </span>
    ),
    Header: "Integration",
    id: "integration",
    accessor: "integration",
    width: "30px",
  };

  const status: IColumn = {
    Cell: (cell) => (
      <div className="d-flex justify-between">
        {cell.value}
        <ColorLabel
          className="d-inline"
          isSingleColor
          color={getStatusColor(cell.value)}
          value={cell.value}
        />
      </div>
    ),
    Header: "Status",
    id: "status",
    accessor: "status",
    width: "20px",
  };

  const id: IColumn = {
    Cell: (cell) => (
      <Link className="font-semibold" to={`/logs/${cell.value}`}>
        {cell.value}
      </Link>
    ),
    Header: "Id",
    id: "id",
    accessor: "id",
    width: "100px",
  };

  const createdAt: IColumn = {
    Cell: (cell) => <span>{formatDateFullTime(cell.value)}</span>,
    Header: "Created",
    id: "createdAt",
    accessor: "createdAt",
    width: "80px",
  };

  return [numberIndex, id, type, lead, integration, status, createdAt];
}
