import { Link } from "react-router-dom";
import { IUser } from "../../models/IUsers";
import { formatDateFullTime } from "../../utils/formats";
import { ColumnDef, PaginationType } from "../common/tables/types";

type IColumn = ColumnDef<IUser>;

interface Options {
  pagination: PaginationType;
}

export default function getColumnDefs(options: Options): IColumn[] {
  const { pagination } = options;

  const numberIndex: IColumn = {
    Cell: ({ row: { index } }: { row: { index: number } }) => {
      return <div>{index + (pagination.from || 1)}</div>;
    },
    Header: "№",
    id: "№",
    disableFilters: true,
    disableSortBy: true,
    width: "25px",
  };

  const name: IColumn = {
    Cell: (cell) => (
      <Link className="font-semibold" to={`/users/${cell.row.original.id}`}>
        {cell.value}
      </Link>
    ),
    Header: "Name",
    id: "name",
    accessor: "name",
  };

  const email: IColumn = {
    Header: "Email",
    accessor: "email",
    id: "email",
  };

  const roles: IColumn = {
    Header: "Role",
    accessor: "roles",
    id: "role",
  };

  const team: IColumn = {
    Cell: (cell) => <span>{cell.value ? cell.value.name : "No"}</span>,
    Header: "Team",
    accessor: "team",
    id: "team",
  };

  const createdAt: IColumn = {
    Cell: (cell) => <span>{formatDateFullTime(cell.value)}</span>,
    Header: "Created",
    accessor: "createdAt",
    id: "createdAt",
  };

  return [numberIndex, name, email, roles, team, createdAt];
}
