import { Link } from "react-router-dom";
import { IIntegrationModel } from "../../models/IIntegration";
import { LabelColors } from "../../types/global";
import { formatDateFullTime, getColumnNumberIndex } from "../../utils/formats";
import CheckboxField from "../common/form/CheckboxField";
import ColorLabel from "../common/items/ColorLabel";
import { ColumnDef, PaginationType } from "../common/tables/types";

type IColumn = ColumnDef<IIntegrationModel>;

interface Options {
  pagination: PaginationType;
}

export default function getColumnDefs(options: Options): IColumn[] {
  const { pagination } = options;

  const numberIndex: IColumn = {
    Cell: ({ row: { index } }: { row: { index: number } }) => {
      return (
        <div>{getColumnNumberIndex(pagination, index)}</div>
      );
    },
    Header: "№",
    id: "№",
    disableFilters: true,
    disableSortBy: true,
    width: "25px",
  };

  const name: IColumn = {
    Cell: (cell) => (
      <div>
        <Link className="font-semibold" to={`./${cell.row.original.id}`}>
          {cell.value}
        </Link>
        {cell.row.original.status === "active" && cell.row.original.type === "main" && (
          <ColorLabel color={LabelColors.PRIMARY} isSingleColor className="d-inline ml-3" />
        )}
      </div>
    ),
    Header: "Name",
    id: "name",
    accessor: "name",
  };

  const type: IColumn = {
    Header: "Type",
    id: "type",
    accessor: "type",
  };

  const isSheet: IColumn = {
    Cell: (cell) => <CheckboxField value={Boolean(cell.value)} onChange={() => {}} />,
    Header: "Is Sheet",
    id: "isSheet",
    accessor: "isSheet",
  };

  const status: IColumn = {
    Header: "Status",
    id: "status",
    accessor: "status",
  };

  const createdAt: IColumn = {
    Cell: (cell) => <span>{formatDateFullTime(cell.value)}</span>,
    Header: "Created",
    id: "createdAt",
    accessor: "createdAt",
  };

  return [numberIndex, name, isSheet, type, status, createdAt];
}
