import React, { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import FilterForm from "../../components/common/form/formik/FilterForm";
import PageTitle from "../../components/common/items/page-title";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { ILabelFilter, emptyFilters } from "../../models/ILabels";
import { fetchLabels } from "../../store/thunks/labels";
import { labelsSlice } from "../../store/reducers/labelsSlice";
import LabelsTable from "../../components/labels/LabelsTable";
import { Link } from "react-router-dom";
import { createLabelFields } from "../../components/fields/create-label";

const LabelList = () => {
  const dispatch = useAppDispatch();
  const { filter } = useAppSelector((state) => state.labelsReducer);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { setFilters } = labelsSlice.actions;

  useEffect(() => {
    dispatch(fetchLabels({}));
    return () => {dispatch(setFilters(emptyFilters))}
  }, []);

  const handleFilter = (values: ILabelFilter) => {
    dispatch(setFilters(values));
    dispatch(fetchLabels({}));
  };

  const fields = useMemo(() => createLabelFields(), []);

  return (
    <>
      <div className="container-fluid px-lg-4 px-xl-5">
        <PageTitle title="Labels" />
        <div className="row">
          <div className="d-flex mb-3">
            <Button variant="primary" onClick={() => setIsFilterOpen((prevState) => !prevState)}>
              {isFilterOpen ? "Close" : "Open"} Filters
            </Button>
            <Link className="btn btn-primary" to="/labels/new">
              Create label
            </Link>
          </div>
          {isFilterOpen && (
            <FilterForm
              className="mb-3"
              fields={fields}
              handleSubmit={handleFilter}
              initialValues={filter}
            />
          )}
          <section className="mb-5">
            <div className="card">
              <div className="card-body">
                <LabelsTable className="min-w-screen-lg" />
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default LabelList;
