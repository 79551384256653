import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchLogs, fetchOneLog } from "../thunks/logs";
import { PaginationQuery, PaginationType } from "../../components/common/tables/types";
import { FetchedStoreModel, PAGINATION_TAKE } from "../../types/global";
import { IFilterLogs, ILog, initialFilters } from "../../models/ILog";

export interface LogsState {
  currentLog: FetchedStoreModel<ILog | null>;
  filter: IFilterLogs;
  logs: FetchedStoreModel<ILog[]>;
  pagination: PaginationType;
  searchById: string;
}

const initialState: LogsState = {
  currentLog: {
    data: null,
    error: null,
    loading: true,
  },
  filter: initialFilters,
  logs: {
    data: [],
    error: null,
    loading: true,
  },
  pagination: {
    lastPage: null,
    page: 1,
    take: PAGINATION_TAKE,
    total: null,
  },
  searchById: "",
};

/* eslint-disable sort-keys */
export const logsSlice = createSlice({
  name: "logs",
  initialState,
  reducers: {
    setPagination: (state, action: PayloadAction<PaginationQuery>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    setFilters: (state, action: PayloadAction<IFilterLogs>) => {
      state.filter = action.payload;
      state.pagination.page = 1;
    },
    setSearchById: (state, action: PayloadAction<string>) => {
      state.searchById = action.payload;
      state.pagination.page = 1;
    },
  },
  extraReducers: (builder) => {
    // fetchLogs
    builder.addCase(fetchLogs.pending, (state) => {
      state.logs.loading = true;
      state.logs.error = null;
    });
    builder.addCase(fetchLogs.fulfilled, (state, action) => {
      state.logs.data = action.payload.list;
      state.logs.loading = false;
      state.pagination = { ...state.pagination, ...action.payload.pagination };
    });
    builder.addCase(fetchLogs.rejected, (state, action) => {
      state.logs.loading = false;
      state.logs.error = action.payload as FixMeLater;
    });

    // fetchOneLog
    builder.addCase(fetchOneLog.pending, (state) => {
      state.currentLog.loading = true;
      state.currentLog.error = null;
    });
    builder.addCase(fetchOneLog.fulfilled, (state, action) => {
      state.currentLog.data = action.payload.model;
      state.currentLog.loading = false;
    });
    builder.addCase(fetchOneLog.rejected, (state, action) => {
      state.currentLog.loading = false;
      state.currentLog.error = action.payload as FixMeLater;
    });
  },
});

export default logsSlice.reducer;
