import { createAsyncThunk } from "@reduxjs/toolkit";
import * as leadsApi from "../../api/leads-api";
import { ListRes, filterEmpyValues } from "../../api/utils";
import { IFilterLeadRequest, ILead, ILeadChange, UpdateSomeLeadsActions } from "../../models/ILead";
import { FetchOptionsParams } from "../../types/global";
import { camelToSnakeCase } from "../../utils/formats";
import { LeadsState } from "../reducers/leadsSlice";

export const formatRequestFilters = (leadsFilter: LeadsState['filter']): IFilterLeadRequest => {
  const filter: IFilterLeadRequest = {
    ...leadsFilter,
    affiliateId: leadsFilter.affiliate.map((option) => option.id),
    clientId: leadsFilter.client.map((option) => option.id),
    teamId: leadsFilter.team.map((option) => option.id),
    status: leadsFilter.status.map((option) => option.value),
    localizationId: leadsFilter.localizations.map((option) => option.id),
    processingStatus: leadsFilter.processingStatus?.system || "",
    isValid: leadsFilter.isValid?.value !== null ? leadsFilter.isValid.value : "",
    isReplace: leadsFilter.isReplace?.value !== null ? leadsFilter.isReplace.value : "",
    hasDeposit: leadsFilter.hasDeposit?.value !== null ? leadsFilter.hasDeposit.value : "",
  };

  if (leadsFilter.isTest?.value !== null) {
    filter["addition.isTest"] = leadsFilter.isTest.value;
  }
  // if (leadsFilter.url !== null) {
  //   filter["addition.url"] = leadsFilter.url;
  // }
  if (leadsFilter.clientType?.value !== null) {
    filter["client.type"] = leadsFilter.clientType.value;
  }
  // if (leadsFilter.sub1 !== null) {
  //   filter["addition.sub1"] = leadsFilter.sub1;
  // }
  // if (leadsFilter.sub2 !== null) {
  //   filter["addition.sub2"] = leadsFilter.sub2;
  // }
  // if (leadsFilter.sub3 !== null) {
  //   filter["addition.sub3"] = leadsFilter.sub3;
  // }
  // if (leadsFilter.sub4 !== null) {
  //   filter["addition.sub4"] = leadsFilter.sub4;
  // }
  // if (leadsFilter.sub5 !== null) {
  //   filter["addition.sub5"] = leadsFilter.sub5;
  // }

  // delete filter.url;
  delete filter.clientType;
  // delete filter.sub1;
  // delete filter.sub2;
  // delete filter.sub3;
  // delete filter.sub4;
  // delete filter.sub5;
  delete filter.isTest;
  delete filter.client;
  delete filter.team;
  delete filter.affiliate;
  delete filter.localizations;
  return filter;
}

export const fetchLeads = createAsyncThunk("leads/fetchLeads", (params: FetchOptionsParams, thunkAPI) => {
  const state: FixMeLater = thunkAPI.getState();
  const leadsState: LeadsState = state.leadsReducer;
  const filter = formatRequestFilters(leadsState.filter);
  const options = {
    filter,
    page: leadsState.pagination.page,
    searchTerm: params.searchTerm || leadsState.searchTerm,
    take: leadsState.pagination.take,
    orderBy: camelToSnakeCase(leadsState.orderBy),
    asc: leadsState.order === "ASC" ? 0 : 1
  };
  return leadsApi.getLeads(options);
});

export const fetchOneLead = createAsyncThunk("leads/fetchOneLead", (id: string) =>
  leadsApi.getLead(id),
);

export const editLead = createAsyncThunk(
  "leads/editLead",
  (values: ILeadChange & { id?: string }) => leadsApi.updateLead(values),
);

export const updateLeads = createAsyncThunk<
ListRes<ILead>, FixMeLater, {
  rejectValue: string
}>(
  "leads/updateLeads",
  (values: UpdateSomeLeadsActions, thunkAPI) => {
    const state: FixMeLater = thunkAPI.getState();
    const leadsState: LeadsState = state.leadsReducer;
    const data = filterEmpyValues({
      ...values,
      status: values.status?.value,
      isValid: values.isValid?.value,
      isReplace: values.isReplace?.value,
    });

    return leadsApi.updateSomeLeads({ ids: leadsState.selectedItems, ...data });
  },
);

export const deleteLead = createAsyncThunk("leads/deleteLead", (id: string) =>
  leadsApi.deleteLead(id),
);

export const changeIsValid = createAsyncThunk(
  "leads/changeIsValidLead",
  ({ isValid, id }: { isValid: boolean; id: string }) => leadsApi.changeIsValidLead(isValid, id),
);

export const changeIsReplace = createAsyncThunk(
  "leads/changeIsReplaceLead",
  ({ isReplace, id }: { isReplace: boolean; id: string }) => leadsApi.changeIsReplaceLead(isReplace, id),
);
