import React, { useEffect, useMemo, useState } from "react";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { Link, useParams } from "react-router-dom";
import Field from "../../components/common/form/formik/Field";
import PageTitle from "../../components/common/items/page-title";
import Loading from "../_layouts/Loading";
import { emptyValues, IPushChange, statusOptions, typeOptions } from "../../models/IPush";
import { changeItem, deleteItem, fetchItem } from "../../store/thunks/pushes";
import { createFields, validationSchema } from "../../components/fields/pushes";
import { fetchList } from "../../store/thunks/offer";
import { getValuesFromModel } from "../,,/../../api/utils";
import { offerTypeOptions } from "../../models/IOffer";
import useModal from "../../hooks/useModal";
import { FetchOptionsParams } from "../../types/global";

const ChangeItem = () => {
  const dispatch = useAppDispatch();
  const { currentItem } = useAppSelector((state) => state.pushesSlice);
  const { list } = useAppSelector((state) => state.offersReducer);
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState<IPushChange>(emptyValues);

  useEffect(() => {
    if (!id) return;
    dispatch(fetchItem(id));
  }, []);

  const handleDelete = () => {
    if (!currentItem.data) return;
    dispatch(deleteItem(currentItem.data.id));
  };

  const { ModalComp, handleShowModal } = useModal(handleDelete);

  useEffect(() => {
    const current = currentItem.data;
    if (!currentItem.loading && current) {
      const data = getValuesFromModel<IPushChange>(
        {
          ...current,
          startDate: current.startDate || "",
          isSend: Boolean(current.isSend),
          status: statusOptions.find((item) => item.value === current.status) || null,
          type: typeOptions.find((item) => item.value === current.type) || null,
          offerType: offerTypeOptions.find((item) => item.value === current.offerType) || null,
        },
        emptyValues,
      );
      setInitialValues(data);
    }
  }, [currentItem]);

  const fields = useMemo(
    () =>
      createFields({
        asyncOffer: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchList(params)),
          isLoading: list.loading,
        },
        offerOptions: list.data,
      }),
    [list],
  );

  const handleSubmit = (values: IPushChange) => {
    dispatch(changeItem({ ...values, id }));
  };

  if (currentItem.loading) {
    return <Loading />;
  }

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <div className="d-flex">
          <Link to="/pushes" className="w-auto">
            <Button className="w-auto mb-3" variant="primary">
              Back
            </Button>
          </Link>
          <Button className="w-auto mb-3" variant="danger" onClick={handleShowModal}>
            Delete
          </Button>
        </div>
        <PageTitle title="Edit push" />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {fields.map((field) => (
                      <Field key={field.name} {...field} />
                    ))}
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                  >
                    Save
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
      </div>
      {ModalComp}
    </div>
  );
};

export default ChangeItem;
