import {
    AsyncThunk, AsyncThunkPayloadCreator, createAsyncThunk,
    SerializedError
} from "@reduxjs/toolkit";
import { ResponseError } from "../../api/utils";
import { AppDispatch, RootState } from "../reducers";

type ThunkApiConfig = {
  state: RootState;
  rejectValue: ResponseError;
  dispatch: AppDispatch;
};

export function createAppThunk<Returned, ThunkArg = void>(
  type: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg>,
): AsyncThunk<Returned, ThunkArg, ThunkApiConfig> {
  return createAsyncThunk<Returned, ThunkArg, ThunkApiConfig>(type, payloadCreator);
}

export interface FulfilledAction<ThunkArg, PromiseResult> {
  type: string;
  payload: PromiseResult;
  meta: {
    requestId: string;
    arg: ThunkArg;
  };
}
export interface RejectedAction<ThunkArg> {
  type: string;
  payload: undefined;
  error: SerializedError | FixMeLater;
  meta: {
    requestId: string;
    arg: ThunkArg;
    aborted: boolean;
    condition: boolean;
  };
}
export interface RejectedWithValueAction<ThunkArg> {
  type: string;
  payload: ResponseError;
  error: { message: "Rejected" };
  meta: {
    requestId: string;
    arg: ThunkArg;
    aborted: boolean;
  };
}
