import { Form, Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getValuesFromModel } from "../../api/utils";
import Field from "../../components/common/form/formik/Field";
import PageTitle from "../../components/common/items/page-title";
import { editLeadFields, EditLeadValidation } from "../../components/fields/edit-lead";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import useModal from "../../hooks/useModal";
import { superRolesWithPM } from "../../models/IAuth";
import { ILeadChange, statusOptions } from "../../models/ILead";
import { UserRole } from "../../models/IUsers";
import { affiliatesSlice } from "../../store/reducers/affiliatesSlice";
import { fetchShortAffiliates } from "../../store/thunks/affiliates";
import { fetchShortClients } from "../../store/thunks/clients";
import { deleteLead, editLead, fetchOneLead } from "../../store/thunks/leads";
import { fetchLocalizations } from "../../store/thunks/localizations";
import { fetchList } from "../../store/thunks/teams";
import { FetchOptionsParams } from "../../types/global";
import { formatDateToState } from "../../utils/formats";
import Loading from "../_layouts/Loading";

const emptyLead: ILeadChange = {
  team: null,
  affiliate: null,
  client: null,
  id: "",
  ip: "",
  country: "",
  email: "",
  landing: "",
  source: "",
  name: "",
  phone: "",
  phoneCode: "",
  phoneNumber: "",
  sentAt: null,
  status: null,
  surname: "",
  url: "",
  referer: "",
  clickId: "",
  sub1: "",
  sub2: "",
  sub3: "",
  sub4: "",
  sub5: "",
  language: "",
  isTest: false,
  isValid: true,
  isReplace: false,
  attempt: 0,
  localization: null,
};

const EditLead = () => {
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const { auth } = useAppSelector((state) => state.authSlice);
  const { currentLead } = useAppSelector((state) => state.leadsReducer);
  const { localizations } = useAppSelector((state) => state.localizationsReducer);
  const { affiliatesShort } = useAppSelector((state) => state.affiliatesReducer);
  const { resetAffiliatesShort } = affiliatesSlice.actions;
  const { clientsShort } = useAppSelector((state) => state.clientsReducer);
  const { list } = useAppSelector((state) => state.teamsSlice);
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState<ILeadChange>(emptyLead);
  const [fields, setFields] = useState<FixMeLater[]>([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [editValues, setEditValues] = useState<ILeadChange | null>(null);

  useEffect(() => {
    if (!id) return;
    dispatch(fetchOneLead(id));
    () => dispatch(resetAffiliatesShort());
  }, []);

  const handleDelete = () => {
    if (!currentLead.data) return;
    dispatch(deleteLead(currentLead.data.id));
  };

  const { ModalComp, handleShowModal } = useModal(handleDelete);

  const role = useMemo(() => auth.user?.roles ?? UserRole.ADMIN, [auth]);

  const getFields = async () => {
    if (!currentLead?.data) return [];
    if (currentLead?.data.affiliate && (!affiliatesShort.data.length && !affiliatesShort.wasFetched) && currentLead.data.affiliateId) {
      await dispatch(fetchShortAffiliates({ searchTerm: currentLead.data.affiliate }));
    }
    if (!affiliatesShort.data) return [];
    setFields(editLeadFields({
      affiliateOptions: affiliatesShort.data,
      asyncAffiliates: {
        fetchCallback: (params: FetchOptionsParams) => dispatch(fetchShortAffiliates(params)),
        isLoading: affiliatesShort.loading,
      },
      clientOptions: clientsShort.data,
      asyncClients: {
        fetchCallback: (params: FetchOptionsParams) => dispatch(fetchShortClients(params)),
        isLoading: clientsShort.loading,
      },
      teamOptions: list.data,
      asyncTeams: {
        fetchCallback: (params: FetchOptionsParams) => dispatch(fetchList(params)),
        isLoading: list.loading,
      },
      asyncLocalizations: {
        fetchCallback: (params: FetchOptionsParams) => dispatch(fetchLocalizations(params)),
        isLoading: localizations.loading,
      },
      localizationsOptions: localizations.data,
      role,
    }))
  }

  useEffect(() => {
    getFields();
  }, [affiliatesShort, clientsShort, list, localizations, currentLead.data]);


  useEffect(() => {
    if (!currentLead.loading && currentLead.data) {
      const data = getValuesFromModel<ILeadChange>(
        {
          ...currentLead.data,
          affiliate: affiliatesShort.data.find(item => item.id === currentLead.data?.affiliateId) ?? null,
          status: statusOptions.find((option) => option.value === currentLead.data?.status) || null,
          isTest: Boolean(currentLead.data.isTest),
          isValid: Boolean(currentLead.data.isValid),
          isReplace: Boolean(currentLead.data.isReplace),
          sentAt: formatDateToState(currentLead.data.sentAt)
        },
        emptyLead,
      );
      setInitialValues(data);
    }
  }, [currentLead, affiliatesShort]);

  const handleSubmit = (values: ILeadChange) => {
    if (!currentLead.data?.id) {
      toast.error("Missing user id!");
      return;
    }
    if (
      values.isReplace !== Boolean(currentLead.data.isReplace)
      || values.isValid !== Boolean(currentLead.data.isValid)
    ) {
      setIsShowModal(true);
      setEditValues(values)
    } else {
      const sendingData = { ...values, id: currentLead.data.id };
      if (!values.affiliate && currentLead.data.affiliate) {
        sendingData.affiliate = currentLead.data.affiliate;
      }
      dispatch(editLead(sendingData));
    }
  };

  const handleChangeLead = () => {
    if (!currentLead.data?.id) {
      toast.error("Missing user id!");
      return;
    }
    if (!editValues) return;
    dispatch(editLead({ ...editValues, id: currentLead.data.id }));
  }

  if (currentLead.loading || !fields.length) {
    return <Loading />;
  }

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <div className="d-flex">
          <Link to={`/leads${search}`} className="w-auto">
            <Button className="w-auto mb-3" variant="primary">
              Back
            </Button>
          </Link>
          {superRolesWithPM.includes(role) && (
            <Button className="w-auto mb-3" variant="danger" onClick={handleShowModal}>
              Delete
            </Button>
          )}
        </div>
        <PageTitle title={`${currentLead.data?.name} ${currentLead.data?.surname}`} />
        <section className="mb-5">
          <div className="d-flex mb-3">
            {superRolesWithPM.includes(role) && (
              <Link className="btn btn-primary" to={`/leads/${id}/logs${search}`}>
                Logs {currentLead.data?.logsCount}
              </Link>
            )}
            <Link className="btn btn-primary" to={`/leads/${id}/comments${search}`}>
              Comments{" "}
              {currentLead.data &&
                currentLead.data.commentsCount &&
                currentLead.data.processingStatusCount &&
                currentLead.data.commentsCount + currentLead.data.processingStatusCount}
            </Link>
          </div>
          <div className="card">
            <div className="card-body">
              <p className="card-title">Edit Mode</p>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={EditLeadValidation}
                onSubmit={handleSubmit}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {fields.map((field) => (
                      <Field key={field.name} {...field} />
                    ))}
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                  >
                    Save
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
      </div>
      {ModalComp}
      <Modal centered show={isShowModal} onHide={() => setIsShowModal(false)}>
        <Modal.Header>&quot;is replace&quot;, &quot;is valid&quot; fields has been changed, are you sure you want to save the changes?</Modal.Header>
        <Modal.Body>
          <Button variant="danger" onClick={handleChangeLead}>
            Yes
          </Button>
          <Button variant="secondary" onClick={() => setIsShowModal(false)}>
            No
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditLead;
