import { IField, TypesOfInput } from "../../models/fields/IField";
import { ITagFilters } from "../../models/ITag";

const filterFields = (): IField<ITagFilters>[] => [
  {
    label: "Slug",
    name: "slug",
    placeholder: "Slug",
    type: TypesOfInput.TEXT,
  },
];

export default filterFields;
