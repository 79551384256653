import React, { useEffect, useState } from "react";
import PageTitle from "../../components/common/items/page-title";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { affiliatesSlice } from "../../store/reducers/affiliatesSlice";
import { Button } from "react-bootstrap";
import FilterForm from "../../components/common/form/formik/FilterForm";
import { fetchAffiliates } from "../../store/thunks/affiliates";
import { IFilterAffiliate, emptyFilters } from "../../models/IAffiliates";
import { affiliateFilters } from "../../components/fields/filters-affiliate";
import AffiliatesTable from "../../components/affiliates/AffiliatesTable";
import { Link } from "react-router-dom";
import { fetchList } from "../../store/thunks/users";
import { FetchOptionsParams } from "../../types/global";

const Affiliates = () => {
  const dispatch = useAppDispatch();
  const { list } = useAppSelector((state) => state.usersReducer);
  const { filter } = useAppSelector((state) => state.affiliatesReducer);
  const { setFilters } = affiliatesSlice.actions;

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchAffiliates());
    return () => {dispatch(setFilters(emptyFilters))}
  }, []);

  const handleFilter = (values: IFilterAffiliate) => {
    dispatch(setFilters(values));
    dispatch(fetchAffiliates());
  };

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <PageTitle title={"Buyers"} />
      <div className="row">
        <div className="d-flex mb-3">
          <Button variant="primary" onClick={() => setIsFilterOpen((prevState) => !prevState)}>
            {isFilterOpen ? "Close" : "Open"} Filters
          </Button>
          <Link className="btn btn-primary" to="/affiliates/new">
            Create Affiliate
          </Link>
        </div>
        {isFilterOpen && (
          <FilterForm
            className="mb-3"
            fields={affiliateFilters({
              asyncUsers: {
                fetchCallback: (params: FetchOptionsParams) => dispatch(fetchList(params)),
                isLoading: list.loading,
              },
              userOptions: list.data,
            })}
            handleSubmit={handleFilter}
            initialValues={filter}
          />
        )}
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <AffiliatesTable className="min-w-screen-lg" />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Affiliates;
