import { ObjectWithKeys } from "../types/global";

export type ITag = ITagChange & {
  createdAt: string;
  isSelected?: boolean;
  id: string;
};

export type ITagChange = {
  slug: string;
  name: ObjectWithKeys;
};

export type ITagFilters = {
  slug: string;
};

export const emptyValues: ITagChange = {
  slug: "",
  name: {},
};

export const emptyFilters: ITagFilters = {
  slug: "",
};
