import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { Button, FormControl } from "react-bootstrap";
import FilterForm from "../../components/common/form/formik/FilterForm";
import PageTitle from "../../components/common/items/page-title";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { fetchLogs } from "../../store/thunks/logs";
import { logsSlice } from "../../store/reducers/logsSlice";
import { IFilterLogs, initialFilters } from "../../models/ILog";
import { logsFilters } from "../../components/fields/filters-logs";
import { fetchIntegrations } from "../../store/thunks/integrations";
import LogsTable from "../../components/logs/LogsTable";
import useDebounce from "../../hooks/useDebounce";
import { TypesOfInput } from "../../models/fields/IField";
import { FetchOptionsParams } from "../../types/global";

const Logs = () => {
  const dispatch = useAppDispatch();
  const { filter, searchById } = useAppSelector((state) => state.logsReducer);
  const { integrations } = useAppSelector((state) => state.integrationsReducer);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [search, setSearch] = useState(searchById);
  const debouncedSearch = useDebounce<string>(search, 500);

  const { setFilters, setSearchById } = logsSlice.actions;

  useEffect(() => () => {dispatch(setFilters(initialFilters))}, []);

  useEffect(() => {
    dispatch(setSearchById(search));
    dispatch(fetchLogs());
  }, [debouncedSearch]);

  const handleFilter = (values: IFilterLogs) => {
    dispatch(setFilters(values));
    dispatch(fetchLogs());
  };

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const fields = useMemo(
    () =>
      logsFilters({
        asyncIntegrations: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchIntegrations(params)),
          isLoading: integrations.loading,
        },
        integrationOptions: integrations.data,
      }),
    [integrations],
  );

  return (
    <>
      <div className="container-fluid px-lg-4 px-xl-5">
        <PageTitle title="Logs" />
        <div className="row">
          <div className="d-flex mb-3 justify-between">
            <div>
              <Button variant="primary" onClick={() => setIsFilterOpen((prevState) => !prevState)}>
                {isFilterOpen ? "Close" : "Open"} Filters
              </Button>
            </div>
            <div className="col-3 align-items-center">
              <span className="mr-3">Search by id:</span>
              <FormControl type={TypesOfInput.TEXT} onChange={handleChangeSearch} value={search} />
            </div>
          </div>
          {isFilterOpen && (
            <FilterForm
              className="mb-3"
              fields={fields}
              handleSubmit={handleFilter}
              initialValues={filter}
            />
          )}
          <section className="mb-5">
            <div className="card">
              <div className="card-body">
                <LogsTable className="min-w-screen-lg" />
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Logs;
