import cn from "classnames";
import { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { Column, Row, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { SettingsTableKey } from "../../../models/IUserSettings";
import Loading from "../../../pages/_layouts/Loading";
import { fetchTableSettings } from "../../../store/thunks/user-settings";
import { SortOrderByType, SortOrderType } from "../../../types/global";
import IndeterminateCheckbox from "../form/IndeterminateCheckbox";
import Icon from "../items/Icon";
import HeadCell from "./HeadCell";
import Pagination from "./Pagination";
import TableSettings from "./TableSettings";
import { PaginationType, SortableColumnInstance } from "./types";

interface Props<T extends UnknownRecord> {
  pageKey: SettingsTableKey;
  className?: string;
  columns: Column<T>[];
  containerClass?: string;
  fixed?: boolean;
  rowSelect?: boolean;
  isLoading?: boolean;
  max?: boolean;
  changePage?: (page: number) => void;
  changePageSize?: (size: number) => void;
  items: T[];
  pagination?: PaginationType;
  handleSelect?: (ids: unknown[]) => void;
  exportApi?: () => Promise<unknown>;
  renderRowBg?: (row: Row<T>) => string;
  handleSort?: (colName: keyof T, order: SortOrderType) => void;
  sortByColumn?: SortOrderByType<T>;
  columnSortFlags?: (keyof T)[];
}

const Table = <T extends UnknownRecord>(props: Props<T>) => {
  const {
    changePage,
    changePageSize,
    columnSortFlags,
    columns,
    containerClass,
    items,
    pagination,
    rowSelect,
    isLoading,
    handleSelect,
    exportApi,
    renderRowBg,
    pageKey,
    handleSort,
    sortByColumn,
  } = props;
  const dispatch = useAppDispatch();
  const { tableSettings } = useAppSelector((state) => state.userSettingsSlice);
  const [isOpenSettings, setIsOpenSettings] = useState(false);
  const [isTableExporting, setIsTableExporting] = useState(false);
  const [hiddenColumnsName, setHiddenColumnsName] = useState<string[]>([]);

  useEffect(() => {
    dispatch(fetchTableSettings(pageKey));
  }, [pageKey]);

  useEffect(() => {
    if (tableSettings && tableSettings.data) {
      const columnsName = [];
      for (const item of Object.entries(tableSettings.data.value || {})) {
        if (!item[1]) columnsName.push(item[0]);
      }
      setHiddenColumnsName(columnsName);
      setHiddenColumns(columnsName);
    }
  }, [tableSettings.data]);

  const table = useTable(
    {
      columns,
      data: items,
      initialState: {
        pageSize: pagination?.take || 100,
        hiddenColumns: hiddenColumnsName,
      },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (rowSelect) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            width: 20,
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <IndeterminateCheckbox {...(getToggleAllRowsSelectedProps() as FixMeLater)} />
            ),
            Cell: ({ row }: FixMeLater) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
          },
          ...columns,
        ]);
      }
    },
  );
  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    footerGroups,
    page,
    prepareRow,
    setPageSize,
    setHiddenColumns,
    selectedFlatRows,
  } = table;

  useEffect(() => {
    if (rowSelect && handleSelect) {
      handleSelect(selectedFlatRows.map((item) => item.original.id));
    }
  }, [selectedFlatRows]);

  const className = cn("Table", props.className, {
    "Table--fixed": props.fixed,
    "Table--w-max": props.max,
    "Table--sortable": columnSortFlags,
  });

  const handleChangePageSize = (page: number) => {
    setPageSize(page);
    if (!changePageSize) return;
    changePageSize(page);
  };

  const columnsName: string[] = useMemo(() => {
    const names: string[] = [];
    columns.forEach((item) => item.id && names.push(item.id));
    return names;
  }, []);

  const handleExport = () => {
    if (!exportApi) return;
    setIsTableExporting(true);
    exportApi()
      .then(() => setIsTableExporting(false))
      .catch(() => setIsTableExporting(false));
  };

  // const displayedColumns = useMemo(() => (
  //   columnsName.filter(item => hiddenColumnsName.indexOf(item) < 0)
  // ), [columnsName, hiddenColumnsName]);

  if (tableSettings.loading && !tableSettings.error || isLoading) return <Loading />;

  return (
    <>
      <div className={containerClass}>
        <table className={className} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <HeadCell
                    column={column}
                    key={column.id}
                    sortableKey={columnSortFlags?.find((item) => item === column.id)}
                    handleSort={handleSort}
                    sortByColumn={sortByColumn}
                  />
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className={renderRowBg ? renderRowBg(row) : ""}>
                  {row.cells.map((cell) => {
                    const { alignRight, dataCellClass } = cell.column as SortableColumnInstance<T>;
                    const className = cn("Table-cell", dataCellClass, {
                      "text-right": alignRight,
                    });
                    return (
                      <td className={className} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {footerGroups.map((footerGroup) => (
              <tr {...footerGroup.getFooterGroupProps()}>
                {footerGroup.headers.map((column) => (
                  <td className="Table-cell" {...column.getFooterProps()}>{column.render("Footer")}</td>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
      {pagination && (
        <div className="dataTable-bottom flex flex-wrap w-full justify-between items-center mt-3">
          <Pagination
            pagination={pagination}
            changePage={changePage}
            changePageSize={handleChangePageSize}
          />
          <div>
            <Button variant="primary" onClick={() => setIsOpenSettings(true)}>
              <Icon name="settings-2" class="svg-icon-md" />
            </Button>
            {exportApi && (
              <Button variant="primary" onClick={handleExport} disabled={isTableExporting}>
                {isTableExporting ? (
                  <Icon name="refresh_file" class="svg-icon-md" />
                ) : (
                  <Icon name="download_file" class="svg-icon-md" />
                )}
              </Button>
            )}
          </div>
        </div>
      )}
      {!tableSettings.error && (
        <TableSettings<T>
          pageKey={pageKey}
          show={isOpenSettings}
          onClose={() => setIsOpenSettings(false)}
          columnsName={columnsName}
          hiddenColumnsName={hiddenColumnsName}
        />
      )}
    </>
  );
};

export default Table;
