import { RequestList } from "../components/common/tables/types";
import {
  IReview,
  IReviewChange,
  IReviewChangeRequest,
  IReviewFiltersRequest,
} from "../models/IReview";
import { Api, formApi, filterEmpyValues, ListRes, ModelRes } from "./utils";
import { getResponseData } from "./utils";

const baseURL = "/v1/reviews";

export const getList = (params: RequestList<IReviewFiltersRequest>): Promise<ListRes<IReview>> => (
  Api.post(baseURL + "/list", filterEmpyValues(params)).then(getResponseData)
);

export const changeItem = (data: IReviewChange): Promise<ModelRes<IReview>> => {
  const sendingData: IReviewChangeRequest = {
    ...data,
    rating: data.rating?.value || "",
  };
  if (typeof sendingData.photo === "string") delete sendingData.photo;
  return formApi.post(baseURL, sendingData).then(getResponseData);
};

export const getItem = (id: string): Promise<ModelRes<IReview>> => {
  return Api.get(`${baseURL}/${id}`).then(getResponseData);
};

export const deleteItem = (id: string) => {
  return Api.delete(`${baseURL}/${id}`);
};
