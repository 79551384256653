import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { fetchItem } from "../../store/thunks/applications";
import Loading from "../../pages/_layouts/Loading";
import ApplicationsTable from "./ApplicationsTable";

interface Props {
  isOpenModal: boolean,
  id: string,
  setIsOpenModal: (val: boolean) => void,
}

const ApplicationItem = ({ isOpenModal, setIsOpenModal, id }: Props) => {
  const dispatch = useAppDispatch();
  const { currentItem } = useAppSelector((state) => state.applicationsSlice);

  useEffect(() => {
    if (!id || !isOpenModal) return;
    dispatch(fetchItem(id));
  }, [id, isOpenModal]);

  return (
    <Modal show={isOpenModal} onHide={() => setIsOpenModal(false)} size="lg" centered fullscreen>
      <Modal.Header closeButton />
      <Modal.Body>
        {currentItem.loading ? <Loading /> : (
          <ApplicationsTable isSmall data={currentItem.data as FixMeLater} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ApplicationItem;
