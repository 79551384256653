import React, { useMemo } from "react";
import useGetWindiwSize from "../../hooks/useGetWindowSize";
import { IDepositAmounts } from "../../models/IDashboard";
import { SettingsTableKey } from "../../models/IUserSettings";
import Table from "../common/tables/Table";
import getColumnDefs from '../../pages/statistics/reports/DepositAmountsColumnDefs';

interface Props {
  list: IDepositAmounts[];
}

const DepositAmounts = ({ list }: Props) => {
  const { atLeastMd } = useGetWindiwSize();
  const columns = useMemo(() => getColumnDefs(), [])

  return (
    <div className="card mb-4">
      <div className="card-header py-3">
        <p className="card-heading">Deposit amounts</p>
      </div>
      <div className="card-body">
        <Table<IDepositAmounts>
          pageKey={SettingsTableKey.DEPOSIT_AMOUNTS}
          className="table"
          columns={columns}
          containerClass="overflow-auto"
          fixed
          items={list}
        />
      </div>
    </div>
  );
}

export default DepositAmounts;
