import { PaginationQuery } from "../components/common/tables/types";
import { ShortModel } from "../api/utils";
import { Modify } from "../types/global";
import { TypeOptions } from "../components/common/items/ColorLabel";

export type TelegramNoticePermissions =
  | "notify_lead_balance"
  | "notify_client_deposit"
  | "notify_expose_replacements"
  | "notify_approved_and_rejected"
  | "notify_successful_payment";

export type TelegramCommandPermissions =
  | "command_start_traffic"
  | "command_stop_traffic"
  | "command_balance_replenishment"
  | "command_lead_balance"
  | "command_dollars_balance"
  | "command_payment";

export type ITelegramChatUpdate = {
  name: string;
  client: ShortModel | null;
  permissions: TypeOptions<TelegramNoticePermissions | TelegramCommandPermissions>[];
};

export type ITelegramChat = ITelegramChatUpdate & {
  id: string;
  isSelected?: boolean;
  createdAt: string;
};

export type ITelegramChatRequest = Modify<
  Partial<ITelegramChatUpdate>,
  {
    clientId: string;
    permissions: string[];
  }
>;

export const initialTelegramChatFilters: ITelegramChatUpdate = {
  client: null,
  name: "",
  permissions: [],
};
export interface RequestTelegramChat extends PaginationQuery {
  filter: ITelegramChatRequest;
}

export const permissionOptions: TypeOptions<
  TelegramNoticePermissions | TelegramCommandPermissions
>[] = [
  {
    label: "notify_lead_balance",
    value: "notify_lead_balance",
  },
  {
    label: "notify_client_deposit",
    value: "notify_client_deposit",
  },
  {
    label: "notify_expose_replacements",
    value: "notify_expose_replacements",
  },
  {
    label: "notify_approved_and_rejected",
    value: "notify_approved_and_rejected",
  },
  {
    label: "notify_successful_payment",
    value: "notify_successful_payment",
  },
  {
    label: "command_start_traffic",
    value: "command_start_traffic",
  },
  {
    label: "command_stop_traffic",
    value: "command_stop_traffic",
  },
  {
    label: "command_balance_replenishment",
    value: "command_balance_replenishment",
  },
  {
    label: "command_lead_balance",
    value: "command_lead_balance",
  },
  {
    label: "command_dollars_balance",
    value: "command_dollars_balance",
  },
  {
    label: "command_payment",
    value: "command_payment",
  },
];

export const emptyValues: ITelegramChatUpdate = {
  client: null,
  name: "",
  permissions: [],
};
