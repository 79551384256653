import {
  IClient,
  IClientChange,
  IClientChangeRequest,
  IClientCloneInfo,
  IClientCloneRequest,
  IClientCreate,
  RequestClient,
  UpdateSomeClients,
  UpdateSomeClientsActionsRequest,
} from "../models/IClients";
import { Api, filterEmpyValues, ListRes, ModelRes } from "./utils";
import { getResponseData } from "./utils";
import { RequestOptions } from "../types/global";
import { IAccessLandingCreateRequest } from "../models/IAccessLandings";

const PATH = "/v1/clients";

export const getClients = (params: RequestClient): Promise<ListRes<IClient>> => (
  Api.post(
    `${PATH}/list`,
    filterEmpyValues(params),
  ).then(getResponseData)
);

export const getShortClients = (params: RequestOptions): Promise<ListRes<IClient>> => {
  return Api.post(`${PATH}/list`, params).then(getResponseData);
};

export const getClient = (id: string): Promise<ModelRes<IClient>> => {
  return Api.get(`${PATH}/${id}`).then(getResponseData);
};

export const changeClient = (data: IClientChange | IClientCreate) => {
  const sendingData: IClientChangeRequest = {
    ...data,
    labelId: data.label?.id || null,
    type: data.type?.value || null,
    landings: data.landings.map((landing) => {
      const newLanding: IAccessLandingCreateRequest = {
        ...landing,
        affiliate_id: landing.affiliate?.id || "",
        type: landing.type?.value || "",
      };
      delete newLanding.affiliate;
      return newLanding;
    }),
    localizations: (data.localizations as {
      value: string,
      label: string,
    }[]).map((localization) => (localization).value) || [],
    teams: data.teams?.map((team) => team.id) || [],
  };

  if (data.type?.value !== "crg") delete sendingData.percent;

  delete sendingData.label;
  delete sendingData.credit;
  delete sendingData.balance;

  return Api.post(PATH, sendingData).then(getResponseData);
};

export const deleteClient = (id: string) => {
  return Api.delete(`${PATH}/${id}`);
};

export const changeStatusClient = (status: boolean, id: string) => {
  return Api.post(`${PATH}/update-short`, { id, status }).then(getResponseData);
};

export const changeAcceptHoldClient = (acceptHold: boolean, id: string) => {
  return Api.post(`${PATH}/update-short`, { id, acceptHold }).then(getResponseData);
};

export const updateSomeClients = (data: UpdateSomeClients): Promise<ListRes<IClient>> => {
  const sendingData: UpdateSomeClientsActionsRequest = {
    ...data,
    status: data.status?.value || "",
  };

  return Api.post(`${PATH}/bulk-update`, sendingData).then(getResponseData);
};

export const cloneClient = (data: IClientCloneRequest, id: string) => {
  return Api.post(`${PATH}/${id}/clone`, data).then(getResponseData);
};

export const getCloneClient = (id: string): Promise<ModelRes<IClientCloneInfo>> => {
  return Api.get(`${PATH}/${id}/clone-info`).then(getResponseData);
};
