import * as Yup from "yup";
import { IDictionaryChange, typeOptions } from "../../models/IDictionaryWords";
import { IAllFields, TypesOfInput } from "../../models/fields/IField";

export const createDictionaryWordValidation = Yup.object({
  title: Yup.string().required("Required"),
  type: Yup.object().typeError("Required"),
});

export const createDictionaryWordFields: IAllFields<IDictionaryChange>[] = [
  {
    label: "Title",
    name: "title",
    placeholder: "Title",
    type: TypesOfInput.TEXT,
  },
  {
    label: "Type",
    name: "type",
    options: typeOptions,
    placeholder: "Type",
    type: TypesOfInput.SELECT,
  },
];
