import { Link } from "react-router-dom";
import { ILabel } from "../../models/ILabels";
import { formatDateFullTime } from "../../utils/formats";
import ColorLabel from "../common/items/ColorLabel";
import { ColumnDef, PaginationType } from "../common/tables/types";

type IColumn = ColumnDef<ILabel>;

interface Options {
  pagination: PaginationType;
}

export default function getColumnDefs(options: Options): IColumn[] {
  const { pagination } = options;

  const numberIndex: IColumn = {
    Cell: ({ row: { index } }: { row: { index: number } }) => {
      return <div>{index + (pagination.from || 1)}</div>;
    },
    Header: "№",
    id: "№",
    disableFilters: true,
    disableSortBy: true,
    width: "25px",
  };

  const name: IColumn = {
    Cell: (cell) => (
      <Link className="font-semibold" to={`/labels/${cell.row.original.id}`}>
        {cell.value}
      </Link>
    ),
    Header: "Name",
    id: "name",
    accessor: "name",
    width: "30px",
  };

  const color: IColumn = {
    Cell: (cell) => <ColorLabel color={cell.value} isSingleColor className="d-inline" />,
    Header: "Color",
    id: "color",
    accessor: "color",
    width: "30px",
  };

  const createdAt: IColumn = {
    Cell: (cell) => <span>{formatDateFullTime(cell.value)}</span>,
    Header: "Created",
    id: "createdAt",
    accessor: "createdAt",
    width: "80px",
  };

  return [numberIndex, name, color, createdAt];
}
