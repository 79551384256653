import { useState, useEffect } from "react";

export type WindowInfo = {
  atLeastLg: boolean;
  atLeastMd: boolean;
  atLeastSm: boolean;
  atLeastXs: boolean;
}

const getWindowInfo = (): WindowInfo => ({
  atLeastLg: window.innerWidth >= 992,
  atLeastMd: window.innerWidth >= 768,
  atLeastSm: window.innerWidth >= 576,
  atLeastXs: window.innerWidth < 576,
});

const useGetWindiwSize = () => {
  const [windowInfo, setWindowInfo] = useState<WindowInfo>(getWindowInfo());

  useEffect(() => {
    const handleResize = () => setWindowInfo(getWindowInfo());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [setWindowInfo]);

  return windowInfo;
};

export default useGetWindiwSize;
