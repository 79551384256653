import * as Yup from "yup";
import { IField, TypesOfInput } from "../../models/fields/IField";
import { ILabelCreate } from "../../models/ILabels";
import { YupValidationFields } from "../../types/global";

export const createLabelValidation = Yup.object<YupValidationFields<ILabelCreate>>({
  name: Yup.string().required("Required"),
  color: Yup.string().required("Required"),
});

export const createLabelFields = (): IField<ILabelCreate>[] => [
  {
    label: "Name",
    name: "name",
    placeholder: "Name",
    type: TypesOfInput.TEXT,
  },
  {
    label: "Color",
    name: "color",
    placeholder: "Color",
    type: TypesOfInput.COLOR_PICKER,
  },
];
