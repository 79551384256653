import { PaginationQuery } from "../components/common/tables/types";
import { Modify } from "../types/global";
import { GenericOption, OptionModel } from "./fields/ISelect";
import { IToken } from "./IIntegrationToken";
import { ILead } from "./ILead";

export type HttpMethod = "get" | "post" | "put";
export type IntegrationType = "main" | "addition" | "postback";
export type AnswerType = "code" | "string" | "object";
export type RedirectType = "string" | "object";
export type Status = "active" | "inactive" | "only_cabinet";
export type PeriodType = "-1 month" | "-2 month" | "-3 month";
export type LeadFields = keyof ILead | "fullName";
export type HeaderValues = "application/json" | "application/x-www-form-urlencoded";

export type Answer = {
  key: string;
  type: OptionModel<GenericOption<AnswerType>>;
  value: string;
};
export type Redirect = {
  type: OptionModel<GenericOption<RedirectType>>;
  value: string;
};

export type PostbackDefault = {
  key: string;
};

export type PostbackChange = PostbackDefault | string;

export type HeaderElement = {
  id?: string;
  key: string;
  value: OptionModel<GenericOption<HeaderValues>>;
};

export type BodyElement = {
  id?: string;
  key: string;
  value: OptionModel<GenericOption<LeadFields>>;
};

export type ParameterElement = {
  key: string;
  landing: string;
  value: string;
};

export type IIntegrationModel = Modify<
  IIntegrationChangeRequest,
  {
    id: string;
    isSelected?: boolean;
    isSheet: boolean;
    withoutPlus: boolean;
    description: string | null;
    wrapArray: number;
    token: IToken | null;
    createdAt: string;
    redirect: Modify<
      Redirect,
      {
        type: RedirectType | "";
      }
    > | null;
    postback: Postback | PostbackDefault;
  }
>;

export type Postback = string;

export type IIntegrationChange = {
  id: string,
  answer?: Answer | null;
  body: BodyElement[];
  headers: HeaderElement[];
  method: OptionModel<GenericOption<HttpMethod>>;
  isSheet?: boolean;
  name: string;
  parameters?: ParameterElement[];
  postback: PostbackChange;
  redirect?: Redirect;
  status: OptionModel<GenericOption<Status>>;
  type: OptionModel<GenericOption<IntegrationType>>;
  url: string;
  wrapArray?: boolean;
  withoutPlus: boolean;
};

export type IIntegrationChangeRequest = Modify<
  IIntegrationChange,
  {
    id?: string,
    status: Status | "";
    type: IntegrationType | "";
    method: HttpMethod | "";
    answer: Modify<
      Answer,
      {
        type: AnswerType | "";
      }
    > | null;
    redirect: Modify<
      Redirect,
      {
        type: RedirectType | "";
      }
    > | null;
    postback: PostbackChange;
    body: Modify<
      BodyElement,
      {
        value: LeadFields | string | null;
        type: "body";
      }
    >[];
    headers: Modify<
      HeaderElement,
      {
        type: "header";
        value: HeaderValues | string | null;
      }
    >[];
  }
>;

export type IIntegrationForm = IntegrationPostback & {
  "answer.key": string;
  "answer.type": AnswerType | "";
  "answer.value": string;
  "body.key": string;
  "body.value": LeadFields;
  "headers.key": string;
  "headers.value": string;
  "parameters.key": string;
  "parameters.landing": string;
  "parameters.value": string;
  "redirect.type": RedirectType;
  "redirect.value": string;
  method: HttpMethod;
  isSheet: boolean;
  name: string;
  postback: string | IntegrationPostback;
  status: Status;
  type: IntegrationType;
  url: string;
  wrapArray: boolean;
};

type IntegrationPostback = {
  "postback.pageKey": string;
  "postback.dateFrom": string;
  "postback.dateTo": string;
  "postback.format": string;
  "postback.period": PeriodType;
  "postback.wrapper": string;
  "postback.searchBy": LeadFields;
  "postback.identify": string;
  "postback.status": string;
};

export const httpMethodOptions: GenericOption<HttpMethod>[] = [
  {
    label: "Get",
    value: "get",
  },
  {
    label: "Post",
    value: "post",
  },
  {
    label: "Put",
    value: "put",
  },
];

export const typeOptions: GenericOption<IntegrationType>[] = [
  {
    label: "Main",
    value: "main",
  },
  {
    label: "Addition",
    value: "addition",
  },
  {
    label: "Postback",
    value: "postback",
  },
];

export const redirectOptions: GenericOption<RedirectType>[] = [
  {
    label: "Object",
    value: "object",
  },
  {
    label: "String",
    value: "string",
  },
];

export const answerOptions: GenericOption<AnswerType>[] = [
  {
    label: "Code",
    value: "code",
  },
  {
    label: "Object",
    value: "object",
  },
  {
    label: "String",
    value: "string",
  },
];

export const statusOptions: GenericOption<Status>[] = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Inactive",
    value: "inactive",
  },
  {
    label: "Only cabinet",
    value: "only_cabinet",
  },
];

export const periodOptions: GenericOption<PeriodType>[] = [
  {
    label: "-1 month",
    value: "-1 month",
  },
  {
    label: "-2 month",
    value: "-2 month",
  },
  {
    label: "-3 month",
    value: "-3 month",
  },
];

export const bodyValueOptions: GenericOption<LeadFields>[] = [
  {
    label: "Id",
    value: "id",
  },
  {
    label: "Name",
    value: "name",
  },
  {
    label: "Surname",
    value: "surname",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "Phone",
    value: "phone",
  },
  {
    label: "Country",
    value: "country",
  },
  {
    label: "Language",
    value: "language",
  },
  {
    label: "Landing",
    value: "landing",
  },
  {
    label: "Ip",
    value: "ip",
  },
  {
    label: "Status",
    value: "status",
  },
  {
    label: "Url",
    value: "url",
  },
  {
    label: "Referer",
    value: "referer",
  },
  {
    label: "sub1",
    value: "sub1",
  },
  {
    label: "sub2",
    value: "sub2",
  },
  {
    label: "sub3",
    value: "sub3",
  },
  {
    label: "sub4",
    value: "sub4",
  },
  {
    label: "sub5",
    value: "sub5",
  },
  {
    label: "click_id",
    value: "click_id" as "clickId",
  },
  {
    label: "phone_code",
    value: "phone_code" as "phoneCode",
  },
  {
    label: "phone_number",
    value: "phone_number" as "phoneNumber",
  },
  {
    label: "full_name",
    value: "full_name" as "fullName",
  },
];

export const headerValueOptions: GenericOption<HeaderValues>[] = [
  {
    label: "application/json",
    value: "application/json",
  },
  {
    label: "application/x-www-form-urlencoded",
    value: "application/x-www-form-urlencoded",
  },
];

export interface RequestIntegrations extends PaginationQuery {
  filter: {
    clientId?: string;
  };
}

export const emptyValues: IIntegrationChange = {
  id: "",
  answer: {
    key: "",
    type: null,
    value: "",
  },
  body: [],
  headers: [
    {
      key: "Content-Type",
      value: headerValueOptions[0],
    }
  ],
  method: httpMethodOptions.find((option) => option.value === "post") || null,
  isSheet: false,
  name: "",
  parameters: [],
  postback: {
    key: "",
  },
  redirect: {
    type: null,
    value: "",
  },
  status: statusOptions.find((option) => option.value === "active") || null,
  type: typeOptions.find((option) => option.value === "main") || null,
  url: "",
  wrapArray: false,
  withoutPlus: false,
};

export type QuickIntegration = {
  clientId: string;
  name: string;
  method: "post";
  type: "main";
  status: "active";
  isSheet: boolean;
};
