import { Api } from "./utils";
import { getResponseData } from "./utils";
import { SettingType } from "../models/ISettings";
import { TableSettingsRequest, SettingsTableKey, TableSettingsResponse } from "../models/IUserSettings";
import { camelizeKeys } from "humps";

const baseURL = "/v1/users/settings";

export const postTableSettings = (data: TableSettingsRequest<UnknownRecord>): Promise<TableSettingsResponse<UnknownRecord>> =>
  Api.post(`${baseURL}/${SettingType.USER_TABLE_COLUMN}`, data).then(getResponseData);

export const getTableSettings = <Model extends UnknownRecord>(key: SettingsTableKey): Promise<TableSettingsResponse<Model> | null> =>
  Api.get(`${baseURL}/${SettingType.USER_TABLE_COLUMN}`, { params: { key } })
    .then(getResponseData)
    .then(data => camelizeKeys(data) as TableSettingsResponse<Model> | null);
