import { Formik, Form } from "formik";
import React, { useMemo, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import FieldBlock from "../../components/common/form/formik/FieldBlock";
import PageTitle from "../../components/common/items/page-title";
import {
  createIntegrationFields,
  integrationValidation,
} from "../../components/fields/create-integration";
import { useAppDispatch } from "../../hooks/redux";
import { emptyValues, IIntegrationChange } from "../../models/IIntegration";
import { changeIntegration } from "../../store/thunks/integrations";
import { decamelizeKeys } from "humps";

const CreateIntegration = () => {
  const dispatch = useAppDispatch();
  const [formikValues, setFormikValues] = useState(emptyValues);
  const { id: clientId } = useParams();

  const handleSubmit = (values: IIntegrationChange) => {
    if (clientId) {
      dispatch(changeIntegration({ ...values, clientId }));
    }
  };

  const fields = useMemo(() => createIntegrationFields(formikValues), [formikValues]);

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <Link to={`/clients/${clientId}/integrations`} className="w-auto">
          <Button className="w-auto mb-3" variant="primary">
            Back
          </Button>
        </Link>
        <PageTitle title="Creating integration" />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Formik
                enableReinitialize
                initialValues={formikValues}
                validationSchema={integrationValidation}
                onSubmit={handleSubmit}
              >
                {({ values }) => {
                  useEffect(() => {
                    if (values.type?.value === "postback") {
                      const newValues: Partial<IIntegrationChange> = { ...values };
                      delete newValues.isSheet;
                      delete newValues.wrapArray;
                      delete newValues.withoutPlus;
                      delete newValues.answer;
                      delete newValues.redirect;
                      delete newValues.parameters;
                      let postback = newValues.postback;

                      if (typeof postback !== "string") {
                        postback = JSON.stringify(decamelizeKeys(newValues.postback ?? {}), null, 2);
                      }

                      setFormikValues({
                        ...newValues,
                        postback,
                      } as IIntegrationChange);
                    } else {
                      setFormikValues({ ...values, postback: { key: "" } });
                    }
                  }, [values]);

                  return (
                    <Form className="w-100 px-4">
                      <div className="row">
                        {fields.map((field) => (
                          <FieldBlock key={field.title} {...field} />
                        ))}
                      </div>
                      <Button
                        type="submit"
                        className="form-control w-25 m-auto d-block"
                        variant="primary"
                      >
                        Create
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CreateIntegration;
