import { ArcElement, Chart, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { useCallback, useEffect, useMemo } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { Bar, Doughnut } from "react-chartjs-2";
import {
    useGetDiagramStatusMutation,
    useGetDiagramValidReplaceMutation
} from "../../../api/statistics-reports-api";
import { filterEmpyValues } from "../../../api/utils";
import ColorLabel from "../../../components/common/items/ColorLabel";
import { DiagramFilter, StatisticReportsFilters } from "../../../models/IStatistics";
import Loading from "../../_layouts/Loading";

ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
  affiliateId: string | null;
  setAffiliateId: (val: string | null) => void;
  filters: StatisticReportsFilters;
}

const AffiliateDiagramModal = ({ setAffiliateId, affiliateId, filters }: Props) => {
  const [statusMutation, { data: status, isLoading: statusIsLoading }] =
    useGetDiagramStatusMutation();
  const [validReplaceMutation, { data: validReplace, isLoading: validReplaceIsLoading }] =
    useGetDiagramValidReplaceMutation();

  useEffect(() => {
    if (!affiliateId) return;
    const data: DiagramFilter = {
      filter: {
        sentAt: filters.sentAt,
        affiliateId,
      },
      type: "query",
    };
    const formattedData = filterEmpyValues(data) as DiagramFilter;
    statusMutation(formattedData).unwrap();
    validReplaceMutation(formattedData).unwrap();
  }, [affiliateId]);

  const filteredStatuses =
    status?.filter((item) => !!item.status).sort((a, b) => b.total - a.total) || [];
  const filteredValidReplace = validReplace?.[0];

  const randomRGB = () => {
    const maxNum = 255;
    return (
      "rgb(" +
      Math.round(Math.random() * maxNum) +
      "," +
      Math.round(Math.random() * maxNum) +
      "," +
      Math.round(Math.random() * maxNum) +
      ")"
    );
  };

  const total = useMemo(
    () => filteredStatuses.reduce((prev, curr) => prev + curr.total, 0),
    [filteredStatuses],
  );
  const colors = useMemo(() => filteredStatuses.map(() => randomRGB()), [filteredStatuses]);
  const getPersentage = useCallback(
    (value: number) => ((value * 100) / total).toFixed(2),
    [filteredStatuses],
  );

  const statusData = {
    labels: filteredStatuses.map((value) => value.status),
    datasets: [
      {
        label: "# of Votes",
        data: filteredStatuses.map((value) => value.total),
        backgroundColor: colors,
        borderColor: "rgb(255, 255, 255)",
        borderWidth: 2,
      },
    ],
  };

  const validReplaceData = {
    labels: ["notApproved", "approved"],
    datasets: [
      {
        label: "Valid replace",
        data: [filteredValidReplace?.notApproved, filteredValidReplace?.approved],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const plugins = [
    {
      beforeDraw: function (chart: Chart) {
        const width = chart.width,
          height = chart.height - 20,
          ctx = chart.ctx;
        ctx.restore();
        const fontSize = (height / 160).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        const text = `${total} - 100%`,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  return (
    <Modal show={!!affiliateId} onHide={() => setAffiliateId(null)} size="lg" centered>
      <Modal.Header closeButton />
      <Modal.Body>
        {statusIsLoading || validReplaceIsLoading ? (
          <Loading />
        ) : (
          <Row>
            <Col xs={12} md={6} className="relative">
              <p>Status</p>
              <Doughnut
                data={statusData}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
                plugins={plugins as FixMeLater}
              />
              <div className="mt-2 border rounded">
                {filteredStatuses.map((item, index) => (
                  <div className="flex border-bottom p-1">
                    <ColorLabel className="d-inline mr-2" isSingleColor color={colors[index]} />
                    <p key={index} className="m-0">
                      <span className="fw-bold">{item.status?.replace(/_/g, " ")}: </span>
                      {item.total} - {getPersentage(item.total)}%
                    </p>
                  </div>
                ))}
              </div>
            </Col>
            <Col xs={12} md={6}>
              <p>Valid replace</p>
              <Bar
                data={validReplaceData}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            </Col>
          </Row>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AffiliateDiagramModal;
