import { FormikProps, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import * as Yup from "yup";
import { getCloneClient } from "../../../api/clients-api";
import Field from "../../../components/common/form/formik/Field";
import { useAppDispatch } from "../../../hooks/redux";
import { TypesOfInput } from "../../../models/fields/IField";
import { IClientClone, IClientCloneInfo, initialCloneValues } from "../../../models/IClients";
import { cloneClient } from "../../../store/thunks/clients";
import Loading from "../../_layouts/Loading";

interface Props {
  onClose: VoidFunction;
  clientId: string;
  formikRef: React.Ref<FormikProps<IClientClone>>;
}

const CloneClientModal = ({ clientId, onClose, formikRef }: Props) => {
  const dispatch = useAppDispatch();
  const [initialValues, setInitialValues] = useState<IClientClone>(initialCloneValues);
  const [cloneInfo, setCloneInfo] = useState<IClientCloneInfo | null>(null);

  useEffect(() => {
    fetchCloneInfo();
  }, [clientId]);

  const fetchCloneInfo = async () => {
    const data = await getCloneClient(clientId);
    const { name, price } = data.model;
    setInitialValues({
      name,
      price,
      integration: null,
      url: "",
    });
    setCloneInfo(data.model);
  };

  const handleSubmit = (values: IClientClone) => {
    dispatch(cloneClient({ data: values, id: clientId })).then(onClose);
  };

  if (!cloneInfo) return <Loading />;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={Yup.object({
        name: Yup.string().required("Required"),
        price: Yup.number().typeError("Must be a number").required("Required"),
        integration: Yup.object().typeError("Required"),
      })}
      innerRef={formikRef}
    >
      {({ values }) => {
        useEffect(() => {
          setInitialValues({
            ...values,
            url: values.integration?.url || "",
          })
        }, [values]);

        return (
          <Form className="w-100">
            <div className="row">
              <Field name="name" />
              <Field name="price" />
              <Field name="url" />
              <Field
                name="integration"
                type={TypesOfInput.SELECT}
                options={cloneInfo.integrations}
                labelName="name"
                valueName="id"
              />
            </div>
          </Form>
        )
      }}
    </Formik>
  );
};

export default CloneClientModal;
