import * as Yup from "yup";
import { ITelegramChatUpdate, permissionOptions } from "../../models/ITelegramChats";
import { IAllFields, TypesOfInput } from "../../models/fields/IField";
import { AsyncObject } from "../../models/fields/ISelect";
import { IClient } from "../../models/IClients";

export const editTelegramChatValidation = Yup.object({
  name: Yup.string().required("Required"),
  permissions: Yup.array().required("Required"),
});

interface Params {
  clientOptions: IClient[];
  asyncClients: AsyncObject;
}

export const editTelegramChatFields = ({
  clientOptions,
  asyncClients,
}: Params): IAllFields<ITelegramChatUpdate>[] => [
  {
    name: "name",
    type: TypesOfInput.TEXT,
  },
  {
    async: asyncClients,
    labelName: "name",
    name: "client",
    options: clientOptions,
    type: TypesOfInput.SELECT,
    valueName: "id",
  },
  {
    isMulti: true,
    name: "permissions",
    options: permissionOptions,
    type: TypesOfInput.SELECT,
  },
];
