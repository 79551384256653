import React, { useEffect, useMemo, useRef, useState } from "react";
import { Formik, Form, FormikProps } from "formik";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { Link, useParams } from "react-router-dom";
import Field from "../../components/common/form/formik/Field";
import PageTitle from "../../components/common/items/page-title";
import Loading from "../_layouts/Loading";
import { emptyValues, IUserChange, roleOptions } from "../../models/IUsers";
import { changeItem, deleteItem, fetchItem } from "../../store/thunks/users";
import { createFields, validationSchema } from "../../components/fields/users";
import { fetchList } from "../../store/thunks/teams";
import { TypesOfInput } from "../../models/fields/IField";
import useModal from "../../hooks/useModal";
import { FetchOptionsParams } from "../../types/global";
import { fetchClients } from "../../store/thunks/clients";
import { handleCopy } from "../../utils/formats";

const ChangeItem = () => {
  const dispatch = useAppDispatch();
  const { currentItem } = useAppSelector((state) => state.usersReducer);
  const { list: teams } = useAppSelector((state) => state.teamsSlice);
  const { clients } = useAppSelector((state) => state.clientsReducer);
  const { id: userId } = useParams();
  const formikRef: React.Ref<FormikProps<IUserChange>> | null = useRef(null);
  const [initialValues, setInitialValues] = useState<IUserChange>(emptyValues);

  useEffect(() => {
    dispatch(fetchList({}));
    dispatch(fetchClients({}));
    if (!userId) return;
    dispatch(fetchItem(userId));
  }, []);

  const handleDelete = () => {
    if (!userId) return;
    dispatch(deleteItem(userId));
  };

  const { ModalComp, handleShowModal } = useModal(handleDelete);

  useEffect(() => {
    if (userId && !currentItem.loading && currentItem.data) {
      const data = currentItem.data;
      setInitialValues({
        ...data,
        roles: roleOptions.find((option) => option.value === data.roles) || null,
        team: teams.data.find((item) => item.id === data.team.id) || null,
        // clients: clients.data.filter((item) => data.clients.find(client => client.id === item.id)) || [],
      });
    }
  }, [currentItem, teams, clients]);

  const fields = useMemo(
    () =>
      createFields({
        asyncTeams: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchList(params)),
          isLoading: teams.loading,
        },
        asyncClients: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchClients(params)),
          isLoading: clients.loading,
        },
        teamOptions: teams.data,
        clientsOptions: clients.data,
      }),
    [teams, clients],
  );
  const validation = useMemo(() => validationSchema(!!userId), []);

  const handleSubmit = (values: IUserChange) => {
    if (userId) {
      dispatch(changeItem({ id: userId, ...values }));
    } else {
      dispatch(changeItem(values));
    }
  };

  const generatePassword = () => {
    const randomstring = Math.random().toString(36).slice(-8);
    formikRef.current?.setFieldValue("password", randomstring);
    handleCopy(randomstring);
  };

  if (userId && currentItem.loading) {
    return <Loading />;
  }

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <div className="d-flex justify-between">
          <Link to="/users" className="w-auto">
            <Button className="w-auto mb-3" variant="primary">
              Back
            </Button>
          </Link>
          <Button className="w-auto mb-3" variant="danger" onClick={handleShowModal}>
            Delete
          </Button>
        </div>
        <PageTitle title={`${userId ? "Edit" : "Create"} user`} />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validation}
                innerRef={formikRef}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {fields.map((field) => (
                      <Field key={field.name} {...field} />
                    ))}
                    <div className="col-12 mb-3 col-md-4 flex-center">
                      <Field size={8} name="password" type={TypesOfInput.TEXT} />
                      <Button onClick={generatePassword} className="col-md-4 mt-1">
                        Generate
                      </Button>
                    </div>
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                  >
                    Save
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
      </div>
      {ModalComp}
    </div>
  );
};

export default ChangeItem;
