import { createAsyncThunk } from "@reduxjs/toolkit";
import * as labelsApi from "../../api/label-api";
import { ILabelCreate } from "../../models/ILabels";
import { FetchOptionsParams } from "../../types/global";
import { LabelsState } from "../reducers/labelsSlice";

export const fetchLabels = createAsyncThunk("labels/fetchLabels", (params: FetchOptionsParams, thunkAPI) => {
  const state: FixMeLater = thunkAPI.getState();
  const labelsState: LabelsState = state.labelsReducer;

  const options = {
    filter: labelsState.filter,
    page: labelsState.pagination.page,
    take: labelsState.pagination.take,
    searchTerm: params.searchTerm,
  };

  if (!params.searchTerm) {
    delete options.searchTerm;
  }
  return labelsApi.getLabels(options);
});

export const editLabel = createAsyncThunk("labels/editLabel", (data: ILabelCreate) =>
  labelsApi.updateLabel(data),
);
