import { RequestList } from "../components/common/tables/types";
import { ILabel, ILabelCreate, ILabelFilter } from "../models/ILabels";
import { Api, filterEmpyValues, ListRes } from "./utils";
import { getResponseData } from "./utils";

const baseURL = "/v1/labels";

export const getLabels = (params: RequestList<ILabelFilter>): Promise<ListRes<ILabel>> => {
  return Api.post(baseURL + "/list", filterEmpyValues(params)).then(getResponseData);
};

export const updateLabel = (data: ILabelCreate) => {
  return Api.post(baseURL, data).then(getResponseData);
};

export const deleteLabel = (id: string) => {
  return Api.delete(`${baseURL}/${id}`);
};
