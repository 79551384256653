import * as statusesApi from "../../api/status-api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ListRes, ModelRes, ResponseError } from "../../api/utils";
import { createAppThunk } from "./createAppThunk";
import { RootState } from "../reducers";
import { State } from "../reducers/statusesSlice";
import { IStatus, IStatusChange, IStatusRequest } from "../../models/IStatus";
import { FetchOptionsParams } from "../../types/global";

export const fetchList = createAppThunk<ListRes<IStatus>, FetchOptionsParams<{ system: string }>>
  ("statuses/fetchList", (params: FetchOptionsParams<{ system: string }>, thunkAPI) => {
    const rootState: RootState = thunkAPI.getState() as RootState;
    const state: State = rootState.statusesSlice;

    const filter: IStatusRequest = {
      ...state.filter,
      system: state.filter.system?.value || "",
      type: state.filter.type?.value || "",
    };

    if (typeof params?.filter?.system === 'string') filter.system = params.filter.system;

    const options = {
      filter,
      page: state.pagination.page,
      take: state.pagination.take,
      searchTerm: params.searchTerm,
    };

    if (!params.searchTerm) {
      delete options.searchTerm;
    }
    return statusesApi.getList(options);
  });

export const fetchGroupList = createAppThunk<IStatus[]>
  ("statuses/fetchGroupList", statusesApi.getGroupList);

export const fetchItem = createAsyncThunk("statuses/fetchItem", (id: string) =>
  statusesApi.getItem(id),
);

export const deleteItem = createAsyncThunk("statuses/deleteItem", (id: string) =>
  statusesApi.deleteItem(id),
);

export const changeItem = createAppThunk<ModelRes<IStatus>, IStatusChange & { id?: string }>(
  "statuses/changeItem",
  async (values: IStatusChange & { id?: string }, thunkAPI) => {
    try {
      return await await statusesApi.changeItem(values);
    } catch (error) {
      return thunkAPI.rejectWithValue(error as ResponseError);
    }
  },
);
