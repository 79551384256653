import { Formik, Form } from "formik";
import React, { useMemo } from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import FieldBlock from "../../components/common/form/formik/FieldBlock";
import PageTitle from "../../components/common/items/page-title";
import { createTokenFields, validation } from "../../components/fields/create-integrationToken";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { emptyValues, ITokenChange } from "../../models/IIntegrationToken";
import { createIntegrationToken } from "../../store/thunks/integrations";

const CreateToken = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentIntegration } = useAppSelector((state) => state.integrationsReducer);

  const handleSubmit = (values: ITokenChange) => {
    if (currentIntegration && currentIntegration.data) {
      dispatch(createIntegrationToken({ ...values, integrationId: currentIntegration.data.id }));
    }
  };

  const fields = useMemo(() => createTokenFields(), []);

  if (!currentIntegration.data) {
    navigate("not-found");
  }

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <Link to={`/clients/${id}/integrations/${currentIntegration.data?.id}`} className="w-auto">
          <Button className="w-auto mb-3" variant="primary">
            Back
          </Button>
        </Link>
        <PageTitle title={`${currentIntegration.data?.name} token`} />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={emptyValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {fields.map((field) => (
                      <FieldBlock key={field.title} {...field} />
                    ))}
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                  >
                    Create
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CreateToken;
