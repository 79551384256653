import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useField } from "formik";
import Select, { MultiValue } from "react-select";
import useDebounce from "../../../../hooks/useDebounce";
import { getLocalizationsByName } from "../../../../api/localizations-api";
import { FindByNamesResponse } from "../../../../models/ILocalizations";
import { selectStyles } from "../../../../models/fields/ISelect";

interface Props {
  name: string;
  label: string;
}

export type LocalizationOption = {
  value: string | null;
  label: string;
};

const LocalizationSelect = ({ name, label }: Props) => {
  const [field, , helpers] = useField<MultiValue<LocalizationOption>>(name);
  const { setValue } = helpers;
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce<string>(search, 1000);
  const [wasOpened, setWasOpened] = useState(false);
  const [options, setOptions] = useState<MultiValue<LocalizationOption>>([]);
  const [isLoading, setIsLoading] = useState(false);

  const formatSearchValue = (search: string) => ({
    names: search.split(" ").filter(Boolean) ?? [],
  });

  const formatOptions = (data: FindByNamesResponse): LocalizationOption[] =>
    Object.entries(data.data).map(([key, value]) => ({ value, label: key }));

  const handleRequestOptions = (search: string) => {
    if (!search) return;
    setIsLoading(true);
    getLocalizationsByName(formatSearchValue(search)).then((data) => {
      setIsLoading(false);
      setOptions(formatOptions(data));
    });
  };

  const onInputChange = (value: string) => setSearch(value.toUpperCase())

  useEffect(() => {
    if (!debouncedSearch && !wasOpened) return;
    handleRequestOptions(debouncedSearch);
  }, [debouncedSearch]);

  return (
    <div>
      {label && (
        <label htmlFor={name} className="form-label-test">
          {label}
        </label>
      )}
      <Select
        {...field}
        placeholder="GB/MT VN/KL"
        isMulti
        closeMenuOnSelect={false}
        value={field.value}
        onChange={(selectedValue: MultiValue<LocalizationOption>) => {
          setValue(selectedValue);
        }}
        inputValue={search}
        options={options}
        filterOption={() => true}
        onInputChange={onInputChange}
        isLoading={isLoading && wasOpened}
        isOptionDisabled={(option) => !option.value}
        onMenuOpen={() => setWasOpened(true)}
        onMenuClose={() => setWasOpened(false)}
        formatOptionLabel={(option) => (
          <div className={cn({ "bg-danger-light": !option.value })}>{option.label}</div>
        )}
        styles={selectStyles}
      />
    </div>
  );
};

export default LocalizationSelect;
