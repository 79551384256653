import { PaginationQuery } from "../components/common/tables/types";
import { ListItem, Modify } from "../types/global";
import { GenericOption, OptionModel } from "./fields/ISelect";
import { IClient } from "./IClients";
import { ITeam } from "./ITeam";

export enum UserRole {
  ADMIN = "admin",
  TEAM_LEAD = "team_lead",
  AFFILIATE = "affiliate",
  AFFILIATE_MANAGER = "affiliate_manager",
  PM = "pm",
}

export type IUser = ListItem<{
  surname?: string;
  clients: IClient[];
  team: ITeam;
  name: string;
  email: string;
  roles: UserRole;
  password: string;
}>;

export type IUserChange = {
  roles: OptionModel<GenericOption<UserRole>>;
  team: OptionModel<ITeam>;
  clients: IClient[];
  surname?: string;
  name: string;
  email: string;
  password: string;
  id?: string;
};

export type IUserChangeRequest = Modify<
  Partial<IUserChange>,
  {
    clients: string[],
    teamId: string;
    roles: string;
  }
>;

export type IUserFilter = {
  roles: OptionModel<GenericOption<UserRole>>;
  email: string;
};

export type IUserFilterRequest = PaginationQuery & {
  roles: string;
  email: string;
};

export const initialFilters: IUserFilter = {
  roles: null,
  email: "",
};

export const roleOptions: GenericOption<UserRole>[] = [
  {
    label: "Admin",
    value: UserRole.ADMIN,
  },
  {
    label: "Team lead",
    value: UserRole.TEAM_LEAD,
  },
  {
    label: "Affiliate",
    value: UserRole.AFFILIATE,
  },
  {
    label: "Affiliate manager",
    value: UserRole.AFFILIATE_MANAGER,
  },
  {
    label: "PM",
    value: UserRole.PM,
  },
];

export const emptyValues: IUserChange = {
  name: "",
  email: "",
  roles: null,
  team: null,
  password: "",
  surname: "",
  clients: [],
};
