import { ArrayOfDate } from "../types/global";

export type ILocalization = ILocalizationChange & {
  id: string;
  isSelected?: boolean;
  createdAt: string;
};

export type ILocalizationShort = ILocalizationChange & {
  id: string;
};

export type IFilterLocalization = ILocalizationChange & {
  createdAt: ArrayOfDate;
  id: string,
};

export const initialLocalizationFilters: IFilterLocalization = {
  id: "",
  country: "",
  createdAt: [null, null],
  language: "",
  name: "",
  location: "",
};

export type ILocalizationChange = {
  location: string;
  name: string;
  country: string;
  language: string;
};

export type ICountry = {
  name: string;
  flag: string;
  code: string;
};

export type FindByNamesRequest = {
  names: string[],
}

export type FindByNamesResponse = {
  data: Record<string, string | null>
}
