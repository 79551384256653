import { IFilterTransaction, ITransaction, emptyFilters } from "../../models/ITransactions";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchTransactions } from "../thunks/transactions";
import { PaginationQuery, PaginationType } from "../../components/common/tables/types";
import { FetchedStoreModel, PAGINATION_TAKE } from "../../types/global";

export interface transactionsState {
  transactions: FetchedStoreModel<ITransaction[]>;
  pagination: PaginationType;
  filter: IFilterTransaction;
}

const initialState: transactionsState = {
  transactions: {
    data: [],
    error: null,
    loading: true,
  },
  pagination: {
    take: PAGINATION_TAKE,
    total: null,
    page: 1,
    lastPage: null,
  },
  filter: emptyFilters,
};

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    setPagination: (state, action: PayloadAction<PaginationQuery>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    setFilters: (state, action: PayloadAction<IFilterTransaction>) => {
      state.filter = action.payload;
      state.pagination.page = 1;
    },
    resetFilters: (state) => {
      state.filter = emptyFilters;
      state.pagination.page = 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTransactions.pending, (state) => {
      state.transactions.loading = true;
      state.transactions.error = null;
    });
    builder.addCase(fetchTransactions.fulfilled, (state, action) => {
      state.transactions.data = action.payload.list;
      state.transactions.loading = false;
      state.pagination = { ...state.pagination, ...action.payload.pagination };
    });
    builder.addCase(fetchTransactions.rejected, (state, action) => {
      state.transactions.loading = false;
      state.transactions.error = action.payload as FixMeLater;
    });
  }
});

export default transactionsSlice.reducer;
