import React, { useEffect, useMemo, useState } from "react";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { Link, useParams } from "react-router-dom";
import Field from "../../components/common/form/formik/Field";
import PageTitle from "../../components/common/items/page-title";
import Loading from "../_layouts/Loading";
import { emptyValues, IStatus, IStatusChange, statusOptions, typeOptions } from '../../models/IStatus';
import { changeItem, deleteItem, fetchItem } from "../../store/thunks/statuses";
import { createFields, validationSchema } from "../../components/fields/statuses";
import useModal from "../../hooks/useModal";
import { getValuesFromModel } from '../../api/utils';

const ChangeItem = () => {
  const dispatch = useAppDispatch();
  const { currentItem } = useAppSelector((state) => state.statusesSlice);
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState<IStatusChange>(emptyValues);

  useEffect(() => {
    if (!id) return;
    dispatch(fetchItem(id));
  }, []);

  const handleDelete = () => {
    if (!currentItem.data) return;
    dispatch(deleteItem(currentItem.data.id));
  };

  const { ModalComp, handleShowModal } = useModal(handleDelete);

  useEffect(() => {
    if (id && !currentItem.loading && currentItem.data) {
      const data = getValuesFromModel<IStatusChange>(
        {
          ...currentItem.data,
          system:
            statusOptions.find((option) => option.value === (currentItem.data as IStatus).system) || null,
          type:
            typeOptions.find((option) => option.value === (currentItem.data as IStatus).type) ||
            null,
        },
        emptyValues,
      );
      setInitialValues(data);
    }
  }, [currentItem]);

  const fields = useMemo(() => createFields, []);

  const handleSubmit = (values: IStatusChange) => {
    dispatch(changeItem({ ...values, id }));
  };

  if (currentItem.loading) {
    return <Loading />;
  }

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <div className="d-flex">
          <Link to="/statuses" className="w-auto">
            <Button className="w-auto mb-3" variant="primary">
              Back
            </Button>
          </Link>
          <Button className="w-auto mb-3" variant="danger" onClick={handleShowModal}>
            Delete
          </Button>
        </div>
        <PageTitle title="Edit status" />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {fields.map((field) => (
                      <Field key={field.name} {...field} />
                    ))}
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                  >
                    Save
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
      </div>
      {ModalComp}
    </div>
  );
};

export default ChangeItem;
