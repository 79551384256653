import { createAsyncThunk } from "@reduxjs/toolkit";
import * as integrationsApi from "../../api/integrations-api";
import { IIntegrationChange } from "../../models/IIntegration";
import { ITokenChange } from "../../models/IIntegrationToken";
import { FetchOptionsParams } from "../../types/global";
import { integrationsState } from "../reducers/integrationsSlice";

export const fetchIntegrations = createAsyncThunk(
  "integrations/fetchIntegrations",
  (params: FetchOptionsParams<{ id?: string, type?: string }>, thunkAPI) => {
    const state: FixMeLater = thunkAPI.getState();
    const integrationsState: integrationsState = state.integrationsReducer;

    const options = {
      filter: {
        clientId: params.filter?.id,
        type: params.filter?.type,
      },
      page: integrationsState.pagination.page,
      take: integrationsState.pagination.take,
      searchTerm: params.searchTerm,
    };

    if (!params.searchTerm) {
      delete options.searchTerm;
    }

    return integrationsApi.getIntegrations(options);
  },
);

export const fetchOneIntegration = createAsyncThunk(
  "integrations/fetchOneIntegration",
  (id: string) => integrationsApi.getIntegration(id),
);

export const changeIntegration = createAsyncThunk(
  "integrations/changeIntegration",
  (values: IIntegrationChange & { clientId: string; id?: string }) =>
    integrationsApi.changeIntegration(values),
);

export const createQuickIntegration = createAsyncThunk(
  "integrations/createQuickIntegration",
  async (values: { clientId: string; name: string }, thunkAPI) => {
    await integrationsApi.createQuickIntegration(values);
    thunkAPI.dispatch(fetchIntegrations({ filter: { id: values.clientId } }));
  },
);

export const createIntegrationToken = createAsyncThunk(
  "integrations/createIntegrationToken",
  (data: ITokenChange & { integrationId: string }) =>
    integrationsApi.createIntegrationToken(data),
);
