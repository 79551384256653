import {
  IAffiliate,
  IAffiliateChange,
  RequestAffiliate,
} from "../models/IAffiliates";
import { Api, filterEmpyValues, ListRes, ModelRes } from "./utils";
import { getResponseData } from "./utils";
import { RequestOptions } from "../types/global";

const PATH = "/v1/affiliates";

export const getAffiliates = (params: RequestAffiliate): Promise<ListRes<IAffiliate>> => (
  Api.post(
    `${PATH}/list`,
    filterEmpyValues({
      ...params,
      des: true,
      orderBy: "status",
    }),
  ).then(getResponseData)
);

export const getShortAffiliates = (params: RequestOptions): Promise<ListRes<IAffiliate>> => {
  return Api.post(`${PATH}/list`, params).then(getResponseData);
};

export const getAffiliate = (id: string): Promise<ModelRes<IAffiliate>> => {
  return Api.get(`${PATH}/${id}`).then(getResponseData);
};

export const changeAffiliate = (data: IAffiliateChange) => {
  const sendingData = filterEmpyValues({
    ...data,
    teamId: data.team?.id || "",
    status: data.status ? data.status?.value : "",
  });
  sendingData.users = data.users.map((user) => user?.id);
  delete sendingData.team;

  return Api.post(PATH, sendingData).then(getResponseData);
};

export const deleteAffiliate = (id: string) => {
  return Api.delete(`${PATH}/${id}`);
};

export const changeStatusAffiliate = (status: boolean, id: string) => {
  return Api.post(`${PATH}/update-short`, { id, status }).then(getResponseData);
};
