import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import FilterForm from "../../components/common/form/formik/FilterForm";
import PageTitle from "../../components/common/items/page-title";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { pushesSlice } from "../../store/reducers/pushesSlice";
import { IPushFilters, emptyFilters } from "../../models/IPush";
import { fetchList } from "../../store/thunks/pushes";
import { filterFields } from "../../components/fields/pushes";
import PushesTable from "../../components/pushes/PushesTable";

const List = () => {
  const dispatch = useAppDispatch();
  const { filter } = useAppSelector((state) => state.pushesSlice);
  const { setFilters, resetFilters } = pushesSlice.actions;

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchList());
    return () => {dispatch(setFilters(emptyFilters))}
  }, []);

  const handleFilter = (values: IPushFilters) => {
    dispatch(setFilters(values));
    dispatch(fetchList());
  };

  const fields = useMemo(() => filterFields(), []);

  return (
    <>
      <div className="container-fluid px-lg-4 px-xl-5">
        <PageTitle title="Pushes" />
        <div className="row">
          <div className="d-flex mb-3 justify-between">
            <div>
              <Button variant="primary" onClick={() => setIsFilterOpen((prevState) => !prevState)}>
                {isFilterOpen ? "Close" : "Open"} Filters
              </Button>
              <Link className="btn btn-primary" to="/pushes/new">
                Create push
              </Link>
            </div>
          </div>
          {isFilterOpen && (
            <FilterForm
              className="mb-3"
              fields={fields}
              handleSubmit={handleFilter}
              handleReset={() => dispatch(resetFilters())}
              initialValues={filter}
            />
          )}
          <section className="mb-5">
            <div className="card">
              <div className="card-body">
                <PushesTable className="min-w-screen-lg" />
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default List;
