import { Link } from "react-router-dom";
import { ISetting } from "../../models/ISettings";
import { formatDateFullTime } from "../../utils/formats";
import { ColumnDef, PaginationType } from "../common/tables/types";

type IColumn = ColumnDef<ISetting>;

interface Options {
  pagination: PaginationType;
}

export default function getColumnDefs(options: Options): IColumn[] {
  const { pagination } = options;

  const numberIndex: IColumn = {
    Cell: ({ row: { index } }: { row: { index: number } }) => {
      return <div>{index + (pagination.from || 1)}</div>;
    },
    Header: "№",
    id: "№",
    disableFilters: true,
    disableSortBy: true,
    width: "25px",
  };

  const name: IColumn = {
    Cell: ({ value, row }) => (
      <Link className="font-semibold" to={`/settings/${row.original.id}`}>
        {value.name}
      </Link>
    ),
    Header: "Value",
    id: "value",
    accessor: "value",
  };

  const type: IColumn = {
    Header: "Type",
    accessor: "type",
    id: "type",
  };

  const createdAt: IColumn = {
    Cell: ({ value }) => <span>{formatDateFullTime(value)}</span>,
    Header: "Created",
    accessor: "createdAt",
    id: "createdAt",
  };

  return [numberIndex, name, type, createdAt];
}
