import * as playersApi from "../../api/player-api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../reducers";
import { State } from "../reducers/playersSlice";
import { IPlayerFiltersRequest } from "../../models/IPlayer";

export const fetchList = createAsyncThunk("players/fetchList", (_, thunkAPI) => {
  const rootState: RootState = thunkAPI.getState() as RootState;
  const state: State = rootState.playersSlice;

  const options: IPlayerFiltersRequest = {
    ...state.filter,
    page: state.pagination.page,
    take: state.pagination.take,
    offerId: state.filter.offer?.id || "",
    status: state.filter.status ? state.filter.status?.value : "",
  };
  return playersApi.getList(options);
});

export const deleteItems = createAsyncThunk("players/deleteItems", (ids: string[]) =>
  playersApi.deleteItems(ids),
);
