import React, { useMemo, memo } from "react";
import { AffiliateProfitType } from "../../../models/IStatistics";

interface Props {
  data: AffiliateProfitType[];
}

const AffiliateProfit = ({ data }: Props) => {
  const emptyClientData = {
    name: "-",
    leads: 0,
    approved: 0,
    price: 0,
    summa: "0",
  };

  const uniqClients: { [key: string]: typeof emptyClientData } = useMemo(
    () =>
      data.reduce(
        (prev, curr) => ({
          ...prev,
          ...Object.entries(curr.clients).reduce(
            (prevClient, [currClientId, { name }]) => ({
              ...prevClient,
              [currClientId]: { ...emptyClientData, name },
            }),
            {},
          ),
        }),
        {},
      ),
    [data],
  );

  const dataToRender = useMemo(
    () =>
      data.map((aff) => ({
        ...aff,
        clients: { ...uniqClients, ...aff.clients },
      })),
    [data],
  );

  const getTotalByAff = (aff: AffiliateProfitType) =>
    Object.values(aff.clients).reduce((prev, curr) => prev + Number(curr.summa), 0);

  return (
    <div className="mb-4 card">
      <div className="card-header py-3">
        <p className="card-heading">Affiliate profit</p>
      </div>
      <div className="card-body table-responsive">
        {!!data.length && (
          <table className="card-text table overflow-auto">
            <thead>
              <tr>
                <th />
                <th />
                {Object.values(uniqClients).map(({ name }) => (
                  <th key={name} colSpan={4} className="text-center b-r-1">
                    {name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td />
                <td className="text-center b-r-1 fw-bold">Total</td>
                {Object.entries(uniqClients).map((_, index) => (
                  <React.Fragment key={index}>
                    <td className="text-center">Leads</td>
                    <td className="text-center">Invalid</td>
                    <td className="text-center">Price</td>
                    <td className="text-center b-r-1 fw-bold">Sum</td>
                  </React.Fragment>
                ))}
              </tr>
              {dataToRender.map((aff) => (
                <tr key={aff.id}>
                  <td>{aff.name}</td>
                  <td className="text-center b-r-1 fw-bold">{getTotalByAff(aff)}</td>
                  {Object.entries(aff.clients).map(([id, client]) => (
                    <React.Fragment key={id}>
                      <td className="text-center">{client.leads}</td>
                      <td className="text-center">{client.approved}</td>
                      <td className="text-center">{client.price}</td>
                      <td className="text-center b-r-1 fw-bold">{client.summa}</td>
                    </React.Fragment>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default memo(AffiliateProfit);
