import { IAllFields, TypesOfInput } from "../../models/fields/IField";
import { AsyncObject } from "../../models/fields/ISelect";
import { IAffiliate } from "../../models/IAffiliates";
import { IClient } from "../../models/IClients";
import { clientTypeOption, IFilterLead, isBooleanOptions, isTestOptions, statusOptions } from '../../models/ILead';
import { ILocalization } from "../../models/ILocalizations";
import { IStatus } from "../../models/IStatus";
import { ITeam } from "../../models/ITeam";
import { UserRole } from "../../models/IUsers";

interface LeadFiltersParams {
  affiliateOptions: IAffiliate[];
  clientOptions: IClient[];
  teamOptions: ITeam[];
  localizationsOptions: ILocalization[];
  asyncAffiliates: AsyncObject;
  asyncLocalizations: AsyncObject;
  asyncClients: AsyncObject;
  asyncProcessingStatuses: AsyncObject;
  processingStatusesOptions: IStatus[];
  asyncTeams: AsyncObject;
  role: UserRole | undefined;
}

export const leadFilters = ({
  affiliateOptions,
  clientOptions,
  localizationsOptions,
  asyncAffiliates,
  asyncLocalizations,
  asyncClients,
  asyncProcessingStatuses,
  processingStatusesOptions,
  role,
  asyncTeams,
  teamOptions,
}: LeadFiltersParams): IAllFields<IFilterLead>[] => [
  // {
  //   label: "Name",
  //   name: "name",
  //   placeholder: "Name",
  //   type: TypesOfInput.TEXT,
  // },
  // {
  //   label: "Surname",
  //   name: "surname",
  //   placeholder: "Surname",
  //   type: TypesOfInput.TEXT,
  // },
  {
    label: "Phone",
    name: "phone",
    placeholder: "Phone",
    type: TypesOfInput.TEXT,
  },
  {
    label: "Email",
    name: "email",
    placeholder: "Email",
    type: TypesOfInput.TEXT,
  },
  {
    label: "Country",
    name: "country",
    placeholder: "Country",
    type: TypesOfInput.TEXT,
  },
  // {
  //   label: "Landing",
  //   name: "landing",
  //   placeholder: "Landing",
  //   type: TypesOfInput.TEXT,
  // },
  {
    label: "Language",
    name: "language",
    placeholder: "Language",
    type: TypesOfInput.TEXT,
  },
  // {
  //   label: "Url",
  //   name: "url",
  //   placeholder: "Url",
  //   type: TypesOfInput.TEXT,
  // },
  // {
  //   label: "Sub1",
  //   name: "sub1",
  //   placeholder: "Sub1",
  //   type: TypesOfInput.TEXT,
  // },
  // {
  //   label: "Sub2",
  //   name: "sub2",
  //   placeholder: "Sub2",
  //   type: TypesOfInput.TEXT,
  // },
  // {
  //   label: "Sub3",
  //   name: "sub3",
  //   placeholder: "Sub3",
  //   type: TypesOfInput.TEXT,
  // },
  // {
  //   label: "Sub4",
  //   name: "sub4",
  //   placeholder: "Sub4",
  //   type: TypesOfInput.TEXT,
  // },
  // {
  //   label: "Sub5",
  //   name: "sub5",
  //   placeholder: "Sub5",
  //   type: TypesOfInput.TEXT,
  // },
  {
    async: asyncClients,
    label: "Client",
    name: "client",
    options: clientOptions,
    placeholder: "Client",
    type: TypesOfInput.SELECT,
    valueName: "id",
    labelName: "name",
    isMulti: true,
  },
  {
    async: asyncTeams,
    label: "Team",
    name: "team",
    options: teamOptions,
    placeholder: "Team",
    type: TypesOfInput.SELECT,
    valueName: "id",
    labelName: "name",
    isMulti: true,
  },
  {
    isMulti: true,
    async: asyncAffiliates,
    label: "Affiliate",
    name: "affiliate",
    options: affiliateOptions,
    placeholder: "Affiliate",
    type: TypesOfInput.SELECT,
    valueName: "id",
    labelName: "name",
    isHidden: role === "affiliate",
    formatOptionLabel: (option: IAffiliate) => (<span>{option.name} - {option.affiliate}</span>),
  },
  {
    isMulti: true,
    async: asyncLocalizations,
    label: "Localizations",
    name: "localizations",
    options: localizationsOptions,
    placeholder: "Localizations",
    type: TypesOfInput.SELECT,
    valueName: "id",
    labelName: "name",
  },
  {
    label: "Created",
    name: "createdAt",
    placeholder: "Created",
    type: TypesOfInput.DATEPICKER_RANGE,
  },
  {
    label: "Sent at",
    name: "sentAt",
    placeholder: "Sent at",
    type: TypesOfInput.DATEPICKER_RANGE,
  },
  {
    label: "Deposited at",
    name: "depositedAt",
    placeholder: "Deposited at",
    type: TypesOfInput.DATEPICKER_RANGE,
  },
  {
    isMulti: true,
    label: "Status",
    name: "status",
    options: statusOptions,
    placeholder: "Status",
    type: TypesOfInput.SELECT,
  },
  {
    label: "Processing Status",
    name: "processingStatus",
    options: processingStatusesOptions,
    type: TypesOfInput.SELECT,
    valueName: "system",
    labelName: "system",
    async: asyncProcessingStatuses
  },
  {
    label: "Is test",
    name: "isTest",
    options: isTestOptions,
    type: TypesOfInput.SELECT,
  },
  {
    label: "Has deposit",
    name: "hasDeposit",
    options: isBooleanOptions,
    type: TypesOfInput.SELECT,
  },
  {
    label: "Is Valid",
    name: "isValid",
    options: isBooleanOptions,
    type: TypesOfInput.SELECT,
  },
  {
    label: "Is Replace",
    name: "isReplace",
    options: isBooleanOptions,
    type: TypesOfInput.SELECT,
  },
  {
    label: "Client Type",
    name: "clientType",
    options: clientTypeOption,
    type: TypesOfInput.SELECT,
  },
];
