import { TypeOptions } from "../components/common/items/ColorLabel";
import { Modify } from "../types/global";
import { OptionModel } from "./fields/ISelect";
import { IShortAffilate } from "./IAffiliates";

export type IAccessLandingChange = IAccessLandingCreate & {
  clientId: string;
};

export type AccessLandingType = "allow_type" | "disallow_type";

export type IAccessLanding = IAccessLandingCreate & {
  createdAt: string;
  id: string;
  type: AccessLandingType;
};

export type IAccessLandingRequest = Modify<
  Partial<IAccessLandingCreate>,
  {
    affiliateId: string;
    type: string;
  }
>;

export type IAccessLandingCreate = {
  name: string;
  type: OptionModel<TypeOptions<AccessLandingType>>;
  affiliate: OptionModel<IShortAffilate>;
};

export type IAccessLandingCreateRequest = Modify<
  Partial<IAccessLandingCreate>,
  {
    type: string;
    affiliate_id: string;
  }
>;

export type IAccessLandingCreateForm = {
  "landings.name": string;
  "landings.type": AccessLandingType;
  "landings.affiliate": string;
};

export const accessLandingTypeOptions: TypeOptions<AccessLandingType>[] = [
  {
    label: "Allow",
    value: "allow_type",
  },
  {
    label: "Disallow",
    value: "disallow_type",
  },
];
