import * as apis from "../../api/tag-api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ITag, ITagChange } from "../../models/ITag";
import { ListRes } from "../../api/utils";
import { State } from "../reducers/tagsSlice";
import { FetchOptionsParams } from "../../types/global";

export const fetchList = createAsyncThunk<
  ListRes<ITag>,
  FetchOptionsParams,
  { state: { tagsReducer: State } }
>("tags/fetchList", (params: FetchOptionsParams, { getState }) => {
  const state: State = getState().tagsReducer;

  const options = {
    filter: state.filter,
    page: state.pagination.page,
    take: state.pagination.take,
    searchTerm: params.searchTerm,
  };

  if (!params.searchTerm) {
    delete options.searchTerm;
  }
  return apis.getList(options);
});

export const changeItem = createAsyncThunk("tags/changeItem", (data: ITagChange) =>
  apis.changeItem(data),
);

export const fetchItem = createAsyncThunk("tags/fetchItem", (id: string) => apis.getItem(id));

export const deleteItem = createAsyncThunk("tags/deleteItem", (id: string) => apis.deleteItem(id));
