import React from "react";
import cn from "classnames";
import { ChangeEvent } from "react";
import { FormCheck, FormGroup } from "react-bootstrap";
import { TypesOfInput } from "../../../models/fields/IField";

export interface Props {
  value: boolean;
  onChange: (value: boolean) => void;
}

const CheckboxField = ({ value, onChange }: Props) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  return (
    <FormGroup>
      <label className={cn("Checkbox", { "Checkbox--active": value })}>
        <FormCheck onChange={handleChange} type={TypesOfInput.CHECKBOX} checked={Boolean(value)} />
        <div className="Checkbox__lable">{value ? "On" : "Off"}</div>
      </label>
    </FormGroup>
  );
};

export default CheckboxField;
