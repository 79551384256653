import { IAllFields, TypesOfInput } from "../../models/fields/IField";
import { AsyncObject } from "../../models/fields/ISelect";
import { IAffiliate } from "../../models/IAffiliates";
import { IClient, typeOptions } from '../../models/IClients';
import { ILabel } from "../../models/ILabels";
import { isBooleanOptions } from "../../models/ILead";
import { ILocalization } from "../../models/ILocalizations";
import { ClientStatisticReportsFilters, ReplacementsFilters, StatisticReportsFilters } from "../../models/IStatistics";
import { UserRole } from "../../models/IUsers";

interface ReportsParams {
  affiliatesOptions: IAffiliate[];
  clientsOptions: IClient[];
  labelsOptions: ILabel[];
  asyncAffiliates: AsyncObject;
  asyncClients: AsyncObject;
  asyncLabels: AsyncObject;
  localizationsOptions: ILocalization[];
  asyncLocalizations: AsyncObject;
  role: UserRole;
}

export const filterReportsFields = ({
  asyncAffiliates,
  asyncClients,
  asyncLabels,
  affiliatesOptions,
  clientsOptions,
  labelsOptions,
  asyncLocalizations,
  localizationsOptions,
  role,
}: ReportsParams): IAllFields<StatisticReportsFilters>[] => [
  {
    async: asyncAffiliates,
    name: "affiliates",
    options: affiliatesOptions,
    type: TypesOfInput.SELECT,
    labelName: "name",
    valueName: "id",
    isMulti: true,
    formatOptionLabel: (option: IAffiliate) => (
      <span>{option.name} - {option.affiliate}</span>
    )
  },
  {
    async: asyncClients,
    name: "clients",
    options: clientsOptions,
    type: TypesOfInput.SELECT,
    labelName: "name",
    valueName: "id",
    isMulti: true,
    isHidden: role === 'affiliate',
  },
  {
    label: "Client type",
    name: "clientType",
    placeholder: "Client type",
    options: typeOptions,
    type: TypesOfInput.SELECT,
    isMulti: true,
  },
  {
    label: "Has deposit",
    name: "hasDeposit",
    options: isBooleanOptions,
    type: TypesOfInput.SELECT,
  },
  {
    async: asyncLabels,
    name: "labels",
    options: labelsOptions,
    type: TypesOfInput.SELECT,
    labelName: "name",
    valueName: "id",
    isMulti: true,
    isHidden: role === 'affiliate',
  },
  {
    label: "Send at",
    name: "sentAt",
    type: TypesOfInput.DATEPICKER_RANGE,
  },
  {
    label: "Created",
    name: "createdAt",
    type: TypesOfInput.DATEPICKER_RANGE,
  },
  {
    async: asyncLocalizations,
    label: "Localization",
    name: "localization",
    options: localizationsOptions,
    placeholder: "Localization",
    type: TypesOfInput.SELECT,
    valueName: "id",
    labelName: "name",
    isMulti: true,
  },
  {
    label: "Is Valid",
    name: "isValid",
    options: isBooleanOptions,
    type: TypesOfInput.SELECT,
  },
  {
    label: "Is Replace",
    name: "isReplace",
    options: isBooleanOptions,
    type: TypesOfInput.SELECT,
  },
];

interface ReplacementsParams {
  affiliatesOptions: IAffiliate[];
  asyncAffiliates: AsyncObject;
  localizationsOptions: ILocalization[];
  asyncLocalizations: AsyncObject;
}

export const filterReplacementsFields = ({
  asyncAffiliates,
  affiliatesOptions,
  asyncLocalizations,
  localizationsOptions,
}: ReplacementsParams): IAllFields<ReplacementsFilters>[] => [
  {
    async: asyncAffiliates,
    name: "affiliate",
    options: affiliatesOptions,
    type: TypesOfInput.SELECT,
    labelName: "name",
    valueName: "id",
    formatOptionLabel: (option: IAffiliate) => (
      <span>{option.name} - {option.affiliate}</span>
    )
  },
  {
    label: "Client type",
    name: "clientType",
    placeholder: "Client type",
    options: typeOptions,
    type: TypesOfInput.SELECT,
  },
  {
    label: "Send at",
    name: "sentAt",
    type: TypesOfInput.DATEPICKER_RANGE,
  },
  {
    async: asyncLocalizations,
    label: "Localization",
    name: "localization",
    options: localizationsOptions,
    placeholder: "Localization",
    type: TypesOfInput.SELECT,
    valueName: "id",
    labelName: "name",
    isMulti: true,
  },
];


interface ClientReportsParams {
  affiliatesOptions: IAffiliate[];
  asyncAffiliates: AsyncObject;
}

export const filterClientReportsFields = ({
  asyncAffiliates,
  affiliatesOptions,
}: ClientReportsParams): IAllFields<ClientStatisticReportsFilters>[] => [
  {
    async: asyncAffiliates,
    name: "affiliates",
    options: affiliatesOptions,
    type: TypesOfInput.SELECT,
    labelName: "name",
    valueName: "id",
    isMulti: true,
    formatOptionLabel: (option: IAffiliate) => (
      <span>{option.name} - {option.affiliate}</span>
    )
  },
  {
    label: "Has deposit",
    name: "hasDeposit",
    options: isBooleanOptions,
    type: TypesOfInput.SELECT,
  },
  {
    label: "Send at",
    name: "sentAt",
    type: TypesOfInput.DATEPICKER_RANGE,
  },
  {
    label: "Is Valid",
    name: "isValid",
    options: isBooleanOptions,
    type: TypesOfInput.SELECT,
  },
  {
    label: "Is Replace",
    name: "isReplace",
    options: isBooleanOptions,
    type: TypesOfInput.SELECT,
  },
];
