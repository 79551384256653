import React, { useEffect } from "react";
import PageTitle from "../../components/common/items/page-title";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { fetchList } from "../../store/thunks/applications";
import ApplicationsTable from "../../components/applications/ApplicationsTable";

const List = () => {
  const dispatch = useAppDispatch();
  const { list } = useAppSelector((state) => state.applicationsSlice);

  useEffect(() => {
    dispatch(fetchList());
  }, []);

  return (
    <>
      <div className="container-fluid px-lg-4 px-xl-5">
        <PageTitle title="Applications" />
        <div className="row">
          <section className="mb-5">
            <div className="card">
              <div className="card-body">
                <ApplicationsTable className="min-w-screen-lg" data={list.data} />
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default List;
