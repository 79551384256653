import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import FilterForm from "../../components/common/form/formik/FilterForm";
import TagsTable from "../../components/tags/TagsTable";
import PageTitle from "../../components/common/items/page-title";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { tagsSlice } from "../../store/reducers/tagsSlice";
import { fetchList } from "../../store/thunks/tag";
import { ITagFilters, emptyFilters } from "../../models/ITag";
import filterFields from "../../components/fields/filters-tags";

const List = () => {
  const dispatch = useAppDispatch();
  const { filter } = useAppSelector((state) => state.tagsReducer);
  const { setFilters, resetFilters } = tagsSlice.actions;

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchList({}));
    return () => {dispatch(setFilters(emptyFilters))}
  }, []);

  const handleFilter = (values: ITagFilters) => {
    dispatch(setFilters(values));
    dispatch(fetchList({}));
  };

  const fields = useMemo(() => filterFields(), []);

  return (
    <>
      <div className="container-fluid px-lg-4 px-xl-5">
        <PageTitle title={"Tags"} />
        <div className="row">
          <div className="d-flex mb-3 justify-between">
            <div>
              <Button variant="primary" onClick={() => setIsFilterOpen((prevState) => !prevState)}>
                {isFilterOpen ? "Close" : "Open"} Filters
              </Button>
              <Link className="btn btn-primary" to="/tags/new">
                Create tag
              </Link>
            </div>
          </div>
          {isFilterOpen && (
            <FilterForm
              className="mb-3"
              fields={fields}
              handleSubmit={handleFilter}
              handleReset={() => dispatch(resetFilters())}
              initialValues={filter}
            />
          )}
          <section className="mb-5">
            <div className="card">
              <div className="card-body">
                <TagsTable className="min-w-screen-lg" />
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default List;
