import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { camelizeKeys, decamelizeKeys } from "humps";
import { RequestList } from "../components/common/tables/types";
import {
  ClientsStatistic,
  DiagramFilter,
  ValidReplaceDiagram,
  StatusDiagram,
  IStatisticAffiliates,
  IStatisticClients,
  IStatisticDeposits,
  LeadsStatistic,
  StatisticRequest,
  AffiliateProfitType,
} from "../models/IStatistics";
import { baseURL, filterEmpyValues, ListRes, ListResWithoutPag, prepareHeaders } from "./utils";
const baseUrl = `${baseURL}/v1/statistics/reports`;

export const statisticsReportsApi = createApi({
  reducerPath: "statisticsReportsApi",
  tagTypes: ["StatisticsReports"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    getAffiliates: build.mutation<IStatisticAffiliates, StatisticRequest>({
      query: (filter) => ({
        url: "affiliates",
        method: "post",
        body: decamelizeKeys(filter),
      }),
      transformResponse: (response: { list: IStatisticAffiliates }) => response.list,
    }),
    getClients: build.mutation<IStatisticClients[], StatisticRequest>({
      query: (filter) => ({
        url: "clients",
        method: "post",
        body: decamelizeKeys(filter),
      }),
      transformResponse: (response: ListResWithoutPag<IStatisticClients>) => response.list,
    }),
    getClientsStatistic: build.query<ClientsStatistic, StatisticRequest>({
      query: (fliter) => ({
        url: "statuses",
        method: "post",
        body: decamelizeKeys(filterEmpyValues(fliter)),
      }),
      transformResponse: (response: { list: ClientsStatistic }) => response.list,
    }),
    getLeadsStatistic: build.query<ListRes<LeadsStatistic>, RequestList>({
      query: (fliter) => ({
        url: "leads",
        method: "post",
        body: decamelizeKeys(filterEmpyValues(fliter)),
      }),
      transformResponse: (response: ListRes<LeadsStatistic>) =>
        camelizeKeys(response) as ListRes<LeadsStatistic>,
    }),
    getDeposits: build.mutation<IStatisticDeposits[], StatisticRequest>({
      query: (filter) => ({
        url: "deposits",
        method: "post",
        body: decamelizeKeys(filter),
      }),
      transformResponse: (response: ListResWithoutPag<IStatisticDeposits>) => response.list,
    }),
    getDiagramStatus: build.mutation<StatusDiagram[], DiagramFilter>({
      query: (filter) => ({
        url: "diagram/status",
        method: "post",
        body: decamelizeKeys(filter),
      }),
      transformResponse: (response: ListResWithoutPag<StatusDiagram>) => camelizeKeys(response.list) as StatusDiagram[],
    }),
    getDiagramValidReplace: build.mutation<ValidReplaceDiagram[], DiagramFilter>({
      query: (filter) => ({
        url: "diagram/valid-replace",
        method: "post",
        body: decamelizeKeys(filter),
      }),
      transformResponse: (response: ListResWithoutPag<ValidReplaceDiagram>) => camelizeKeys(response.list) as ValidReplaceDiagram[],
    }),
    getAffiliateProfit: build.mutation<AffiliateProfitType[], StatisticRequest>({
      query: (filter) => ({
        url: "affiliate-profit",
        method: "post",
        body: decamelizeKeys(filter),
      }),
      transformResponse: (response: ListResWithoutPag<AffiliateProfitType>) => camelizeKeys(response.list) as AffiliateProfitType[],
    }),
  }),
});

export const {
  useGetAffiliatesMutation,
  useGetClientsMutation,
  useGetDepositsMutation,
  useGetClientsStatisticQuery,
  useLazyGetClientsStatisticQuery,
  useLazyGetLeadsStatisticQuery,
  useGetLeadsStatisticQuery,
  useGetDiagramStatusMutation,
  useGetDiagramValidReplaceMutation,
  useGetAffiliateProfitMutation
} = statisticsReportsApi;
