import React, { useRef, useState } from "react";
import { FormikProps } from "formik";
import { Button, Modal } from "react-bootstrap";
import { useAppSelector } from "../../hooks/redux";
import { IClientClone, UpdateSomeClientsActions } from "../../models/IClients";
import MassEditModal from "./modals/MassEditModal";
import CloneClientModal from "./modals/CloneClientModal";
import { useParams } from "react-router-dom";

type ActionType = "Change status" | "Clone client";

const Actions = () => {
  const { selectedItems } = useAppSelector((state) => state.clientsReducer);
  const formikRef: React.Ref<FormikProps<IClientClone | UpdateSomeClientsActions>> | null =
    useRef(null);
  const [actionType, setActionType] = useState<ActionType | null>(null);

  const actions: {
    name: ActionType;
    disabled: boolean;
  }[] = [
    {
      name: "Change status",
      disabled: !selectedItems.length,
    },
    {
      name: "Clone client",
      disabled: selectedItems.length !== 1,
    },
  ];

  const handleOpenModal = (type: ActionType) => {
    setActionType(type);
  };

  const handleCloseModal = () => setActionType(null);

  const renderModalForm = () => {
    switch (actionType) {
      case "Change status":
        return (
          <MassEditModal
            onClose={handleCloseModal}
            formikRef={formikRef as React.Ref<FormikProps<UpdateSomeClientsActions>>}
          />
        );

      case "Clone client":
        return (
          <CloneClientModal
            onClose={handleCloseModal}
            clientId={selectedItems[0]}
            formikRef={formikRef as React.Ref<FormikProps<IClientClone>>}
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      {actions.map(({ disabled, name }) => (
        <Button
          key={name}
          variant="primary"
          onClick={() => handleOpenModal(name)} disabled={disabled}
          className="mb-2"
        >
          {name}
        </Button>
      ))}
      <Modal show={!!actionType} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Change clients</Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderModalForm()}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={() => formikRef.current?.handleSubmit()}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Actions;
