import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import Field from "../../components/common/form/formik/Field";
import PageTitle from "../../components/common/items/page-title";
import Loading from "../_layouts/Loading";
import { emptyValues, ITelegramChatUpdate } from "../../models/ITelegramChats";
import { changeTelegramChat, fetchOneTelegramChat } from "../../store/thunks/telegramChats";
import {
  editTelegramChatFields,
  editTelegramChatValidation,
} from "../../components/fields/edit-telegramChat";
import { deleteTelegramChat } from "../../api/telegramChats-api";
import { fetchShortClients } from "../../store/thunks/clients";
import { getValuesFromModel } from "../../api/utils";
import { FetchOptionsParams } from "../../types/global";
import useModal from "../../hooks/useModal";

const EditTelegramChat = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentTelegramChat } = useAppSelector((state) => state.telegramChatsReducer);
  const { id } = useParams();
  const { clientsShort } = useAppSelector((state) => state.clientsReducer);
  const [initialValues, setInitialValues] = useState<ITelegramChatUpdate>(emptyValues);

  useEffect(() => {
    if (!id) return;
    dispatch(fetchOneTelegramChat(id));
  }, []);

  const handleDeleteTelegramChat = () => {
    if (currentTelegramChat.data) {
      void deleteTelegramChat(currentTelegramChat.data.id);
      navigate("/telegram-chats");
    }
  };

  const { ModalComp, handleShowModal } = useModal(handleDeleteTelegramChat);

  useEffect(() => {
    if (!currentTelegramChat.loading && currentTelegramChat.data) {
      setInitialValues(
        getValuesFromModel<ITelegramChatUpdate>(currentTelegramChat.data, emptyValues),
      );
    }
  }, [currentTelegramChat]);

  const handleSubmit = (values: ITelegramChatUpdate) => {
    if (currentTelegramChat.data?.id) {
      dispatch(changeTelegramChat({ ...values, id: currentTelegramChat.data.id }));
    }
  };

  if (currentTelegramChat.loading) {
    return <Loading />;
  }

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <div className="d-flex justify-between">
          <Link to="/telegram-chats" className="w-auto">
            <Button className="w-auto mb-3" variant="primary">
              Back
            </Button>
          </Link>
          <Button className="w-auto mb-3" variant="danger" onClick={handleShowModal}>
            Delete Telegram Chat
          </Button>
        </div>
        <PageTitle title={currentTelegramChat.data?.name} />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <p className="card-title">Edit Mode</p>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={editTelegramChatValidation}
                onSubmit={handleSubmit}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {editTelegramChatFields({
                      asyncClients: {
                        fetchCallback: (params: FetchOptionsParams) =>
                          dispatch(fetchShortClients(params)),
                        isLoading: clientsShort.loading,
                      },
                      clientOptions: clientsShort.data,
                    }).map((field) => (
                      <Field key={field.name} {...field} />
                    ))}
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                  >
                    Save
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
      </div>
      {ModalComp}
    </div>
  );
};

export default EditTelegramChat;
