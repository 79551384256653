import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import Field from "../../components/common/form/formik/Field";
import { toast } from "react-toastify";
import PageTitle from "../../components/common/items/page-title";
import Loading from "../_layouts/Loading";
import { ILocalizationChange } from "../../models/ILocalizations";
import { changeLocalization, fetchOneLocalization } from "../../store/thunks/localizations";
import { deleteLocalization } from "../../api/localizations-api";
import {
  createLocalizationFields,
  createLocalizationValidation,
} from "../../components/fields/create-localization";
import useModal from "../../hooks/useModal";

const emptyValues: ILocalizationChange = {
  location: "",
  name: "",
  country: "",
  language: "",
};

const EditLocalization = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentLocalization } = useAppSelector((state) => state.localizationsReducer);
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState(emptyValues);

  useEffect(() => {
    if (!id) return;
    dispatch(fetchOneLocalization(id));
  }, []);

  const handleDeleteLocalization = () => {
    if (currentLocalization.data) {
      deleteLocalization(currentLocalization.data.id);
      navigate("/localizations");
    }
  };

  const { ModalComp, handleShowModal } = useModal(handleDeleteLocalization);

  useEffect(() => {
    if (!currentLocalization.loading && currentLocalization.data) {
      setInitialValues(currentLocalization.data as unknown as ILocalizationChange);
    }
  }, [currentLocalization]);

  const handleSubmit = (values: ILocalizationChange) => {
    if (!currentLocalization.data?.id) {
      toast.error("Missing user id!");
      return;
    }
    dispatch(changeLocalization({ ...values, id: currentLocalization.data.id }));
  };

  if (currentLocalization.loading) {
    return <Loading />;
  }

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <div className="d-flex justify-between">
          <Link to="/localizations" className="w-auto">
            <Button className="w-auto mb-3" variant="primary">
              Back
            </Button>
          </Link>
          <Button className="w-auto mb-3" variant="danger" onClick={handleShowModal}>
            Delete Localization
          </Button>
        </div>
        <PageTitle title={currentLocalization.data?.name} />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <p className="card-title">Edit Mode</p>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={createLocalizationValidation}
                onSubmit={handleSubmit}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {createLocalizationFields.map((field) => (
                      <Field key={field.name} {...field} />
                    ))}
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                  >
                    Save
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
      </div>
      {ModalComp}
    </div>
  );
};

export default EditLocalization;
