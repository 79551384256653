import { ShortModel } from "../api/utils";
import { TypeOptions } from "../components/common/items/ColorLabel";
import { PaginationQuery } from "../components/common/tables/types";
import { ArrayOfDate, LabelColors, Modify } from "../types/global";
import { OptionModel } from "./fields/ISelect";
import { IAffiliate } from "./IAffiliates";
import { ClientType, IClient } from "./IClients";
import { IIntegrationModel } from "./IIntegration";
import { ILocalization } from "./ILocalizations";
import { ILog } from "./ILog";
import { IStatus, SystemsList } from "./IStatus";
import { ITeam } from "./ITeam";

export type LeadStatus =
  | "approved"
  | "failed"
  | "duplicate"
  | "on_check"
  | "created"
  | "hold"
  | "success"
  | "waiting"
  | "out_of_time"
  | "send";
export type HistoryStatus = "success" | "created" | "send";

export type ClientTypeOption = "cpa" | "cpl" | "crg" | null;

export type StatusHistory = {
  client: ShortModel | null;
  updatedAt: number;
  id: string;
  status: HistoryStatus;
};

export type ILead = Modify<
  ILeadChange,
  {
    isSelected?: boolean;
    id: string;
    language: string;
    status: LeadStatus;
    ip: string;
    clickId: string;
    processingStatus: string;
    clientStatus: string | null;
    team: ITeam | null;

    price: number;
    referer: string;
    isTest: 0 | 1;
    isValid: 0 | 1;
    isReplace: 0 | 1;
    redirect: string;

    commentsCount: number;
    processingStatusCount: number;
    logsCount: number;

    client: (ShortModel & { type: ClientType }) | null;
    affiliate: string | null;
    affiliateId: string | null;
    errors: null;

    sentAt: string | null;
    createdAt: string;
    localization: ILocalization | null,
    depositedAt: string | null;
  }
>;

export type ILeadChange = {
  phoneCode: string,
  phoneNumber: string,
  id: string;
  ip: string;
  country: string;
  email: string;
  landing: string;
  source: string;
  name: string;
  phone: string;
  status: TypeOptions<LeadStatus> | null;
  surname: string;
  url: string;
  client: OptionModel<ShortModel>;
  affiliate: OptionModel<IAffiliate> | string,
  localization: OptionModel<ILocalization>,
  team: OptionModel<ITeam>;
  sentAt: null | string;
  isTest: boolean,
  isValid: boolean,
  isReplace: boolean,
  language: string;

  referer: string;
  clickId: string;
  sub1: string;
  sub2: string;
  sub3: string;
  sub4: string;
  sub5: string;

  attempt?: number;
};

export type ILeadRequest = Modify<
  Partial<ILeadChange>,
  {
    clientId: string | null;
    localizationId: string | null;
    teamId: string | null;
    affiliateId: string | null;
    affiliate: string | null;
    status: string;
  }
>;

export type IFilterLead = {
  // name: string;
  // surname: string;
  phone: string;
  email: string;
  // landing: string;
  client: IClient[];
  team: ITeam[];
  affiliate: IAffiliate[];
  country: string;
  // url: string;
  // sub1: string;
  // sub2: string;
  // sub3: string;
  // sub4: string;
  // sub5: string;
  localizations: ILocalization[];
  status: TypeOptions<LeadStatus>[];
  processingStatus: OptionModel<IStatus>;
  sentAt: ArrayOfDate | [Date, Date];
  createdAt: ArrayOfDate | [Date, Date];
  depositedAt: ArrayOfDate | [Date, Date];
  isTest: TypeOptions<boolean | null>,
  isValid: TypeOptions<boolean | null>,
  isReplace: TypeOptions<boolean | null>,
  clientType: TypeOptions<ClientTypeOption>,
  hasDeposit: TypeOptions<boolean | null>,
};

type PartialLeadFields = Partial<Pick<IFilterLead, "localizations" | "affiliate" | "client" | "isTest" | "clientType" | "team">>;
export type IFilterLeadRequest = Modify<
  PartialLeadFields,
  {
    localizationId: string[];
    clientId: string[];
    teamId: string[];
    affiliateId: string[];
    status: string[];
    processingStatus: SystemsList | "";
    hasDeposit: boolean | "",
    isValid: boolean | "",
    isReplace: boolean | "",
    "addition.isTest"?: boolean,
    "addition.url"?: string,
    "client.type"?: string,
    // "addition.sub1"?: string,
    // "addition.sub2"?: string,
    // "addition.sub3"?: string,
    // "addition.sub4"?: string,
    // "addition.sub5"?: string,
  }
>;

export interface RequestLead extends PaginationQuery {
  filter: IFilterLeadRequest;
  searchTerm?: string;
}

export const isTestOptions: TypeOptions<boolean | null>[] = [
  {
    label: "All",
    value: null,
  },
  {
    label: "Is test",
    value: true,
  },
  {
    label: "Not test",
    value: false,
  },
];

export const clientTypeOption: TypeOptions<ClientTypeOption>[] = [
  {
    label: "All",
    value: null,
  },
  {
    label: "CPA",
    value: "cpa",
  },
  {
    label: "CPL",
    value: "cpl",
  },
  {
    label: "CRG",
    value: "crg",
  },
];

export const isBooleanOptions: TypeOptions<boolean | null>[] = [
  {
    label: "All",
    value: null,
  },
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const initialLeadFilters: IFilterLead = {
  localizations: [],
  affiliate: [],
  client: [],
  team: [],
  country: "",
  createdAt: [null, null],
  email: "",
  // landing: "",
  // name: "",
  // surname: "",
  phone: "",
  // url: "",
  // sub1: "",
  // sub2: "",
  // sub3: "",
  // sub4: "",
  // sub5: "",
  isTest: isTestOptions[0],
  depositedAt: [null, null],
  hasDeposit: isBooleanOptions[0],
  isValid: isBooleanOptions[0],
  isReplace: isBooleanOptions[0],
  clientType: clientTypeOption[0],
  sentAt: [null, null],
  status: [],
  processingStatus: null
};

export const statusOptions: TypeOptions<LeadStatus>[] = [
  {
    color: LabelColors.SUCCESS,
    label: "Approved",
    value: "approved",
  },
  {
    color: LabelColors.GRAY,
    label: "Send",
    value: "send",
  },
  {
    color: LabelColors.FAILED,
    label: "Failed",
    value: "failed",
  },
  {
    color: LabelColors.WARNING,
    label: "Duplicate",
    value: "duplicate",
  },
  {
    color: LabelColors.PRIMARY,
    label: "On check",
    value: "on_check",
  },
  {
    color: LabelColors.PRIMARY,
    label: "Created",
    value: "created",
  },
  {
    color: LabelColors.WARNING,
    label: "Hold",
    value: "hold",
  },
  {
    color: LabelColors.SUCCESS,
    label: "Success",
    value: "success",
  },
  {
    color: LabelColors.PINK,
    label: "Waiting",
    value: "waiting",
  },
  {
    color: LabelColors.FAILED,
    label: "Out of time",
    value: "out_of_time",
  },
];

export type UpdateSomeLeadsActions = {
  country?: string;
  language?: string;
  status?: TypeOptions<LeadStatus> | null;
  isValid?: TypeOptions<"1" | "0"> | null;
  isReplace?: TypeOptions<"1" | "0"> | null;
};

export type UpdateSomeLeadsActionsRequest = Modify<
  Partial<UpdateSomeLeadsActions>,
  {
    isValid?: string;
    isReplace?: string;
    status?: string;
  }
>;

export type UpdateSomeLeads = UpdateSomeLeadsActions & {
  ids: string[];
};

export type TestLeadResponse = {
  log: ILog;
  status: boolean;
};

export type TestLeadInitialValues = {
  team: OptionModel<ITeam>;
  integration: OptionModel<IIntegrationModel>;
  isTest: boolean;
  email?: string;
  phoneCode?: string;
  phoneNumber?: string;
  phone?: string;
  ip?: string;
};

export type TestLeadRequest = Partial<TestLeadInitialValues> & {
  teamId: string;
  integrationId: string;
};
