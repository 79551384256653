import { ShortModel } from "../api/utils";
import { TypeOptions } from "../components/common/items/ColorLabel";
import { PaginationQuery } from "../components/common/tables/types";
import { ArrayOfDate, LabelColors, Modify } from "../types/global";
import { OptionModel } from "./fields/ISelect";
import { ILead } from "./ILead";

export type TransactionType =
  "replenishment" |
  "withdrawal" |
  "credit" |
  "loan_repayment" |
  "balance_fix" |
  "deposit_payment" |
  "pay_lead" |
  "replacement";

export type ITransaction = ITransactionChange & {
  id: string;
  balance: number | null;
  createdAt: string;
  type: TransactionType;
};

export type ITransactionChange = {
  amount: number | null;
  type: TypeOptions<TransactionType> | null;
  comment: string;
  client: OptionModel<ShortModel>;
  model: OptionModel<ILead>,
};

type ExtraFields = {
  clientId: string;
  modelId: string,
  type: string;
};

export type ITransactionCreateRequest = Modify<Partial<ITransactionChange>, ExtraFields & { modelType: string, }>
export type IFilterTransactionRequest = Modify<Partial<IFilterTransaction>, ExtraFields>

export type IFilterTransaction = ITransactionChange & {
  balance: string | null;
  createdAt: ArrayOfDate;
};

export const emptyValues: ITransactionChange = {
  amount: null,
  client: null,
  model: null,
  comment: "",
  type: null,
};

export const emptyFilters: IFilterTransaction = {
  ...emptyValues,
  balance: null,
  createdAt: [null, null],
};

export interface RequestTransaction extends PaginationQuery {
  filter: IFilterTransactionRequest
}

export const typeOptions: TypeOptions<TransactionType>[] = [
  {
    color: LabelColors.SUCCESS,
    label: "Пополнение",
    value: "replenishment",
  },
  {
    color: LabelColors.GRAY,
    label: "Оплата за депозиты",
    value: "deposit_payment",
  },
  {
    color: LabelColors.PRIMARY,
    label: "Оплата за лид",
    value: "pay_lead",
  },
  {
    color: LabelColors.FAILED,
    label: "Вывод",
    value: "withdrawal",
  },
  {
    color: LabelColors.WARNING,
    label: "Кредит",
    value: "credit",
  },
  {
    color: LabelColors.SUCCESS,
    label: "Погашение кредита",
    value: "loan_repayment",
  },
  {
    color: LabelColors.PINK,
    label: "Фиксирование баланса",
    value: "balance_fix",
  },
  {
    color: LabelColors.FAILED,
    label: "Замены",
    value: "replacement",
  },
];

export const MODEL_TYPE = "App\\Models\\Lead";
