import * as teamsApi from "../../api/team-api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ITeamChange, ITeamFilterRequest } from "../../models/ITeam";
import { RootState } from "../reducers";
import { State } from "../reducers/teamsSlice";
import { RequestList } from "../../components/common/tables/types";
import { FetchOptionsParams } from "../../types/global";

export const fetchList = createAsyncThunk("teams/fetchList", (params: FetchOptionsParams, thunkAPI) => {
  const rootState: RootState = thunkAPI.getState() as RootState;
  const state: State = rootState.teamsSlice;

  const options: RequestList<ITeamFilterRequest> = {
    filter: state.filter,
    page: state.pagination.page,
    take: state.pagination.take,
    searchTerm: params.searchTerm,
  };

  if (!params.searchTerm) {
    delete options.searchTerm;
  }
  return teamsApi.getList(options);
});

export const changeItem = createAsyncThunk("teams/changeItem", (values: ITeamChange) =>
  teamsApi.changeItem(values),
);

export const fetchItem = createAsyncThunk("teams/fetchItem", (id: string) => teamsApi.getItem(id));

export const deleteItem = createAsyncThunk("teams/deleteItem", (id: string) =>
  teamsApi.deleteItem(id),
);
