import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { PaginationQuery, PaginationType } from "../../components/common/tables/types";
import {
  ITelegramChat,
  ITelegramChatUpdate,
  initialTelegramChatFilters,
} from "../../models/ITelegramChats";
import { FetchedStoreModel, PAGINATION_TAKE } from "../../types/global";
import {
  changeTelegramChat,
  fetchTelegramChats,
  fetchOneTelegramChat,
} from "../thunks/telegramChats";

export interface TelegramChatsState {
  telegramChats: FetchedStoreModel<ITelegramChat[]>;
  currentTelegramChat: FetchedStoreModel<ITelegramChat | null>;
  pagination: PaginationType;
  filter: ITelegramChatUpdate;
}

const initialState: TelegramChatsState = {
  telegramChats: {
    data: [],
    error: null,
    loading: true,
  },
  currentTelegramChat: {
    data: null,
    error: null,
    loading: true,
  },
  pagination: {
    lastPage: null,
    page: 1,
    take: PAGINATION_TAKE,
    total: null,
  },
  filter: initialTelegramChatFilters,
};

/* eslint-disable sort-keys */
export const telegramChatsSlice = createSlice({
  name: "telegramChats",
  initialState,
  reducers: {
    setPagination: (state, action: PayloadAction<PaginationQuery>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    setFilters: (state, action: PayloadAction<ITelegramChatUpdate>) => {
      state.filter = action.payload;
      state.pagination.page = 1;
    },
    resetFilters: (state) => {
      state.filter = initialTelegramChatFilters;
      state.pagination.page = 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTelegramChats.pending, (state) => {
      state.telegramChats.loading = true;
      state.telegramChats.error = null;
    });
    builder.addCase(fetchTelegramChats.fulfilled, (state, action) => {
      state.telegramChats.data = action.payload.list;
      state.telegramChats.loading = false;
      state.pagination = { ...state.pagination, ...action.payload.pagination };
    });
    builder.addCase(fetchTelegramChats.rejected, (state, action) => {
      state.telegramChats.loading = false;
      state.telegramChats.error = action.payload as FixMeLater;
    });

    builder.addCase(fetchOneTelegramChat.pending, (state) => {
      state.currentTelegramChat.loading = true;
      state.currentTelegramChat.error = null;
    });
    builder.addCase(fetchOneTelegramChat.fulfilled, (state, action) => {
      state.currentTelegramChat.data = action.payload.model;
      state.currentTelegramChat.loading = false;
    });
    builder.addCase(fetchOneTelegramChat.rejected, (state, action) => {
      state.currentTelegramChat.loading = false;
      state.currentTelegramChat.error = action.payload as FixMeLater;
    });

    builder.addCase(changeTelegramChat.fulfilled, (state, action) => {
      state.currentTelegramChat.data = action.payload.model;
      toast.success(`Dictionary word ${action.payload.model.name} successfully updated`);
      window.location.pathname = "/telegram-chats";
    });
  },
});

export default telegramChatsSlice.reducer;
