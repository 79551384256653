import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICountry } from "../../models/ILocalizations";
import countriesJson from "../../countries.json";
import { RootState } from ".";

export interface State {
  selectedCountry: ICountry | null;
  openedCountries: ICountry[];
}

const initialState: State = {
  selectedCountry: null,
  openedCountries: [],
};

export const countriesSlice = createSlice({
  name: "countries",
  initialState,
  reducers: {
    setSelectedCountry: (state, action: PayloadAction<ICountry | null>) => {
      state.selectedCountry = action.payload;
    },
    setOpenedCountries: (state, action: PayloadAction<ICountry[]>) => {
      state.openedCountries = action.payload;
    },
    addOpenedCountry: (state, action: PayloadAction<ICountry[]>) => {
      state.openedCountries = [...state.openedCountries, ...action.payload];
    },
    deleteCountry: (state, action: PayloadAction<ICountry>) => {
      state.openedCountries = state.openedCountries.filter(
        (country) => country.name !== action.payload.name,
      );
    },
    setOpenedCountriesByKey: (state, action: PayloadAction<string[]>) => {
      const countries: ICountry[] = countriesJson;
      const selectedCountries = countries.filter((item) => action.payload?.includes(item.code));
      state.openedCountries = selectedCountries;
    },
  },
});

export const getOpenedCountriesCodes = (state: RootState) =>
  state.countriesSlice.openedCountries.map((item) => item.code);

export default countriesSlice.reducer;
