import { LocalizationOption } from "../components/common/form/formik/LocalizationSelect";
import { TypeOptions } from "../components/common/items/ColorLabel";
import { PaginationQuery } from "../components/common/tables/types";
import { ArrayOfDate, LabelColors, Modify, WeekDays } from "../types/global";
import { generateOptions } from "../utils/formats";
import { OptionModel } from "./fields/ISelect";
import {
  IAccessLanding,
  IAccessLandingCreate,
  IAccessLandingCreateRequest,
} from "./IAccessLandings";
import { ILabel } from "./ILabels";
import { ILocalizationShort } from "./ILocalizations";
import { ITeam } from "./ITeam";

export const generalDefaultParameter: Parameter = {
  count: 10,
  endTime: "19:00:00",
  startTime: "10:00:00",
};

export const initialParameters = {
  monday: generalDefaultParameter,
  tuesday: generalDefaultParameter,
  wednesday: generalDefaultParameter,
  thursday: generalDefaultParameter,
  friday: generalDefaultParameter,
  saturday: { ...generalDefaultParameter, count: 0 },
  sunday: { ...generalDefaultParameter, count: 0 },
};

export type ClientStatus = "1" | "0";
export type ClientType = "cpa" | "cpl" | "crg";

export type IClient = Modify<
  IClientChange,
  {
    id: string;
    balance: number;
    type: ClientType;
    createdAt: string;
    credit: number;
    integrationsCount: number;
    teams: ITeam[];
    isSelected?: boolean;
    leadsCount: number;
    link: string;
    label: ILabel | null;
    landings: IAccessLanding[];
    localizations: OptionModel<ILocalizationShort[]>;
  }
>;

export type Parameter = {
  count: number;
  startTime: string;
  endTime: string;
};
export type ParameterKeys = "count" | "startTime" | "endTime";

export type ClientParameters = {
  [key in WeekDays]: Parameter;
};

export type IFilterClient = {
  name: string;
  price: string;
  balance: string;
  credit: string;
  createdAt: ArrayOfDate;
  status: OptionModel<TypeOptions<ClientStatus>>;
  label: OptionModel<ILabel>;
};

export type IFilterClientRequest = Modify<
  Partial<IFilterClient>,
  {
    labelId: string;
    status: string;
  }
>;

export const statusOptions: TypeOptions<ClientStatus>[] = [
  {
    label: "Выкл.",
    value: "0",
  },
  {
    label: "Вкл.",
    value: "1",
  },
];

export const typeOptions: TypeOptions<ClientType>[] = [
  {
    color: LabelColors.SUCCESS,
    label: "CPA",
    value: "cpa",
  },
  {
    color: LabelColors.PRIMARY,
    label: "CPL",
    value: "cpl",
  },
  {
    color: LabelColors.GRAY,
    label: "CRG",
    value: "crg",
  },
];

export const emptyFilters: IFilterClient = {
  createdAt: [null, null],
  label: null,
  name: "",
  price: "",
  balance: "",
  credit: "",
  status: null,
};

export const createEmptyValues: IClientCreate = {
  label: null,
  type: null,
  landings: [],
  localizations: [],
  teams: [],
  name: "",
  parameters: initialParameters,
  price: 0,
  percent: 0,
  status: false,
  acceptHold: false,
  storeReplaceTable: false,
};

export const emptyValues: IClientChange = {
  ...createEmptyValues,
  localizations: [],
  id: "",
  credit: 0,
  balance: 0,
  leads: "",
};

export type IClientCreate = {
  label: OptionModel<ILabel>;
  landings: IAccessLandingCreate[];
  localizations: OptionModel<ILocalizationShort[]>;
  teams: OptionModel<ITeam[]>;
  name: string;
  parameters: ClientParameters;
  type: OptionModel<TypeOptions<ClientType>>;
  price: number;
  percent: number;
  status: boolean;
  acceptHold: boolean;
  storeReplaceTable?: boolean;
};

export type IClientChange = {
  id: string,
  label: OptionModel<ILabel>;
  landings: IAccessLandingCreate[];
  localizations: OptionModel<LocalizationOption[]>;
  teams: OptionModel<ITeam[]>;
  name: string;
  parameters: ClientParameters;
  type: OptionModel<TypeOptions<ClientType>>;
  price: number;
  percent: number;
  status: boolean;
  acceptHold: boolean;
  storeReplaceTable?: boolean;
  credit: number,
  balance: number,
  leads: string,
};

export type IClientChangeRequest = Modify<
  Partial<IClientChange>,
  {
    labelId?: string | null;
    localizations: string[];
    teams: string[];
    type: ClientType | null;
    landings: IAccessLandingCreateRequest[];
  }
>;

export type OrderByFilterType = "name" | "price" | "balance" | "status" | "credit";

export const orderByOptions = generateOptions<OrderByFilterType>(["name", "price", "balance", "status", "credit"]);

export interface RequestClient extends PaginationQuery {
  filter: IFilterClientRequest;
  searchTerm: string;
  orderBy: keyof IClient;
  asc: 0 | 1;
}

export type UpdateSomeClientsActions = {
  status?: TypeOptions<ClientStatus> | null;
};

export type UpdateSomeClientsActionsRequest = Modify<
  Partial<UpdateSomeClientsActions>,
  {
    status: string;
  }
>;

export type UpdateSomeClients = UpdateSomeClientsActions & {
  ids: string[];
};

export type IClientCloneInfo = {
  name: string,
  price: number,
  integrations: {
    id: string,
    name: string,
    url: string,
  }[],
}

export type IClientClone = {
  name: string,
  price: number,
  integration: OptionModel<{
    id: string,
    name: string,
    url: string,
  }>,
  url: string,
}

export type IClientCloneRequest = {
  name: string,
  price: number,
  integrationId: string,
  url: string,
}

export const initialCloneValues: IClientClone = {
  name: "",
  url: "",
  price: 0,
  integration: null,
};
