import React, { useMemo } from "react";
import { IReplacementsStatisticAffiliates, ReplacementsFilters, emptyLead } from "../../../models/IStatistics";
import Loading from "../../_layouts/Loading";

interface Props {
  data?: IReplacementsStatisticAffiliates[];
  isLoading: boolean;
  filter: ReplacementsFilters;
}

const StatisticAffiliates = ({ data, isLoading, filter }: Props) => {
  const countries = useMemo(() => {
    if (!data) return [];
    return Array.from(
      new Set(
        data
          .map((affiliate) => Object.keys(affiliate.leads))
          .flat(),
      ),
    ).sort();
  }, [data]);

  const affiliatesData = useMemo(() => {
    if (!data) return [];
    return data.map((affiliate) => {
      let filledLeads: IReplacementsStatisticAffiliates["leads"] = {};
      countries.forEach((country) => {
        filledLeads[country] = emptyLead;
      });
      filledLeads = { ...filledLeads, ...affiliate.leads };
      return { ...affiliate, leads: filledLeads };
    });
  }, [data, countries]);

  if (isLoading || !data) return <Loading />;

  return (
    <div className="mb-4 card">
      <div className="card-header py-3">
        <p className="card-heading">Affiliates</p>
      </div>
      <div className="card-body table-responsive">
        <table className="card-text table overflow-auto">
          <thead>
            <tr>
              <th />
              {countries.map((item) => (
                <th key={item} colSpan={3} className="text-center b-r-1">{item.toUpperCase()}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td />
              {countries.map((_, index) => (
                <React.Fragment key={index}>
                  <td className="text-center">Total</td>
                  <td className="text-center">Replace</td>
                  <td className="text-center b-r-1">Percent</td>
                </React.Fragment>
              ))}
            </tr>
            {affiliatesData.map((item) => (
              <tr key={item.id}>
                <td>{item.name}</td>
                {Object.values(item.leads).map((lead, index) => (
                  <React.Fragment key={index}>
                    <td className="text-center">{lead.total}</td>
                    <td className="text-center">{lead.approved}</td>
                    <td className="text-center b-r-1">{lead.percent}</td>
                  </React.Fragment>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StatisticAffiliates;
