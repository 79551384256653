import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { ModelRes } from "../../api/utils";
import PageTitle from "../../components/common/items/page-title";
import IntegrationsTable from "../../components/integrations/IntegrationsTable";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { IClient } from "../../models/IClients";
import { fetchOneClient } from "../../store/thunks/clients";
import { FulfilledAction } from "../../store/thunks/createAppThunk";
import { createQuickIntegration, fetchIntegrations } from "../../store/thunks/integrations";

const Integrations = () => {
  const { currentClient } = useAppSelector((state) => state.clientsReducer);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (!id) return;
    dispatch(fetchIntegrations({ filter: { id } }));
  }, []);

  const handleCreateQuickIntegration = async () => {
    let clientName = currentClient.data?.name;
    if (!id) return;
    setIsDisabled(true);
    if (!clientName) {
      const result = (await dispatch(fetchOneClient(id))) as unknown as FulfilledAction<
        unknown,
        ModelRes<IClient>
      >;
      clientName = result.payload.model.name;
    }
    dispatch(
      createQuickIntegration({
        clientId: id,
        name: clientName,
      }),
    ).then(() => setIsDisabled(false));
  };

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <Link className="w-auto" to="/clients">
          <Button className="w-auto mb-3" variant="primary">
            Back
          </Button>
        </Link>
        <PageTitle title="Integrations" />
        <div className="d-flex mb-3">
          <Link className="btn btn-primary" to="new">
            Create Integration
          </Link>
          <Button variant="primary" disabled={isDisabled} onClick={handleCreateQuickIntegration}>
            Create Quick Sheet
          </Button>
        </div>
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <IntegrationsTable />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Integrations;
