import React, { useMemo, useRef, useState } from "react";
import { Formik, Form, FormikProps } from "formik";
import { Button, Modal } from "react-bootstrap";
import Field from "../form/formik/Field";
import { TypesOfInput } from "../../../models/fields/IField";
import { useAppDispatch } from "../../../hooks/redux";
import { SettingsTableKey } from "../../../models/IUserSettings";
import { changeTableSettings } from "../../../store/thunks/user-settings";

type TypeOfNames = string[];

interface Props {
  pageKey: SettingsTableKey;
  show: boolean;
  onClose: VoidFunction;
  columnsName: TypeOfNames;
  hiddenColumnsName: TypeOfNames;
}

type FormikValue<Model extends UnknownRecord> = { [key in keyof Model]: boolean };

const TableSettings = <Model extends UnknownRecord>({
  pageKey,
  show,
  onClose,
  columnsName,
  hiddenColumnsName,
}: Props) => {
  const dispatch = useAppDispatch();
  const formikRef: React.Ref<FormikProps<FormikValue<Model>>> | null = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (value: FormikValue<Model>) => {
    setIsLoading(true);
    dispatch(changeTableSettings({
      key: pageKey,
      value,
    })).then(() => {
      setIsLoading(false);
      onClose();
    });
  };

  const initialValues = useMemo(
    () =>
      columnsName.reduce(
        (previousValue, currentValue) => ({
          ...previousValue,
          [currentValue]: !hiddenColumnsName.some((item) => item === currentValue),
        }),
        {},
      ) as FormikValue<Model>,
    [show],
  );

  const fields = useMemo(() => Object.keys(initialValues), [columnsName]);

  return (
    <Modal show={show} onHide={onClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} innerRef={formikRef}>
          <Form className="w-100 px-4">
            <div className="row">
              {fields.map((field) => (
                <Field key={field} name={field} type={TypesOfInput.CHECKBOX} />
              ))}
            </div>
          </Form>
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={() => formikRef.current?.handleSubmit()} disabled={isLoading}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TableSettings;
