import * as Yup from "yup";
import { ListItem } from "../types/global";
import { UserRole } from "./IUsers";

export type IAuth = {
  email: string;
  password: string;
};

export type IAuthUser = ListItem<{
  name: string,
  email: string,
  roles: UserRole,
}>

export type IAuthResponse = {
  token: string;
  user: IAuthUser;
};

export type IAuth2FA = {
  data: string,
  requestId: string,
}

export type IAuth2FAForm = {
  code: string,
}

export const emptyValues: IAuth = process.env.REACT_APP_ENV_NAME === "dev" ? {
  email: process.env.REACT_APP_USER_DEV || "",
  password: process.env.REACT_APP_PASSWORD_DEV || "",
} : {
  email: process.env.REACT_APP_USER || "",
  password: process.env.REACT_APP_PASSWORD || "",
};

export const validationSchema = Yup.object<Record<keyof IAuth, Yup.AnySchema>>({
  email: Yup.string().email().required("Required"),
  password: Yup.string().required("Required"),
});

export const superRoles: UserRole[] = [UserRole.ADMIN];
export const superRolesWithPM: UserRole[] = [UserRole.ADMIN, UserRole.PM];
