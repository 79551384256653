import React, { memo, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { ITelegramChat } from "../../models/ITelegramChats";
import { SettingsTableKey } from "../../models/IUserSettings";
import Loading from "../../pages/_layouts/Loading";
import { telegramChatsSlice } from "../../store/reducers/telegramChatsSlice";
import { fetchTelegramChats } from "../../store/thunks/telegramChats";
import Table from "../common/tables/Table";
import getColumnDefsTelegramChats from "./ColumnDefs";

interface Props {
  className?: string;
}

const TelegramChatsTable = ({ className }: Props) => {
  const dispatch = useAppDispatch();
  const { pagination, telegramChats } = useAppSelector((state) => state.telegramChatsReducer);
  const { setPagination } = telegramChatsSlice.actions;

  const onChangePagination = (page: number) => {
    dispatch(setPagination({ page }));
    dispatch(fetchTelegramChats());
  };

  const onChangePerPage = (take: number) => {
    dispatch(setPagination({ take }));
    dispatch(fetchTelegramChats());
  };

  const columns = useMemo(
    () =>
      getColumnDefsTelegramChats({
        pagination,
      }),
    [pagination, telegramChats],
  );

  if (telegramChats.loading) {
    return <Loading />;
  }

  return (
    <Table<ITelegramChat>
      pageKey={SettingsTableKey.TELEGRAM_CHATS}
      changePage={onChangePagination}
      changePageSize={onChangePerPage}
      className={className}
      columns={columns}
      fixed
      items={telegramChats.data}
      pagination={pagination}
    />
  );
};

export default memo(TelegramChatsTable);
