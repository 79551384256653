import React, { useEffect, useState, useMemo, useRef } from "react";
import { useField } from "formik";
import Select, { GroupBase, MultiValue, OptionsOrGroups } from "react-select";
import useDebounce from "../../../../hooks/useDebounce";
import { IAsyncSelect, selectStyles } from "../../../../models/fields/ISelect";

const AsyncMultipleSelect = <T, Option extends { id: string }>(props: IAsyncSelect<T, Option>) => {
  const { async, name, valueName, options } = props;
  const asyncRef = useRef(async);
  const promiseRef = useRef<null | FixMeLater>(null);
  const [field, , helpers] = useField<MultiValue<Option>>(name);
  const { setValue } = helpers;
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce<string>(search, 500);
  const [wasOpened, setWasOpened] = useState(false);
  const [selectOptions, setSelectOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (!debouncedSearch && !wasOpened) return;

    if (asyncRef.current.isLoading) promiseRef.current.abort();
    promiseRef.current = async.fetchCallback({ searchTerm: debouncedSearch, filter: {} });
  }, [debouncedSearch]);

  useEffect(() => {
    if (!options.length) return;
    const filteredOptions = options.filter(
      (option) => !selectOptions.find((selectOption) => selectOption.id === (option as Option & { id: string }).id),
    );
    setSelectOptions((prevState) => [...filteredOptions, ...prevState] as Option[]);
  }, [options]);

  const optionsValues = useMemo(
    () => field.value?.map((option) => option[valueName]) || [],
    [selectOptions, field.value],
  );

  const isOptionsGrouped = (options: OptionsOrGroups<Option, GroupBase<Option>>): options is GroupBase<Option>[] => (
    options.length ? 'options' in options[0] : false
  );

  const handleFetchOptions = () => {
    setWasOpened(true);
    if (!!async && !wasOpened) {
      promiseRef.current = async.fetchCallback({});
    }
  };

  const handleSelectGroup = (groupLabel: GroupBase<Option>) => {
    console.log('aasasdasdasd');
    
    setValue([...findValue(), ...groupLabel.options])
  }

  const findValue = (): MultiValue<Option> => {
    if (!field.value) return [];

    if (isOptionsGrouped(options)) {
      return options
        .map(group => group.options)
        .flat()
        .filter((option) => optionsValues.includes(option[valueName]));
    }

    const filteredOptions = selectOptions.filter((option) =>
      optionsValues.includes(option[valueName]),
    );

    return filteredOptions.length ? filteredOptions : field.value;
  };

  return (
    <Select
      {...field}
      {...props}
      isMulti
      closeMenuOnSelect={false}
      value={field.value}
      onChange={(selectedValue: MultiValue<Option>) => {
        setValue(selectedValue);
      }}
      filterOption={() => true}
      onMenuOpen={handleFetchOptions}
      onInputChange={setSearch}
      isLoading={async.isLoading && wasOpened}
      formatGroupLabel={(group) => (
        <div onClick={() => handleSelectGroup(group)} className="select_option">
          {group.label} ({group.options.length})

        </div>
      )}
      styles={selectStyles}
    />
  );
};

export default AsyncMultipleSelect;
