import cn from "classnames";
import { useState } from "react";
import { Link } from "react-router-dom";
import { IApplications } from "../../models/IApplications";
import { formatDateFullTime, getColumnNumberIndex } from "../../utils/formats";
import { ColumnDef, PaginationType } from "../common/tables/types";
import ApplicationItem from "./ApplicationItem";

type IColumn = ColumnDef<IApplications>;

interface Options {
  pagination: PaginationType;
  isSmall?: boolean;
}

export default function getColumnDefs(options: Options): IColumn[] {
  const { pagination, isSmall } = options;

  const numberIndex: IColumn = {
    Cell: ({ row: { index, original } }: { row: { index: number, original: { id: string } } }) => {
      const [isOpenModal, setIsOpenModal] = useState(false);
      return (
        <>
          <div
            onClick={() => {
              if (isSmall) return;
              setIsOpenModal(true);
            }}
            role="button"
            className={cn("p-1", { "btn-link": !isSmall })}
          >
            {getColumnNumberIndex(pagination, index)}
          </div>
          <ApplicationItem
            id={original.id}
            isOpenModal={isOpenModal}
            setIsOpenModal={setIsOpenModal}
          />
        </>
      );
    },
    Header: "№",
    id: "№",
    disableFilters: true,
    disableSortBy: true,
    width: "25px",
  };

  const name: IColumn = {
    Cell: (cell) => <span>{cell.value ? cell.value : "-"}</span>,
    Header: "Name",
    id: "name",
    accessor: "name",
    width: "50px",
  };

  const surname: IColumn = {
    Cell: (cell) => <span>{cell.value ? cell.value : "-"}</span>,
    Header: "Surname",
    id: "surname",
    accessor: "surname",
    width: "50px",
  };

  const phone: IColumn = {
    Cell: (cell) => <span>{cell.value ? cell.value : "-"}</span>,
    Header: "Phone",
    id: "phone",
    accessor: "phone",
    width: "100px",
  };

  const email: IColumn = {
    Cell: (cell) => <span>{cell.value ? cell.value : "-"}</span>,
    Header: "Email",
    id: "email",
    accessor: "email",
    width: "100px",
  };

  const sessionId: IColumn = {
    Cell: (cell) => <span>{cell.value ? cell.value : "-"}</span>,
    Header: "sessionId",
    id: "sessionId",
    accessor: "sessionId",
    width: "100px",
  };

  const lead: IColumn = {
    Cell: (cell) =>
      cell.value ? <Link to={`/leads/${cell.value.id}`}>{cell.value.name}</Link> : <span>-</span>,
    Header: "Lead",
    id: "lead",
    accessor: "lead",
    width: "50px",
  };

  const createdAt: IColumn = {
    Cell: (cell) => <span>{formatDateFullTime(cell.value)}</span>,
    Header: "Created",
    id: "createdAt",
    accessor: "createdAt",
    width: "95px",
  };

  return isSmall
    ? [numberIndex, name, surname, phone, email, createdAt]
    : [numberIndex, name, surname, phone, email, sessionId, lead, createdAt];
}
