import * as Yup from "yup";
import { IField, TypesOfInput } from "../../models/fields/IField";
import { ITeamChange, ITeamFilter } from "../../models/ITeam";
import { FilterConditionally } from "../../types/global";
import { fieldsConstructor } from "../../utils/formats";

export const createFields: IField<ITeamChange>[] = fieldsConstructor<ITeamChange>(["name"]);

type ValidationFields = string & keyof FilterConditionally<ITeamChange, string>;

export const validationSchema = Yup.object<Record<ValidationFields, Yup.AnySchema>>({
  name: Yup.string().required(),
});

export const filterFields = (): IField<ITeamFilter>[] => [
  ...createFields,
  {
    label: "Created",
    name: "createdAt",
    placeholder: "Created",
    type: TypesOfInput.DATEPICKER_RANGE,
  },
];
