import { createAsyncThunk } from "@reduxjs/toolkit";
import * as telegramChatsApi from "../../api/telegramChats-api";
import { ITelegramChatRequest, ITelegramChatUpdate, RequestTelegramChat } from "../../models/ITelegramChats";
import { TelegramChatsState } from "../reducers/telegramChatsSlice";

export const fetchTelegramChats = createAsyncThunk(
  "telegramChats/fetchTelegramChats",
  (_, thunkAPI) => {
    const state: FixMeLater = thunkAPI.getState();
    const telegramChatsState: TelegramChatsState = state.telegramChatsReducer;

    const filter: ITelegramChatRequest = {
      ...telegramChatsState.filter,
      clientId: telegramChatsState.filter.client?.id || "",
      permissions: telegramChatsState.filter.permissions.map((option) => option.value),
    };
  
    delete filter.client;

    const options: RequestTelegramChat = {
      filter,
      page: telegramChatsState.pagination.page,
      take: telegramChatsState.pagination.take,
    };
    return telegramChatsApi.getTelegramChats(options);
  },
);

export const fetchOneTelegramChat = createAsyncThunk(
  "telegramChats/fetchOneTelegramChat",
  (id: string) => telegramChatsApi.getTelegramChat(id),
);

export const changeTelegramChat = createAsyncThunk(
  "telegramChats/changeTelegramChat",
  (values: ITelegramChatUpdate & { id?: string }) => telegramChatsApi.changeTelegramChat(values),
);
