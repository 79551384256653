import { TypeOptions } from "../components/common/items/ColorLabel";
import { PaginationQuery } from "../components/common/tables/types";
import { LabelColors, Modify, ObjectWithKeys } from "../types/global";
import { OptionModel } from "./fields/ISelect";
import { IIntegrationModel } from "./IIntegration";
import { TokenMethodType } from "./IIntegrationToken";
import { ILead } from "./ILead";

export type LogType = "integration" | "request" | "system";

export type Request = {
  headers: [];
  method: TokenMethodType;
  parameters: ObjectWithKeys;
  url: string;
};

export type ILog = {
  createdAt: string;
  id: string;
  integration: IIntegrationModel | null;
  request: Request;
  response: ObjectWithKeys;
  lead: ILead | null;
  status: string;
  type: LogType;
};

export type IFilterLogs = {
  type: OptionModel<TypeOptions<LogType>>;
  status: string;
  leadId: string;
  integration: OptionModel<IIntegrationModel>;
  id?: string;
};

export type IFilterLogsRequest = Modify<
  Partial<IFilterLogs>,
  {
    type: string;
    integrationId: string;
  }
>;
export interface RequestLogs extends PaginationQuery {
  filter: IFilterLogsRequest;
}

export const initialFilters: IFilterLogs = {
  integration: null,
  leadId: "",
  status: "",
  type: null,
};

export const typeOptions: TypeOptions<LogType>[] = [
  {
    color: LabelColors.FAILED,
    label: "system",
    value: "system",
  },
  {
    color: LabelColors.WARNING,
    label: "request",
    value: "request",
  },
  {
    color: LabelColors.PRIMARY,
    label: "integration",
    value: "integration",
  },
];
