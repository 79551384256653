import { useMemo, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import {
  useLazyGetDashboardAffiliatesListQuery,
  useLazyGetDashboardCapsListQuery,
  useLazyGetDashboardStatusesListQuery,
  useLazyUseGetDashboardDepositsListQuery,
  useLazyUseGetDashboardGroupCapsQuery,
} from "../../api/dashboard-api";
import LeadsStatusCard from "../../components/ui/LeadsStatusCard";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { LeadStatus, isTestOptions, statusOptions } from "../../models/ILead";
import { fetchOneClient } from "../../store/thunks/clients";
import Loading from "../_layouts/Loading";

import { PayloadAction } from "@reduxjs/toolkit";
import { Button } from "react-bootstrap";
import { ModelRes } from "../../api/utils";
import PageTitle from "../../components/common/items/page-title";
import AffiliateCard from "../../components/ui/AffiliateCard";
import CapCard from "../../components/ui/CapCard";
import DepositCard from "../../components/ui/DepositCard";
import GroupCapsCard from "../../components/ui/GroupCapsCard";
import useRefreshPage from "../../hooks/useRefreshPage";
import { IClient } from "../../models/IClients";
import { formatDateToState } from "../../utils/formats";
import { UserRole } from "../../models/IUsers";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const DashboardAdmin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isShowAllAff, setIsShowAllAff] = useState(false);
  const { auth } = useAppSelector((state) => state.authSlice);
  const role = useMemo(() => auth.user?.roles || 'admin', [auth]);

  const [fetchAffiliates, { data: affiliatesList, isLoading: isLoadingAffiliates, isFetching: isRefetchingAffiliates }] = useLazyGetDashboardAffiliatesListQuery();
  const [fetchCaps, { data: capsList, isLoading: isLoadingCaps, isFetching: isRefetchingCaps }] = useLazyGetDashboardCapsListQuery();
  const [fetchStatusees, { data: statusesList, isLoading: isLoadingStatuses, isFetching: isRefetchingStatuses }] = useLazyGetDashboardStatusesListQuery();
  const [fetchDeposits, { data: depositsList, isLoading: isLoadingDeposits, isFetching: isRefetchingDeposits }] = useLazyUseGetDashboardDepositsListQuery();
  const [fetchGroupCaps, capsData] = useLazyUseGetDashboardGroupCapsQuery();

  useRefreshPage(
    () => {
      if (role !== 'affiliate_manager') fetchAffiliates();
      fetchCaps();
      fetchStatusees();
      fetchDeposits();
      fetchGroupCaps();
    },
    [],
    "/dashboard",
  );

  const affiliatesToRender = useMemo(() => {
    if (!affiliatesList) return [];
    const affiliatesToday = affiliatesList?.filter((a) => a.today)
      .sort((a, b) => b.today - a.today);
    const affiliatesMonth = affiliatesList?.filter((a) => !a.today && a.month)
      .sort((a, b) => b.month - a.month);
    const poorAffiliates = affiliatesList?.filter((a) => !a.month);
    const result = [...affiliatesToday, ...affiliatesMonth];
    return isShowAllAff ? [...result, ...poorAffiliates] : result;
  }, [affiliatesList, isShowAllAff]);

  if (
    isLoadingStatuses ||
    isLoadingCaps ||
    (role !== 'affiliate_manager' && isLoadingAffiliates) ||
    isLoadingDeposits ||
    isRefetchingStatuses ||
    isRefetchingCaps ||
    (role !== 'affiliate_manager' && isRefetchingAffiliates) ||
    isRefetchingDeposits
  ) {
    return <Loading />;
  }

  const handleFilterByStatus = (status: LeadStatus) => {
    const selectedStatus = statusOptions.find((option) => option.value === status);
    navigate(`/leads?status=${selectedStatus?.value}&isTest=${isTestOptions[2].value}`);
  };

  const handleFilterByCountry = (country: string) => {
    navigate(`/leads?country=${country}`);
  };

  const handleFilterByClient = (clientId: string) => {
    dispatch(fetchOneClient(clientId)).then((data) => {
      const { payload } = data as PayloadAction<ModelRes<IClient>>;
      navigate({
        pathname: "/leads",
        search: createSearchParams({
          clientId: payload.model.id,
          isTest: `${isTestOptions[2].value}`,
          "sentAt[0]": `${formatDateToState(new Date())}`,
          "sentAt[1]": `${formatDateToState(new Date())}`,
        }).toString(),
      });
    });
  };

  const handleFilterByAffiliate = async (affiliate: string) => {
    const params: {
      'sentAt[0]': string,
      'sentAt[1]': string,
    } = {
      'sentAt[0]': formatDateToState(new Date()) || '',
      'sentAt[1]': formatDateToState(new Date()) || '',
    };
    navigate({
      pathname: '/leads',
      search: createSearchParams(params).toString(),
    });
  };

  const dataHorBar = {
    labels: affiliatesList?.filter((item) => !!item.today).map((item) => item.affiliate) || [],
    datasets: [
      {
        label: "Number of leads",
        backgroundColor: "#4650dd",
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: affiliatesList?.filter((item) => !!item.today).map((item) => item.today) || [],
      },
    ],
  };

  const options = {
    indexAxis: "y" as const,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        ticks: {
          autoSkip: false,
        },
      },
    },
  };

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <section className="mb-3 mb-lg-5">
        <PageTitle title="Statuses" />
        <div className="row">
          {statusesList &&
            statusesList.map((item, index) => (
              <LeadsStatusCard
                key={item.status}
                onClick={handleFilterByStatus}
                item={item}
                index={index}
              />
            ))}
        </div>
      </section>
      {role !== UserRole.AFFILIATE_MANAGER && (
        <GroupCapsCard
          capsData={{
            data: capsData.data,
            isLoading: capsData.isLoading,
            isFetching: capsData.isFetching
          }}
        />
      )}
        <CapCard
          filterByClient={handleFilterByClient}
          // onClick={handleFilterByCountry}
          caps={capsList}
        />
        {role !== UserRole.AFFILIATE_MANAGER && !!affiliatesToRender.length &&
          <section className="mb-3 mb-lg-5">
            <PageTitle title="Affiliates" />
            <div className="row">
                {affiliatesToRender.map((item) => (
                  <AffiliateCard
                    onClick={handleFilterByAffiliate}
                    key={item.affiliate}
                    affiliate={item}
                  />
                ))}
            </div>
            {!isShowAllAff && (
              <Button className="mb-5 w-100" onClick={() => setIsShowAllAff(true)}>
                Show all affiliates
              </Button>
            )}
            <div className="card mb-4">
              <div className="card-header py-4">
                Balance: {affiliatesToRender.reduce((prev, curr) => prev + curr.today, 0)}
              </div>
              <div className="card-body">
                <Bar options={options} data={dataHorBar} />
              </div>
            </div>
          </section>
        }
      {depositsList && <DepositCard list={depositsList} />}
    </div>
  );
};

export default DashboardAdmin;
