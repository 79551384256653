import { IPlayer, IPlayerFiltersRequest } from "../models/IPlayer";
import { Api, filterEmpyValues, ListRes } from "./utils";
import { getResponseData } from "./utils";

const baseURL = "/v1/players";

export const getList = (params: IPlayerFiltersRequest): Promise<ListRes<IPlayer>> => {
  return Api.post(`${baseURL}/list`, filterEmpyValues(params)).then(getResponseData);
};

export const deleteItems = (ids: string[]) => {
  return Api.delete(`${baseURL}/bulk-destroy`, { data: { ids } }).then(getResponseData);
};
