import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { Formik, Form, FormikProps } from "formik";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Field from "../../components/common/form/formik/Field";
import PageTitle from "../../components/common/items/page-title";
import Loading from "../_layouts/Loading";
import { emptyValues, ISettingChange, typeOptions } from "../../models/ISettings";
import { changeItem, deleteItem, fetchItem } from "../../store/thunks/settings";
import { createFields, validationSchema } from "../../components/fields/settings";
import useModal from "../../hooks/useModal";

const ChangeItem = () => {
  const dispatch = useAppDispatch();
  const { currentItem } = useAppSelector((state) => state.settingsSlice);
  const { id: settingId } = useParams();
  const formikRef: React.Ref<FormikProps<ISettingChange>> | null = useRef(null);
  const [initialValues, setInitialValues] = useState<ISettingChange>(emptyValues);

  useEffect(() => {
    if (!settingId) return;
    dispatch(fetchItem(settingId));
  }, []);

  const handleDelete = () => {
    if (!settingId) return;
    dispatch(deleteItem(settingId));
  };

  const { ModalComp, handleShowModal } = useModal(handleDelete);

  useEffect(() => {
    if (settingId && !currentItem.loading && currentItem.data) {
      const data = currentItem.data;
      setInitialValues({
        ...data,
        type: typeOptions.find((option) => option.value === data.type) || null,
      });
    }
  }, [currentItem]);

  const fields = useMemo(() => createFields(), []);
  const validation = useMemo(() => validationSchema(), []);

  const handleSubmit = (values: ISettingChange) => {
    if (settingId) {
      dispatch(changeItem({ id: settingId, ...values }));
    } else {
      dispatch(changeItem(values));
    }
  };

  if (settingId && currentItem.loading) {
    return <Loading />;
  }

  const convertText = (text: string): ReactNode => <b>{text}</b>;

  useEffect(() => {
    toast.info(infoMessage, {
      style: { width: "max-content" },
      position: toast.POSITION.TOP_CENTER,
    });
  }, []);

  const infoMessage = (
    <div>
      <div>utm_campaign - Разбивается на три переменные</div>
      <div>utm_campaign = gp_0a5979bdc5rxYvbySqMpN_alex</div>
      <ol>
        <li>{convertText("{partner}")} - gp</li>
        <li>{convertText("{linkid}")} - 0a5979bdc5rxYvbySqMpN</li>
        <li>{convertText("{sid}")} - alex</li>
        <li>{convertText("{accid}")} - берется с гет параметров</li>
        <li>{convertText("{adname}")} - берется с гет параметров</li>
      </ol>
      <p>
        Пример: https://partner.cabinte.com?sub1={convertText("{partner}")}&amp;id=
        {convertText("{accid}")}&amp;campaign={convertText("{adname}")}
      </p>
    </div>
  );

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <div className="d-flex">
          <Link to="/settings" className="w-auto">
            <Button className="w-auto mb-3" variant="primary">
              Back
            </Button>
          </Link>
          {settingId && (
            <Button className="w-auto mb-3" variant="danger" onClick={handleShowModal}>
              Delete
            </Button>
          )}
        </div>
        <PageTitle title={`${settingId ? "Edit" : "Create"} setting`} />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validation}
                innerRef={formikRef}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {fields.map((field) => (
                      <Field key={field.name} {...field} />
                    ))}
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                  >
                    Save
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
      </div>
      {ModalComp}
    </div>
  );
};

export default ChangeItem;
