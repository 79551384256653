import {
  IDictionaryWord,
  IDictionaryChange,
  RequestDictionaryWord,
  IDictionaryChangeRequest,
} from "../models/IDictionaryWords";
import { Api, filterEmpyValues, ListRes, ModelRes } from "./utils";
import { getResponseData } from "./utils";

const PATH = "/v1/dictionary-words";

export const getDictionaryWords = (
  params: RequestDictionaryWord,
): Promise<ListRes<IDictionaryWord>> => (
  Api.post(`${PATH}/list`, filterEmpyValues(params)).then(getResponseData)
);

export const getDictionaryWord = (id: string): Promise<ModelRes<IDictionaryWord>> => {
  return Api.get(`${PATH}/${id}`).then(getResponseData);
};

export const changeDictionaryWord = (data: IDictionaryChange & { id?: string }) => {
  const sendingData: IDictionaryChangeRequest = {
    ...data,
    type: data.type?.value || "",
  };
  return Api.post(PATH, sendingData).then(getResponseData);
};

export const deleteDictionaryWord = (id: string) => {
  return Api.delete(`${PATH}/${id}`);
};
