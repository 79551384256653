import { PayloadAction } from "@reduxjs/toolkit";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { Button, Col, FormControl, Row } from "react-bootstrap";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { deleteLeads } from "../../api/leads-api";
import { filterEmpyValues, ListRes, ModelRes } from "../../api/utils";
import FilterForm from "../../components/common/form/formik/FilterForm";
import Icon from "../../components/common/items/Icon";
import PageTitle from "../../components/common/items/page-title";
import { leadFilters } from "../../components/fields/filters-lead";
import LeadsTable from "../../components/leads/LeadsTable";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import useDebounce from "../../hooks/useDebounce";
import useModal from "../../hooks/useModal";
import useRefreshPage from "../../hooks/useRefreshPage";
import { TypesOfInput } from "../../models/fields/IField";
import { IAffiliate } from "../../models/IAffiliates";
import { superRoles, superRolesWithPM } from "../../models/IAuth";
import { IClient } from "../../models/IClients";
import { IFilterLead, initialLeadFilters, isBooleanOptions, isTestOptions, statusOptions } from "../../models/ILead";
import { ILocalization } from "../../models/ILocalizations";
import { IStatus } from "../../models/IStatus";
import { UserRole } from "../../models/IUsers";
import { leadsSlice } from "../../store/reducers/leadsSlice";
import { localizationsSlice } from "../../store/reducers/localizationsSlice";
import { fetchOneAffiliate, fetchShortAffiliates } from "../../store/thunks/affiliates";
import { fetchOneClient, fetchShortClients } from "../../store/thunks/clients";
import { fetchLeads } from "../../store/thunks/leads";
import { fetchLocalizations } from "../../store/thunks/localizations";
import { fetchGroupList, fetchList } from "../../store/thunks/statuses";
import { fetchList as fetchTeams } from "../../store/thunks/teams";
import { FetchOptionsParams } from "../../types/global";
import { formatDate, stringToDate } from "../../utils/formats";
import MassEditLeads from "./MassEditLeads";

const Leads = () => {
  const { search: locationSearch } = useLocation();
  const dispatch = useAppDispatch();
  const { auth } = useAppSelector((state) => state.authSlice);
  const { leads, filter, searchTerm, selectedItems } = useAppSelector(
    (state) => state.leadsReducer,
  );
  const { affiliatesShort } = useAppSelector((state) => state.affiliatesReducer);
  const { localizations, filter: localizationsFilter } = useAppSelector((state) => state.localizationsReducer);
  const { clientsShort } = useAppSelector((state) => state.clientsReducer);
  const { list: teamList } = useAppSelector((state) => state.teamsSlice);
  const { groupList: statusesGroupList } = useAppSelector((state) => state.statusesSlice);
  const { setFilters: setLocalizationsFilters } = localizationsSlice.actions;
  const { setFilters, setSearchTerm, resetFilters, setOrderBy, resetFetchedCount } = leadsSlice.actions;
  const [searchParams, setSearchParams] = useSearchParams();

  const role = useMemo(() => auth.user?.roles ?? UserRole.ADMIN, [auth]);

  const handleSetFiltersBySearch = async () => {
    const params: Record<string, string> = {};
    searchParams.forEach((value, key) => params[key as (keyof IFilterLead)] = value);
    const values: IFilterLead = {
      ...initialLeadFilters,
      email: params.email,
      country: params.country,
    };

    if (params.processingStatus) {
      const { payload } = await dispatch(fetchList({ filter: { system: params.processingStatus } })) as PayloadAction<ListRes<IStatus>>;
      const selectedStatus = payload.list.find(
        (option) => option.system.toLocaleLowerCase() === "deposit",
      );
      values.processingStatus = selectedStatus ?? null;
    }
    if (params.status) {
      const selectedStatus = statusOptions.find((option) => option.value === params.status);
      values.status = selectedStatus ? [selectedStatus] : [];
    }
    if (params.isTest) {
      const selectedOption = isTestOptions.find((option) => `${option.value}` === params.isTest);
      values.isTest = selectedOption ?? isTestOptions[0];
    }
    if (params.hasDeposit) {
      const selectedOption = isBooleanOptions.find((option) => `${option.value}` === params.hasDeposit);
      values.hasDeposit = selectedOption ?? isBooleanOptions[0];
      dispatch(setOrderBy({ orderBy: "depositedAt", order: "ASC" }));
    }
    if (params.isValid) {
      const selectedOption = isBooleanOptions.find((option) => `${option.value}` === params.isValid);
      values.isValid = selectedOption ?? isBooleanOptions[0];
    }
    if (params.isReplace) {
      const selectedOption = isBooleanOptions.find((option) => `${option.value}` === params.isReplace);
      values.isReplace = selectedOption ?? isBooleanOptions[0];
    }
    if (params["sentAt[0]"] !== "null" && params["sentAt[1]"] !== "null") {
      values.sentAt = [params["sentAt[0]"], params["sentAt[1]"]];
    }
    if (params.clientId) {
      const { payload } = await dispatch(fetchOneClient(params.clientId)) as PayloadAction<ModelRes<IClient>>;
      values.client = [payload.model];
    }
    if (params.localizationIds) {
        await dispatch(setLocalizationsFilters({ ...localizationsFilter, id: params.localizationIds }));
        const { payload } = await dispatch(fetchLocalizations({ filter: { id: params.localizationIds } })) as PayloadAction<ListRes<ILocalization>>;
        values.localizations = payload.list;
    }
    if (params.affiliateId) {
      const { payload } = await dispatch(fetchOneAffiliate(params.affiliateId)) as PayloadAction<ModelRes<IAffiliate>>;
      values.affiliate = [payload.model];
    }

    handleFilter(values);
  };

  const [isShowModal, setIsShowModal] = useState(false);

  const handleCloseModal = () => {
    setIsShowModal(false);
  };

  const handleShowModal = () => {
    setIsShowModal(true);
  };

  const [search, setSearch] = useState(searchTerm);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const debouncedSearch = useDebounce<string>(search, 500);

  useRefreshPage(() => {
    handleSetFiltersBySearch();
    return () => {
      dispatch(setFilters(initialLeadFilters));
      dispatch(setSearchTerm(""));
    };
  }, [], "/leads");

  const handleDeleteLeads = async () => {
    await deleteLeads(selectedItems);
    requestLeads();
  };

  const { ModalComp, handleShowModal: handleShowDeleteModal } = useModal(handleDeleteLeads);

  const filtersToRender = useMemo(() => {
    const localFilter = { ...filter } as Partial<IFilterLead>;
    delete localFilter.sentAt;
    delete localFilter.createdAt;
    delete localFilter.depositedAt;

    const filtered = filterEmpyValues({
      ...localFilter,
      client: filter.client.map((item) => item.name),
      status: filter.status.map((item) => item.label),
      affiliate: filter.affiliate.map((item) => item.name),
      localizations: filter.localizations.map((item) => item.name),
      processingStatus: filter.processingStatus?.system,
      isTest: filter.isTest?.value,
      isValid: filter.isValid?.value,
      hasDeposit: filter.hasDeposit?.value,
      isReplace: filter.isReplace?.value,
      clientType: filter.clientType?.value,
    });

    if (filter.sentAt[0]) {
      filtered.sentAt = `${formatDate(stringToDate(filter.sentAt[0] as string))} - ${formatDate(stringToDate(filter.sentAt[1] as string))}`;
    }
    if (filter.createdAt[0]) {
      filtered.createdAt = `${formatDate(stringToDate(filter.createdAt[0] as string))} - ${formatDate(stringToDate(filter.createdAt[1] as string))}`;
    }
    if (filter.depositedAt[0]) {
      filtered.depositedAt = `${formatDate(stringToDate(filter.depositedAt[0] as string))} - ${formatDate(stringToDate(filter.depositedAt[1] as string))}`;
    }

    return filtered;
  }, [filter]);

  const requestLeads = () => dispatch(fetchLeads({}));

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    dispatch(fetchGroupList());
    return () => { dispatch(resetFetchedCount()) };
  }, []);

  useEffect(() => {
    if (!leads.data.length && !debouncedSearch) {
      return;
    }
    dispatch(setSearchTerm(search));
    if (leads.fetchedCount) {
      requestLeads();
    }
  }, [debouncedSearch]);

  const handleFilter = (values: IFilterLead) => {
    if (values.hasDeposit.value) dispatch(setOrderBy({ orderBy: "depositedAt", order: "ASC" }));
    dispatch(setFilters(values));
    return requestLeads();
  };

  const handleDeleteFilter = (key: keyof IFilterLead) => {
    const newFilter = {
      ...filter,
      [key]: initialLeadFilters[key],
    };

    if (key === 'localizations') searchParams.delete('localizationIds');
    searchParams.delete(key);

    setSearchParams(searchParams);
    handleDeleteFilterFromSearch(key);
    handleFilter(newFilter);
  };

  const handleDeleteFilterFromSearch = (key: keyof IFilterLead) => {
    switch (key) {
      case "client":
        searchParams.delete("clientId");
        break;

      case "sentAt":
        searchParams.delete("sentAt[0]");
        searchParams.delete("sentAt[1]");
        break;

      default:
        break;
    }

    setSearchParams(searchParams);
  };

  const fields = useMemo(
    () =>
      leadFilters({
        asyncLocalizations: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchLocalizations(params)),
          isLoading: localizations.loading,
        },
        asyncAffiliates: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchShortAffiliates(params)),
          isLoading: affiliatesShort.loading,
        },
        asyncClients: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchShortClients(params)),
          isLoading: clientsShort.loading,
        },
        asyncTeams: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchTeams(params)),
          isLoading: teamList.loading,
        },
        asyncProcessingStatuses: {
          fetchCallback: () => dispatch(fetchGroupList()),
          isLoading: statusesGroupList.loading,
        },
        localizationsOptions: localizations.data,
        affiliateOptions: affiliatesShort.data,
        clientOptions: clientsShort.data,
        processingStatusesOptions: statusesGroupList.data,
        teamOptions: teamList.data,
        role,
      }),
    [affiliatesShort, clientsShort, statusesGroupList, localizations, role, teamList],
  );

  return (
    <>
      <div className="container-fluid">
        <PageTitle title={"Leads"} />
        <div className="row">
          <div className="row d-flex mb-3 justify-between">
            <div className="col-sm-12 col-md-6 d-flex">
              <Button variant="primary" className="mb-2" onClick={() => setIsFilterOpen((prevState) => !prevState)}>
                {isFilterOpen ? "Close" : "Open"} Filters
              </Button>
              {superRolesWithPM.includes(role) && (
                <Link className="btn btn-primary mb-2" to={`/leads/create-test/${selectedItems[0] ?? ''}${locationSearch}`}>
                  Make test
                </Link>
              )}
              {!!selectedItems.length && (
                <>
                  <Button variant="primary" className="mb-2" onClick={handleShowModal}>
                    Actions
                  </Button>
                  {superRolesWithPM.includes(role) && (
                    <Button variant="danger" className="mb-2" onClick={handleShowDeleteModal}>
                      Delete leads
                    </Button>
                  )}
                  {superRoles.includes(role) && (
                    <Link to="/leads/create-fake-statistics" className="mb-2">
                      <Button variant="primary">
                        Create fake statistics
                      </Button>
                    </Link>
                  )}
                </>
              )}
            </div>
            <div className="col-sm-6 col-md-3 d-flex align-items-center mt-2">
              <span className="mr-3">Search:</span>
              <FormControl type={TypesOfInput.TEXT} onChange={handleChangeSearch} value={search} />
            </div>
          </div>
          {isFilterOpen && (
            <FilterForm
              className="mb-3"
              fields={fields}
              handleSubmit={handleFilter}
              handleReset={() => dispatch(resetFilters())}
              initialValues={filter}
            />
          )}
          {!!Object.keys(filtersToRender).length && (
            <div>
              <p className="mb-2">Selected filters:</p>
              <Row className="m-0">
                {Object.entries(filtersToRender).map(([key, value]) => (
                  <Col xs={6} md={3} lg={2} className="mb-2" key={key}>
                    <Button
                      variant="primary"
                      onClick={() => handleDeleteFilter(key as keyof IFilterLead)}
                      className="d-flex flex-column w-100 m-0"
                    >
                      <span>{key} <Icon name="bin-1" class="svg-icon-sm ml-2" /></span>
                      <span className="cut_text"> {value}</span>
                    </Button>
                  </Col>
                ))}
              </Row>
            </div>
          )}
          <section className="mb-5">
            <div className="card">
              <div className="card-body">
                <LeadsTable />
              </div>
            </div>
          </section>
        </div>
        <MassEditLeads show={isShowModal} onClose={handleCloseModal} />
      </div>
      {ModalComp}
    </>
  );
};

export default Leads;
