import React from "react";
import DatePicker from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import setSeconds from "date-fns/setSeconds";
import { formatTime, stringTimeToDate } from "../../../utils/formats";
interface Props {
  name?: string;
  placeholder?: string;
  onChange: (date: string) => void;
  value: string;
  showSec?: boolean;
}

const TimeField = ({ onChange, value, showSec }: Props) => {
  const selectedValue = stringTimeToDate(value);
  const handleChange = (date: Date) => {
    onChange(formatTime(date));
  };

  return (
    <DatePicker
      className="form-control"
      onChange={handleChange}
      selected={selectedValue}
      showTimeSelect
      showTimeSelectOnly
      dateFormat={showSec ? "HH:mm:ss" : "HH:mm"}
      timeFormat={showSec ? "HH:mm:ss" : "HH:mm"}
      injectTimes={[setHours(setMinutes(setSeconds(new Date(), 59), 59), 23)]}
      timeCaption="Time"
    />
  );
};

export default TimeField;
