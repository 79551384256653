import * as Yup from "yup";
import { IField, TypesOfInput } from "../../models/fields/IField";
import { ICountry } from "../../models/ILocalizations";
import { ITagChange } from "../../models/ITag";

export const getValidation = (localizations: string[]) => {
  type YupObject = {
    [key: string]: Yup.StringSchema<string | undefined, UnknownRecord, string | undefined>;
  };
  const object: YupObject = {};
  localizations.forEach((localization) => {
    const schema = Yup.string().test("check localizations", "Required", (_, context) => {
      return !!Object.values(context.parent).filter(Boolean).length;
    });
    object[localization] = schema;
  });

  return Yup.object<Record<keyof ITagChange, Yup.AnySchema>>({
    name: Yup.object(object),
    slug: Yup.string().required("Required"),
  });
};

interface Params {
  selectedCountry: ICountry | null;
}

export const createFields = ({ selectedCountry }: Params): IField<ITagChange>[] => [
  {
    label: "Slug",
    name: "slug",
    placeholder: "Slug",
    type: TypesOfInput.TEXT,
  },
  {
    label: `Name ${selectedCountry?.flag}`,
    name: "name",
    placeholder: "Name",
    type: TypesOfInput.TEXT,
    prefix: true,
  },
];
