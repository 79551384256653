import { createSlice } from "@reduxjs/toolkit";
import { TableSettingsResponse } from "../../models/IUserSettings";
import { FetchedStoreModel } from "../../types/global";
import { changeTableSettings, fetchTableSettings } from "../thunks/user-settings";

export interface State {
  tableSettings: FetchedStoreModel<TableSettingsResponse<UnknownRecord> | null>;
}

const initialState: State = {
  tableSettings: {
    data: null,
    error: null,
    loading: true,
  },
};

export const userSettingsSlice = createSlice({
  name: "user-settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetchTableSettings
    builder.addCase(fetchTableSettings.pending, (state) => {
      state.tableSettings.loading = true;
      state.tableSettings.error = null;
    });
    builder.addCase(fetchTableSettings.fulfilled, (state, action) => {
      state.tableSettings.data = action.payload;
      state.tableSettings.loading = false;
    });
    builder.addCase(fetchTableSettings.rejected, (state, action) => {
      state.tableSettings.loading = false;
      state.tableSettings.error = action.payload as FixMeLater;
    });

    // changeTableSettings
    builder.addCase(changeTableSettings.pending, (state) => {
      state.tableSettings.error = null;
    });
    builder.addCase(changeTableSettings.fulfilled, (state, action) => {
      state.tableSettings.data = action.payload;
    });
    builder.addCase(changeTableSettings.rejected, (state, action) => {
      state.tableSettings.error = action.payload as FixMeLater;
    });
  },
});

export default userSettingsSlice.reducer;
