import { Modify } from "../types/global";
import { OptionModel, GenericOption } from "./fields/ISelect";

export enum SettingType {
  POSTBACK_PARTNER_URL = "postback-partner-url",
  USER_TABLE_COLUMN = "user-table-column",
};

type SettingValue = {
  url: string;
  name: string;
};

export type ISetting = Modify<
  ISettingChange,
  {
    type: SettingType;
    createdAt: string;
    id: string;
  }
>;

export type ISettingChange = {
  type: OptionModel<GenericOption<SettingType>>;
  value: SettingValue;
};

export type ISettingChangeRequest = {
  id?: string;
  type: SettingType;
  value: SettingValue;
};

export type ISettingFilter = ISettingChange;

export const typeOptions: GenericOption<SettingType>[] = [
  {
    label: "Postback partner url",
    value: SettingType.POSTBACK_PARTNER_URL,
  },
];

export const emptyValues: ISettingChange = {
  type: null,
  value: {
    url: "",
    name: "",
  },
};

export const emptyFilters: ISettingFilter = { ...emptyValues };
