import React from "react";
import { Link } from "react-router-dom";
import { DashboardAffiliate, DashboardCap } from "../../models/IDashboard";

interface Props {
  affiliate: DashboardAffiliate;
  onClick: (affiliate: string) => void;
}

const AffiliateCard = ({ affiliate, onClick }: Props) => (
  <div
    role="button"
    onClick={() => onClick(affiliate.affiliate)}
    className="col-xl-3 col-md-6 mb-2"
  >
    <div className="card mb-4">
      <div className="card-body">
        <h6 className="subtitle fw-normal text-muted">
          {affiliate.name} ({affiliate.affiliate})
        </h6>
        <h5 className="m-b-25">
          {affiliate.today}
          <span className="ms-3 float-end text-blue">month - {affiliate.month}</span>
        </h5>
        <div className="progress">
          <div
            className="progress-bar bg-blue"
            role="progressbar"
            style={{ width: `${affiliate.percent}%` }}
          />
        </div>
      </div>
    </div>
  </div>
);

export default AffiliateCard;
