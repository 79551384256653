import { IAllFields, TypesOfInput } from "../../models/fields/IField";
import { getRatingOptions, IReviewFilters } from "../../models/IReview";

const filterFields = (): IAllFields<IReviewFilters>[] => [
  {
    label: "Slug",
    name: "slug",
    placeholder: "Slug",
    type: TypesOfInput.TEXT,
  },
  {
    label: "Rating",
    name: "rating",
    placeholder: "Rating",
    type: TypesOfInput.SELECT,
    options: getRatingOptions(),
  },
];

export default filterFields;
