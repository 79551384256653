import React, { useEffect, useMemo, useState } from "react";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { Link, useParams } from "react-router-dom";
import Field from "../../components/common/form/formik/Field";
import PageTitle from "../../components/common/items/page-title";
import Loading from "../_layouts/Loading";
import { emptyValues, ITeamChange } from "../../models/ITeam";
import { changeItem, deleteItem, fetchItem } from "../../store/thunks/teams";
import { createFields, validationSchema } from "../../components/fields/teams";
import useModal from "../../hooks/useModal";

const ChangeItem = () => {
  const dispatch = useAppDispatch();
  const { currentItem } = useAppSelector((state) => state.teamsSlice);
  const { id: teamId } = useParams();
  const [initialValues, setInitialValues] = useState<ITeamChange>(emptyValues);

  useEffect(() => {
    if (!teamId) return;
    dispatch(fetchItem(teamId));
  }, []);

  const handleDelete = () => {
    if (!teamId) return;
    dispatch(deleteItem(teamId));
  };

  const { ModalComp, handleShowModal } = useModal(handleDelete);

  useEffect(() => {
    if (teamId && !currentItem.loading && currentItem.data) {
      setInitialValues({ ...currentItem.data });
    }
  }, [currentItem]);

  const fields = useMemo(() => createFields, []);

  const handleSubmit = (values: ITeamChange) => {
    if (teamId) {
      dispatch(changeItem({ id: teamId, ...values }));
    } else {
      dispatch(changeItem(values));
    }
  };

  if (teamId && currentItem.loading) {
    return <Loading />;
  }

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <div className="d-flex">
          <Link to="/teams" className="w-auto">
            <Button className="w-auto mb-3" variant="primary">
              Back
            </Button>
          </Link>
          {teamId && (
            <Button className="w-auto mb-3" variant="danger" onClick={handleShowModal}>
              Delete
            </Button>
          )}
        </div>
        <PageTitle title={`${teamId ? "Edit" : "Create"} team`} />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {fields.map((field) => (
                      <Field key={field.name} {...field} />
                    ))}
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                  >
                    Save
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
      </div>
      {ModalComp}
    </div>
  );
};

export default ChangeItem;
