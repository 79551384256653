import { ReactNode } from "react";
import { GroupBase, OptionsOrGroups } from "react-select";
import { FetchOptionsParams, ObjectWithKeys } from "../../types/global";
import { IField } from "./IField";

export type AsyncObject = {
  fetchCallback: (params: FetchOptionsParams) => FixMeLater;
  isLoading: boolean;
};
export interface ISelect<ChangingFields, Option> extends IField<ChangingFields> {
  formatOptionLabel?: (data: Option) => ReactNode;
  options: OptionsOrGroups<Option, GroupBase<Option>>;
  labelName?: keyof Option;
  valueName: keyof Option;
  isMulti?: boolean;
  isCustomValue?: boolean;
  defaultProps: ISelectDefaultProps<ChangingFields, Option>;
  isGrouped?: boolean,
}
export interface ISelectDefaultProps<ChangingFields, Option> {
  getOptionLabel: (item: Option) => string;
  getOptionValue: (item: Option) => string;
  isClearable: boolean;
  id: string & keyof ChangingFields;
}

export interface IAsyncSelect<ChangingFields, Option> extends ISelect<ChangingFields, Option> {
  async: AsyncObject;
}

export type ISelectField<ChangingFields, Option> = IAsyncSelect<ChangingFields, Option> | FixMeLater;

export interface GenericOption<T> extends ObjectWithKeys {
  label: string;
  value: T & string;
}

export type OptionType<T = string> = ObjectWithKeys<T> | null;
export type OptionModel<T> = T | null;

export const selectStyles = {
  input: (base: FixMeLater) => ({
    ...base,
    gridTemplateColumns: '0fr',
  }),
  placeholder: (base: FixMeLater) => ({
    ...base,
    pointerEvents: "none",
    userSelect: "none",
  }),
}
