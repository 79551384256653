import {IAllFields, TypesOfInput} from "../../models/fields/IField";
import {AsyncObject} from "../../models/fields/ISelect";
import {IFilterTransaction} from "../../models/ITransactions";
import {IClient} from "../../models/IClients";
import {createTransactionFields} from "./create-transaction";
import { ILead } from "../../models/ILead";

interface balanceHistoryFiltersParams {
    clientOptions: IClient[];
    asyncClients: AsyncObject;
    leadOptions: ILead[];
    asyncLeads: AsyncObject;
}

export const balanceHistoryFilters =
    (params: balanceHistoryFiltersParams): IAllFields<IFilterTransaction>[] => [
    ...createTransactionFields(params),
    {
        label: "Balance",
        name: "balance",
        placeholder: "Balance",
        type: TypesOfInput.TEXT,
    },
    {
        label: "Created",
        name: "createdAt",
        placeholder: "Created",
        type: TypesOfInput.DATEPICKER_RANGE,
    },
];
