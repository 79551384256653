import { TypeOptions } from "../components/common/items/ColorLabel";
import { Modify, ObjectWithKeys } from "../types/global";
import { OptionModel } from "./fields/ISelect";

type Rating = "1" | "2" | "3" | "4" | "5";

export type IReview = IReviewChange & {
  id: string;
  createdAt: string;
  isSelected?: boolean;
  rating: Rating;
};

export type IReviewChange = IReviewFilters & {
  photo: File | null;
  name: ObjectWithKeys;
  text: ObjectWithKeys;
  answer: ObjectWithKeys;
};

export type IReviewChangeRequest = Modify<
  Partial<IReviewChange>,
  {
    rating: string;
  }
>;
export type IReviewFiltersRequest = Modify<
  Partial<IReviewFilters>,
  {
    rating: string;
  }
>;

export type IReviewFilters = {
  slug: string;
  rating: OptionModel<TypeOptions<Rating>>;
};

export const emptyFilters: IReviewFilters = {
  slug: "",
  rating: null,
};

export const getRatingOptions = (): TypeOptions<Rating>[] => {
  const rating: Rating[] = ["1", "2", "3", "4", "5"];
  return rating.map((item) => ({
    label: item,
    value: item,
  }));
};

export const emptyValues: IReviewChange = {
  slug: "",
  rating: null,
  photo: null,
  name: {},
  text: {},
  answer: {},
};
