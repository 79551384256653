import { Link } from "react-router-dom";
import { IReview } from "../../models/IReview";
import { formatDateFullTime } from "../../utils/formats";
import { ColumnDef, PaginationType } from "../common/tables/types";

type IColumn = ColumnDef<IReview>;

interface Options {
  pagination: PaginationType;
}

export default function getColumnDefs(options: Options): IColumn[] {
  const { pagination } = options;

  const numberIndex: IColumn = {
    Cell: ({ row: { index } }: { row: { index: number } }) => {
      return <div>{index + (pagination.from || 1)}</div>;
    },
    Header: "№",
    id: "№",
    disableFilters: true,
    disableSortBy: true,
    width: "25px",
  };

  const slug: IColumn = {
    Cell: (cell) => (
      <Link className="font-semibold" to={`/reviews/${cell.row.original.id}`}>
        {cell.value}
      </Link>
    ),
    Header: "Slug",
    id: "slug",
    accessor: "slug",
    width: "80px",
  };

  const rating: IColumn = {
    Header: "Rating",
    id: "rating",
    accessor: "rating",
    width: "50px",
  };

  const createdAt: IColumn = {
    Cell: (cell) => <span>{formatDateFullTime(cell.value)}</span>,
    Header: "Created",
    id: "createdAt",
    accessor: "createdAt",
    width: "50px",
  };

  return [numberIndex, slug, rating, createdAt];
}
