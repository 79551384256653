import { IFilterAffiliate, statusOptions } from "../../models/IAffiliates";
import { IAllFields, TypesOfInput } from "../../models/fields/IField";
import { AsyncObject } from "../../models/fields/ISelect";
import { IUser } from "../../models/IUsers";

interface affiliatesFiltersParams {
  userOptions: IUser[];
  asyncUsers: AsyncObject;
}

export const affiliateFilters = ({
  userOptions,
  asyncUsers,
}: affiliatesFiltersParams): IAllFields<IFilterAffiliate>[] => [
  {
    label: "Name",
    name: "name",
    placeholder: "Name",
    type: TypesOfInput.TEXT,
  },
  {
    label: "Affiliate",
    name: "affiliate",
    placeholder: "Affiliate",
    type: TypesOfInput.TEXT,
  },
  {
    label: "Price",
    name: "price",
    placeholder: "Price",
    type: TypesOfInput.TEXT,
  },
  {
    label: "After days",
    name: "afterDays",
    placeholder: "After days",
    type: TypesOfInput.TEXT,
  },
  {
    async: asyncUsers,
    label: "Users",
    name: "users",
    options: userOptions,
    placeholder: "Users",
    type: TypesOfInput.SELECT,
    isMulti: true,
    valueName: "id",
    labelName: "name",
  },
  {
    isMulti: true,
    label: "Status",
    name: "status",
    options: statusOptions,
    placeholder: "Status",
    type: TypesOfInput.SELECT,
  },
  {
    label: "Created",
    name: "createdAt",
    placeholder: "Created",
    type: TypesOfInput.DATEPICKER_RANGE,
  },
  {
    label: "Show redirect",
    name: "isShowRedirect",
    placeholder: "Show redirect",
    type: TypesOfInput.CHECKBOX,
  },
  {
    label: "Accept duplicate",
    name: "isAcceptDuplicate",
    placeholder: "Accept duplicate",
    type: TypesOfInput.CHECKBOX,
  },
  {
    label: "Is self",
    name: "isSelf",
    placeholder: "Is self",
    type: TypesOfInput.CHECKBOX,
  },
];
