import React from "react";
import cn from "classnames";
import { SortableColumnInstance } from "./types";
import { SortOrderByType, SortOrderType } from "../../../types/global";

interface Props<T extends UnknownRecord> {
  column: SortableColumnInstance<T>;
  sortableKey?: keyof T;
  handleSort?: (colName: keyof T, order: SortOrderType) => void;
  sortByColumn?: SortOrderByType<T>;
}

const HeadCell = <T extends UnknownRecord>({
  column,
  sortableKey,
  handleSort,
  sortByColumn,
}: Props<T>) => {
  let className = cn("Table-headCell", column.headCellClass, { "text-right": column.alignRight });

  if (!sortableKey)
    return (
      <th className={className} {...column.getHeaderProps()} style={{ width: column.width }}>
        {column.render("Header")}
      </th>
    );

  className = cn(className, "Table-headCell--sortable", {
    "Table-headCell--down": sortByColumn?.order === 'ASC',
    "Table-headCell--up": sortByColumn?.order === 'DESC',
  });

  const handleColumnSort = () =>
    handleSort && handleSort(sortableKey, sortByColumn?.order === 'ASC' ? "DESC" : "ASC");

  return (
    <th
      className={className}
      onClick={handleColumnSort}
      style={{ width: column.width }}
      {...column.getHeaderProps()}
    >
      <div className="Table-headContent">
        <span className="btn-link">{column.render("Header")}</span>
        {sortByColumn?.orderBy === sortableKey && (
          <svg className="Table-sortMark" width="8" height="10" viewBox="0 0 8 10">
            <path className="Table-sortUp" d="M8 4L4 0L0 4H8Z" />
            <path className="Table-sortDown" d="M0 6L4 10L8 6L0 6Z" />
          </svg>
        )}
      </div>
    </th>
  );
};

export default HeadCell;
