import { ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
import countriesJson from "../../countries.json";
import { ClientParameters, IClient, typeOptions } from "../../models/IClients";
import { ICountry } from "../../models/ILocalizations";
import { formatDateFullTime, getColumnNumberIndex, getCurrentWeekDay } from "../../utils/formats";
import CheckboxField from "../common/form/CheckboxField";
import ColorLabel from "../common/items/ColorLabel";
import Icon from "../common/items/Icon";
import { ColumnDef, PaginationType } from "../common/tables/types";

type IColumn = ColumnDef<IClient>;

interface Options {
  pagination: PaginationType;
  handleChangeStatus: (status: boolean, id: string) => void;
  handleChangeAcceptHold: (acceptHold: boolean, id: string) => void;
}

const relativelyAmountOfLeadsCount = (parameters: ClientParameters, leadsCount: number): number => {
  return (leadsCount * 100) / parameters?.[getCurrentWeekDay()]?.count;
};

export default function getColumnDefsClients(options: Options, isMobile: boolean): IColumn[] {
  const { pagination, handleChangeStatus, handleChangeAcceptHold } = options;

  const balance: IColumn = {
    Header: "Balance",
    id: "balance",
    accessor: "balance",
    width: "80px",
  };

  const credit: IColumn = {
    Header: "Credit",
    id: "credit",
    accessor: "credit",
    width: "80px",
  };

  const link: IColumn = {
    Cell: (cell) => (
      <>
        {cell.row.original.integrationsCount ? (
          <Link className="font-semibold" to={`/clients/${cell.row.original.id}/integrations`}>
            APIs
          </Link>
        ) : (
          <Link className="font-semibold" to={`/clients/${cell.row.original.id}/integrations`}>
            <Icon name="add_document" class="svg-icon-sm" />
          </Link>
        )}

        {cell.value && (
          <a href={cell.value} className="fa-pull-right" rel="noreferrer" target="_blank">
            Table
          </a>
        )}
      </>
    ),
    Header: "Links",
    id: "link",
    accessor: "link",
    width: "100px",
  };

  const price: IColumn = {
    Cell: (cell) => (
      <div className="d-flex justify-between">
        {cell.value}
        <ColorLabel
          className="d-inline fa-pull-right"
          colors={typeOptions}
          value={cell.row.original.type}
          label={cell.row.original.type.toLocaleUpperCase()}
        />
      </div>
    ),
    Header: "Price",
    id: "price",
    accessor: "price",
    width: "85px",
  };

  const label: IColumn = {
    Cell: (cell) => (
      <span>
        {cell.value ? (
          <div className="d-flex justify-between">
            {cell.value.name}

            <ColorLabel className="d-inline pl-5" isSingleColor color={cell.value.color} />
          </div>
        ) : (
          "No"
        )}
      </span>
    ),
    Header: "Label",
    id: "label",
    accessor: "label",
    width: "80px",
  };

  const progress: IColumn = {
    Cell: ({ row }) => (
      <div className="progress position-relative">
        <div
          className="progress-bar bg-success"
          role="progressbar"
          style={{
            width: `${relativelyAmountOfLeadsCount(
              row.original.parameters,
              row.original.leadsCount,
            )}%`,
          }}
          aria-valuenow={relativelyAmountOfLeadsCount(
            row.original.parameters,
            row.original.leadsCount,
          )}
          aria-valuemin={0}
          aria-valuemax={100}
        />
        <span className="text-dark justify-content-center d-flex position-absolute w-100">
          {row.original.leadsCount} / {row.original.parameters?.[getCurrentWeekDay()]?.count}
        </span>
      </div>
    ),
    Header: "Leads Progress",
    id: "leadsProgress",
    accessor: "leadsCount",
  };

  const numberIndex: IColumn = {
    Cell: ({ row: { index } }: { row: { index: number } }) => {
      return <div>{getColumnNumberIndex(pagination, index)}</div>;
    },
    Header: "№",
    id: "№",
    disableFilters: true,
    disableSortBy: true,
    width: "45px",
  };

  const name: IColumn = {
    Cell: (cell) => (
      <Link className="font-semibold" to={`/clients/${cell.row.original.id}`}>
        {cell.value}
      </Link>
    ),
    Header: "Name",
    id: "name",
    accessor: "name",
  };

  const acceptHold: IColumn = {
    Cell: (cell) => (
      <CheckboxField
        value={Boolean(cell.value)}
        onChange={(value: boolean) => handleChangeAcceptHold(value, cell.row.original.id)}
      />
    ),
    Header: "Acc. Hold",
    id: "acceptHold",
    accessor: "acceptHold",
    width: "90px",
  };

  const status: IColumn = {
    Cell: (cell) => (
      <CheckboxField
        value={Boolean(cell.value)}
        onChange={(value: boolean) => handleChangeStatus(value, cell.row.original.id)}
      />
    ),
    Header: "Status",
    id: "status",
    accessor: "status",
    width: "60px",
  };

  const createdAt: IColumn = {
    Cell: (cell) => <span>{formatDateFullTime(cell.value)}</span>,
    Header: "Created",
    id: "createdAt",
    accessor: "createdAt",
  };

  const workingTime: IColumn = {
    Cell: (cell) => (
      <span>
        {cell.value?.[getCurrentWeekDay()]?.startTime +
          "/" +
          cell.value?.[getCurrentWeekDay()]?.endTime}
      </span>
    ),
    Header: "Time",
    id: "time",
    accessor: "parameters",
  };

  const numberLeadsBasedOnBalance: IColumn = {
    Cell: ({ row: { original } }) => (
      <span>{((original.balance + original.credit) / original.price).toFixed(2)}</span>
    ),
    Header: "Leads",
    id: "leads",
    accessor: "type",
    width: "75px",
  };

  const localizations: IColumn = {
    Cell: ({ value }) => {
      const countries: ICountry[] = countriesJson;
      const flags = new Set(
        value?.map(
          (item) => countries.find((country) => country.code === item.country.toLowerCase())?.flag,
        ),
      );
      return <span>{Array.from(flags).join(" ")}</span>;
    },
    Header: "Localizations",
    id: "localizations",
    accessor: "localizations",
    width: "100px",
  };

  const mobileColumn: IColumn = {
    accessor: "id",
    id: "id",
    Cell: ({ row: { original, index } }) => (
      <div>
        <div className="d-flex align-items-center justify-content-between mb-1">
          <span>
            {pagination.total &&
              pagination.currentPage &&
              pagination.total - index * pagination?.currentPage}
          </span>
          <span>
            <Link className="font-semibold" to={`/clients/${original.id}`}>
              {original.name}
            </Link>
          </span>
          <span>
            <CheckboxField
              value={Boolean(original.status)}
              onChange={(value: boolean) => handleChangeStatus(value, original.id)}
            />
          </span>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-1">
          <ColorLabel
            className="d-inline"
            colors={typeOptions}
            value={original.type}
            label={original.type.toLocaleUpperCase()}
          />
          <span>B: {original.balance}</span>
          <span>C: {original.credit}</span>
          <span>P: {original.price}</span>
          <span>L: {((original.balance + original.credit) / original.price).toFixed(2)}</span>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-1">
          <span>
            {original.parameters?.[getCurrentWeekDay()]?.startTime +
              "/" +
              original.parameters?.[getCurrentWeekDay()]?.endTime}
          </span>
          <span>
            Leads Progress:
            <div className="progress position-relative">
              <div
                className="progress-bar bg-success"
                role="progressbar"
                style={{
                  width: `${relativelyAmountOfLeadsCount(
                    original.parameters,
                    original.leadsCount,
                  )}%`,
                }}
                aria-valuenow={relativelyAmountOfLeadsCount(
                  original.parameters,
                  original.leadsCount,
                )}
                aria-valuemin={0}
                aria-valuemax={100}
              />
              <span className="text-dark justify-content-center d-flex position-absolute w-100">
                {original.leadsCount} / {original.parameters?.[getCurrentWeekDay()]?.count}
              </span>
            </div>
          </span>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-1">
          <Link className="font-semibold" to={`/clients/${original.id}/integrations`}>
            {original.integrationsCount ? (
              `Integrations ${original.integrationsCount}`
            ) : (
              <Icon name="add_document" class="svg-icon-sm" />
            )}
          </Link>
          <span>{formatDateFullTime(original.createdAt)}</span>
        </div>
      </div>
    ),
  };

  if (isMobile) return [mobileColumn];

  return [
    numberIndex,
    name,
    price,
    balance,
    credit,
    localizations,
    numberLeadsBasedOnBalance,
    progress,
    workingTime,
    link,
    label,
    acceptHold,
    status,
    createdAt,
  ];
}
