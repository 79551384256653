import React from "react";
import { IDashboardStatuses } from "../../models/IDashboard";
import { LeadStatus, statusOptions } from "../../models/ILead";

interface Props {
  item: IDashboardStatuses;
  index: number;
  onClick: (status: LeadStatus) => void;
}

const icons = ["fas fa-server", "far fa-clipboard", "fa fa-dolly-flatbed", "fas fa-receipt"];

const LeadsStatusCard = ({ item, index, onClick }: Props) => (
  <div className="col-xl-3 col-md-6 mb-2" onClick={() => onClick(item.status)}>
    <div className="card-widget h-100">
      <div className="card-widget-body">
        <div
          className="dot me-3"
          style={{
            backgroundColor:
              statusOptions.find((option) => item.status === option.value)?.color || "#000000",
          }}
        />
        <div className="text">
          <h6 className="mb-0">
            {statusOptions.find((option) => item.status === option.value)?.label}
          </h6>
          <span className="text-gray-500">{item.leads}</span>
        </div>
      </div>
      <div
        className="icon text-white"
        style={{
          backgroundColor:
            statusOptions.find((option) => item.status === option.value)?.color || "#000000",
        }}
      >
        <i className={icons[index]} />
      </div>
    </div>
  </div>
);

export default LeadsStatusCard;
