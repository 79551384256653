import { TypeOptions } from "../components/common/items/ColorLabel";
import { PaginationQuery } from "../components/common/tables/types";
import { ArrayOfDate, Modify } from "../types/global";
import { OptionModel } from "./fields/ISelect";

export type DictionaryType = "name" | "email";

export type IDictionaryWord = {
  id: string;
  isSelected?: boolean;
  type: DictionaryType;
  createdAt: string;
  title: string;
};

export type IFilterDictionaryWord = IDictionaryChange & {
  createdAt: ArrayOfDate;
};

export const emptyValues: IDictionaryChange = {
  title: "",
  type: null,
};

export const emptyFilters: IFilterDictionaryWord = {
  createdAt: [null, null],
  title: "",
  type: null,
};

export type IDictionaryChange = {
  title: string;
  type: OptionModel<TypeOptions<DictionaryType>>;
};

export type IDictionaryChangeRequest = Modify<
  Partial<IDictionaryChange>,
  {
    type: DictionaryType | "";
  }
>;

export type IDictionaryFilterRequest = Modify<
  Partial<IDictionaryChange>,
  {
    type: DictionaryType | "";
  }
>;

export interface RequestDictionaryWord extends PaginationQuery {
  filter: IDictionaryFilterRequest;
}

export const typeOptions: TypeOptions<DictionaryType>[] = [
  {
    label: "Имя|Фамилия",
    value: "name",
  },
  {
    label: "E-mail",
    value: "email",
  },
];
