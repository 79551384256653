import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import FilterForm from "../../components/common/form/formik/FilterForm";
import PageTitle from "../../components/common/items/page-title";
import DictionaryWordsTable from "../../components/dictionary-words/DictionaryWordsTable";
import { dictionaryWordFilters } from "../../components/fields/filters-dictionaryWord";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { IFilterDictionaryWord, emptyFilters } from "../../models/IDictionaryWords";
import { dictionaryWordsSlice } from "../../store/reducers/dictionaryWordsSlice";
import { fetchDictionaryWords } from "../../store/thunks/dictionaryWords";

const DictionaryWords = () => {
  const dispatch = useAppDispatch();
  const { filter } = useAppSelector((state) => state.dictionaryWordsReducer);
  const { setFilters, resetFilters } = dictionaryWordsSlice.actions;

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  useEffect(() => {
    requestDictionaryWords();
    return () => {dispatch(setFilters(emptyFilters))}
  }, []);

  const requestDictionaryWords = () => {
    dispatch(fetchDictionaryWords());
  };

  const handleFilter = (values: IFilterDictionaryWord) => {
    dispatch(setFilters(values));
    requestDictionaryWords();
  };

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <PageTitle title={"DictionaryWords"} />
      <div className="row">
        <div className="d-flex mb-3 justify-between">
          <Button variant="primary" onClick={() => setIsFilterOpen((prevState) => !prevState)}>
            {isFilterOpen ? "Close" : "Open"} Filters
          </Button>
          <Link className="btn btn-primary" to="/dictionary-words/new">
            Create Dictionary Word
          </Link>
        </div>
        {isFilterOpen && (
          <FilterForm
            className="mb-3"
            fields={dictionaryWordFilters()}
            handleSubmit={handleFilter}
            handleReset={() => dispatch(resetFilters())}
            initialValues={filter}
          />
        )}
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <DictionaryWordsTable className="min-w-screen-lg" />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default DictionaryWords;
