import React, { useEffect, useMemo, useState } from "react";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import Field from "../../components/common/form/formik/Field";
import PageTitle from "../../components/common/items/page-title";
import Loading from "../_layouts/Loading";
import { emptyValues, IClient, IClientChange, typeOptions } from "../../models/IClients";
import { changeClient, fetchOneClient } from "../../store/thunks/clients";
import ParametersField from "../../components/common/form/formik/ScheduleFields";
import { deleteClient } from "../../api/clients-api";
import { getValuesFromModel } from "../../api/utils";
import FieldBlock from "../../components/common/form/formik/FieldBlock";
import { fetchShortAffiliates } from "../../store/thunks/affiliates";
import { createAccessLandingFields } from "../../components/fields/create-accessLanding";
import { fetchLabels } from "../../store/thunks/labels";
import { editClientFields, editClientValidation } from "../../components/fields/edit-client";
import { accessLandingTypeOptions, IAccessLandingCreate } from "../../models/IAccessLandings";
import { fetchList as fetchTeamList } from "../../store/thunks/teams";
import useModal from "../../hooks/useModal";
import { FetchOptionsParams } from "../../types/global";
import { TypesOfInput } from "../../models/fields/IField";

const EditClient = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentClient } = useAppSelector((state) => state.clientsReducer);
  const { affiliatesShort } = useAppSelector((state) => state.affiliatesReducer);
  const { labels } = useAppSelector((state) => state.labelsReducer);
  const { list: teamList } = useAppSelector((state) => state.teamsSlice);

  const { id: clientId } = useParams();
  const [initialValues, setInitialValues] = useState<IClientChange>(emptyValues);

  const handleDeleteClient = () => {
    if (currentClient.data) {
      void deleteClient(currentClient.data.id);
      navigate("/clients");
    }
  };

  const { ModalComp, handleShowModal } = useModal(handleDeleteClient);

  useEffect(() => {
    if (!clientId) return;
    dispatch(fetchOneClient(clientId));
  }, []);

  useEffect(() => {
    if (!currentClient.loading && currentClient.data) {
      const data = getValuesFromModel<IClientChange>(
        {
          ...currentClient.data,
          type:
            typeOptions.find((option) => option.value === (currentClient.data as IClient).type) ||
            null,
          landings:
            (currentClient.data.landings.map((landing) => ({
              ...landing,
              type: accessLandingTypeOptions.find((option) => option.value === landing.type) || "",
            })) as IAccessLandingCreate[]) || [],
          status: Boolean(currentClient.data.status),
          acceptHold: Boolean(currentClient.data.acceptHold),
          storeReplaceTable: false,
          leads: currentClient.data.price ? (
            (currentClient.data.balance + currentClient.data.credit) /
            currentClient.data.price
          ).toFixed(2) : '0',
          localizations: currentClient.data.localizations?.map(item => ({ value: item.id, label: item.name })) || [],
        },
        emptyValues,
      );
      setInitialValues(data);
    }
  }, [currentClient]);

  const fields = useMemo(
    () =>
      editClientFields({
        asyncLabels: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchLabels(params)),
          isLoading: labels.loading,
        },
        asyncTeams: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchTeamList(params)),
          isLoading: teamList.loading,
        },
        labelOptions: labels.data,
        teamOptions: teamList.data,
      }),
    [labels, teamList],
  );

  const accessLandingFields = useMemo(
    () =>
      createAccessLandingFields({
        affiliateOptions: affiliatesShort.data,
        asyncAffiliates: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchShortAffiliates(params)),
          isLoading: affiliatesShort.loading,
        },
      }),
    [affiliatesShort],
  );

  const handleSubmit = (values: IClientChange) => {
    if (currentClient?.data?.id) {
      dispatch(
        changeClient({
          ...values,
          id: currentClient.data.id,
        }),
      );
    }
  };

  if (currentClient.loading) {
    return <Loading />;
  }

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <PageTitle title={currentClient.data?.name} />
      <div className="row">
        <div className="d-flex justify-between">
          <Link to="/clients" className="w-auto">
            <Button className="w-auto mb-3" variant="primary">
              Back
            </Button>
          </Link>
          <div>
            <Button className="w-auto mb-3" variant="danger" onClick={handleShowModal}>
              Delete Client
            </Button>
          </div>
        </div>
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <p className="card-title">Edit Mode</p>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={editClientValidation}
                onSubmit={handleSubmit}
              >
                {(formik) =>
                  currentClient.data && (
                    <Form onSubmit={formik.handleSubmit} className="w-100 px-4">
                      <div className="row">
                        {fields.map((field) => (
                          <Field key={field.name} {...field} />
                        ))}
                        <Field
                          label="Percent"
                          name="percent"
                          placeholder="0"
                          type={TypesOfInput.TEXT}
                          isHidden={formik.values.type?.value !== 'crg'}
                        />
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-5">
                          <ParametersField name="parameters" />
                        </div>
                        <div className="col-12 col-md-7">
                          <p className="m-0">Access Landings</p>
                          {accessLandingFields.map((field) => (
                            <FieldBlock key={field.name} {...field} />
                          ))}
                        </div>
                      </div>
                      <Button type="submit" className="form-control d-block" variant="primary">
                        Save Client
                      </Button>
                    </Form>
                  )
                }
              </Formik>
            </div>
          </div>
        </section>
      </div>
      {ModalComp}
    </div>
  );
};

export default EditClient;
