import { createSlice } from "@reduxjs/toolkit";
import { getToken, removeCookies, setCookies } from "../../api/utils";
import { IAuthUser } from "../../models/IAuth";
import { UserRole } from "../../models/IUsers";
import { getCurrentUser, login, login2Fa } from "../thunks/auth";

export interface State {
  auth: {
    token: string | null;
    user: IAuthUser | null;
  };
  isAuth: boolean;
}

const initialState: State = {
  auth: {
    token: null,
    user: null,
  },
  isAuth: !!getToken(),
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      removeCookies("authorization");
      state = { ...initialState, isAuth: false };
      return state;
    },
  },
  extraReducers: (builder) => {
    // login
    builder.addCase(login.fulfilled, (_, action) => {
      setCookies("confirm_id", action.payload.data);
    });
    builder.addCase(login.rejected, () => {
      removeCookies("confirm_id");
    });

    // login2Fa
    builder.addCase(login2Fa.fulfilled, (state, action) => {
      state.isAuth = true;
      state.auth = action.payload.model;
      setCookies("authorization", action.payload.model.token);
      removeCookies("confirm_id");
    });

    // getCurrentUser
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.auth.user = action.payload;
      // state.auth.user = {
      //   ...action.payload,
      //   roles: UserRole.PM,
      // };
    });
  },
});

export default authSlice.reducer;
