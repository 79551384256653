import React, { useMemo, useRef } from "react";
import { Formik, Form, FormikProps } from "formik";
import { Button, Modal } from "react-bootstrap";
import { editMassLeadsFields } from "../../components/fields/edit-lead";
import { useAppDispatch } from "../../hooks/redux";
import { updateLeads } from "../../store/thunks/leads";
import Field from "../../components/common/form/formik/Field";
import { UpdateSomeLeadsActions } from "../../models/ILead";

const initialValues: UpdateSomeLeadsActions = {
  language: "",
  country: "",
  status: null,
};

interface Props {
  show: boolean;
  onClose: VoidFunction;
}

const MassEditLeads = ({ show, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const formikRef: React.Ref<FormikProps<UpdateSomeLeadsActions>> | null = useRef(null);

  const fields = useMemo(() => editMassLeadsFields(), []);

  const handleSubmit = (values: UpdateSomeLeadsActions) => {
    dispatch(updateLeads(values));
    onClose();
  };

  return (
    <Modal show={show} onHide={onClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Change leads</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} innerRef={formikRef}>
          <Form className="w-100 px-4">
            <div className="row">
              {fields.map((field) => (
                <Field key={field.name} {...field} />
              ))}
            </div>
          </Form>
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={() => formikRef.current?.handleSubmit()}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MassEditLeads;
