import React from "react";
import cn from "classnames";
import { IAllFields, IField, TypesOfInput } from "../../../../models/fields/IField";
import { capitalizeFirstLetter } from "../../../../utils/formats";
import { ObjectWithKeys } from "../../../../types/global";

import CheckboxField from "./CheckboxField";
import DateField from "./DateField";
import DateRangeField from "./DateRangeField";
import SelectField from "../Select";
import TextField from "./TextField";
import TimeField from "./TimeField";
import FileField from "./FileField";
import TextEditorField from "./TextEditorField";
import TextAreaField from "./TextAreaField";
import LocalizationSelect from "./LocalizationSelect";

const Field = <T extends ObjectWithKeys>(props: IAllFields<T>) => {
  const changedProps: IField<T> = {
    ...props,
    label: props.label === "" ? "" : props.label ? props.label : capitalizeFirstLetter(props.name),
    placeholder: props.placeholder || capitalizeFirstLetter(props.name),
  };

  if (props.isHidden) return null;

  const renderFieldByType = () => {
    switch (changedProps.type) {
      case TypesOfInput.SELECT:
        return <SelectField {...changedProps} />;

      case TypesOfInput.CHECKBOX:
        return <CheckboxField {...changedProps} />;

      case TypesOfInput.DATEPICKER:
        return <DateField {...changedProps} />;

      case TypesOfInput.TIMEPICKER:
        return <TimeField {...changedProps} />;

      case TypesOfInput.DATEPICKER_RANGE:
        return <DateRangeField {...changedProps} />;

      case TypesOfInput.FILE:
        return <FileField {...changedProps} />;

      case TypesOfInput.TEXT_EDITOR:
        return <TextEditorField {...changedProps} />;

      case TypesOfInput.TEXTAREA:
        return <TextAreaField {...changedProps} />;

      case TypesOfInput.LOCALIZATIONS:
        return <LocalizationSelect name={props.name} label={props.label} />;

      default:
        return <TextField {...changedProps} />;
    }
  };

  return (
    <div className={cn(`col-12 mb-3 col-md-${changedProps.size || 4}`)}>{renderFieldByType()}</div>
  );
};

export default Field;
