import { FieldArray, Form, Formik } from "formik";
import { useEffect, useMemo } from "react";
import { Button, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useCreateClientsStatisticsMutation } from "../../api/statistics-clients-api";
import Field from "../../components/common/form/formik/Field";
import PageTitle from "../../components/common/items/page-title";
import { createFakeStatisticsFields, createFakeStatisticsValidation } from "../../components/fields/create-fake-statistics";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { TypesOfInput } from "../../models/fields/IField";
import { CreateClientsStatisticsT } from "../../models/IClientsStatistics";
import { fetchGroupList } from "../../store/thunks/statuses";
import Loading from "../_layouts/Loading";


const CreateFakeStatistics = () => {
  const dispatch = useAppDispatch();
  const { groupList: statusesGroupList } = useAppSelector((state) => state.statusesSlice);
  const [clientsStatisticsMutation, { isLoading }] = useCreateClientsStatisticsMutation();

  useEffect(() => {
    dispatch(fetchGroupList());
  }, []);

  const initialValues: CreateClientsStatisticsT = useMemo(() => ({
    cap: 10,
    country: '',
    landing: '',
    language: '',
    sentAt: [null, null],
    timeEnd: '',
    timeStart: '',
    statuses: statusesGroupList.data.map(({ system }) => ({
      status: system,
      percent: Number((99 / statusesGroupList.data.length).toFixed(2)),
    }))
  }), [statusesGroupList]);

  const fields = useMemo(() => createFakeStatisticsFields(), [])

  const handleSubmit = (values: CreateClientsStatisticsT) => {
    clientsStatisticsMutation(values).then(() => {
      toast.success("Statistic successfully created");
    });
  };

  if (statusesGroupList.loading || !fields.length) {
    return <Loading />;
  }

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <div className="d-flex">
          <Link to="/leads" className="w-auto">
            <Button className="w-auto mb-3" variant="primary">
              Back
            </Button>
          </Link>
        </div>
        <PageTitle title="Create fake lead statistic" />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <p className="card-title">Edit Mode</p>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={createFakeStatisticsValidation}
                onSubmit={handleSubmit}
              >
                {({ values }) => {
                  const total = values.statuses.reduce((prev, curr) => prev + Number(curr.percent), 0);
                  const isCountInvalid = total > 100;                  
                  return (
                    <Form className="w-100 px-4">
                      <div className="row">
                        {fields.map((field) => (
                          <Field key={field.name} {...field} />
                        ))}
                        <div className="col-12 col-md-4">
                          <div className="row">
                            <label className="form-label-test">Time</label>
                            <Field name="timeStart" type={TypesOfInput.TIMEPICKER} size={6} label="" />
                            <Field name="timeEnd" type={TypesOfInput.TIMEPICKER} size={6} label="" />
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <p className="m-0">Total - {total.toFixed(2)}</p>
                        {isCountInvalid && <p className="invalid-feedback d-block m-0">the percentage must be less than 100</p>}
                        {isNaN(total) && <p className="invalid-feedback d-block m-0">percentage must be a number</p>}
                      </div>
                      <FieldArray
                        name="statuses"
                        render={() => <div className="row">
                          {statusesGroupList.data.map(({ system }, index) => (
                            <Field key={system} name={`statuses.${index}.percent`} label={system} placeholder={system} />
                          ))}</div>
                        }
                      />
                      <Button
                        type="submit"
                        className="form-control w-25 m-auto d-block"
                        variant="primary"
                        disabled={isLoading || isCountInvalid}
                      >
                        Create
                      </Button>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CreateFakeStatistics;
