import React, { useEffect, useMemo, useState } from "react";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { Link, useParams } from "react-router-dom";
import Field from "../../components/common/form/formik/Field";
import PageTitle from "../../components/common/items/page-title";
import Loading from "../_layouts/Loading";
import { emptyValues, getRatingOptions, IReviewChange } from "../../models/IReview";
import { changeItem, deleteItem, fetchItem } from "../../store/thunks/review";
import { createReviewFields, createReviewValidation } from "../../components/fields/reviews";
import LanguagesGroup from "../../components/common/form/LanguagesGroup";
import { ObjectWithKeys } from "../../types/global";
import { AnySchema } from "yup";
import { getLocalizationsFromData } from "../../utils/formats";
import { countriesSlice, getOpenedCountriesCodes } from "../../store/reducers/countriesSlice";
import { filterDataByCountries } from "../../api/utils";
import useModal from "../../hooks/useModal";

const ChangeItem = () => {
  const dispatch = useAppDispatch();
  const { currentItem } = useAppSelector((state) => state.reviewsReducer);
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState<IReviewChange>(emptyValues);
  const [validationSchema, setValidationSchema] = useState<AnySchema | null>(null);
  const { selectedCountry } = useAppSelector((state) => state.countriesSlice);
  const { setOpenedCountriesByKey } = countriesSlice.actions;
  const openedCountriesCodes = useAppSelector(getOpenedCountriesCodes);

  useEffect(() => {
    if (!id) return;
    dispatch(fetchItem(id));
  }, []);

  const handleDelete = () => {
    if (!currentItem.data) return;
    dispatch(deleteItem(currentItem.data.id));
  };

  const { ModalComp, handleShowModal } = useModal(handleDelete);

  useEffect(() => {
    if (!currentItem.loading && currentItem.data) {
      const localizations = getLocalizationsFromData(
        currentItem.data as unknown as ObjectWithKeys,
        ["name", "text", "answer"],
      );
      dispatch(setOpenedCountriesByKey(localizations));
    }
  }, [currentItem]);

  useEffect(() => {
    if (!currentItem.loading && currentItem.data) {
      const emptyObject: ObjectWithKeys = {};
      openedCountriesCodes.forEach((item) => {
        emptyObject[item] = "";
      });

      setInitialValues({
        ...currentItem.data,
        rating:
          getRatingOptions().find((option) => option.value === currentItem.data?.rating) || null,
        name: {
          ...emptyObject,
          ...filterDataByCountries(currentItem.data.name, openedCountriesCodes),
        },
        text: {
          ...emptyObject,
          ...filterDataByCountries(currentItem.data.text, openedCountriesCodes),
        },
        answer: {
          ...emptyObject,
          ...filterDataByCountries(currentItem.data.answer, openedCountriesCodes),
        },
      });
      const validation = createReviewValidation(openedCountriesCodes);
      setValidationSchema(validation);
    }
  }, [openedCountriesCodes]);

  const fields = useMemo(
    () =>
      createReviewFields({
        selectedCountry,
      }),
    [selectedCountry],
  );

  const handleSubmit = (values: IReviewChange) => {
    dispatch(changeItem(values));
  };

  if (currentItem.loading) {
    return <Loading />;
  }

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <div className="d-flex">
          <Link to="/reviews" className="w-auto">
            <Button className="w-auto mb-3" variant="primary">
              Back
            </Button>
          </Link>
          <Button className="w-auto mb-3" variant="danger" onClick={handleShowModal}>
            Delete
          </Button>
        </div>
        <PageTitle title="Edit review" />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <LanguagesGroup />
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {fields.map((field) => (
                      <Field
                        key={field.name}
                        {...field}
                        prefix={field.prefix === true ? selectedCountry?.code : false}
                      />
                    ))}
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                  >
                    Save
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
      </div>
      {ModalComp}
    </div>
  );
};

export default ChangeItem;
