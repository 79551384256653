import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { camelizeKeys, decamelizeKeys } from "humps";
import {
  DashboardAffiliate, DashboardCap,
  DashboardDeposit,
  DashboardGroupCaps,
  IDashboardStatuses,
  IDepositAmounts,
} from "../models/IDashboard";
import { StatisticRequest } from "../models/IStatistics";
import { ListRes, baseURL, prepareHeaders, ListResWithoutPag } from "./utils";

const baseUrl = `${baseURL}/v1/dashboard`;

export const dashboardApi = createApi({
  reducerPath: "dashboardApi",
  tagTypes: ["Dashboard"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    getDashboardStatusesList: build.query<IDashboardStatuses[], void>({
      query: () => ({
        url: "statuses",
        method: "get",
      }),
      transformResponse: (data: ListRes<IDashboardStatuses>) => camelizeKeys(
        data.list.map(item => ({
          ...item,
          order: item.status === "hold" ? 1 : item.status === "send" ? 2 : item.status === "created" ? 3 : 4
        }))
      ) as IDashboardStatuses[],
    }),
    getDashboardCapsList: build.query<DashboardCap[], void>({
      query: () => ({
        url: "caps",
        method: "get",
      }),
      transformResponse: (response: ListRes<DashboardCap>) => camelizeKeys(response.list) as DashboardCap[],
    }),
    getDashboardAffiliatesList: build.query<DashboardAffiliate[], void>({
      query: () => ({
        url: "affiliates",
        method: "get",
      }),
      transformResponse: (response: ListRes<DashboardAffiliate>) => camelizeKeys(response.list) as DashboardAffiliate[],
    }),
    useGetDashboardDepositsList: build.query<DashboardDeposit[], void>({
      query: () => ({
        url: "deposits",
        method: "get",
      }),
      transformResponse: (response: ListRes<DashboardDeposit>) => camelizeKeys(response.list) as DashboardDeposit[],
    }),
    useGetDashboardGroupCaps: build.query<DashboardGroupCaps, void>({
      query: () => ({
        url: "group-caps",
        method: "get",
      }),
      transformResponse: (response: ListRes<DashboardGroupCaps>) => camelizeKeys(response.list) as unknown as DashboardGroupCaps,
    }),
    getDepositAmounts: build.mutation<IDepositAmounts[], StatisticRequest>({
      query: (filter) => ({
        url: "deposit-amounts",
        method: "post",
        body: decamelizeKeys({
          ...filter,
          filter: {
            ...filter.filter,
            "client.type": "cpa,crg"
          }
        }),
      }),
      transformResponse: (response: ListResWithoutPag<IDepositAmounts>) => camelizeKeys(response.list) as IDepositAmounts[],
    }),
  }),
});

export const {
  useLazyGetDashboardAffiliatesListQuery,
  useLazyGetDashboardCapsListQuery,
  useLazyGetDashboardStatusesListQuery,
  useLazyUseGetDashboardDepositsListQuery,
  useLazyUseGetDashboardGroupCapsQuery,
  useGetDepositAmountsMutation
} = dashboardApi;
