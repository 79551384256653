import React, { useMemo } from "react";
import { Formik, Form, FormikProps } from "formik";
import { useAppDispatch } from "../../../hooks/redux";
import Field from "../../../components/common/form/formik/Field";
import { updateClients } from "../../../store/thunks/clients";
import { UpdateSomeClientsActions } from "../../../models/IClients";
import { editMassFields } from "../../../components/fields/create-client";

const initialValues: UpdateSomeClientsActions = {
  status: null,
};

interface Props {
  onClose: VoidFunction;
  formikRef: React.Ref<FormikProps<UpdateSomeClientsActions>>,
}

const MassEditModal = ({ onClose, formikRef }: Props) => {
  const dispatch = useAppDispatch();
  const fields = useMemo(() => editMassFields(), []);

  const handleSubmit = (values: UpdateSomeClientsActions) => {
    dispatch(updateClients(values)).then(onClose);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} innerRef={formikRef}>
      <Form className="w-100 px-4">
        <div className="row">
          {fields.map((field) => (
            <Field key={field.name} {...field} />
          ))}
        </div>
      </Form>
    </Formik>
  );
};

export default MassEditModal;
