import React from "react";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import { useAppDispatch } from "../../hooks/redux";
import { Link } from "react-router-dom";
import Field from "../../components/common/form/formik/Field";
import PageTitle from "../../components/common/items/page-title";
import {
  createDictionaryWordFields,
  createDictionaryWordValidation,
} from "../../components/fields/create-dictionaryWord";
import { emptyValues, IDictionaryChange } from "../../models/IDictionaryWords";
import { changeDictionaryWord } from "../../store/thunks/dictionaryWords";

const CreateDictionaryWord = () => {
  const dispatch = useAppDispatch();

  const handleSubmit = (values: IDictionaryChange) => {
    dispatch(changeDictionaryWord(values));
  };

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <Link to="/dictionary-words" className="w-auto">
          <Button className="w-auto mb-3" variant="primary">
            Back
          </Button>
        </Link>
        <PageTitle title="Creating DictionaryWord" />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <Formik
                enableReinitialize
                initialValues={emptyValues}
                validationSchema={createDictionaryWordValidation}
                onSubmit={handleSubmit}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {createDictionaryWordFields.map((field) => (
                      <Field key={field.name} {...field} />
                    ))}
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                  >
                    Create
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CreateDictionaryWord;
