import React, { useEffect, useMemo, useState } from "react";
import PageTitle from "../../components/common/items/page-title";
import TransactionsTable from "../../components/transactions/TransactionsTable";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { transactionsSlice } from "../../store/reducers/transactionsSlice";
import { fetchTransactions } from "../../store/thunks/transactions";
import { Button } from "react-bootstrap";
import FilterForm from "../../components/common/form/formik/FilterForm";
import { balanceHistoryFilters } from "../../components/fields/filters-transaction";
import { IFilterTransaction, emptyFilters } from "../../models/ITransactions";
import { fetchShortClients } from "../../store/thunks/clients";
import { Link } from "react-router-dom";
import { FetchOptionsParams } from "../../types/global";
import { fetchLeads } from "../../store/thunks/leads";

const Transactions = () => {
  const dispatch = useAppDispatch();
  const { filter } = useAppSelector(state => state.transactionsReducer);
  const { leads } = useAppSelector(state => state.leadsReducer);
  const { clientsShort } = useAppSelector(state => state.clientsReducer);
  const { setFilters, resetFilters } = transactionsSlice.actions;

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  useEffect(() => {
    requestTransactions();
    return () => {dispatch(setFilters(emptyFilters))}
  }, []);

  const requestTransactions = () => {
    dispatch(fetchTransactions());
  };

  const handleFilter = (values: IFilterTransaction) => {
    dispatch(setFilters(values));
    requestTransactions();
  };

  const fields = useMemo(
    () =>
      balanceHistoryFilters({
        asyncClients: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchShortClients(params)),
          isLoading: clientsShort.loading,
        },
        clientOptions: clientsShort.data,
        leadOptions: leads.data,
        asyncLeads: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchLeads(params)),
          isLoading: leads.loading
        }
      }),
    [clientsShort, leads],
  );

  return (
    <>
      <div className="container-fluid px-lg-4 px-xl-5">
        <PageTitle title={"Transactions"} />
        <div className="row">
          <div className="d-flex mb-3 justify-between">
            <Button variant="primary" onClick={() => setIsFilterOpen((prevState) => !prevState)}>
              {isFilterOpen ? "Close" : "Open"} Filters
            </Button>
            <Link className="btn btn-primary" to="/transactions/new">Create Transaction</Link>
          </div>
          {isFilterOpen && (
            <FilterForm
              className="mb-3"
              fields={fields}
              handleSubmit={handleFilter}
              handleReset={() => dispatch(resetFilters())}
              initialValues={filter}
            />
          )}
          <section className="mb-5">
            <div className="card">
              <div className="card-body">
                <TransactionsTable className="min-w-screen-lg" />
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Transactions;
