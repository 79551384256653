import React from "react";
import { NavLink } from "react-router-dom";
import { useAppDispatch } from "../../hooks/redux";
import { Button } from "react-bootstrap";
import { authSlice } from "../../store/reducers/authSlice";

interface Props {
  toggleMenuHandle: VoidFunction;
}

const Header = ({ toggleMenuHandle }: Props) => {
  const dispatch = useAppDispatch();
  const { logout } = authSlice.actions;

  const logoutHandle = () => {
    dispatch(logout());
  };

  return (
    <>
      <header className="header">
        <nav className="navbar navbar-expand-lg px-4 py-2 bg-white shadow">
          <a className="sidebar-toggler text-gray-500 me-4 me-lg-5 lead" onClick={toggleMenuHandle}>
            <i className="fas fa-align-left" />
          </a>
          <NavLink to="/dashboard" className="navbar-brand fw-bold text-uppercase text-base">
            <span className="d-none d-brand-partial">Mini </span>
            <span className="d-none d-sm-inline">LBDT</span>
          </NavLink>
          <ul className="ms-auto d-flex align-items-center liaria-haspopupst-unstyled mb-0">
            <li className="nav-item dropdown me-2 me-lg-3">
              <Button onClick={logoutHandle}>
                Logout
              </Button>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};

export default Header;
