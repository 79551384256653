import { PaginationQuery } from "../components/common/tables/types";
import { ListItem, Modify } from "../types/global";
import { GenericOption, OptionModel } from "./fields/ISelect";
import { IOffer, OfferType } from "./IOffer";

export type PushStatus = "visited" | "installed" | "opened" | "registered" | "deposit";
export type PushType = "regular" | "single";

export type IPush = ListItem<
  Modify<
    IPushChange,
    {
      status: PushStatus;
      startDate: null | string;
      isSend: number;
      offer: IOffer;
      type: PushType;
      offerType: OfferType;
    }
  >
>;

export type IPushChange = {
  title: string;
  body: string;
  startDate?: string | Date;
  isSend?: boolean;
  icon?: File | null;
  locale: string;
  status: OptionModel<GenericOption<PushStatus>>;
  offer?: OptionModel<IOffer> | null;
  type: OptionModel<GenericOption<PushType>>;
  offerType: OptionModel<GenericOption<OfferType>>;
  afterTime: number;
};

export type IPushFilters = Omit<IPushChange, "icon">;
export type IPushFiltersRequest = PaginationQuery & Omit<IPushChangeRequest, "icon">;

export type IPushChangeRequest = Modify<
  Partial<IPushChange>,
  {
    status: PushStatus | "";
    type: PushType | "";
    offerType: OfferType | "";
    offerId: string | null;
    isSend: number;
  }
>;

export type RequestPush = PaginationQuery & IPushFiltersRequest;

export const statusOptions: GenericOption<PushStatus>[] = [
  {
    label: "Visited",
    value: "visited",
  },
  {
    label: "Installed",
    value: "installed",
  },
  {
    label: "Opened",
    value: "opened",
  },
];

export const typeOptions: GenericOption<PushType>[] = [
  {
    label: "Regular",
    value: "regular",
  },
  {
    label: "Single",
    value: "single",
  },
];

export const emptyFilters: IPushFilters = {
  title: "",
  status: null,
  locale: "",
  body: "",
  isSend: false,
  offer: null,
  type: null,
  offerType: null,
  afterTime: 72,
};

export const emptyValues: IPushChange = {
  ...emptyFilters,
  startDate: new Date(),
  icon: null,
};
