import React from "react";
import ColorLabel from "../components/common/items/ColorLabel";
import { ColumnDef } from "../components/common/tables/types";
import { typeOptions } from "../models/IClients";
import { DashboardDeposit } from "../models/IDashboard";
import { formatDateFullTime } from "./formats";

type IColumn = ColumnDef<DashboardDeposit>;

export default function getDepositColumnDefs(
  handleFilterByEmail: (email: DashboardDeposit["email"]) => void,
): IColumn[] {
  const email: IColumn = {
    Cell: (cell) => (
      <span onClick={() => handleFilterByEmail(cell.value)} role="button" className="p-1 btn-link">
        {cell.value}
      </span>
    ),
    Header: "Email",
    accessor: "email",
    id: "email",
    width: "200px",
  };

  const price: IColumn = {
    Header: "Price",
    accessor: "price",
    id: "price",
    width: "60px",
  };

  const affiliate: IColumn = {
    Header: "Affiliate",
    accessor: "affiliate",
    id: "affiliate",
    width: "100px",
  };

  const client: IColumn = {
    Cell: (cell) => (
      <div>
        <ColorLabel className="d-inline mr-2" colors={typeOptions} value={cell.row.original.clientType} label={cell.row.original.clientType.toUpperCase()}  />
        {cell.value}
      </div>
    ),
    Header: "Client",
    accessor: "client",
    id: "client",
    width: "200px",
  };

  const landing: IColumn = {
    Header: "Landing",
    accessor: "landing",
    id: "landing",
    width: "100px",
  };

  const locale: IColumn = {
    Header: "Locale",
    accessor: "locale",
    id: "locale",
    width: "100px",
  };

  const depositedAt: IColumn = {
    Cell: (cell) => <span>{formatDateFullTime(cell.value)}</span>,
    Header: "Deposited at",
    accessor: "depositedAt",
    id: "depositedAt",
    width: "100px",
  };

  const createdAt: IColumn = {
    Cell: (cell) => <span>{formatDateFullTime(cell.value)}</span>,
    Header: "Created at",
    accessor: "createdAt",
    id: "createdAt",
    width: "100px",
  };

  return [
    email,
    price,
    affiliate,
    client,
    landing,
    locale,
    depositedAt,
    createdAt,
  ];
}
