import { createAsyncThunk } from "@reduxjs/toolkit";
import * as localizationsApi from "../../api/localizations-api";
import { ILocalizationChange } from "../../models/ILocalizations";
import { FetchOptionsParams } from "../../types/global";
import { localizationsState } from "../reducers/localizationsSlice";

export const fetchLocalizations = createAsyncThunk(
  "localizations/fetchLocalizations",
  (params: FetchOptionsParams, thunkAPI) => {
    const state: FixMeLater = thunkAPI.getState();
    const localizationsState: localizationsState = state.localizationsReducer;

    const options = {
      filter: localizationsState.filter,
      page: localizationsState.pagination.page,
      take: localizationsState.pagination.take,
      searchTerm: params.searchTerm,
    };

    if (!params.searchTerm) {
      delete options.searchTerm;
    }
    return localizationsApi.getLocalizations(options);
  },
);

export const fetchOneLocalization = createAsyncThunk(
  "localizations/fetchOneLocalization",
  (id: string) => localizationsApi.getLocalization(id),
);

export const changeLocalization = createAsyncThunk(
  "localizations/changeLocalization",
  (values: ILocalizationChange & { id?: string }) => localizationsApi.changeLocalization(values),
);
