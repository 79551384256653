import { useCallback } from "react";
import { useNavigate } from "react-router";
import { ColumnDef } from "../../../components/common/tables/types";
import { IReplacementsStatisticClients, ReplacementsFilters } from "../../../models/IStatistics";
import { formatDateToBack, formatDateToState } from "../../../utils/formats";

type IColumn = ColumnDef<IReplacementsStatisticClients>;

export default function useGetStatisticClientsColumnDefs(filter: ReplacementsFilters): IColumn[] {
  const navigate = useNavigate();

  const handleFilter = useCallback((clientId: string) => {
    const params = new URLSearchParams({
      clientId,
      isValid: 'false',
      isReplace: 'false',
    });
    if (filter.sentAt[0] && filter.sentAt[1]) {3
      params.append("sentAt[0]", `${formatDateToState(formatDateToBack(filter.sentAt[0]))}`)
      params.append("sentAt[1]", `${formatDateToState(formatDateToBack(filter.sentAt[1]))}`)
    }
    if (filter.localization) {
      params.append("localizationIds", filter.localization.map(item => item?.id).join(','))
    }
    if (filter.affiliate) params.append("affiliateId", filter.affiliate.id)
    
    navigate(`/leads?${params.toString()}`);
  }, [filter]);

  const client: IColumn = {
    Cell: ({ value }) => <span>{value.name}</span>,
    Header: "Client",
    accessor: "client",
    width: "auto",
    id: "client",
  };

  const total: IColumn = {
    Header: "Total",
    accessor: "leads",
    width: "auto",
    id: "sum",
  };

  const approved: IColumn = {
    Header: "Approved",
    accessor: "approved",
    width: "auto",
    id: "approved",
  };

  const notApproved: IColumn = {
    Cell: ({ value, row }) => <span onClick={() => handleFilter(row.original.clientId)} className="btn-link c-pointer">{value}</span>,
    Header: "Not Approved",
    accessor: "notApproved",
    width: "auto",
    id: "notApproved",
  };

  const amountCredited: IColumn = {
    Cell: ({ value, cell }) => <span>{value * cell.row.original.client.price}</span>,
    Header: "Amount Credited",
    accessor: "notApproved",
    width: "auto",
    id: "amountCredited",
  };

  const percent: IColumn = {
    Cell: ({ value, cell }) => <span>{(value * 100 / cell.row.original.leads).toFixed(2)}%</span>,
    Header: "Percent",
    accessor: "approved",
    width: "auto",
    id: "percent",
  };

  return [client, total, approved, notApproved, amountCredited, percent];
}
