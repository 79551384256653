import { Modify } from "../types/global";
import { GenericOption, OptionModel } from "./fields/ISelect";

export type PlaceType = "header" | "body" | "uri";
export type TokenMethodType = "post" | "get";
export type FormatType = "json" | "plain_text";
export type TypeParameterToken = "header" | "body";

export type ParameterTokenTypeChange = {
  key: string;
  value: string;
  type: OptionModel<GenericOption<TypeParameterToken>>;
};

export type ParameterTokenType = {
  key: string;
  value: string;
  type: TypeParameterToken;
};

export type IToken = Modify<
  ITokenChange,
  {
    place: PlaceType;
    method: TokenMethodType;
    parameters: ParameterTokenType[];
    format: FormatType;
  }
>;

export type ITokenChange = {
  place: OptionModel<GenericOption<PlaceType>>;
  method: OptionModel<GenericOption<TokenMethodType>>;
  key: string;
  answer: string;
  extraString: string;
  url: string;
  parameters: ParameterTokenTypeChange[];
  format: OptionModel<GenericOption<FormatType>>;
};

export type ITokenChangeForm = {
  place: PlaceType;
  method: TokenMethodType;
  format: FormatType;
  key: string;
  answer: string;
  extraString: string;
  url: string;
  "parameters.key": string;
  "parameters.value": string;
  "parameters.type": TypeParameterToken;
};

export const placeOptions: GenericOption<PlaceType>[] = [
  {
    label: "Header",
    value: "header",
  },
  {
    label: "Body",
    value: "body",
  },
  {
    label: "Uri",
    value: "uri",
  },
];

export const tokenMethodOptions: GenericOption<TokenMethodType>[] = [
  {
    label: "Get",
    value: "get",
  },
  {
    label: "Post",
    value: "post",
  },
];

export const formatOptions: GenericOption<FormatType>[] = [
  {
    label: "JSON",
    value: "json",
  },
  {
    label: "Plain text",
    value: "plain_text",
  },
];

export const parameterTokenOptions: GenericOption<TypeParameterToken>[] = [
  {
    label: "Header",
    value: "header",
  },
  {
    label: "Body",
    value: "body",
  },
];

export const emptyValues: ITokenChange = {
  answer: "",
  extraString: "",
  format: formatOptions.find((option) => option.value === "json") || null,
  key: "",
  method: tokenMethodOptions.find((option) => option.value === "post") || null,
  parameters: [],
  place: placeOptions.find((option) => option.value === "header") || null,
  url: "",
};
