import { ILog, RequestLogs } from "../models/ILog";
import { Api, filterEmpyValues, ListRes, ModelRes } from "./utils";
import { getResponseData } from "./utils";

const LEADS_PATH = "/v1/logs";

export const getLogs = (params: RequestLogs): Promise<ListRes<ILog>> => (
  Api.post(`${LEADS_PATH}/list`, filterEmpyValues(params)).then(getResponseData)
);

export const getOneLog = (id: string): Promise<ModelRes<ILog>> => {
  return Api.get(`${LEADS_PATH}/${id}`).then(getResponseData);
};
