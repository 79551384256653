import React, { useRef, useState, useMemo, useEffect } from "react";
import { ButtonGroup, Button, Form, Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import countriesJson from "../../../countries.json";
import { ICountry } from "../../../models/ILocalizations";
import { FormikProps, Formik } from "formik";
import { TypesOfInput } from "../../../models/fields/IField";
import Field from "./formik/Field";
import { countriesSlice } from "../../../store/reducers/countriesSlice";
import Icon from "../items/Icon";

interface InitialValues {
  countries: ICountry[];
}

const LanguagesGroup = () => {
  const dispatch = useAppDispatch();
  const formikRef: React.Ref<FormikProps<InitialValues>> | null = useRef(null);
  const countries: ICountry[] = countriesJson;

  const [isShowModel, setIsShowModel] = useState(false);
  const { selectedCountry, openedCountries } = useAppSelector((state) => state.countriesSlice);
  const { setSelectedCountry, addOpenedCountry, deleteCountry, setOpenedCountries } =
    countriesSlice.actions;

  const handleAddLanguage = (values: InitialValues) => {
    dispatch(addOpenedCountry(values.countries));
    setIsShowModel(false);
  };

  useEffect(() => {
    return () => {
      dispatch(setOpenedCountries([]));
    };
  }, []);

  useEffect(() => {
    if (!openedCountries.length) {
      dispatch(addOpenedCountry([countries[countries.length - 1]]));
      dispatch(setSelectedCountry(countries[countries.length - 1]));
    }
  }, [selectedCountry]);

  const hidenCountries = useMemo(
    () => countries.filter((item) => !openedCountries.includes(item)),
    [openedCountries, countries],
  );

  const handleDeleteCountry = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    country: ICountry,
  ) => {
    e.stopPropagation();
    dispatch(deleteCountry(country));
  };

  return (
    <div>
      <ButtonGroup className="mb-4 ml-4 flex-wrap">
        {openedCountries.map((country) => (
          <Button
            size="sm"
            key={country.code}
            variant={country.code === selectedCountry?.code ? "primary" : "outline-primary"}
            onClick={() => dispatch(setSelectedCountry(country))}
          >
            {country.name}
            {country.code !== selectedCountry?.code && (
              <Button variant="danger" size="sm" onClick={(e) => handleDeleteCountry(e, country)}>
                <Icon name="bin-1" class="svg-icon-sm" />
              </Button>
            )}
          </Button>
        ))}
        {Boolean(hidenCountries.length) && (
          <Button size="sm" variant="outline-primary" onClick={() => setIsShowModel(true)}>
            Add language
          </Button>
        )}
      </ButtonGroup>
      <Modal show={isShowModel} onHide={() => setIsShowModel(false)} size="lg" centered>
        <Modal.Header closeButton />
        <Modal.Body>
          <Formik
            initialValues={{
              countries: [],
            }}
            onSubmit={handleAddLanguage}
            innerRef={formikRef}
          >
            <Form className="w-100">
              <Field
                type={TypesOfInput.SELECT}
                name="countries"
                label=""
                labelName="name"
                valueName="code"
                options={hidenCountries}
                isMulti
                size={12}
              />
            </Form>
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsShowModel(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => formikRef.current?.handleSubmit()}>
            Add language
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LanguagesGroup;
