import React, { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";

import { useGetDepositAmountsMutation, useLazyUseGetDashboardDepositsListQuery } from "../../../api/dashboard-api";
import {
  useGetAffiliateProfitMutation,
  useGetAffiliatesMutation,
  useGetClientsMutation,
  // useGetDepositsMutation,
} from "../../../api/statistics-reports-api";
import { filterEmpyValues } from "../../../api/utils";
import FilterForm from "../../../components/common/form/formik/FilterForm";
import { filterReportsFields } from "../../../components/fields/filters-statistics";
import DepositCard from "../../../components/ui/DepositCard";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import useRefreshPage from "../../../hooks/useRefreshPage";
import {
  StatisticReportsFilters,
  StatisticReportsFiltersRequest,
  StatisticRequest,
  StatisticRequestType,
  filters,
} from "../../../models/IStatistics";
import { fetchShortAffiliates } from "../../../store/thunks/affiliates";
import { fetchShortClients } from "../../../store/thunks/clients";
import { fetchLabels } from "../../../store/thunks/labels";
import { fetchLocalizations } from "../../../store/thunks/localizations";
import { FetchOptionsParams } from "../../../types/global";
import Loading from "../../_layouts/Loading";
import StatisticAffiliates from "./Affiliates";
import StatisticClients from "./Clients";
import Deposits from "./Deposits";
import AffiliateProfit from "./AffiliateProfit";
import DepositAmounts from "../../../components/ui/DepositAmounts";
import { UserRole } from "../../../models/IUsers";

const AffiliateReports = () => {
  const dispatch = useAppDispatch();
  const { auth } = useAppSelector((state) => state.authSlice);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [savedFilters, setSavedFilters] = useState<StatisticReportsFilters>(filters);
  const { affiliatesShort } = useAppSelector((state) => state.affiliatesReducer);
  const { clientsShort } = useAppSelector((state) => state.clientsReducer);
  const { labels } = useAppSelector((state) => state.labelsReducer);
  const { localizations } = useAppSelector((state) => state.localizationsReducer);
  const [fetchDeposits, { data: depositsList, isLoading: isLoadingDeposits, isFetching: isRefetchingDeposits }] = useLazyUseGetDashboardDepositsListQuery();

  const role = useMemo(() => auth.user?.roles || UserRole.ADMIN, [auth]);

  useRefreshPage(
    () => {
      fetchDeposits();
    },
    [],
    "/reports",
  );

  useEffect(() => {
    handleFilter(filters);
  }, []);

  // const [depositsMutation, { data: depositsData, isLoading: depositsIsLoading }] =
  //   useGetDepositsMutation();
  const [affiliatesMutation, { data: affiliatesData, isLoading: affiliatesIsLoading }] =
    useGetAffiliatesMutation();
  const [clientsMutation, { data: clientsData, isLoading: clientsIsLoading }] =
    useGetClientsMutation();
  const [affiliateProfitMutation, { data: affiliateProfitData, isLoading: affiliateProfitIsLoading }] =
    useGetAffiliateProfitMutation();
  const [depositAmountsMutation, { data: depositAmountsData, isLoading: depositAmountsIsLoading }] =
  useGetDepositAmountsMutation();
  const isLoading = (
    // depositsIsLoading ||
    affiliatesIsLoading || clientsIsLoading || isLoadingDeposits || isRefetchingDeposits || affiliateProfitIsLoading || depositAmountsIsLoading
  );

  const fields = useMemo(
    () =>
      filterReportsFields({
        asyncAffiliates: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchShortAffiliates(params)),
          isLoading: affiliatesShort.loading,
        },
        asyncClients: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchShortClients(params)),
          isLoading: clientsShort.loading,
        },
        asyncLabels: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchLabels(params)),
          isLoading: labels.loading,
        },
        asyncLocalizations: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchLocalizations(params)),
          isLoading: localizations.loading,
        },
        localizationsOptions: localizations.data,
        affiliatesOptions: affiliatesShort.data,
        clientsOptions: clientsShort.data,
        labelsOptions: labels.data,
        role,
      }),
    [affiliatesShort, clientsShort, localizations, labels, role],
  );

  const handleFilter = async (filters: StatisticReportsFilters) => {
    const filtersRequest: StatisticReportsFiltersRequest = {
      affiliateId: filters.affiliates.map((option) => option.id),
      clientId: filters.clients.map((option) => option.id),
      "client.label_id": filters.labels.map((option) => option.id),
      "client.type": filters.clientType?.map((option) => option?.value).join(','),
      sentAt: filters.sentAt,
      createdAt: filters.createdAt,
      localizationId: filters.localization?.map(item => item?.id).join(','),
      isValid: filters.isValid?.value !== null ? filters.isValid.value : "",
      isReplace: filters.isReplace?.value !== null ? filters.isReplace.value : "",
      hasDeposit: filters.hasDeposit?.value !== null ? filters.hasDeposit.value : "",
    };

    setSavedFilters(filters);
    fetchStatistics(filtersRequest);
    return;
  };

  type FilteredFiltersT<F = UnknownRecord> = {
    type: StatisticRequestType;
    filter: F;
    id?: string[];
  }

  const fetchDepositAmounts = (filtersRequest?: StatisticReportsFiltersRequest) => {
    type DepositAmountsFiltersT = StatisticReportsFiltersRequest & { depositedAt: StatisticReportsFiltersRequest['sentAt'] }
    const depositAmountsFilters: DepositAmountsFiltersT = {
      ...filtersRequest,
      depositedAt: filtersRequest?.sentAt,
    } as DepositAmountsFiltersT;
    delete depositAmountsFilters.sentAt;
    const filteredFilters: FilteredFiltersT<DepositAmountsFiltersT> = {
      type: "query" as StatisticRequestType,
      filter: (depositAmountsFilters ? filterEmpyValues(depositAmountsFilters) : {}) as DepositAmountsFiltersT,
      id: filtersRequest?.clientId || [],
    };
    if (!filteredFilters.id?.length) {
      delete filteredFilters.id;
    }
    depositAmountsMutation(filteredFilters).unwrap();
  }

  const fetchStatistics = (filtersRequest?: StatisticReportsFiltersRequest) => {
    const filteredFilters: FilteredFiltersT<StatisticReportsFiltersRequest> = {
      type: "query" as StatisticRequestType,
      filter: (filtersRequest ? filterEmpyValues(filtersRequest) : {}) as StatisticReportsFiltersRequest,
      id: filtersRequest?.clientId || [],
    };
    if (!filteredFilters.id?.length) {
      delete filteredFilters.id;
    }
    affiliatesMutation(filteredFilters).unwrap();
    clientsMutation(filteredFilters).unwrap();
    affiliateProfitMutation(filteredFilters).unwrap();

    (filteredFilters.filter as FixMeLater).depositedAt_from = (filtersRequest as FixMeLater)?.sentAt_from;
    (filteredFilters.filter as FixMeLater).depositedAt_to = (filtersRequest as FixMeLater)?.sentAt_to;

    fetchDepositAmounts(filtersRequest)
    
    // const depositFilters = {
    //   type: filteredFilters.type,
    //   filter: filterEmpyValues({
    //     affiliateId: filteredFilters.filter.affiliateId,
    //     type: filteredFilters.filter["client.type"],
    //     client: { label_id: filteredFilters.filter["client.label_id"] },
    //     depositedAt: filteredFilters.filter.depositedAt || [null, null],
    //     createdAt: filteredFilters.filter.createdAt || [null, null],
    //     id: filteredFilters.filter.clientId,
    //   }),
    // } as StatisticRequest;

    // depositsMutation(depositFilters).unwrap();
  };

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <Button
        variant="primary"
        className="mb-3"
        onClick={() => setIsFilterOpen((prevState) => !prevState)}
      >
        {isFilterOpen ? "Close" : "Open"} Filters
      </Button>
      {isFilterOpen && (
        <FilterForm
          className="mb-3"
          fields={fields}
          handleSubmit={handleFilter}
          initialValues={filters}
        />
      )}
      {isLoading ? (
        <Loading />
      ) : (
        <div className="row">
          {depositAmountsData && (
            <div className="col-md-6">
              <DepositAmounts list={depositAmountsData} />
            </div>
          )}
          {depositsList && (
            <div className="col-md-12">
              <DepositCard list={depositsList} />
            </div>
          )}
          {role !== 'affiliate_manager' && affiliatesData && (
            <div className="col-md-12">
              <StatisticAffiliates data={affiliatesData} filters={savedFilters} />
            </div>
          )}
          {role !== 'affiliate' && role !== 'team_lead' && (
            <div className="col-md-12">
              <StatisticClients filter={savedFilters} data={clientsData} />
            </div>
          )}
          {/*<div className="col-md-6">*/}
          {/*  <Deposits data={depositsData} />*/}
          {/*</div>*/}
          {role !== 'affiliate_manager'  && affiliateProfitData && (
            <div className="col-md-12">
              <AffiliateProfit data={affiliateProfitData} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AffiliateReports;
