import React, { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { FixTypeLater } from "react-redux";

import {
  useGetReplacemetsAffiliatesMutation,
  useGetReplacemetsClientsMutation
} from "../../../api/statistics-replacements-api";
import FilterForm from "../../../components/common/form/formik/FilterForm";
import { filterReplacementsFields } from "../../../components/fields/filters-statistics";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { superRolesWithPM } from "../../../models/IAuth";
import {
  ReplacementsFilters,
  replacementsFilters,
} from "../../../models/IStatistics";
import { UserRole } from "../../../models/IUsers";
import { fetchShortAffiliates } from "../../../store/thunks/affiliates";
import { fetchLocalizations } from "../../../store/thunks/localizations";
import { FetchOptionsParams } from "../../../types/global";
import StatisticAffiliates from "./Affiliates";
import StatisticClients from "./Clients";

const Replacements = () => {
  const dispatch = useAppDispatch();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { affiliatesShort } = useAppSelector((state) => state.affiliatesReducer);
  const { localizations } = useAppSelector((state) => state.localizationsReducer);
  const [savedFilters, setSavedFilters] = useState<ReplacementsFilters>(replacementsFilters);
  const { auth } = useAppSelector((state) => state.authSlice);
  const role = useMemo(() => auth.user?.roles, [auth]);

  useEffect(() => {
    handleFilter(replacementsFilters);
  }, []);

  const [clientsMutation, { data: clientsData, isLoading: clientsIsLoading }] = useGetReplacemetsClientsMutation();
  const [affiliatesMutation, { data: affiliatesData, isLoading: affiliatesIsLoading }] = useGetReplacemetsAffiliatesMutation();

  const fields = useMemo(
    () =>
      filterReplacementsFields({
        asyncAffiliates: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchShortAffiliates(params)),
          isLoading: affiliatesShort.loading,
        },
        asyncLocalizations: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchLocalizations(params)),
          isLoading: localizations.loading,
        },
        localizationsOptions: localizations.data,
        affiliatesOptions: affiliatesShort.data,
      }),
    [affiliatesShort, localizations],
  );

  const handleFilter = (filter: ReplacementsFilters) => {
    setSavedFilters(filter);
    let arr = [affiliatesMutation(filter).unwrap()];
    if (superRolesWithPM.includes(role ?? UserRole.ADMIN)) arr = [...arr, clientsMutation(filter).unwrap() as FixTypeLater];
    return Promise.all(arr);
  };

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <Button
        variant="primary"
        className="mb-3"
        onClick={() => setIsFilterOpen((prevState) => !prevState)}
      >
        {isFilterOpen ? "Close" : "Open"} Filters
      </Button>
      {isFilterOpen && (
        <FilterForm
          className="mb-3"
          fields={fields}
          handleSubmit={handleFilter}
          initialValues={replacementsFilters}
        />
      )}
      <div className="row">
        <div className="col-12">
          {superRolesWithPM.includes(role ?? UserRole.ADMIN) && (
            <StatisticClients data={clientsData} isLoading={clientsIsLoading} filter={savedFilters} />
          )}
        </div>
        <div className="col-12">
          <StatisticAffiliates data={affiliatesData} isLoading={affiliatesIsLoading} filter={savedFilters} />
        </div>
      </div>
    </div>
  );
};

export default Replacements;
