import { RequestList } from "../components/common/tables/types";
import { Api, filterEmpyValues, ListRes, ModelRes, formApi } from "./utils";
import { getResponseData } from "./utils";
import { ITagFilters, ITag, ITagChange } from "../models/ITag";

const baseURL = "/v1/tags";

export const getList = (params: RequestList<ITagFilters>): Promise<ListRes<ITag>> => {
  return Api.post(baseURL + "/list", filterEmpyValues(params)).then(getResponseData);
};

export const changeItem = (data: ITagChange): Promise<ModelRes<ITag>> => {
  return formApi.post(baseURL, data).then(getResponseData);
};

export const getItem = (id: string): Promise<ModelRes<ITag>> => {
  return Api.get(`${baseURL}/${id}`).then(getResponseData);
};

export const deleteItem = (id: string) => {
  return Api.delete(`${baseURL}/${id}`);
};
