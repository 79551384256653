import React, { memo, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { IApplications } from "../../models/IApplications";
import { SettingsTableKey } from "../../models/IUserSettings";
import { applicationsSlice } from "../../store/reducers/applicationsSlice";
import { fetchList } from "../../store/thunks/offer";
import Table from "../common/tables/Table";
import getColumnDefs from "./ColumnDefs";

interface Props {
  className?: string;
  isSmall?: boolean;
  data: IApplications[];
}

const ApplicationsTable = ({ className, isSmall, data }: Props) => {
  const dispatch = useAppDispatch();
  const { pagination } = useAppSelector((state) => state.applicationsSlice);
  const { setPagination } = applicationsSlice.actions;

  const onChangePagination = (page: number) => {
    dispatch(setPagination({ page }));
    dispatch(fetchList({}));
  };

  const onChangePerPage = (take: number) => {
    dispatch(setPagination({ take }));
    dispatch(fetchList({}));
  };

  const columns = useMemo(
    () =>
      getColumnDefs({
        pagination,
        isSmall,
      }),
    [pagination, data, isSmall],
  );

  return (
    <Table<IApplications>
      pageKey={SettingsTableKey.APPLICATIONS}
      changePage={onChangePagination}
      changePageSize={onChangePerPage}
      className={className}
      columns={columns}
      containerClass="overflow-auto"
      fixed
      items={data}
      pagination={isSmall ? undefined : pagination}
    />
  );
};

export default memo(ApplicationsTable);
