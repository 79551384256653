import {
  IIntegrationChange,
  IIntegrationChangeRequest,
  IIntegrationModel,
  QuickIntegration,
  RequestIntegrations,
} from "../models/IIntegration";
import { ITokenChange } from "../models/IIntegrationToken";
import { isJsonString } from "../utils/formats";
import { Api, filterEmpyValues, ListRes, ModelRes } from "./utils";
import { getResponseData } from "./utils";

const PATH = "/v1/integrations";

export const getIntegrations = (
    params: RequestIntegrations,
): Promise<ListRes<IIntegrationModel>> => {
  return Api.post(`${PATH}/list`, params).then(getResponseData);
};

export const getIntegration = (id: string): Promise<ModelRes<IIntegrationModel>> => {
  return Api.get(`${PATH}/${id}`).then(getResponseData);
};

export const changeIntegration = (data: IIntegrationChange & { clientId: string; id?: string }): Promise<ModelRes<IIntegrationModel>> => {
  let postback = data.postback;
  if (data.type?.value === "postback" && typeof postback === "string" && isJsonString(postback)) {
    postback = JSON.parse(postback);
  }

  const sendingData: IIntegrationChangeRequest = {
    ...data,
    postback,
    answer: data.answer
        ? {
          ...data.answer,
          type: data.answer.type?.value || "",
        }
        : null,
    body: data.body
        ? data.body.map((item) => ({
          ...item,
          type: "body",
          value: item.value?.value || "",
        }))
        : [],
    headers: data.headers
        ? data.headers.map((item) => ({
          ...item,
          type: "header",
          value: item.value?.value || "",
        }))
        : [],
    method: data.method?.value || "",
    redirect:
        data.redirect && data.redirect.type && data.redirect.value
            ? {
              ...data.redirect,
              type: data.redirect.type?.value || "",
            }
            : null,
    status: data.status?.value || "",
    type: data.type?.value || "",
    wrapArray: data.wrapArray || false,
    withoutPlus: data.withoutPlus || false,
    isSheet: data.isSheet || false,
  };

  if (!sendingData.id) delete sendingData.id;

  if (data.id) {
    return Api.post(PATH, {
      ...filterEmpyValues(sendingData, true),
      postback: sendingData.postback,
    }).then(getResponseData);
  } else {
    return Api.post(PATH, sendingData).then(getResponseData);
  }
};

export const createIntegrationToken = (data: ITokenChange & { integrationId: string }) => {
  const sendingData = {
    ...data,
    format: data.format?.value,
    method: data.method?.value,
    parameters: data.parameters.map((parameter) => ({
      ...parameter,
      type: parameter.type?.value,
    })),
    place: data.place?.value,
  };

  return Api.post(`${PATH}/token`, filterEmpyValues(sendingData, true)).then(getResponseData);
};

export const deleteIntegration = (id: string) => {
  return Api.delete(`${PATH}/${id}`);
};

export const createQuickIntegration = ({ clientId, name }: { clientId: string; name: string }) => {
  const data: QuickIntegration = {
    name: `${name} (Sheet)`,
    clientId,
    method: "post",
    type: "main",
    status: "active",
    isSheet: true,
  };
  return Api.post(PATH, data).then(getResponseData);
};
