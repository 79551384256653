import { createAsyncThunk } from "@reduxjs/toolkit";
import * as logsApi from "../../api/logs-api";
import { IFilterLogsRequest, RequestLogs } from "../../models/ILog";
import { LogsState } from "../reducers/logsSlice";

export const fetchLogs = createAsyncThunk("logs/fetchLogs", (_, thunkAPI) => {
  const state: FixMeLater = thunkAPI.getState();
  const logsState: LogsState = state.logsReducer;

  const filter: IFilterLogsRequest  = {
    ...logsState.filter,
    integrationId: logsState.filter.integration?.id || "",
    type: logsState.filter.type?.value || "",
    id: logsState.searchById,
  }

  delete filter.integration;

  const options: RequestLogs = {
    filter,
    page: logsState.pagination.page,
    take: logsState.pagination.take,
  };
  return logsApi.getLogs(options);
});

export const fetchOneLog = createAsyncThunk("logs/fetchOneLog", (id: string) =>
  logsApi.getOneLog(id),
);
