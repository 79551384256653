import React from "react";
import { useAppSelector } from "../../hooks/redux";
import DashboardAdmin from "./Dashboard-admin";
import DashboardAffiliate from "./Dashboard-affiliate";

const DashboardPage = () => {
  const { auth } = useAppSelector((state) => state.authSlice);

  if (auth.user?.roles === 'affiliate') return <DashboardAffiliate/>;
 
  return <DashboardAdmin/>;
};

export default DashboardPage;
