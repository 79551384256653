import React, { Suspense, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/common/Header";
import SideBar from "../../components/common/SideBar";
import Loading from "./Loading";
import { useAppSelector } from "../../hooks/redux";
import Footer from "../../components/common/Footer";

const AdminLayout = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const { auth } = useAppSelector((state) => state.authSlice);

  if (!auth.user) {
    return (
      <div className="absolute top-50per left-50per">
        <Loading />
      </div>
    )
  }

  return (
    <>
      <Header toggleMenuHandle={() => setToggleMenu((prevState) => !prevState)} />
      <div className="d-flex align-items-stretch">
        <SideBar toggleMenu={toggleMenu} />
        <div className="page-holder bg-gray-100">
          <Suspense fallback={<Loading />}>
            <Outlet />
          </Suspense>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default AdminLayout;
