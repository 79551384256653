import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

interface UseModalValue {
  ModalComp: JSX.Element;
  handleCloseModal: VoidFunction;
  handleShowModal: VoidFunction;
}

function useModal(onSubmit: VoidFunction): UseModalValue {
  const [show, setShow] = useState(false);

  const handleCloseModal = () => {
    setShow(false);
  };

  const handleShowModal = () => {
    setShow(true);
  };

  const handleSubmit = () => {
    onSubmit();
    handleCloseModal();
  };

  const ModalComp = (
    <Modal centered show={show} onHide={handleCloseModal}>
      <Modal.Header>Are you sure?</Modal.Header>
      <Modal.Body>
        <Button variant="danger" onClick={handleSubmit}>Yes</Button>
        <Button variant="secondary" onClick={handleCloseModal}>No</Button>
      </Modal.Body>
    </Modal>
  );

  return {
    handleShowModal,
    handleCloseModal,
    ModalComp,
  };
}

export default useModal;
