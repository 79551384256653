import { Link } from "react-router-dom";
import { DictionaryType, IDictionaryWord, typeOptions } from "../../models/IDictionaryWords";
import { formatDateFullTime, formatOption, getColumnNumberIndex } from "../../utils/formats";
import { ColumnDef, PaginationType } from "../common/tables/types";

type IColumn = ColumnDef<IDictionaryWord>;

interface Options {
  pagination: PaginationType;
}

export default function getColumnDefsDictionaryWords(options: Options): IColumn[] {
  const { pagination } = options;

  const numberIndex: IColumn = {
    Cell: ({ row: { index } }: { row: { index: number } }) => {
      return <div>{getColumnNumberIndex(pagination, index)}</div>;
    },
    Header: "№",
    id: "№",
    disableFilters: true,
    disableSortBy: true,
    width: "25px",
  };

  const title: IColumn = {
    Cell: (cell) => (
      <Link className="font-semibold" to={`/dictionary-words/${cell.row.original.id}`}>
        {cell.value}
      </Link>
    ),
    Header: "Title",
    id: "title",
    accessor: "title",
  };

  const type: IColumn = {
    Cell: (cell) => <span>{formatOption<DictionaryType>(cell.value, typeOptions)}</span>,
    Header: "Type",
    id: "type",
    accessor: "type",
  };

  const createdAt: IColumn = {
    Cell: (cell) => <span>{formatDateFullTime(cell.value)}</span>,
    Header: "Created",
    id: "createdAt",
    accessor: "createdAt",
  };

  return [numberIndex, title, type, createdAt];
}
