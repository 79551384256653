import React, { ChangeEvent, memo, useMemo, useState } from "react";
import { FormControl, Button } from "react-bootstrap";
import { TypesOfInput } from "../../../../models/fields/IField";
import {
  Parameter,
  ParameterKeys,
  ClientParameters,
  generalDefaultParameter,
} from "../../../../models/IClients";
import { WeekDays } from "../../../../types/global";
import TimeField from "../TimeField";
import { useField } from "formik";
import Field from "./Field";
import { weekDays } from "../../../../utils/formats";

interface Props {
  name: string;
}

interface ScheduleFieldProps {
  objectName: string;
  dayName: WeekDays;
}

interface GeneralFieldProps {
  onChange: (name: ParameterKeys, value: string) => void;
  values: Parameter;
}

const ScheduleField = (props: ScheduleFieldProps) => {
  const { dayName, objectName } = props;

  return (
    <>
      <p className="text-capitalize m-0">{dayName}</p>
      <div className="row">
        <Field label="" name={`${objectName}.${dayName}.count`} type={TypesOfInput.TEXT} showSec />
        <Field
          label=""
          name={`${objectName}.${dayName}.startTime`}
          type={TypesOfInput.TIMEPICKER}
          showSec
        />
        <Field
          label=""
          name={`${objectName}.${dayName}.endTime`}
          type={TypesOfInput.TIMEPICKER}
          showSec
        />
      </div>
    </>
  );
};

const GeneralField = ({ onChange, values }: GeneralFieldProps) => {
  const handelChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.name as ParameterKeys, e.target.value);
  };

  return (
    <>
      <p className="m-0">General</p>
      <div className="row">
        <div className="col-12 mb-3 col-md-4">
          <FormControl
            name="count"
            onChange={handelChange}
            value={values.count}
            type={TypesOfInput.TEXT}
          />
        </div>
        <div className="col-12 mb-3 col-md-4">
          <TimeField
            onChange={(time: string) => onChange("startTime", time)}
            value={values.startTime}
            showSec
          />
        </div>
        <div className="col-12 mb-3 col-md-4">
          <TimeField
            onChange={(time: string) => onChange("endTime", time)}
            value={values.endTime}
            showSec
          />
        </div>
      </div>
    </>
  );
};

const ScheduleFields = ({ name }: Props) => {
  const [scheduleIsOpen, setScheduleIsOpen] = useState(false);
  const [field, meta, helpers] = useField<ClientParameters>(name);
  const { value, touched, error } = meta;
  const { setValue } = helpers;

  const [general, setGeneral] = useState(generalDefaultParameter);

  const handleChangeGereral = (name: ParameterKeys, scheduleValue: string) => {
    setGeneral({ ...general, [name]: scheduleValue });

    const days = Object.keys(value) as WeekDays[];
    const newParameters: ClientParameters = {} as ClientParameters;
    days.forEach((day: WeekDays) => {
      if (day === 'saturday' || day === 'sunday') {
        newParameters[day] = value[day]
      } else {
        newParameters[day] = { ...general, [name]: scheduleValue };
      }
    });
    setValue(newParameters);
  };

  const days = useMemo(() => {
    const localDays = [...weekDays];
    localDays.push(localDays.shift() as WeekDays)
    return localDays;
  }, [weekDays]);

  return (
    <>
      <p className="m-0">Schedule</p>
      <div className="row">
        <Button
          className="ms-2.5 w-auto mb-3 btn btn-primary"
          variant="primary"
          onClick={() => setScheduleIsOpen((prevState) => !prevState)}
        >
          {!scheduleIsOpen ? "Open" : "Close"} Schedule
        </Button>
      </div>
      {scheduleIsOpen && (
        <>
          <div className="row">
            <div className="col-12">
              <GeneralField onChange={handleChangeGereral} values={general} />
            </div>
          </div>
          <div className="row">
            {days.map((day) => (
              <div className="col-12" key={day}>
                <ScheduleField objectName={name} dayName={day as WeekDays} />
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default memo(ScheduleFields);
