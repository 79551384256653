import React, { useEffect, useState } from "react";
import { useField } from "formik";
import Select, { SingleValue } from "react-select";
import useDebounce from "../../../../hooks/useDebounce";
import { IAsyncSelect, selectStyles } from "../../../../models/fields/ISelect";

const AsyncSelect = <T, Option>(props: IAsyncSelect<T, Option>) => {
  const { async, name } = props;
  const [field, , helpers] = useField(name);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce<string>(search, 500);
  const [wasOpened, setWasOpened] = useState(false);

  useEffect(() => {
    if (!search) return;
    async.fetchCallback({ searchTerm: debouncedSearch, filter: {} });
  }, [debouncedSearch]);

  const { setValue } = helpers;

  const handleFetchOptions = () => {
    setWasOpened(true);
    if (!!async && !wasOpened) {
      async.fetchCallback({});
    }
  };

  return (
    <Select
      {...field}
      {...props}
      isMulti={false}
      onChange={(selectedValue: SingleValue<Option>) => {
        setValue(selectedValue);
        setSearch('');
      }}
      filterOption={() => true}
      onMenuOpen={handleFetchOptions}
      onInputChange={setSearch}
      isLoading={async.isLoading && wasOpened}
      styles={selectStyles}
    />
  );
};

export default AsyncSelect;
