import React, { useEffect, useMemo, useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import Field from "../../components/common/form/formik/Field";
import { toast } from "react-toastify";
import PageTitle from "../../components/common/items/page-title";
import Loading from "../_layouts/Loading";
import { changeAffiliate, fetchOneAffiliate } from "../../store/thunks/affiliates";
import { deleteAffiliate } from "../../api/affiliates-api";
import { fetchList } from "../../store/thunks/users";
import { fetchList as fetchTeamList } from "../../store/thunks/teams";
import { getValuesFromModel } from "../../api/utils";
import {
  createAffiliateFields,
  createAffiliateValidation,
} from "../../components/fields/create-affiliate";
import { emptyValues, IAffiliate, IAffiliateChange, statusOptions } from "../../models/IAffiliates";
import { RejectedWithValueAction } from "../../store/thunks/createAppThunk";
import useModal from "../../hooks/useModal";
import { FetchOptionsParams } from "../../types/global";

const EditAffiliate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentAffiliate } = useAppSelector((state) => state.affiliatesReducer);
  const { id: affiliateId } = useParams();
  const [initialValues, setInitialValues] = useState<IAffiliateChange>(emptyValues);
  const { list } = useAppSelector((state) => state.usersReducer);
  const { list: teamList } = useAppSelector((state) => state.teamsSlice);

  useEffect(() => {
    dispatch(fetchList({}));
    if (!affiliateId) return;
    dispatch(fetchOneAffiliate(affiliateId));
  }, []);

  const handleDeleteAffiliate = () => {
    if (currentAffiliate.data) {
      void deleteAffiliate(currentAffiliate.data.id);
      navigate("/affiliates");
    }
  };

  const { ModalComp, handleShowModal } = useModal(handleDeleteAffiliate);

  useEffect(() => {
    if (!currentAffiliate.loading && currentAffiliate.data) {
      const data = getValuesFromModel<IAffiliateChange>(
        {
          ...currentAffiliate.data,
          isAcceptDuplicate: Boolean(currentAffiliate.data.isAcceptDuplicate),
          isShowRedirect: Boolean(currentAffiliate.data.isShowRedirect),
          isSelf: Boolean(currentAffiliate.data.isSelf),
          status:
            statusOptions.find(
              (option) => Number(option.value) === (currentAffiliate.data as IAffiliate).status,
            ) || null,
        },
        emptyValues,
      );

      setInitialValues(data);
    }
  }, [currentAffiliate, list]);

  const fields = useMemo(
    () =>
      createAffiliateFields({
        asyncUsers: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchList(params)),
          isLoading: list.loading,
        },
        userOptions: list.data,
        asyncTeams: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchTeamList(params)),
          isLoading: teamList.loading,
        },
        teamOptions: teamList.data,
      }),
    [list, teamList],
  );

  const handleSubmit = async (
    values: IAffiliateChange,
    formikHelpers: FormikHelpers<IAffiliateChange>,
  ) => {
    if (!currentAffiliate.data?.id) {
      toast.error("Missing user id!");
      return;
    }

    const result = (await dispatch(
      changeAffiliate({
        ...values,
        id: currentAffiliate.data.id,
      }),
    )) as unknown as RejectedWithValueAction<IAffiliate>;
    if (result.payload) {
      formikHelpers.setErrors(result.payload.errors);
    }
  };

  if (currentAffiliate.loading) {
    return <Loading />;
  }

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <div className="d-flex justify-between">
          <Link to="/affiliates" className="w-auto">
            <Button className="w-auto mb-3" variant="primary">
              Back
            </Button>
          </Link>
          <Button className="w-auto mb-3" variant="danger" onClick={handleShowModal}>
            Delete Affiliate
          </Button>
        </div>
        <PageTitle title={currentAffiliate.data?.name} />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <p className="card-title">Edit Mode</p>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={createAffiliateValidation}
                onSubmit={handleSubmit}
              >
                <Form className="w-100 px-4">
                  <div className="row">
                    {fields.map((field) => (
                      <Field key={field.name} {...field} />
                    ))}
                  </div>
                  <Button
                    type="submit"
                    className="form-control w-25 m-auto d-block"
                    variant="primary"
                  >
                    Save
                  </Button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
      </div>
      {ModalComp}
    </div>
  );
};

export default EditAffiliate;
