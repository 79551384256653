import download from "downloadjs";
import { toast } from "react-toastify";
import { TypeOptions } from "../components/common/items/ColorLabel";
import { PaginationType } from "../components/common/tables/types";
import { IField } from "../models/fields/IField";
import { ObjectWithKeys, WeekDays } from "../types/global";

export const dateFormatter = new Intl.DateTimeFormat("el-GR", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});
const dateFormatterTime = new Intl.DateTimeFormat("zh-CN", {
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
});
const dateFormatterToState = new Intl.DateTimeFormat("zh-CN", {
  year: "2-digit",
  month: "2-digit",
  day: "2-digit",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  hourCycle: "h23"
});
const dateFormatterNormal = new Intl.DateTimeFormat("fr-CA", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});
const dateFormatterToBack = new Intl.DateTimeFormat("sv-SE", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  hourCycle: "h23"
});
const timeFormatter = new Intl.DateTimeFormat("ua", { hour: "numeric", minute: "numeric" });
const timeFormatterWithSeconds = new Intl.DateTimeFormat("ua", {
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
});

export const formatDate = (
  dateOrStr: Date | string | number | null = new Date(),
): string | null => {
  if (dateOrStr === null) return null;

  const date =
    typeof dateOrStr === "string" || typeof dateOrStr === "number"
      ? new Date(dateOrStr)
      : dateOrStr;

  if (!isValidDate(date)) return null;

  return dateFormatter.format(date);
};

export const formatDateToState = (
  dateOrStr: Date | string | number | null = new Date(),
): string | null => {
  if (dateOrStr === null) return null;

  const date =
    typeof dateOrStr === "string" || typeof dateOrStr === "number"
      ? new Date(dateOrStr)
      : dateOrStr;

  if (!isValidDate(date)) return null;

  return dateFormatterToState.format(date);
};

export const formatDateToBack = (
  dateOrStr: Date | string | number | null = new Date(),
): string | null => {
  if (dateOrStr === null) return null;

  const date =
    typeof dateOrStr === "string" || typeof dateOrStr === "number"
      ? new Date(`20${dateOrStr}`)
      : dateOrStr;
  if (!isValidDate(date)) return null;

  return dateFormatterNormal.format(date);
};

export const formatDateAndTimeToBack = (
  dateOrStr: Date | string | number | null = new Date(),
): string | null => {
  if (dateOrStr === null) return null;

  const date =
    typeof dateOrStr === "string" || typeof dateOrStr === "number"
      ? new Date(`20${dateOrStr}`)
      : dateOrStr;
  if (!isValidDate(date)) return null;

  return dateFormatterToBack.format(date);
};

export const formatTime = (time: Date): string => {
  if (!isValidDate(time)) return "";
  return timeFormatter.format(time);
};

export const formatTimeWithSeconds = (time: Date): string => {
  if (!isValidDate(time)) return "";
  return timeFormatterWithSeconds.format(time);
};

export const formatDateAndTime = (dateOrStr: Date | string = new Date()): string => {
  const date = typeof dateOrStr === "string" ? new Date(dateOrStr) : dateOrStr;
  if (!isValidDate(date)) return "";
  return `${dateFormatterToState.format(date)}`;
};

export const formatDateFullTime = (dateOrStr: Date | string = new Date()): string => {
  const date = typeof dateOrStr === "string" ? new Date(dateOrStr) : dateOrStr;
  if (!isValidDate(date)) return "";
  return `${dateFormatter.format(date)} ${dateFormatterTime.format(date)}`;
};

export const formatOption = <T extends string | number>(
  value: T,
  selectOptions: ObjectWithKeys[],
): string | number => {
  const result = selectOptions.find((item) => item.value === value);

  if (result) {
    return result.label;
  }

  return value;
};

export const generateOptions = <V extends string>(keys: V[]): TypeOptions<V>[] => (
  keys.map(key => ({ value: key, label: key.charAt(0).toUpperCase() + key.slice(1) }))
);

export const weekDays: WeekDays[] = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const getCurrentWeekDay = (): WeekDays => {
  const day = new Date().getDay();
  return weekDays[day];
};

export const stringToDate = (str: string | null): Date | null => {
  if (typeof str !== "string") return str;
  const date = new Date(`20${str}`);
  if (!isValidDate(date)) return null;
  return date;
};

const isValidDate = (dateObject: Date) => new Date(dateObject).toString() !== "Invalid Date";

export const stringTimeToDate = (str: string): Date | null => {
  if (typeof str !== "string") return str;
  if (str.length > 10) new Date(str);
  const times: string[] = str.split(":");
  const date = new Date();

  date.setHours(Number(times[0]));
  date.setMinutes(Number(times[1]));
  // date.setSeconds(Number(times[2]));
  date.setSeconds(0);
  if (!isValidDate(date)) return null;
  return date;
};

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const fieldsConstructor = <M>(fieldNames: (keyof M | IField<M>)[]): IField<M>[] => {
  return fieldNames.map((field) => (typeof field === "string" ? { name: field } : field as IField<M>));
};

export const getLocalizationsFromData = (object: ObjectWithKeys, keys: string[]): string[] => {
  const localizationsArray: string[] = [];

  keys.forEach((key) => {
    if (object[key]) {
      localizationsArray.push(...Object.keys(object[key]));
    }
  });
  return Array.from(new Set(localizationsArray));
};

export const handleCopy = (text: string) => {
  navigator.clipboard.writeText(text).then(() => {
    toast.success("Successfully copied");
  });
};

export const handleDownload = (file: Blob, name = "list") => {
  download(file, `${name}.xls`);
};

export const getColumnNumberIndex = (pagination: PaginationType, index: number) => {
  const total = pagination.total ?? 0;
  const currentPage = pagination.currentPage ?? 1;
  return total - pagination.take * (currentPage - 1) - index;
};

export const getPrevWeekFromMonday = (): [Date, Date] => {
  const today = new Date();
  const lastWeekMonday = new Date(today);
  const lastWeekSunday = new Date(today);

  const currentDayOfWeek = today.getDay(); // 0 for Sunday, 1 for Monday, etc.
  const daysUntilLastMonday = currentDayOfWeek + 6; // 6 days ago was last Monday
  const daysUntilLastSunday = currentDayOfWeek; // Last Sunday was today or less

  lastWeekMonday.setDate(today.getDate() - daysUntilLastMonday);
  lastWeekSunday.setDate(today.getDate() - daysUntilLastSunday);

  return [lastWeekMonday, lastWeekSunday];
};

export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const isJsonString = (str: string) => {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
};

// Convert 06/11/2023 to 06/11/23
export const formatDateSrtToBackForm = (date: string) => {
  const arr = date.split("/");
  const year = arr[2].slice(2);
  arr.pop();
  return `${arr.join("/")}/${year}`;
};

export const checkFileSize = (value: FixMeLater) => {
  console.log("value", value);

  if (typeof value === "string") return true;
  if (!value) return false;
  return value.size <= 2000000;
};
