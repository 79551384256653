import { IFilterDictionaryWord } from "../../models/IDictionaryWords";
import { IAllFields, TypesOfInput } from "../../models/fields/IField";
import { createDictionaryWordFields } from "./create-dictionaryWord";

export const dictionaryWordFilters = (): IAllFields<IFilterDictionaryWord>[] => [
  ...createDictionaryWordFields,
  {
    label: "Created",
    name: "createdAt",
    placeholder: "Created",
    type: TypesOfInput.DATEPICKER_RANGE,
  },
];
