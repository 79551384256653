import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { Modal } from "react-bootstrap";
import { ClientsStatisticItem } from "../../../models/IStatistics";

ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
  currClientVal: ClientsStatisticItem[] | null;
  setCurrClientVal: (val: Props["currClientVal"]) => void;
}

const ClientChart = ({ currClientVal, setCurrClientVal }: Props) => {
  if (!currClientVal) return null;

  const filteredData = currClientVal.filter((value) => !!value.count);

  const data = {
    labels: filteredData.map((value) => `${value.status} - ${Number(value.percent).toFixed(1)}%`),
    datasets: [
      {
        label: "# of Votes",
        data: filteredData.map((value) => value.count),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Modal show={!!currClientVal} onHide={() => setCurrClientVal(null)}>
      <Modal.Header closeButton />
      <Modal.Body>
        <Pie data={data} />
      </Modal.Body>
    </Modal>
  );
};

export default ClientChart;
