import { Link } from "react-router-dom";
import { IPlayer } from "../../models/IPlayer";
import { formatDateFullTime } from "../../utils/formats";
import { ColumnDef, PaginationType } from "../common/tables/types";

type IColumn = ColumnDef<IPlayer>;

interface Options {
  pagination: PaginationType;
  handleSelectAll: VoidFunction;
  handleSelectItem: (id: string) => void;
  isSelectedAll: boolean;
}

export default function getColumnDefs(options: Options): IColumn[] {
  const { pagination, isSelectedAll, handleSelectAll, handleSelectItem } = options;

  const numberIndex: IColumn = {
    Cell: ({ row: { index } }: { row: { index: number } }) => {
      return <div>{index + (pagination.from || 1)}</div>;
    },
    Header: "№",
    id: "№",
    disableFilters: true,
    disableSortBy: true,
    width: "25px",
  };

  const offer: IColumn = {
    Cell: (cell) => (
      <Link className="font-semibold" to={`/offers/${cell.value.id}`}>
        {cell.value.title}
      </Link>
    ),
    Header: "Offer",
    id: "offer",
    accessor: "offer",
    width: "65px",
  };

  const affiliate: IColumn = {
    Cell: (cell) => (
      <span>
        {cell.value ? (
          <Link className="font-semibold" to={`/affiliates/${cell.value.id}`}>
            {cell.value.name}
          </Link>
        ) : (
          "No"
        )}
      </span>
    ),
    Header: "Affiliate",
    id: "affiliate",
    accessor: "affiliate",
    width: "40px",
  };

  const hash: IColumn = {
    Cell: (cell) => <span>{cell.value ? cell.value.slice(0, 10) : "No"}</span>,
    Header: "Hash",
    id: "hash",
    accessor: "hash",
    width: "40px",
  };

  const pushId: IColumn = {
    Cell: (cell) => <span>{cell.value ? cell.value.slice(0, 10) : "No"}</span>,
    Header: "Push id",
    id: "pushId",
    accessor: "pushId",
    width: "40px",
  };

  const clickId: IColumn = {
    Cell: (cell) => <span>{cell.value ? cell.value.slice(0, 10) : "No"}</span>,
    Header: "Click id",
    id: "clickId",
    accessor: "clickId",
    width: "40px",
  };

  const locale: IColumn = {
    Header: "Locale",
    id: "locale",
    accessor: "locale",
    width: "40px",
  };

  const device: IColumn = {
    Header: "Device",
    id: "device",
    accessor: "device",
    width: "70px",
  };

  const ip: IColumn = {
    Header: "Ip",
    id: "ip",
    accessor: "ip",
    width: "60px",
  };

  const status: IColumn = {
    Header: "Status",
    id: "status",
    accessor: "status",
    width: "40px",
  };

  const sendTo: IColumn = {
    Cell: (cell) => <span>{formatDateFullTime(cell.value)}</span>,
    Header: "Send To",
    id: "sendTo",
    accessor: "sendTo",
    width: "65px",
  };

  const createdAt: IColumn = {
    Cell: (cell) => <span>{formatDateFullTime(cell.value)}</span>,
    Header: "Created",
    id: "createdAt",
    accessor: "createdAt",
    width: "65px",
  };

  return [
    numberIndex,
    offer,
    affiliate,
    hash,
    pushId,
    clickId,
    locale,
    device,
    ip,
    status,
    sendTo,
    createdAt,
  ];
}
