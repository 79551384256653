import { DependencyList, EffectCallback, useEffect, useState } from "react";

export default function useRefreshPage(
  useEffectCallBack: EffectCallback,
  deps: DependencyList,
  linkId: string,
) {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const item = document.getElementById(linkId);
    if (!item) return;
    item.addEventListener("click", () => setCounter(prev => prev + 1));
  }, []);

  useEffect(useEffectCallBack, [...deps, counter]);
}
