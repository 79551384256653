import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { JSONTree } from "react-json-tree";
import { Link, useLocation, useParams } from "react-router-dom";
import { getLeadLogs } from "../../api/leads-api";
import ColorLabel from "../../components/common/items/ColorLabel";
import PageTitle from "../../components/common/items/page-title";
import { getStatusColor } from "../../components/logs/ColumnDefs";
import { ILog } from "../../models/ILog";
import { formatDateFullTime } from "../../utils/formats";
import Loading from "../_layouts/Loading";

const LeadLogs = () => {
  const { search } = useLocation();
  const { id } = useParams();
  const [logs, setLogs] = useState<ILog[] | null>(null);

  useEffect(() => {
    void fetchLogs();
  }, []);

  const fetchLogs = async () => {
    if (!id) return;
    const data = await getLeadLogs(id);
    setLogs(data.list);
  };

  if (!logs) {
    return <Loading />;
  }

  const theme = {
    base00: "#fff",
  };

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <Link to={`/leads/${id}${search}`} className="w-auto">
          <Button className="w-auto mb-3" variant="primary">
            Back
          </Button>
        </Link>
        <PageTitle title="Logs" />
        <section className="mb-5 row">
          {logs &&
            logs.map((log) => (
              <div key={log.id} className="col-xs-12 col-sm-6 col-md-4 card">
                <div className="card-header">
                  {log.integration && (
                    <h6>
                      {log.integration.name} - {formatDateFullTime(log.createdAt)}
                    </h6>
                  )}
                  Status:{" "}
                  <ColorLabel
                    className="d-inline"
                    label={log.status}
                    isSingleColor
                    color={getStatusColor(log.status)}
                    value={log.status}
                  />
                </div>
                <div className="card-body">
                  <p>Response</p>
                  <JSONTree data={log.response} theme={theme} invertTheme={false} hideRoot={true} />
                  <hr />
                  <p>Request</p>
                  <JSONTree data={log.request} theme={theme} invertTheme={false} hideRoot={true} />
                </div>
              </div>
            ))}
        </section>
      </div>
    </div>
  );
};

export default LeadLogs;
