import { ArrayOfDate } from "../types/global";
import { SystemsList } from "./IStatus";
import { getPrevWeekFromMonday } from "../utils/formats";
import { StatisticRequestType } from "./IStatistics";

export type CreateClientsStatisticsT = {
  cap: number,
  country: string,
  landing: string,
  language: string,
  sentAt: ArrayOfDate,
  timeEnd: string;
  timeStart: string;
  statuses: {
    status: SystemsList,
    percent: number,
  }[];
}

export type ClientsStatusesStatisticT = Record<SystemsList, string>[];

export type DataT<T> = {
  data: T,
}

export type ClientsLeadsStatisticT = {
  id: string,
  country: string,
  language: string,
  landing: string,
  status: SystemsList,
  sentAt: string,
  createdAt: string,
  updatedAt: string,
}

export type ClientsConversationsStatisticT = {
  leads: number,
  deposits: number,
  conversations: number,
}
export type ClientsStatisticFilterT = {
  country: string,
  language: string,
  landing: string,
  sentAt: ArrayOfDate | [Date, Date];
};

export type ClientsStatisticRequestT = {
  type: StatisticRequestType;
  filter: ClientsStatisticFilterT,
}

export const clientFilters: ClientsStatisticFilterT = {
  sentAt: getPrevWeekFromMonday(),
  country: "",
  language: "",
  landing: "",
};

export type DestroyResponseT = {
  deleted: number
}
