import React, { memo, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { IReview } from "../../models/IReview";
import { SettingsTableKey } from "../../models/IUserSettings";
import Loading from "../../pages/_layouts/Loading";
import { reviewsSlice } from "../../store/reducers/reviewsSlice";
import { fetchList } from "../../store/thunks/review";
import Table from "../common/tables/Table";
import getColumnDefs from "./ColumnDefs";

interface Props {
  className?: string;
}

const ReviewsTable = ({ className }: Props) => {
  const dispatch = useAppDispatch();
  const { pagination, list } = useAppSelector((state) => state.reviewsReducer);
  const { setPagination } = reviewsSlice.actions;

  const onChangePagination = (page: number) => {
    dispatch(setPagination({ page }));
    dispatch(fetchList({}));
  };

  const onChangePerPage = (take: number) => {
    dispatch(setPagination({ take }));
    dispatch(fetchList({}));
  };

  const columns = useMemo(
    () =>
      getColumnDefs({
        pagination,
      }),
    [pagination, list],
  );

  if (list.loading) {
    return <Loading />;
  }

  return (
    <Table<IReview>
      pageKey={SettingsTableKey.REVIEWS}
      changePage={onChangePagination}
      changePageSize={onChangePerPage}
      className={className}
      columns={columns}
      containerClass="overflow-auto"
      fixed
      items={list.data}
      pagination={pagination}
    />
  );
};

export default memo(ReviewsTable);
