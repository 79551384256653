import * as Yup from "yup";
import { IAllFields, TypesOfInput } from "../../models/fields/IField";
import { ICountry } from "../../models/ILocalizations";
import { IReviewChange } from "../../models/IReview";
import { checkFileSize } from "../../utils/formats";
import filterFields from "./filters-reviews";

export const createReviewValidation = (localizations: string[]) => {
  type YupObject = {
    [key: string]: Yup.StringSchema<string | undefined, UnknownRecord, string | undefined>;
  };
  const object: YupObject = {};
  localizations.forEach((localization) => {
    const schema = Yup.string().test("check localizations", "Required", (_, context) => {
      return !!Object.values(context.parent).filter(Boolean).length;
    });
    object[localization] = schema;
  });

  return Yup.object<Record<keyof IReviewChange, Yup.AnySchema>>({
    name: Yup.object(object),
    text: Yup.object(object),
    answer: Yup.object(),
    slug: Yup.string().required("Required"),
    rating: Yup.object().typeError("Required"),
    photo: Yup.mixed()
      .test("fileSize", "Required", (value) => {
        if (!value && typeof value !== "string") return false;
        return true;
      })
      .test("fileSize", "The file is too large", checkFileSize),
  });
};

interface Params {
  selectedCountry: ICountry | null;
}

export const createReviewFields = ({ selectedCountry }: Params): IAllFields<IReviewChange>[] => [
  ...filterFields(),
  {
    label: "Photo",
    name: "photo",
    placeholder: "Photo",
    type: TypesOfInput.FILE,
    size: 12,
  },
  {
    label: `Name ${selectedCountry?.flag}`,
    name: "name",
    placeholder: "Name",
    type: TypesOfInput.TEXT,
    prefix: true,
    size: 12,
  },
  {
    label: `Text ${selectedCountry?.flag}`,
    name: "text",
    placeholder: "Text",
    type: TypesOfInput.TEXT,
    prefix: true,
    size: 12,
  },
  {
    label: `Answer ${selectedCountry?.flag}`,
    name: "answer",
    placeholder: "Answer",
    type: TypesOfInput.TEXT,
    prefix: true,
    size: 12,
  },
];
