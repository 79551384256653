import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { PaginationQuery, PaginationType } from "../../components/common/tables/types";
import { emptyFilters, IAffiliate, IFilterAffiliate } from "../../models/IAffiliates";
import { FetchedStoreModel, PAGINATION_TAKE } from "../../types/global";
import {
  changeAffiliate,
  changeStatus,
  fetchAffiliates,
  fetchOneAffiliate,
  fetchShortAffiliates,
} from "../thunks/affiliates";

export interface affiliatesState {
  affiliates: FetchedStoreModel<IAffiliate[]>;
  affiliatesShort: FetchedStoreModel<IAffiliate[]>;
  currentAffiliate: FetchedStoreModel<IAffiliate | null>;
  pagination: PaginationType;
  filter: IFilterAffiliate;
}

const initialState: affiliatesState = {
  affiliates: {
    data: [],
    error: null,
    loading: true,
  },
  affiliatesShort: {
    data: [],
    error: null,
    loading: false,
    wasFetched: false,
  },
  currentAffiliate: {
    data: null,
    error: null,
    loading: true,
  },
  filter: emptyFilters,
  pagination: {
    lastPage: null,
    page: 1,
    take: PAGINATION_TAKE,
    total: null,
  },
};

/* eslint-disable sort-keys */
export const affiliatesSlice = createSlice({
  initialState,
  name: "affiliates",
  reducers: {
    setFilters: (state, action: PayloadAction<IFilterAffiliate>) => {
      state.filter = action.payload;
      state.pagination.page = 1;
    },
    setPagination: (state, action: PayloadAction<PaginationQuery>) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    resetAffiliatesShort: (state) => {
      state.affiliatesShort.wasFetched = false;
      state.affiliatesShort.data = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAffiliates.pending, (state) => {
      state.affiliates.loading = true;
      state.affiliates.error = null;
    });
    builder.addCase(fetchAffiliates.fulfilled, (state, action) => {
      state.affiliates.data = action.payload.list;
      state.affiliates.loading = false;
      state.pagination = { ...state.pagination, ...action.payload.pagination };
    });
    builder.addCase(fetchAffiliates.rejected, (state, action) => {
      state.affiliates.loading = false;
      state.affiliates.error = action.payload as FixMeLater;
    });
    builder.addCase(fetchShortAffiliates.pending, (state) => {
      state.affiliatesShort.loading = true;
      state.affiliatesShort.wasFetched = true;
      state.affiliatesShort.error = null;
    });
    builder.addCase(fetchShortAffiliates.fulfilled, (state, action) => {
      state.affiliatesShort.data = action.payload.list;
      state.affiliatesShort.loading = false;
    });
    builder.addCase(fetchShortAffiliates.rejected, (state, action) => {
      state.affiliatesShort.loading = false;
      state.affiliatesShort.error = action.payload as FixMeLater;
    });
    builder.addCase(fetchOneAffiliate.pending, (state) => {
      state.currentAffiliate.loading = true;
      state.currentAffiliate.error = null;
    });
    builder.addCase(fetchOneAffiliate.fulfilled, (state, action) => {
      state.currentAffiliate.data = action.payload.model;
      state.currentAffiliate.loading = false;
    });
    builder.addCase(fetchOneAffiliate.rejected, (state, action) => {
      state.currentAffiliate.loading = false;
      state.currentAffiliate.error = action.payload as FixMeLater;
    });
    builder.addCase(changeAffiliate.fulfilled, (_, action) => {
      toast.success(`Affiliate ${action.payload.model.name} successfully changed`);
      window.location.pathname = "/affiliates";
    });
    builder.addCase(changeStatus.pending, (state) => {
      state.affiliates.loading = true;
    });
    builder.addCase(changeStatus.fulfilled, (state, action) => {
      state.affiliates.data = state.affiliates.data.map((affiliate) =>
        affiliate.id === action.payload.model.id
          ? { ...affiliate, status: action.payload.model.status }
          : affiliate,
      );
      state.affiliates.loading = false;
      toast.success(`Affiliate ${action.payload.model.name} successfully changed`);
    });
    builder.addCase(changeStatus.rejected, (state) => {
      state.affiliates.loading = false;
    });
  },
});

export default affiliatesSlice.reducer;
