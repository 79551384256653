import { SettingType } from "./ISettings";

export enum SettingsTableKey {
  AFFILIATES = "page:affiliates",
  APPLICATIONS = "page:applications",
  CLIENTS = "page:clients",
  DICTIONARY_WORDS = "page:dictionary-words",
  INTEGRATIONS = "page:integrations",
  LABELS = "page:labels",
  LEADS = "page:leads",
  LOCALIZATIONS = "page:localizations",
  LOGS = "page:logs",
  OFFERS = "page:offers",
  OFFERS_PLAYERS = "page:offers-players",
  OFFERS_PUSHES = "page:offers-pushes",
  OFFERS_STATUSES = "page:offers-statuses",
  REPORTS_CLIENT = "page:reports-clients",
  REPORTS_CLIENTS_LEADS = "page:reports-clients-leads",
  REPORTS_LEADS = "page:reports-leads",
  REVIEWS = "page:reviews",
  SETTINGS = "page:settings",
  STATUSES = "page:statuses",
  DEPOSIT_AMOUNTS = "page:deposit-amounts",
  TAGS = "page:tags",
  TEAMS = "page:teams",
  TELEGRAM_CHATS = "page:telegram-chats",
  TRANSACTIONS = "page:transactions",
  USERS = "page:users",
}

export type TableSettings<Model extends UnknownRecord> = {
  key: SettingsTableKey,
  value: Record<keyof Model, boolean>
}

export type TableSettingsRequest<Model extends UnknownRecord> = TableSettings<Model> & {
  type: SettingType,
}

export type TableSettingsResponse<Model extends UnknownRecord> = TableSettingsRequest<Model> & {
  id: string;
  userId: string;
  deletedAt: string | null;
  updatedAt: string;
  createdAt: string;
}
