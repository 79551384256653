import React from "react";
import { ReactNode } from "react";
import { accessLandingTypeOptions, IAccessLandingCreateForm } from "../../models/IAccessLandings";
import { IFieldBlock, TypesOfInput } from "../../models/fields/IField";
import { LabelColors, ObjectWithKeys } from "../../types/global";
import ColorLabel from "../common/items/ColorLabel";
import { AsyncObject } from "../../models/fields/ISelect";
import { IAffiliate } from "../../models/IAffiliates";

interface Params {
  asyncAffiliates: AsyncObject;
  affiliateOptions: IAffiliate[];
}

const formatOptionLabel = ({ value, label }: ObjectWithKeys): ReactNode => (
  <ColorLabel
    color={value === "allow_type" ? LabelColors.SUCCESS : LabelColors.FAILED}
    isSingleColor
    label={label}
  />
);

export const createAccessLandingFields = ({
  affiliateOptions,
  asyncAffiliates,
}: Params): IFieldBlock<IAccessLandingCreateForm>[] => [
  {
    fields: [
      {
        label: "Name",
        name: "landings.name",
        placeholder: "Name",
        size: 12,
        type: TypesOfInput.TEXT,
      },
      {
        formatOptionLabel,
        label: "Type",
        name: "landings.type",
        options: accessLandingTypeOptions,
        placeholder: "Type",
        size: 6,
        type: TypesOfInput.SELECT,
      },
      {
        async: asyncAffiliates,
        label: "Affiliate",
        labelName: "name",
        name: "landings.affiliate",
        options: affiliateOptions,
        placeholder: "Affiliate",
        size: 6,
        type: TypesOfInput.SELECT,
        valueName: "id",
        formatOptionLabel: (option: IAffiliate) => <span>{option.name} - {option.affiliate}</span>,
      },
    ],
    isList: true,
    label: "",
    name: "landings",
  },
];
