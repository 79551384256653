import React from "react";
import { DashboardGroupCaps } from "../../models/IDashboard";
import { ICountry } from "../../models/ILocalizations";
import countriesJson from "../../countries.json";
import Loading from "../../pages/_layouts/Loading";
import { useNavigate } from "react-router";
import { formatDateToState } from "../../utils/formats";
import { createSearchParams } from "react-router-dom";

interface Props {
  capsData: { isLoading: boolean; isFetching: boolean; data: DashboardGroupCaps | undefined };
  isShowPrice?: boolean;
}

const GroupCapsCard = ({ capsData, isShowPrice }: Props) => {
  const navigate = useNavigate();
  const { data, isLoading, isFetching } = capsData;
  const renderLocalizations = (country: string) => {
    const countries: ICountry[] = countriesJson;
    const flag = countries.find((item) => item.code === country.toLowerCase())?.flag;
    return <span>{flag}</span>;
  };

  const handleRedirect = (localizationIds: string[]) => {
    const params: {
      'sentAt[0]': string,
      'sentAt[1]': string,
      localizationIds?: string
    } = {
      'sentAt[0]': formatDateToState(new Date()) || '',
      'sentAt[1]': formatDateToState(new Date()) || '',
    };
    if (localizationIds.length) params.localizationIds = localizationIds.join(',');
    navigate({
      pathname: '/leads',
      search: createSearchParams(params).toString(),
    });
  };

  if (isLoading || isFetching) {
    return <Loading />;
  }

  if (!data) return null;

  return (
    <div className="mb-4 card">
      <div className="card-header py-3">
        <p className="card-heading">Group Caps</p>
      </div>
      <div className="card-body row">
        {Object.entries(data).map(([key, cap]) => (
          <div key={key} className="col-xs-6 col-md-4 mb-4">
            <div className="card h-100">
              <div
                role="button"
                className="card-header py-4 d-flex justify-content-between btn-link"
                onClick={() => handleRedirect(cap.localizationIds)}
              >
                <h3 className="card-heading">
                  {key}
                  {renderLocalizations(key)}
                </h3>
                <p className="card-heading">
                  {cap.leads} / {cap.total} {isShowPrice && cap.price && `~ ${cap.price}`}
                </p>
              </div>
              <div className="card-body">
                <div className="progress">
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    style={{ width: `${(cap.leads * 100) / cap.total}%` }}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GroupCapsCard;
