import { createAsyncThunk } from "@reduxjs/toolkit";
import * as transactionsApi from "../../api/transactions-api";
import { IFilterTransactionRequest, ITransactionChange } from "../../models/ITransactions";
import { transactionsState } from "../reducers/transactionsSlice";

export const fetchTransactions = createAsyncThunk(
  "transactions/fetchTransactions",
  (_, thunkAPI) => {
    const state: FixMeLater = thunkAPI.getState();
    const transactionsState: transactionsState = state.transactionsReducer;

    const filter: IFilterTransactionRequest = {
      ...transactionsState.filter,
      modelId: transactionsState.filter.model?.id || "",
      clientId: transactionsState.filter.client?.id || "",
      type: transactionsState.filter.type?.value || "",
    };
    delete filter.client;
    delete filter.model;

    const options = {
      filter,
      page: transactionsState.pagination.page,
      take: transactionsState.pagination.take,
    };
    return transactionsApi.getTransactions(options);
  },
);

export const createTransaction = createAsyncThunk(
  "transactions/createTransaction",
  (values: ITransactionChange) => transactionsApi.createTransaction(values),
);
