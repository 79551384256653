import { IAllFields, TypesOfInput } from "../../models/fields/IField";
import { AsyncObject } from "../../models/fields/ISelect";
import { IOffer } from "../../models/IOffer";
import { IPlayerFilters } from "../../models/IPlayer";
import { statusOptions } from "../../models/IPush";
import { fieldsConstructor } from "../../utils/formats";

const filterFielsNames: (keyof IPlayerFilters)[] = ["hash"];

interface Params {
  asyncOffer: AsyncObject;
  offerOptions: IOffer[];
}

export const filterFields = ({
  asyncOffer,
  offerOptions,
}: Params): IAllFields<IPlayerFilters>[] => [
  ...fieldsConstructor<IPlayerFilters>(filterFielsNames),
  {
    name: "offer",
    type: TypesOfInput.SELECT,
    options: offerOptions,
    async: asyncOffer,
    valueName: "id",
    labelName: "title",
  },
  {
    name: "status",
    type: TypesOfInput.SELECT,
    options: statusOptions,
  },
];
