import ColorLabel from "../../../components/common/items/ColorLabel";
import { ColumnDef } from "../../../components/common/tables/types";
import { typeOptions } from "../../../models/IClients";
import { IDepositAmounts } from "../../../models/IDashboard";

type IColumn = ColumnDef<IDepositAmounts>;

export default function getColumnDefs(): IColumn[] {
  const client: IColumn = {
    Header: "Client",
    accessor: "client",
    width: "180px",
    id: "client",
    Footer: () => <span>Total</span>
  };

  const count: IColumn = {
    Header: "Count",
    accessor: "count",
    width: "80px",
    id: "count",
    Footer: (cell) => <span>{cell.rows.reduce((prev, curr) => prev + curr.original.count ?? 0, 0)}</span>
  };

  const price: IColumn = {
    Header: "Price",
    accessor: "price",
    width: "80px",
    id: "price",
    Footer: (cell) => <span>{cell.rows.reduce((prev, curr) => prev + curr.original.price ?? 0, 0)}</span>
  };

  const amount: IColumn = {
    Header: "Amount",
    accessor: "amount",
    width: "80px",
    id: "amount",
    Footer: (cell) => <span>{cell.rows.reduce((prev, curr) => prev + curr.original.amount ?? 0, 0)}</span>
  };

  return [client, count, price, amount];
}
