import * as Yup from "yup";
import { IAllFields, TypesOfInput } from "../../models/fields/IField";
import {
  ClientType,
  IClientChange,
  statusOptions,
  typeOptions,
  UpdateSomeClientsActions,
} from "../../models/IClients";
import { AsyncObject, OptionModel } from "../../models/fields/ISelect";
import { ILabel } from "../../models/ILabels";
import { ITeam } from "../../models/ITeam";
import { TypeOptions } from "../common/items/ColorLabel";

export const editClientValidation = Yup.object<Record<keyof IClientChange, Yup.AnySchema>>({
  id: Yup.string(),
  label: Yup.object().typeError("Required"),
  type: Yup.object().typeError("Required"),
  landings: Yup.array().of(
    Yup.object().shape({
      affiliate: Yup.object().nullable(),
      name: Yup.string().required("Required"),
      type: Yup.object().typeError("Required"),
    }),
  ),
  localizations: Yup.array().of(Yup.object()),
  name: Yup.string().required("Required"),
  parameters: Yup.object().shape({
    friday: Yup.object().shape({
      count: Yup.string().required("Required"),
      endTime: Yup.string().required("Required"),
      startTime: Yup.string().required("Required"),
    }),
    monday: Yup.object().shape({
      count: Yup.string().required("Required"),
      endTime: Yup.string().required("Required"),
      startTime: Yup.string().required("Required"),
    }),
    saturday: Yup.object().shape({
      count: Yup.string().required("Required"),
      endTime: Yup.string().required("Required"),
      startTime: Yup.string().required("Required"),
    }),
    sunday: Yup.object().shape({
      count: Yup.string().required("Required"),
      endTime: Yup.string().required("Required"),
      startTime: Yup.string().required("Required"),
    }),
    thursday: Yup.object().shape({
      count: Yup.string().required("Required"),
      endTime: Yup.string().required("Required"),
      startTime: Yup.string().required("Required"),
    }),
    tuesday: Yup.object().shape({
      count: Yup.string().required("Required"),
      endTime: Yup.string().required("Required"),
      startTime: Yup.string().required("Required"),
    }),
    wednesday: Yup.object().shape({
      count: Yup.string().required("Required"),
      endTime: Yup.string().required("Required"),
      startTime: Yup.string().required("Required"),
    }),
  }),
  price: Yup.number().required("Required"),
  credit: Yup.number(),
  balance: Yup.number(),
  leads: Yup.string(),
  acceptHold: Yup.boolean(),
  status: Yup.boolean(),
  storeReplaceTable: Yup.boolean(),
  teams: Yup.array(),
  percent: Yup.number().when("type", {
    is: (val: OptionModel<TypeOptions<ClientType>>) => val?.value === "crg",
    then: (schema) => schema.required("Required")
  })
});

interface Params {
  asyncLabels: AsyncObject;
  labelOptions: ILabel[];
  asyncTeams: AsyncObject;
  teamOptions: ITeam[];
}

export const editClientFields = ({
  asyncLabels,
  labelOptions,
  asyncTeams,
  teamOptions,
}: Params): IAllFields<IClientChange>[] => [
  {
    name: "id",
    type: TypesOfInput.TEXT,
    disabled: true,
  },
  {
    label: "Name",
    name: "name",
    placeholder: "Name",
    type: TypesOfInput.TEXT,
  },
  {
    label: "Price",
    name: "price",
    placeholder: "Price",
    type: TypesOfInput.TEXT,
  },
  {
    name: "credit",
    disabled: true,
  },
  {
    name: "balance",
    disabled: true,
  },
  {
    name: "leads",
    disabled: true,
  },
  {
    label: "Deposit",
    name: "deposit",
    placeholder: "Deposit",
    type: TypesOfInput.TEXT,
  },
  {
    label: "Localizations",
    name: "localizations",
    type: TypesOfInput.LOCALIZATIONS,
  },
  {
    async: asyncTeams,
    isMulti: true,
    labelName: "name",
    name: "teams",
    options: teamOptions,
    type: TypesOfInput.SELECT,
    valueName: "id",
  },
  {
    label: "Type",
    name: "type",
    placeholder: "Type",
    options: typeOptions,
    type: TypesOfInput.SELECT,
  },
  {
    async: asyncLabels,
    label: "Label",
    labelName: "name",
    name: "label",
    options: labelOptions,
    placeholder: "Label",
    type: TypesOfInput.SELECT,
    valueName: "id",
  },
  {
    label: "Status",
    name: "status",
    placeholder: "Status",
    size: 1,
    type: TypesOfInput.CHECKBOX,
  },
  {
    label: "Acc. Hold",
    name: "acceptHold",
    placeholder: "Acc. Hold",
    size: 1,
    type: TypesOfInput.CHECKBOX,
  },
  {
    label: "Replace Table",
    name: "storeReplaceTable",
    size: 2,
    type: TypesOfInput.CHECKBOX,
  },
];

export const editMassFields = (): IAllFields<UpdateSomeClientsActions>[] => [
  {
    label: "Status",
    name: "status",
    options: statusOptions,
    type: TypesOfInput.SELECT,
  },
];
