import React, { useEffect, useMemo } from "react";
import Icon from "./items/Icon";
import { NavLink } from "react-router-dom";
import { RouteType, sideBarLinks } from "../../App";
import cn from "classnames";
import useGetWindiwSize from "../../hooks/useGetWindowSize";
import { useAppSelector } from "../../hooks/redux";
import { FixTypeLater } from "react-redux";
import { superRoles } from "../../models/IAuth";
import { UserRole } from "../../models/IUsers";

interface Props {
  toggleMenu: boolean;
}

const SideBar = ({ toggleMenu }: Props) => {
  const { atLeastMd } = useGetWindiwSize();
  const { auth } = useAppSelector((state) => state.authSlice);

  useEffect(() => {
    document.querySelectorAll("[data-bs-toggle='collapse']").forEach((item) => {
      const attribute = item.getAttribute("data-bs-target");
      item.addEventListener("click", () => {
        if (attribute) {
          item.toggleAttribute("aria-expanded");
          document.querySelector(attribute)?.classList.toggle("collapse__list--closed");
        }
      });
    });
  }, []);

  const filteredLinksByRole = useMemo(() => {
    if (!auth.user) return sideBarLinks;
    if (!auth.user.roles) return sideBarLinks;
    if (superRoles.includes(auth.user.roles)) return sideBarLinks;
    let links = sideBarLinks.filter((item) => item.roles?.includes(auth.user?.roles || UserRole.ADMIN));

    links = [
      ...links.map((link) =>
        link.pages
          ? {
              ...link,
              pages: link.pages.filter((page) => (page as RouteType).roles?.includes(auth.user?.roles || UserRole.ADMIN)
              ) as RouteType[],
            }
          : link,
      ),
    ] as FixTypeLater;
    return links;
  }, [auth, sideBarLinks]);

  return (
    <div
      className={cn("sidebar pb-3", { "shrink show": toggleMenu, sidebar_mobile: !atLeastMd })}
      id="sidebar"
    >
      <h6 className="sidebar-heading">Main</h6>
      <ul className="list-unstyled">
        {filteredLinksByRole.map((item) => (
          <li key={item.name} className="sidebar-list-item">
            {item.pages ? (
              <>
                <div
                  className="sidebar-link text-muted"
                  data-bs-target={`#${item.name}`}
                  role="button"
                  aria-expanded="false"
                  data-bs-toggle="collapse"
                >
                  <Icon name={item.icon} class="me-3" />
                  <span className="sidebar-link-title">{item.name}</span>
                </div>
                <ul
                  className="sidebar-menu list-unstyled collapse show collapse__list--closed"
                  id={item.name}
                >
                  {item.pages.map((page) => (
                    <li key={page.path} className="sidebar-list-item">
                      <NavLink className="sidebar-link text-muted" id={page.path} to={page.path}>
                        {page.name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <NavLink className="sidebar-link text-muted" id={item.path} to={item.path}>
                <Icon name={item.icon} class="me-3" />
                <span className="sidebar-link-title">{item.name}</span>
              </NavLink>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideBar;
