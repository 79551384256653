import React, { memo, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { ILocalization } from "../../models/ILocalizations";
import { SettingsTableKey } from "../../models/IUserSettings";
import Loading from "../../pages/_layouts/Loading";
import { localizationsSlice } from "../../store/reducers/localizationsSlice";
import { fetchLocalizations } from "../../store/thunks/localizations";
import Table from "../common/tables/Table";
import getColumnDefsLocalizations from "./ColumnDefs";

interface Props {
  className?: string;
}

const LocalizationsTable = ({ className }: Props) => {
  const dispatch = useAppDispatch();
  const { pagination, localizations } = useAppSelector((state) => state.localizationsReducer);
  const { setPagination } = localizationsSlice.actions;

  const onChangePagination = (page: number) => {
    dispatch(setPagination({ page }));
    dispatch(fetchLocalizations({}));
  };

  const onChangePerPage = (take: number) => {
    dispatch(setPagination({ take }));
    dispatch(fetchLocalizations({}));
  };

  const columns = useMemo(
    () =>
      getColumnDefsLocalizations({
        pagination,
      }),
    [pagination, localizations],
  );

  if (localizations.loading) {
    return <Loading />;
  }

  return (
    <Table<ILocalization>
      pageKey={SettingsTableKey.LOCALIZATIONS}
      changePage={onChangePagination}
      changePageSize={onChangePerPage}
      className={className}
      columns={columns}
      containerClass="overflow-auto"
      fixed
      items={localizations.data}
      pagination={pagination}
    />
  );
};

export default memo(LocalizationsTable);
