import * as Yup from "yup";
import { IAllFields, TypesOfInput } from "../../models/fields/IField";
import { AsyncObject, GenericOption, OptionModel } from "../../models/fields/ISelect";
import { IOffer, OfferType, offerTypeOptions } from "../../models/IOffer";
import {
  IPushChange,
  IPushFilters,
  PushStatus,
  PushType,
  statusOptions,
  typeOptions,
} from "../../models/IPush";
import { FilterConditionally } from "../../types/global";
import { fieldsConstructor } from "../../utils/formats";

type ValidationFields = string &
  keyof FilterConditionally<
    IPushChange,
    | string
    | number
    | File
    | null
    | OptionModel<GenericOption<PushStatus>>
    | OptionModel<GenericOption<PushType>>
    | OptionModel<GenericOption<OfferType>>
  >;

export const validationSchema = Yup.object<Record<ValidationFields, Yup.AnySchema>>({
  afterTime: Yup.number()
    .typeError("Must be a number")
    .when("type", {
      is: (type: OptionModel<GenericOption<PushType>>) => type?.value === "regular",
      then: (schema) => schema.typeError("Must be a number").required("Required"),
    }),
  title: Yup.string().required("Required"),
  locale: Yup.string().required("Required"),
  body: Yup.string().required("Required"),
  status: Yup.object().typeError("Required"),
  type: Yup.object().typeError("Required"),
  offerType: Yup.object().typeError("Required"),
});

const filterFielsNames: (keyof IPushChange)[] = ["title", "locale", "body", "afterTime"];

interface Params {
  asyncOffer: AsyncObject;
  offerOptions: IOffer[];
}

export const createFields = ({
  asyncOffer,
  offerOptions,
}: Params): (keyof IPushChange | IAllFields<IPushChange>)[] => [
  ...filterFields(),
  {
    name: "offer",
    type: TypesOfInput.SELECT,
    options: offerOptions,
    async: asyncOffer,
    valueName: "id",
    labelName: "title",
  },
  {
    label: "Start date",
    placeholder: "Start date",
    name: "startDate",
    type: TypesOfInput.DATEPICKER,
    showTime: true,
  },
  {
    name: "icon",
    type: TypesOfInput.FILE,
  },
];

export const filterFields = (): IAllFields<IPushFilters>[] => {
  return [
    ...fieldsConstructor<IPushChange>(filterFielsNames),
    {
      name: "status",
      type: TypesOfInput.SELECT,
      options: statusOptions,
    },
    {
      name: "type",
      type: TypesOfInput.SELECT,
      options: typeOptions,
    },
    {
      label: "Offer type",
      name: "offerType",
      type: TypesOfInput.SELECT,
      options: offerTypeOptions,
    },
  ];
};
