import React, { memo, useCallback, useMemo, useState } from "react";
import cn from 'classnames';
import {
  TabKeysType,
  IStatisticAffiliates,
  LeadCount,
  StatisticReportsFilters,
  IStatisticAffiliatesData,
} from "../../../models/IStatistics";
import { Tab, Tabs } from "react-bootstrap";
import AffiliateDiagramModal from "./AffiliateDiagramModal";
import { SortOrderType } from "../../../types/global";

interface Props {
  data: IStatisticAffiliates;
  filters: StatisticReportsFilters;
}

const StatisticAffiliates = ({ data, filters }: Props) => {
  const [affiliateId, setAffiliateId] = useState<string | null>(null);
  const [tabKey, setTabKey] = useState<TabKeysType>("detail");
  const [sortBy, setSortBy] = useState<string | 'Total' | 'Name'>('Name');
  const [orderBy, setOrderBy] = useState<SortOrderType>('DESC');
  const getTotal = (item: LeadCount) =>
    Object.values(item).reduce((partialSum, a) => partialSum + a, 0);

  const formatByOrderBy = useCallback(
    (a: number, b: number) => orderBy === 'ASC' ? a - b : b - a,
    [orderBy],
  )

  const formatByOrderByForName = useCallback(
    (a: string, b: string) => {
      if (orderBy === 'DESC') {
        if (a < b) return -1;
        if (a > b) return 1;
      } else {
        if (a > b) return -1;
        if (a < b) return 1;
      }
      return 0;
    },
    [orderBy],
  )

  const formatData = useCallback((data: IStatisticAffiliatesData[]) => (
    data
      .map((item) => ({ ...item, total: getTotal(item.leads) }))
      .sort((a, b) => sortBy === 'Total'
        ? formatByOrderBy(a.total ?? 0, b.total ?? 0)
        : sortBy === 'Name' ? formatByOrderByForName(a.affiliate, b.affiliate)
        : formatByOrderBy(a.leads[sortBy] ?? 0, b.leads[sortBy] ?? 0)
      )
  ), [sortBy, formatByOrderBy]);

  const detailData = useMemo(() => formatData(data.detail), [data, formatData]);
  const locationData = useMemo(() => formatData(data.location), [data, formatData]);

  const currData = useMemo(
    () => (tabKey === "detail" ? detailData : locationData),
    [tabKey, detailData, locationData],
  );

  const handleSortBy = (key: string) => {
    if (key === sortBy) {
      if (orderBy === 'DESC') setOrderBy('ASC')
      else setOrderBy('DESC')
    } else {
      setSortBy(key)
      setOrderBy('DESC')
    }
  }

  const getCountries = useCallback(() => {
    const countries = currData.map((item) => Object.keys(item.leads)) || [];
    const countriesSet = new Set<string>(countries.flat());
    return Array.from(countriesSet).sort();
  }, [currData, detailData, locationData, tabKey]);

  const totalSum = useMemo(
    () => data[tabKey].reduce((partialSum, a) => partialSum + getTotal(a.leads), 0),
    [data, tabKey],
  );

  const getLeadsCount = (leads: LeadCount): number[] =>
    getCountries().map((date) => leads[date] || 0);

  const getLeadsTotalByCountry = useCallback(
    (country: string) => data[tabKey].reduce((prev, curr) => prev + (curr.leads[country] || 0), 0),
    [data, tabKey],
  );

  return (
    <div className="mb-4 card">
      <div className="card-header py-3">
        <p className="card-heading">Affiliates</p>
        <Tabs
          id="controlled-tab-example"
          activeKey={tabKey}
          onSelect={(k) => {
            setTabKey(k as TabKeysType)
            setSortBy('Name');
            setOrderBy('DESC')
          }}
          className="mb-3"
        >
          <Tab eventKey="detail" title="Detail" />
          <Tab eventKey="location" title="Location" />
        </Tabs>
      </div>
      <div className="card-body table-responsive">
        <table className="card-text table overflow-auto">
          <thead>
            <tr>
              <th className={cn("c-pointer", { "fw-extra-bold": sortBy === 'Name' })} onClick={() => handleSortBy('Name')}>
                Name
                {sortBy === 'Name' && (orderBy === 'DESC' ? <span>&darr;</span> : <span>&uarr;</span>)}
              </th>
              <th className={cn("c-pointer", { "fw-extra-bold": sortBy === 'Total' })} onClick={() => handleSortBy('Total')}>
                Total
                {sortBy === 'Total' && (orderBy === 'DESC' ? <span>&darr;</span> : <span>&uarr;</span>)}
              </th>
              {getCountries().map((country, index) => (
                <th className={cn("c-pointer", { "fw-extra-bold": sortBy === country })} key={index} onClick={() => handleSortBy(country)}>
                  {country}
                  {sortBy === country && (orderBy === 'DESC' ? <span>&darr;</span> : <span>&uarr;</span>)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currData.map((item, index) => (
              <tr key={index}>
                <th
                  scope="row"
                  className="c-pointer btn-link"
                  onClick={() => setAffiliateId(item.id)}
                >
                  {item.affiliate}
                </th>
                <th>{getTotal(item.leads)}</th>
                {getLeadsCount(item.leads).map((count, index) => (
                  <th key={index}>{count}</th>
                ))}
              </tr>
            ))}
            <tr>
              <th scope="row">Total</th>
              <th>{totalSum}</th>
              {getCountries().map((country, index) => (
                <th key={index}>{getLeadsTotalByCountry(country)}</th>
              ))}
            </tr>
          </tbody>
        </table>
        <AffiliateDiagramModal
          affiliateId={affiliateId}
          setAffiliateId={setAffiliateId}
          filters={filters}
        />
      </div>
    </div>
  );
};

export default memo(StatisticAffiliates);
