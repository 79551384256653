import { IFilterClient, statusOptions, typeOptions } from "../../models/IClients";
import { IAllFields, TypesOfInput } from "../../models/fields/IField";
import { AsyncObject } from "../../models/fields/ISelect";
import { ILabel } from "../../models/ILabels";

interface Params {
  asyncLabels: AsyncObject;
  labelOptions: ILabel[];
}

export const clientFilters = ({
  asyncLabels,
  labelOptions,
}: Params): IAllFields<IFilterClient>[] => [
  {
    label: "Name",
    name: "name",
    placeholder: "Name",
    type: TypesOfInput.TEXT,
  },
  {
    label: "Price",
    name: "price",
    type: TypesOfInput.TEXT,
  },
  {
    label: "Balance",
    name: "balance",
    type: TypesOfInput.TEXT,
  },
  {
    label: "Credit",
    name: "credit",
    type: TypesOfInput.TEXT,
  },
  {
    label: "Created",
    name: "createdAt",
    placeholder: "Created",
    type: TypesOfInput.DATEPICKER_RANGE,
  },
  {
    label: "Status",
    name: "status",
    options: statusOptions,
    placeholder: "Status",
    type: TypesOfInput.SELECT,
  },
  {
    label: "Type",
    name: "type",
    placeholder: "Type",
    options: typeOptions,
    type: TypesOfInput.SELECT,
  },
  {
    async: asyncLabels,
    label: "Label",
    labelName: "name",
    name: "label",
    options: labelOptions,
    placeholder: "Label",
    type: TypesOfInput.SELECT,
    valueName: "id",
  },
];
