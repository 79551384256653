import { IAllFields, TypesOfInput } from "../../models/fields/IField";
import { AsyncObject } from "../../models/fields/ISelect";
import { IIntegrationModel } from "../../models/IIntegration";
import { IFilterLogs, typeOptions } from "../../models/ILog";

interface Params {
  integrationOptions: IIntegrationModel[];
  asyncIntegrations: AsyncObject;
}

export const logsFilters = ({
  asyncIntegrations,
  integrationOptions,
}: Params): IAllFields<IFilterLogs>[] => [
  {
    name: "type",
    options: typeOptions,
    type: TypesOfInput.SELECT,
  },
  {
    name: "status",
    type: TypesOfInput.TEXT,
  },
  {
    label: "Lead Id",
    name: "leadId",
    placeholder: "Lead Id",
    type: TypesOfInput.TEXT,
  },
  {
    async: asyncIntegrations,
    name: "integration",
    options: integrationOptions,
    type: TypesOfInput.SELECT,
    labelName: "name",
    valueName: "id",
  },
];
