import React, { memo, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { ILabel } from "../../models/ILabels";
import { SettingsTableKey } from "../../models/IUserSettings";
import Loading from "../../pages/_layouts/Loading";
import { labelsSlice } from "../../store/reducers/labelsSlice";
import { fetchLabels } from "../../store/thunks/labels";
import Table from "../common/tables/Table";
import getColumnDefs from "./ColumnDefs";

interface Props {
  className?: string;
}

const LabelsTable = ({ className }: Props) => {
  const dispatch = useAppDispatch();
  const { pagination, labels } = useAppSelector((state) => state.labelsReducer);
  const { setPagination } = labelsSlice.actions;

  const onChangePagination = (page: number) => {
    dispatch(setPagination({ page }));
    dispatch(fetchLabels({}));
  };

  const onChangePerPage = (take: number) => {
    dispatch(setPagination({ take }));
    dispatch(fetchLabels({}));
  };

  const columns = useMemo(() => getColumnDefs({ pagination }), [pagination, labels]);

  if (labels.loading) {
    return <Loading />;
  }

  return (
    <Table<ILabel>
      pageKey={SettingsTableKey.LABELS}
      changePage={onChangePagination}
      changePageSize={onChangePerPage}
      className={className}
      columns={columns}
      containerClass="overflow-auto"
      fixed
      items={labels.data}
      pagination={pagination}
    />
  );
};

export default memo(LabelsTable);
