import * as Yup from "yup";
import { IFieldBlock, TypesOfInput } from "../../models/fields/IField";
import {
  formatOptions,
  ITokenChange,
  ITokenChangeForm,
  parameterTokenOptions,
  placeOptions,
  tokenMethodOptions,
} from "../../models/IIntegrationToken";

export const validation = Yup.object<
  Record<keyof Omit<ITokenChange, "integrationId">, Yup.AnySchema>
>({
  answer: Yup.string(),
  extraString: Yup.string(),
  format: Yup.object(),
  key: Yup.string(),
  method: Yup.object(),
  parameters: Yup.array().of(
    Yup.object().shape({
      key: Yup.string(),
      type: Yup.object(),
      value: Yup.string(),
    }),
  ),
  place: Yup.object(),
  url: Yup.string(),
});

export const createTokenFields = (): IFieldBlock<ITokenChangeForm>[] => [
  {
    fields: [
      {
        label: "Url",
        name: "url",
        type: TypesOfInput.TEXT,
      },
      {
        label: "Place",
        name: "place",
        options: placeOptions,
        type: TypesOfInput.SELECT,
      },
      {
        label: "Method",
        name: "method",
        options: tokenMethodOptions,
        type: TypesOfInput.SELECT,
      },
      {
        label: "Key",
        name: "key",
        type: TypesOfInput.TEXT,
      },
      {
        label: "Answer",
        name: "answer",
        type: TypesOfInput.TEXT,
      },
      {
        label: "Extra String",
        name: "extraString",
        type: TypesOfInput.TEXT,
      },
      {
        label: "Format",
        name: "format",
        options: formatOptions,
        type: TypesOfInput.SELECT,
      },
    ],
    title: "Token Info",
  },
  {
    fields: [
      {
        label: "",
        name: "parameters.type",
        options: parameterTokenOptions,
        placeholder: "Parameter type",
        type: TypesOfInput.SELECT,
      },
      {
        label: "",
        name: "parameters.key",
        placeholder: "Parameter key",
        type: TypesOfInput.TEXT,
      },
      {
        label: "",
        name: "parameters.value",
        placeholder: "Parameter value",
        type: TypesOfInput.TEXT,
      },
    ],
    isList: true,
    name: "parameters",
    title: "Parameters",
  },
];
