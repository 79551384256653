import React, { useEffect, useMemo, useState, useRef } from "react";
import { Formik, Form, FormikProps } from "formik";
import { Button } from "react-bootstrap";
import { AnySchema } from "yup";
import Field from "../../components/common/form/formik/Field";
import PageTitle from "../../components/common/items/page-title";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { createFields, getValidation } from "../../components/fields/offers";
import { emptyValues, IOfferChange } from "../../models/IOffer";
import { FetchOptionsParams, ObjectWithKeys } from "../../types/global";
import { changeItem } from "../../store/thunks/offer";
import LanguagesGroup from "../../components/common/form/LanguagesGroup";
import { fetchList as fetchTagsList } from "../../store/thunks/tag";
import { fetchList as fetchReviewsList } from "../../store/thunks/review";
import { fetchList as fetchOffersList } from "../../store/thunks/offer";
import { getOpenedCountriesCodes } from "../../store/reducers/countriesSlice";
import { filterDataByCountries } from "../../api/utils";

const CreateItem = () => {
  const dispatch = useAppDispatch();
  const [initialValues, setInitialValues] = useState<IOfferChange>(emptyValues);
  const [validationSchema, setValidationSchema] = useState<AnySchema | null>(null);
  const formikRef: React.Ref<FormikProps<IOfferChange>> | null = useRef(null);
  const { selectedCountry, openedCountries } = useAppSelector((state) => state.countriesSlice);
  const { list: tagsList } = useAppSelector((state) => state.tagsReducer);
  const { list: reviewsList } = useAppSelector((state) => state.reviewsReducer);
  const { list: offersList } = useAppSelector((state) => state.offersReducer);
  const openedCountriesCodes = useAppSelector(getOpenedCountriesCodes);

  useEffect(() => {
    dispatch(fetchTagsList({}));
    dispatch(fetchReviewsList({}));
    dispatch(fetchOffersList({}));
  }, []);

  useEffect(() => {
    const emptyObject: ObjectWithKeys = {};
    const objectFiles: ObjectWithKeys<File[] | null[]> = {};
    openedCountriesCodes.forEach((item) => {
      emptyObject[item] = "";
      objectFiles[item] = [];
    });
    if (formikRef && formikRef.current) {
      setInitialValues({
        ...formikRef.current.values,
        bonus: {
          ...emptyObject,
          ...filterDataByCountries(formikRef.current.values.bonus, openedCountriesCodes),
        },
        preview: {
          ...emptyObject,
          ...filterDataByCountries(formikRef.current.values.preview, openedCountriesCodes),
        },
        download: {
          ...emptyObject,
          ...filterDataByCountries(formikRef.current.values.download, openedCountriesCodes),
        },
        subtitle: {
          ...emptyObject,
          ...filterDataByCountries(formikRef.current.values.subtitle, openedCountriesCodes),
        },
        description: {
          ...emptyObject,
          ...filterDataByCountries(formikRef.current.values.description, openedCountriesCodes),
        },
        images: {
          ...objectFiles,
          ...filterDataByCountries(formikRef.current.values.images, openedCountriesCodes),
        },
      });
    }
    const validation = getValidation(openedCountriesCodes);
    setValidationSchema(validation);
  }, [openedCountries]);

  const fields = useMemo(
    () =>
      createFields({
        asyncTags: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchTagsList(params)),
          isLoading: tagsList.loading,
        },
        asyncReviews: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchReviewsList(params)),
          isLoading: reviewsList.loading,
        },
        asyncOffers: {
          fetchCallback: (params: FetchOptionsParams) => dispatch(fetchOffersList(params)),
          isLoading: reviewsList.loading,
        },
        tagOptions: tagsList.data,
        reviewOptions: reviewsList.data,
        offerOptions: offersList.data,
        selectedCountry,
      }),
    [tagsList, reviewsList, offersList, selectedCountry],
  );

  const handleSubmit = (values: IOfferChange) => {
    dispatch(changeItem({ data: values, localizations: openedCountries.map((item) => item.code) }));
  };

  return (
    <div className="container-fluid px-lg-4 px-xl-5">
      <div className="row">
        <Link to="/offers" className="w-auto">
          <Button className="w-auto mb-3" variant="primary">
            Back
          </Button>
        </Link>
        <PageTitle title="Creating offer" />
        <section className="mb-5">
          <div className="card">
            <div className="card-body">
              <LanguagesGroup />
              {validationSchema && (
                <Formik
                  innerRef={formikRef}
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
                >
                  <Form className="w-100 px-4">
                    <div className="row">
                      {fields.map((field) => (
                        <Field
                          key={field.name}
                          {...field}
                          prefix={field.prefix === true ? selectedCountry?.code : false}
                        />
                      ))}
                    </div>
                    <Button
                      type="submit"
                      className="form-control w-25 m-auto d-block"
                      variant="primary"
                    >
                      Create
                    </Button>
                  </Form>
                </Formik>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CreateItem;
